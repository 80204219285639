import React, { useContext, useEffect, useState } from "react";
import { Button, Grid, Typography, Container, Paper, Line, useNavigate, Loader } from "@cbmisorg/client-app";
import ShredHeader from "../../sharedUi/header/ShredHeader";
import { AppContext } from "../../../configuration/contextapi/AppContext";
import { FormData, FormField } from "../../../appHelper/formApi/FormAPI";
import { degreeID, employmentStatusID, genderID, globalKey, nationalityID, objUploadType, requirementFlow } from "../../../appHelper/appVariables";
import { dictionary } from "../../../appHelper/appLanguage/dictionary";
import { CtrlJoinRequest } from "./controller/CtrlJoinRequest";
import { appStyle } from "../../../appHelper/appStyle";
import useCheckSystemInfo from "../../../appHelper/appHook/useCheckSystemInfo";
import RequirementInfo from "../../sharedUi/requirementInfo/RequirementInfo";
import Uploader from "../../sharedUi/uploader/Uploader";
import { getNextStrURL } from "../../../appHelper/appFunctions";
import { App_Primary_Color, App_Text_Color } from "../../../appHelper/appColor";

function JoinRequest() {
  useCheckSystemInfo();
  const searchParams = new URL(window.location.href);
  const extLang = searchParams.searchParams.get("lang") || null;
  const navigate = useNavigate();
  const { appState, appDispatch } = useContext(AppContext);
  const appLanguage = extLang || appState?.clientInfo?.strLanguage || "arb";
  const labels = dictionary?.components?.public?.join;
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    listFileGovID: [],
    listFileRestID: [],
  });
  const uploaderGovID = Uploader(
    state,
    setState,
    "listFileGovID",
    dictionary?.components?.manageSystem?.govID,
    { blnIsMultiple: false, blnForceNewName: true, fileType: objUploadType?.["aaiot"].id },
    "Badge"
  );
  const uploaderRest = Uploader(
    state,
    setState,
    "listFileRestID",
    dictionary?.components?.manageSystem?.rest,
    { blnIsMultiple: false, blnForceNewName: true, fileType: objUploadType?.["aaiot"].id },
    "Description"
  );

  const fields = FormData({
    strFullName: {
      strControl: "text",
      objLanguage: false,
      objValidations: { required: true },
    },
    strEmail: {
      strControl: "text",
      objLanguage: false,
      objValidations: { required: true, email: true },
    },
    strPhone: {
      strControl: "text",
      objLanguage: false,
      objValidations: { required: true,onlyNumbers:true, minLength: { condition: 8 }, maxLength: { condition: 8 } },
    },
    dtmDob: {
      strControl: "date",
      objLanguage: false,
      objValidations: { required: true },
    },
    bigNationality: {
      strControl: "list",
      objLanguage: false,
      objValidations: { required: true },
      lstOption: [
        { key: nationalityID?.kuwaiti, value: dictionary?.shared?.account?.country?.kwt?.[appLanguage] },
        { key: nationalityID?.notKuwaiti, value: dictionary?.shared?.account?.country?.notKwt?.[appLanguage] },
      ],
    },
    bigGender: {
      strControl: "list",
      objLanguage: false,
      objValidations: { required: true },
      lstOption: [
        { key: genderID.male, value: dictionary?.shared?.account?.gender?.male?.[appLanguage] },
        { key: genderID.female, value: dictionary?.shared?.account?.gender?.female?.[appLanguage] },
      ],
    },
    bigDegreeID: {
      strControl: "list",
      objLanguage: false,
      objValidations: { required: true },
      lstOption: [
        { key: degreeID.diploma, value: dictionary?.shared?.account?.degreey?.diploma?.[appLanguage] },
        { key: degreeID.bachelor, value: dictionary?.shared?.account?.degreey?.bachelor?.[appLanguage] },
        { key: degreeID.masters, value: dictionary?.shared?.account?.degreey?.masters?.[appLanguage] },
        { key: degreeID.doctorate, value: dictionary?.shared?.account?.degreey?.doctorate?.[appLanguage] },
      ],
    },
    bigMajorID: {
      strControl: "text",
      objLanguage: false,
      objValidations: { required: true },
    },
    bigEmploymentID: {
      strControl: "list",
      objLanguage: false,
      objValidations: { required: true },
      lstOption: [
        { key: employmentStatusID?.professor, value: dictionary?.components?.public?.landingPage?.members?.professor?.[appLanguage] },
        { key: employmentStatusID?.student, value: dictionary?.components?.public?.landingPage?.members?.student?.[appLanguage] },
        { key: employmentStatusID?.govEmployee, value: dictionary?.components?.public?.landingPage?.members?.govEmployee?.[appLanguage] },
        { key: employmentStatusID?.prvtEmployee, value: dictionary?.components?.public?.landingPage?.members?.prvtEmployee?.[appLanguage] },
        { key: employmentStatusID?.notEmployee, value: dictionary?.components?.public?.landingPage?.members?.notEmployee?.[appLanguage] },
      ],
    },
    strWorkPlace: {
      strControl: "text",
      objLanguage: false,
      objValidations: { required: true },
    },
    strUserPassword: {
      strControl: "password",
      objLanguage: false,
      objValidations: { required: true, minLength: { condition: 6 } },
    },
    strConfirmPassword: {
      strControl: "password",
      objLanguage: false,
      objValidations: { required: true, minLength: { condition: 6 }, equal: { condition: "strUserPassword" } },
    },
  });

  const handlers = CtrlJoinRequest(appState, appDispatch, isLoading, setIsLoading, navigate, state, setState);

  function handleSubmit() {
    handlers.addRequested(fields, uploaderGovID, uploaderRest);
  }

  useEffect(() => {
    const accountUrl = getNextStrURL();
    if (appState?.clientInfo?.blnIsUserLoggedIn) {
      navigate(accountUrl, { replace: true });
    }
    if(extLang){
      appState.clientInfo.strLanguage = extLang;
      appState.clientInfo.strDataLanguage = extLang;
      appDispatch()
    }
  }, []);

  return (
    <React.Fragment>
      <ShredHeader title={labels?.title?.[appLanguage]} lang={appLanguage}  blnBottomBar={false}/>
      <Container py-5 pb-xs-10 mb-xs-10 pb-sm-10 mb-sm-10>
        {isLoading ? <Loader backdrop={true} color={App_Primary_Color} /> : null}
        <Grid container justify={"center"} pb-xs-10 mb-xs-10>
          <Grid item xs="12" md="10">
            <Paper p-0 elevation={4} outline={1} sx={{ borderRadius: "8px !important", borderColor: "var(--cbmis-dark)" }} className="cardContainer">
              <Grid container justify={"center"} pt-3>
                <Grid item xs="12">
                  <Typography as="body2" color={App_Text_Color} sx={{ fontWeight: "700 !important", fontFamily: "Times New Roman !important", textAlign: "center !important" }}>
                    {labels?.thank?.[appLanguage]}
                  </Typography>
                </Grid>
              </Grid>
              <Line py-3 />
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography as="body2" sx={appStyle?.title1}>
                    {labels?.personalInfo?.[appLanguage]}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} justify={"center"}>
                <Grid item xs={12}>
                  <FormField objHandler={fields.strFullName} icon="AccountCircle" />
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    objHandler={fields.strPhone}
                    icon={appLanguage === "arb" ? "phone" : null}
                    endIcon={appLanguage !== "arb" ? "phone" : null}
                    endAdornment={appLanguage === "arb" ? globalKey : null}
                    adornment={appLanguage !== "arb" ? globalKey : null}
                    className={`inputLeft`}
                  />
                </Grid>
                <Grid item xs={12} md="4">
                  <FormField objHandler={fields.dtmDob} icon="Cake" dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                </Grid>
                <Grid item xs={12} md="4">
                  <FormField objHandler={fields.bigGender} icon="Wc" dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                </Grid>
                <Grid item xs={12} md="4">
                  <FormField objHandler={fields.bigNationality} icon="public" dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                </Grid>
                <Grid item xs={12} md="6">
                  <FormField objHandler={fields.bigDegreeID} icon="ImportContacts" dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                </Grid>
                <Grid item xs={12} md="6">
                  <FormField objHandler={fields.bigMajorID} icon="School" dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                </Grid>
                <Grid item xs={12} md="6">
                  <FormField objHandler={fields.bigEmploymentID} icon="Work" dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                </Grid>
                <Grid item xs={12} md="6">
                  <FormField objHandler={fields.strWorkPlace} icon="business" dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                </Grid>
              </Grid>
              <Line py-3 />
              {appState?.clientInfo?.objSystemInfo?.jsnSystemConfig?.bigFlowID === requirementFlow.requirementInfoDuringReg && (
                <React.Fragment>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Typography as="body2" sx={appStyle?.title1}>
                        {dictionary?.components?.manageSystem?.requirementTitle?.[appLanguage]}
                      </Typography>
                    </Grid>
                    <RequirementInfo objSystemInfo={appState?.clientInfo?.objSystemInfo} lang={appLanguage} uploaderGovID={uploaderGovID} uploaderRest={uploaderRest} />
                  </Grid>
                  <Line py-3 />
                </React.Fragment>
              )}
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography as="body2" sx={appStyle?.title1}>
                    {labels?.registrationInfo?.[appLanguage]}
                  </Typography>
                </Grid>
                <Grid container spacing={2} justify={"center"}>
                  <Grid item xs={12}>
                    <FormField objHandler={fields.strEmail} icon="email" className={`inputLeft inputLeft${appLanguage}`} />
                  </Grid>
                  <Grid item xs={12} md="6">
                    <FormField objHandler={fields.strUserPassword} icon="vpnKey" className={`inputLeft`} dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                  </Grid>
                  <Grid item xs={12} md="6">
                    <FormField objHandler={fields.strConfirmPassword} icon="vpnKey" className={`inputLeft`} dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justify={"center"}>
                <Grid item xs="12" pt-5 pb-8>
                  <Button py-0 px-10 label={labels?.btnReg?.[appLanguage]} onClick={handleSubmit} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default JoinRequest;
