import React from "react";
import { Grid, Typography,  CardImage, Box } from "@cbmisorg/client-app";
import linkedIn from "../../../assets/images/Linkedin-Logo.png";

const MainMemberCard = ({ memberInfo, lang = "arb" }) => {

  return (
    <React.Fragment>
      <Box sx={{ width: "100%", background:"#fefefe", borderRadius:"12px" }} outline={1} my-6 >
        <img src={memberInfo?.image} alt={memberInfo?.strName?.[lang]} style={{ top: "-40px", zIndex: 1, borderRadius: "12px", width: "75%", height: "275px", margin: "auto", border:"1px solid #aaa" }} />
        <Grid container justify={"center"}>
          <Grid item xs="12" pb-0>
            <Typography as="h6" color="primary">
              {memberInfo?.strName?.[lang]}
            </Typography>
          </Grid>
          <Grid item xs="12">
            <Typography as="body2">{memberInfo?.strPosition?.[lang]}</Typography>
          </Grid>
          <Grid item>
            {memberInfo?.strLinkedin && (
              <a href={memberInfo?.strLinkedin} target="_blank" rel="noreferrer" className="button-10">
                <img src={linkedIn} alt="linkedIn" width="60px" />
              </a>
            )}
            {memberInfo?.blnIsMainFounder && (
              <a href="https://www.sheikhmohammedalsabah.com/" target="_blank" rel="noreferrer" className="button-10">
                {{ eng: "Private Office", arb: "مكتب الشيخ" }?.[lang]}
              </a>
            )}
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default MainMemberCard;
