import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../../configuration/contextapi/AppContext';
import { Container, Grid, Icon,  Paper, Typography, Avatar, Line, useLocation, useNavigate, Button, Modal, ModalHeader, ModalBody, Loader, Note } from '@cbmisorg/client-app';
import { App_Primary_Color } from '../../../../appHelper/appColor';
import { CtrlManageMembers } from './controler/CtrlManageMembers';
import { dictionary } from '../../../../appHelper/appLanguage/dictionary';
import ShredHeader from '../../../sharedUi/header/ShredHeader';
import { degreeID, employmentStatusID, genderID, globalKey, nationalityID,} from '../../../../appHelper/appVariables';
import { FormData } from '../../../../appHelper/formApi/FormAPI';
import UserForm from '../../../sharedUi/userForm/UserForm';

const labels = dictionary?.components?.manageMembers

function RouteViewMember() {
  const { appState } = useContext(AppContext);
  const lang = appState.clientInfo.strLanguage
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const {state} = useLocation()
  const [stateInfo, setStateInfo] = useState({
      blnIsInitDone:false,
      modalOpen:false,
      modalEmailOpen:false,

  })



  const handlers = CtrlManageMembers(appState,isLoading, setIsLoading,stateInfo, setStateInfo,navigate);

  const fields = FormData({
    strFullName: {
      strControl: "text",
      objLanguage: false,
      objValidations: { required: true },
    },
    strEmail: {
        strControl: "text",
        objLanguage: false,
        objValidations: { required: true , email: true},
    },
    strPhone: {
        strControl: "text",
        objLanguage: false,
        objValidations: { required: true , onlyNumbers:true, minLength:{condition:8}, maxLength:{condition:8}},
    },
    dtmDob: {
        strControl: "date",
        objLanguage: false,
        objValidations: { required: true , },
    },
    bigNationality: {
        strControl: "list",
        objLanguage: false,
        objValidations: { required: true },
  lstOption:[
    {key:nationalityID?.kuwaiti, value:dictionary?.shared?.account?.country?.kwt?.[lang]},
    {key:nationalityID?.notKuwaiti, value:dictionary?.shared?.account?.country?.notKwt?.[lang]}, 
  ]
    },
    bigGender: {
        strControl: "list",
        objLanguage: false,
        objValidations: { required: true },
  lstOption:[
    {key:genderID.male, value:dictionary?.shared?.account?.gender?.male?.[lang]},
    {key:genderID.female, value:dictionary?.shared?.account?.gender?.female?.[lang]}, 
  ]
    },
    bigDegreeID: {
        strControl: "list",
        objLanguage: false,
        objValidations: { required: true },
  lstOption: [
    {key:degreeID.diploma, value:dictionary?.shared?.account?.degreey?.diploma?.[lang]},
    {key:degreeID.bachelor,value:dictionary?.shared?.account?.degreey?.bachelor?.[lang]},
    {key:degreeID.masters,value:dictionary?.shared?.account?.degreey?.masters?.[lang]},
    {key:degreeID.doctorate,value:dictionary?.shared?.account?.degreey?.doctorate?.[lang]},
  ]
    },
    strMajor: {
        strControl: "text",
        objLanguage: false,
        objValidations: { required: true },
    },
    bigEmploymentID: {
        strControl: "list",
        objLanguage: false,
        objValidations: { required: true },
  lstOption:[
    {key:employmentStatusID?.professor, value:dictionary?.components?.public?.landingPage?.members?.professor?.[lang]},
    {key:employmentStatusID?.student, value:dictionary?.components?.public?.landingPage?.members?.student?.[lang]},
    {key:employmentStatusID?.govEmployee, value:dictionary?.components?.public?.landingPage?.members?.govEmployee?.[lang]},
    {key:employmentStatusID?.prvtEmployee, value:dictionary?.components?.public?.landingPage?.members?.prvtEmployee?.[lang]},
    {key:employmentStatusID?.notEmployee, value:dictionary?.components?.public?.landingPage?.members?.notEmployee?.[lang]},
  ]
    },
    strWorkPlace: {
        strControl: "text",
        objLanguage: false,
        objValidations: { required: true },
    },
    blnIsActive: {
      strControl: "switch",
      objLanguage: false,
      lstOption:[{key:"0", value:"0"},{key:"1", value:"1"}]
    },
  });

  useEffect(() => {
    if(state?.selected){
      const memmberInfo=state?.selected
      fields?.strFullName?.setValue(memmberInfo?.jsnUserInfo?.strFullName)
      fields?.bigDegreeID?.setValue(memmberInfo?.jsnUserInfo?.bigDegreeID)
      fields?.bigEmploymentID?.setValue(memmberInfo?.jsnUserInfo?.bigEmploymentID)
      fields?.strMajor?.setValue(memmberInfo?.jsnUserInfo?.strMajor)
      fields?.bigNationality?.setValue(memmberInfo?.jsnUserInfo?.bigNationality)
      fields?.bigGender?.setValue(memmberInfo?.jsnUserInfo?.bigGender)
      fields?.strWorkPlace?.setValue(memmberInfo?.jsnUserInfo?.strWorkPlace)
      fields?.dtmDob?.setValue(memmberInfo?.jsnUserInfo?.dtmDob)
      fields?.strEmail?.setValue(memmberInfo?.strUserEmail)
      fields?.strPhone?.setValue(memmberInfo?.strUserPhone?.slice(5))
      fields?.blnIsActive?.setValue(memmberInfo?.blnIsActive?{key:"1", value:"1"}:"0")

    }
  }, [])

  return (
    <React.Fragment>
      <ShredHeader title={labels?.vewtitle?.[lang]} blnBottomBar={false} lang={lang}/>
      <Container dir="lrt" py-3>
      {
        isLoading?<Loader backdrop={true} color={App_Primary_Color}/>:null
      }
        <Grid container justify={"center"}>
         <UserForm 
            stateInfo={stateInfo} 
            setStateInfo={setStateInfo} 
            objSystemInfo={appState?.clientInfo?.objSystemInfo} 
            handlers={handlers} 
            userInfo={state?.selected} 
            lang={lang}
            blnActive={true}
            blnCall={true}
            />

        </Grid>
      </Container>
    </React.Fragment>
  )
}

export default RouteViewMember