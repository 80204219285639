import { ClientDirection, Container,} from '@cbmisorg/client-app';
import React, { useContext, useEffect } from 'react'
import { dictionary } from '../../../../appHelper/appLanguage/dictionary';
import { AppContext } from '../../../../configuration/contextapi/AppContext';
import ShredHeader from '../../../sharedUi/header/ShredHeader';
import MessageOfSheikhMuhammad from '../home/sections/MessageOfSheikhMuhammad';

function RouteMessageOfSheikhMuhammad() {
    const {appState} = useContext(AppContext)
    const lang = appState?.clientInfo?.strLanguage || "arb"
    const labels = dictionary?.components?.public?.landingPage?.home?.messageofSheikhMuhammad

    useEffect(() => {
        ClientDirection.setDirection(lang==="arb"?"rtl":"ltr");
      }, [lang])
  return (
    <React.Fragment>
        <ShredHeader title={labels?.subtitle?.[lang]} lang={lang}/>
        <Container>
            <MessageOfSheikhMuhammad lang={lang} />
        </Container>
    </React.Fragment>
  )
}


export default RouteMessageOfSheikhMuhammad


