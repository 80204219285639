import JSZip from "jszip";
import * as indexDB from "./DatabaseIDB";

/**
 * @deprecated since we can create multi partition in index.js and every operation can handle with multi tables
 */
var strTableName = "tblPartition";

var intFileType = {
  file: 1,
  folder: 2,
};

var dbName = "FileSystemAPI";
var dbVersion = 1;
var parentIndexNames = "parent_idx";
var pathIndexNames = "path_idx";

/**
 *
 * @param {{blnIsInTestMode}} PkgConfig
 */
export function utilPackage(PkgConfig) {
  let path = {
    parentRootPath: "~",
    rootPath: "cbmisFolder:",
    delimiter: "/",
    intFileType: intFileType,
    /**
     *
     * @param  {string[]} paths
     * @returns {string}
     */
    join: (...paths) => {
      try {
        let _path = path.rootPath;

        for (let i = 0; i < paths.length; i++) {
          if (typeof paths[i] !== "string") {
            throw new Error(`TypeError: Path must be a string. Received ${JSON.stringify(paths[i])}`);
          }

          if (String(paths[i]).includes(path.delimiter)) {
            let lstPathSplit = String(paths[i]).split(path.delimiter);
            for (let j = 0; j < lstPathSplit.length; j++) {
              if (
                String(lstPathSplit[j]).includes("\\") ||
                String(lstPathSplit[j]) === "" ||
                String(lstPathSplit[j]) === " " ||
                String(lstPathSplit[j]) === path.rootPath
              ) {
                continue;
              }
              if (i !== paths.length - 1) {
                if (!String(lstPathSplit[j]).includes(".")) {
                  _path += `${path.delimiter}${lstPathSplit[j]}`;
                }
              } else {
                if (j !== lstPathSplit.length - 1) {
                  if (!String(lstPathSplit[j]).includes(".")) {
                    _path += `${path.delimiter}${lstPathSplit[j]}`;
                  }
                } else {
                  _path += `${path.delimiter}${lstPathSplit[j]}`;
                }
              }
            }
          } else {
            if (i !== paths.length - 1) {
              //last path
              if (
                !(
                  String(paths[i]).includes(".") ||
                  String(paths[i]).includes("\\") ||
                  String(paths[i]) === path.rootPath ||
                  String(paths[i]) === "" ||
                  String(paths[i]) === " "
                ) &&
                i !== paths.length - 1
              ) {
                _path += `${path.delimiter}${paths[i]}`;
              }
            } else {
              if (!(String(paths[i]).includes("\\") || String(paths[i]) === path.rootPath || String(paths[i]) === "" || String(paths[i]) === " ")) {
                _path += `${path.delimiter}${paths[i]}`;
              }
            }
          }
        }
        return _path;
      } catch (error) {
        if (PkgConfig.blnIsInTestMode) {
          console.error(error);
        }
      }
    },
    /**
     *
     * @param {string} pathFile
     * @param {string} [ext]
     * @returns {string}
     */
    basename: (pathFile, ext = null) => {
      try {
        let lstPath = String(pathFile).split(path.delimiter);
        let lastIndex = lstPath.pop();
        if (!lastIndex) {
          throw new Error("Wrong path!");
        }
        if (ext) {
          let lstSplitExt = lastIndex.split(ext);
          if (lstSplitExt.length < 2) {
            throw new Error("Couldn't find file extension!");
          }
          return lstSplitExt[0];
        } else {
          return lastIndex;
        }
      } catch (error) {
        if (PkgConfig.blnIsInTestMode) {
          console.error(error);
        }
      }
    },
    /**
     *
     * @param {string} pathFile
     * @returns {string}
     */
    dirname: (pathFile) => {
      try {
        let lstPath = String(pathFile).split(path.delimiter);
        if (lstPath.length < 1) {
          return pathFile;
        }
        if (lstPath.length > 2) {
          let dirPath = lstPath.slice(0, lstPath.length - 1);
          return dirPath.join(path.delimiter);
        }
        return lstPath[0];
      } catch (error) {
        if (PkgConfig.blnIsInTestMode) {
          console.error(error);
        }
      }
    },
    /**
     *
     * @param {string} pathFile
     * @returns {string}
     */
    parentName: (pathFile) => {
      try {
        let lstPath = String(pathFile).split(path.delimiter);
        if (lstPath.length < 1) {
          return pathFile;
        }
        if (lstPath.length > 1) {
          return lstPath[lstPath.length - 2];
        }
        return lstPath[lstPath.length - 1];
      } catch (error) {
        if (PkgConfig.blnIsInTestMode) {
          console.error(error);
        }
      }
    },
    /**
     *
     * @param {string} pathFile
     * @returns {string}
     */
    extname: (pathFile) => {
      try {
        let lstPath = String(pathFile).split(path.delimiter);
        let lastIndex = lstPath.pop();
        if (!lastIndex) {
          throw new Error("Wrong path!");
        }
        let lstSplitExt = lastIndex.split(".");
        if (lstSplitExt.length < 3) {
          throw new Error("Couldn't find file extension!");
        }
        return lstSplitExt[lstSplitExt.length - 1];
      } catch (error) {
        if (PkgConfig.blnIsInTestMode) {
          console.error(error);
        }
      }
    },
    isInclude: (str, cmp) => {
      try {
        if (String(str).includes(cmp)) {
          if (String(str).length === String(cmp).length) {
            return true;
          }

          if (String(str).length > String(cmp).length) {
            let lstSplit = String(str).split(String(cmp));

            if (!lstSplit?.[1] || lstSplit?.[1].length < 1) {
              return true;
            }

            if (lstSplit[1].charAt(0) === "/") {
              return true;
            }
          }

          return false;
        } else {
          return false;
        }
      } catch (error) {
        if (PkgConfig.blnIsInTestMode) {
          console.error(error);
        }
        return false;
      }
    },
  };

  return path;
}

/**
 *
 * @param {{blnIsInTestMode, lstPartitionName: string[]}} PkgConfig
 */
export function fi(PkgConfig) {
  const path = utilPackage(PkgConfig);
  const DatabaseIDB = indexDB.DatabaseIDB(PkgConfig);

  async function getConnection() {
    try {
      let objInitConfigTable = [];

      for (let i = 0; i < PkgConfig.lstPartitionName.length; i++) {
        objInitConfigTable.push({
          strTableName: PkgConfig.lstPartitionName[i],
          blnIsPrimaryKeyAutoIncrement: true,
          strPrimaryKeyName: "id",
          foreignKey: [
            { name: parentIndexNames, sourceKey: "strParentFolder" },
            { name: pathIndexNames, sourceKey: "strAbsolutePath" },
          ],
          funOnCreateDB: (idbObjectStore) => {
            idbObjectStore.add({
              blobContent: null,
              intFileType: intFileType.folder,
              strAbsolutePath: path.rootPath,
              strFileName: path.rootPath,
              strParentFolder: path.parentRootPath,
              payload: null,
            });
          },
        });
      }

      return await DatabaseIDB.connectDB(dbName, dbVersion, objInitConfigTable);
    } catch (error) {
      if (PkgConfig.blnIsInTestMode) {
        console.error(error);
      }
      return false;
    }
  }

  /**
   * @param {File} file
   * @param {string} [relativePath]
   * @param {any} [payload]
   * @param {{strPartitionName:string, setPayloadToParent: boolean}} [config]
   * @returns {Promise<false | {intFileType : number,  strFileName: string, strParentFolder: string, strAbsolutePath: string, payload: any }>}
   */
  async function writeFile(file, relativePath = path.rootPath, payload = null, config = { strPartitionName: null, setPayloadToParent: false }) {
    try {
      if (!(file instanceof File)) {
        throw new Error("Data isn't instance of File, we can't save it!");
      }
      if (String(relativePath).includes(".")) {
        throw new Error("relativePath should be a folder only and cannot be a file!");
      }
      if (!String(file.name).includes(".")) {
        throw new Error("File has wrong extension!");
      }

      const strTablePartitionName = !config?.strPartitionName ? PkgConfig.lstPartitionName?.[0] : config?.strPartitionName;
      const dbConnection = await getConnection();

      if (!dbConnection) {
        throw new Error("Can't get the db connection!");
      }

      let checkedIndex = await checkPathDirectories(dbConnection, relativePath, 0, payload, config?.setPayloadToParent ? true : false, {
        strPartitionName: strTablePartitionName,
        blnIsMikDir: false,
      });

      if (!checkedIndex || !checkedIndex?.aggPath) {
        throw new Error("Error Occurred while checking file path!");
      }

      let isFoundValidName = false;
      let validName = file.name;
      let counter = 0;
      while (!isFoundValidName) {
        counter++;
        let checkFileName = await DatabaseIDB.findOne(dbConnection, strTablePartitionName, path.join(checkedIndex.aggPath, validName), pathIndexNames);
        if (checkFileName) {
          isFoundValidName = false;
          let lstSplitExt = String(file.name).split(".");
          if (lstSplitExt.length < 2) {
            throw new Error("Wrong file extension!");
          }
          validName = `${lstSplitExt[0]}(${String(counter)}).${lstSplitExt[lstSplitExt.length - 1]}`;
        } else {
          isFoundValidName = true;
        }
      }

      let strParentFolder = checkedIndex.aggPath;

      if (!strParentFolder) {
        strParentFolder = path.rootPath;
      }

      let bufferFile = await file.arrayBuffer();
      await DatabaseIDB.create(dbConnection, strTablePartitionName, {
        intFileType: intFileType.file,
        strFileName: validName,
        blobContent: bufferFile,
        strParentFolder: strParentFolder,
        strAbsolutePath: path.join(checkedIndex.aggPath, validName),
        payload: payload,
      });

      return {
        intFileType: intFileType.file,
        strFileName: validName,
        strParentFolder: strParentFolder,
        strAbsolutePath: path.join(checkedIndex.aggPath, validName),
        payload: payload,
      };
    } catch (error) {
      if (PkgConfig.blnIsInTestMode) {
        console.error(error);
      }
      return false;
    }
  }

  /**
   *
   * @param {ArrayBuffer} arrayBuffer
   * @param {string} absolutePath
   * @param {{strPartitionName:string}} [config]
   * @returns {Promise<boolean>}
   */
  async function overwriteFile(arrayBuffer, absolutePath, config = { strPartitionName: null }) {
    try {
      if (!(arrayBuffer instanceof ArrayBuffer)) {
        throw new Error("Data isn't instance of ArrayBuffer, use converts function to make it ArrayBuffer!");
      }

      if (!path.basename(String(absolutePath)).includes(".")) {
        throw new Error("file name should contain extension and cannot be a folder !");
      }

      const strTablePartitionName = !config?.strPartitionName ? PkgConfig.lstPartitionName?.[0] : config?.strPartitionName;
      const dbConnection = await getConnection();

      if (!dbConnection) {
        throw new Error("Can't get the db connection!");
      }

      let fileData = await DatabaseIDB.findOne(dbConnection, strTablePartitionName, absolutePath, pathIndexNames);

      if (!fileData) {
        throw new Error("Cannot located file, be sure to provide connect path!");
      }

      if (String(fileData.intFileType) !== String(intFileType.file)) {
        throw new Error("we cannot overwrite folder data, file only allowed, please check intFileType to be file!");
      }

      let result = await DatabaseIDB.update(dbConnection, strTablePartitionName, { blobContent: arrayBuffer }, fileData.id);

      return result;
    } catch (error) {
      if (PkgConfig.blnIsInTestMode) {
        console.error(error);
      }
      return false;
    }
  }

  /**
   *
   * @param {IDBDatabase} connection
   * @param {string} targetPath
   * @param {number} intSplitIndex
   * @param {any} [payload]
   * @param {boolean} [blnSetPayload]
   * @param {{strPartitionName:string, blnIsMikDir:boolean}} [config]
   * @returns {Promise<false | {dirInfo:object, aggPath:string}>}
   */
  async function checkPathDirectories(
    connection,
    targetPath,
    intSplitIndex = 0,
    payload = null,
    blnSetPayload = false,
    config = { strPartitionName: null, blnIsMikDir: false }
  ) {
    try {
      let strPath = path.join(targetPath);
      let lstPath = String(strPath).split(path.delimiter);
      const strTablePartitionName = !config?.strPartitionName ? PkgConfig.lstPartitionName?.[0] : config?.strPartitionName;
      const dbConnection = connection;

      if (!dbConnection) {
        throw new Error("Can't get the db connection!");
      }

      let aggPath = path.rootPath;
      let dirInfo = {};
      for (let i = 0; i < lstPath.length - intSplitIndex; i++) {
        if (lstPath[i] === path.rootPath || String(lstPath[i]).includes(".") || lstPath[i] === "" || lstPath[i] === " ") {
          continue;
        }
        try {
          aggPath += `${path.delimiter}${String(lstPath[i])}`;

          let result = await DatabaseIDB.findOne(dbConnection, strTablePartitionName, aggPath, pathIndexNames);
          if (!result) {
            let strParentFolder = path.dirname(aggPath);

            if (strParentFolder) {
              let payloadTmp = blnSetPayload ? payload : null;
              if (config?.blnIsMikDir) {
                if (aggPath === strPath) {
                  payloadTmp = payload;
                }
              }
              dirInfo = {
                intFileType: intFileType.folder,
                strFileName: String(path.basename(aggPath)),
                blobContent: null,
                strParentFolder: strParentFolder,
                strAbsolutePath: aggPath,
                payload: payloadTmp,
              };
              await DatabaseIDB.create(dbConnection, strTablePartitionName, {
                intFileType: intFileType.folder,
                strFileName: String(path.basename(aggPath)),
                blobContent: null,
                strParentFolder: strParentFolder,
                strAbsolutePath: aggPath,
                payload: payloadTmp,
              });
            }
          }
        } catch (error) {
          if (PkgConfig.blnIsInTestMode) {
            console.error(error);
          }
        }
      }

      return { aggPath, dirInfo };
    } catch (error) {
      if (PkgConfig.blnIsInTestMode) {
        console.error(error);
      }
      return false;
    }
  }

  /**
   * @param {string} targetPath
   * @param {any} [payload]
   * @param {{strPartitionName:string, setPayloadToParent:boolean}} [config]
   * @returns {Promise<false | {intFileType: number,  strFileName: string, strParentFolder: string, strAbsolutePath: string, payload: any}>}
   */
  async function mkdir(targetPath, payload = null, config = { strPartitionName: null, setPayloadToParent: false }) {
    try {
      if (String(path.basename(targetPath)).includes(".")) {
        throw new Error("Directory cannot have extension!");
      }

      const strTablePartitionName = !config?.strPartitionName ? PkgConfig.lstPartitionName?.[0] : config?.strPartitionName;
      const dbConnection = await getConnection();

      if (!dbConnection) {
        throw new Error("Can't get the db connection!");
      }

      let checkFileIsExist = await DatabaseIDB.findOne(dbConnection, strTablePartitionName, targetPath, pathIndexNames);

      if (checkFileIsExist) {
        throw new Error("Folder already exist!");
      }

      let resultAdd = await checkPathDirectories(dbConnection, targetPath, 0, payload, config?.setPayloadToParent ? true : false, {
        strPartitionName: strTablePartitionName,
        blnIsMikDir: true,
      });

      if (!resultAdd || !resultAdd?.dirInfo) {
        throw new Error("Error occurred while create new directory!");
      }

      return resultAdd.dirInfo;
    } catch (error) {
      if (PkgConfig.blnIsInTestMode) {
        console.error(error);
      }
      return false;
    }
  }

  /**
   * @param {string} targetPath
   * @param {{strPartitionName:string}} [config]
   * @returns {Promise<boolean>}
   */
  async function exists(targetPath, config = { strPartitionName: null }) {
    try {
      const strTablePartitionName = !config?.strPartitionName ? PkgConfig.lstPartitionName?.[0] : config?.strPartitionName;
      const dbConnection = await getConnection();

      if (!dbConnection) {
        throw new Error("Can't get the db connection!");
      }

      let result = await DatabaseIDB.findOne(dbConnection, strTablePartitionName, targetPath, pathIndexNames);
      if (!result) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      if (PkgConfig.blnIsInTestMode) {
        console.error(error);
      }
      return false;
    }
  }

  /**
   * @param {string} targetPath
   * @param {{strPartitionName:string}} [config]
   * @returns {Promise<false | ArrayBuffer>}
   */
  async function read(targetPath, config = { strPartitionName: null }) {
    try {
      const strTablePartitionName = !config?.strPartitionName ? PkgConfig.lstPartitionName?.[0] : config?.strPartitionName;
      const dbConnection = await getConnection();

      if (!dbConnection) {
        throw new Error("Can't get the db connection!");
      }

      let result = await DatabaseIDB.findOne(dbConnection, strTablePartitionName, targetPath, pathIndexNames);
      if (!result) {
        throw new Error("Can't found the file!");
      }

      if (!result.intFileType || !result.blobContent) {
        throw new Error("Can't open file!");
      }

      if (result.intFileType === intFileType.folder) {
        throw new Error("this is folder, we can't read it!");
      }

      return await result.blobContent;
    } catch (error) {
      if (PkgConfig.blnIsInTestMode) {
        console.error(error);
      }
      return false;
    }
  }

  /**
   * @param {string} targetPath
   * @param {{strPartitionName:string}} [config]
   * @returns {Promise<false | File>}
   */
  async function open(targetPath, config = { strPartitionName: null }) {
    try {
      const strTablePartitionName = !config?.strPartitionName ? PkgConfig.lstPartitionName?.[0] : config?.strPartitionName;
      const dbConnection = await getConnection();

      if (!dbConnection) {
        throw new Error("Can't get the db connection!");
      }

      let result = await DatabaseIDB.findOne(dbConnection, strTablePartitionName, targetPath, pathIndexNames);
      if (!result) {
        throw new Error("Can't found the file!");
      }

      if (!result.intFileType || !result.blobContent) {
        throw new Error("Can't open file!");
      }

      if (result.intFileType === intFileType.folder) {
        throw new Error("this is folder, we can't read it!");
      }

      return new File([result.blobContent], result.strFileName);
    } catch (error) {
      if (PkgConfig.blnIsInTestMode) {
        console.error(error);
      }
      return false;
    }
  }

  /**
   *
   * @param {string} targetPath
   * @param {{strPartitionName:string}} [config]
   * @returns {Promise<false | {blobContent: ArrayBuffer, id: number, intFileType: number, strAbsolutePath: string, strFileName: string, strParentFolder: string, payload: any}[]>}
   */
  async function readdir(targetPath, config = { strPartitionName: null }) {
    try {
      const strTablePartitionName = !config?.strPartitionName ? PkgConfig.lstPartitionName?.[0] : config?.strPartitionName;
      const dbConnection = await getConnection();

      if (!dbConnection) {
        throw new Error("Can't get the db connection!");
      }

      if (targetPath !== path.parentRootPath) {
        let isDirExist = await DatabaseIDB.findOne(dbConnection, strTablePartitionName, targetPath, pathIndexNames);
        if (!isDirExist) {
          throw new Error("Can't found directory!");
        }
        if (!isDirExist.intFileType) {
          throw new Error("Can't resolve folder!");
        }
        if (String(isDirExist.intFileType) !== String(intFileType.folder)) {
          throw new Error("must be a folder!");
        }
      }

      let lstChildren = [];

      await DatabaseIDB.cursor(
        dbConnection,
        strTablePartitionName,
        (primaryKey, key, value) => {
          if (path.isInclude(key, targetPath)) {
            lstChildren.push(value);
          }
        },
        parentIndexNames
      );

      return lstChildren;
    } catch (error) {
      if (PkgConfig.blnIsInTestMode) {
        console.error(error);
      }
      return false;
    }
  }

  /**
   *
   * @param {string} targetPath
   * @param {{strPartitionName:string}} [config]
   * @returns {Promise<boolean | {id: number, intFileType: number, strAbsolutePath: string, strFileName: string, strParentFolder: string, payload: any}[]>}
   */
  async function readdirInfo(targetPath, config = { strPartitionName: null }) {
    try {
      const strTablePartitionName = !config?.strPartitionName ? PkgConfig.lstPartitionName?.[0] : config?.strPartitionName;
      const dbConnection = await getConnection();

      if (!dbConnection) {
        throw new Error("Can't get the db connection!");
      }

      if (targetPath !== path.parentRootPath) {
        let isDirExist = await DatabaseIDB.findOne(dbConnection, strTablePartitionName, targetPath, pathIndexNames);

        if (!isDirExist) {
          throw new Error("Can't found directory!");
        }
        if (!isDirExist.intFileType) {
          throw new Error("Can't resolve folder!");
        }
        if (String(isDirExist.intFileType) !== String(intFileType.folder)) {
          throw new Error("must be a folder!");
        }
      }

      let lstChild = [];

      await DatabaseIDB.cursor(
        dbConnection,
        strTablePartitionName,
        (primaryKey, key, value) => {
          if (path.isInclude(key, targetPath)) {
            lstChild.push({
              id: value.id,
              intFileType: value.intFileType,
              strAbsolutePath: value.strAbsolutePath,
              strFileName: value.strFileName,
              strParentFolder: value.strParentFolder,
              payload: value.payload,
            });
          }
        },
        parentIndexNames
      );

      return lstChild;
    } catch (error) {
      if (PkgConfig.blnIsInTestMode) {
        console.error(error);
      }
      return false;
    }
  }

  /**
   *
   * @param {string} targetPath
   * @param {{strPartitionName:string}} [config]
   * @returns {Promise<false | {id: number, intFileType: number, strAbsolutePath: string, strFileName: string, strParentFolder: string, payload: any}[]>}
   */
  async function readdirBaseInfo(targetPath, config = { strPartitionName: null }) {
    try {
      const strTablePartitionName = !config?.strPartitionName ? PkgConfig.lstPartitionName?.[0] : config?.strPartitionName;
      const dbConnection = await getConnection();

      if (!dbConnection) {
        throw new Error("Can't get the db connection!");
      }

      let isDirExist = await DatabaseIDB.findOne(dbConnection, strTablePartitionName, targetPath, pathIndexNames);
      if (!isDirExist) {
        throw new Error("Can't found path!");
      }

      let lstChild = [];
      await DatabaseIDB.cursor(
        dbConnection,
        strTablePartitionName,
        (primaryKey, key, value) => {
          if (String(key) === String(targetPath)) {
            lstChild.push({
              id: value.id,
              intFileType: value.intFileType,
              strAbsolutePath: value.strAbsolutePath,
              strFileName: value.strFileName,
              strParentFolder: value.strParentFolder,
              payload: value.payload,
            });
          }
        },
        parentIndexNames
      );

      return lstChild;
    } catch (error) {
      if (PkgConfig.blnIsInTestMode) {
        console.error(error);
      }
      return false;
    }
  }

  /**
   *
   * @param {string} targetPath
   * @param {{strPartitionName:string}} [config]
   * @returns {Promise<false | {blobContent: ArrayBuffer, id: number, intFileType: number, strAbsolutePath: string, strFileName: string, strParentFolder: string, payload: any}[]>}
   */
  async function readdirBase(targetPath, config = { strPartitionName: null }) {
    try {
      const strTablePartitionName = !config?.strPartitionName ? PkgConfig.lstPartitionName?.[0] : config?.strPartitionName;
      const dbConnection = await getConnection();

      if (!dbConnection) {
        throw new Error("Can't get the db connection!");
      }

      let isDirExist = await DatabaseIDB.findOne(dbConnection, strTablePartitionName, targetPath, pathIndexNames);
      if (!isDirExist) {
        throw new Error("Can't found path!");
      }

      let result = await DatabaseIDB.findAll(dbConnection, strTablePartitionName, targetPath, parentIndexNames);

      return result;
    } catch (error) {
      if (PkgConfig.blnIsInTestMode) {
        console.error(error);
      }
      return false;
    }
  }

  /**
   *
   * @param {string} targetPath
   * @param {{strPartitionName:string}} [config]
   * @returns {Promise<false | {blobContent: ArrayBuffer, id: number, intFileType: number, strAbsolutePath: string, strFileName: string, strParentFolder: string, payload: any}[]>}
   */
  async function readPath(targetPath, config = { strPartitionName: null }) {
    try {
      const strTablePartitionName = !config?.strPartitionName ? PkgConfig.lstPartitionName?.[0] : config?.strPartitionName;
      const dbConnection = await getConnection();

      if (!dbConnection) {
        throw new Error("Can't get the db connection!");
      }

      let isDirExist = await DatabaseIDB.findOne(dbConnection, strTablePartitionName, targetPath, pathIndexNames);
      if (!isDirExist) {
        throw new Error("Can't found path!");
      }

      let result = await DatabaseIDB.findAll(dbConnection, strTablePartitionName, targetPath, pathIndexNames);

      return result;
    } catch (error) {
      if (PkgConfig.blnIsInTestMode) {
        console.error(error);
      }
      return false;
    }
  }

  /**
   *
   * @param {string} targetPath
   * @param {any} [payload]
   * @param {{strPartitionName:string}} [config]
   * @returns {Promise<boolean>}
   */
  async function updatePayload(targetPath, payload, config = { strPartitionName: null }) {
    try {
      const strTablePartitionName = !config?.strPartitionName ? PkgConfig.lstPartitionName?.[0] : config?.strPartitionName;
      const dbConnection = await getConnection();

      if (!dbConnection) {
        throw new Error("Can't get the db connection!");
      }

      let pathResult = await DatabaseIDB.findOne(dbConnection, strTablePartitionName, targetPath, pathIndexNames);
      if (!pathResult) {
        throw new Error("Can't found path!");
      }

      pathResult.payload = payload;

      let result = await DatabaseIDB.update(dbConnection, strTablePartitionName, pathResult, pathResult.id);

      return result;
    } catch (error) {
      if (PkgConfig.blnIsInTestMode) {
        console.error(error);
      }
      return false;
    }
  }

  /**
   *
   * @param {string} src
   * @param {string} dest
   * @param {{strPartitionName:string}} [config]
   * @returns {Promise<boolean>}
   */
  async function copyFile(src, dest, config = { strPartitionName: null }) {
    try {
      let lstSrcFile = await readPath(src, config);

      if (!lstSrcFile || lstSrcFile?.length < 1) {
        return false;
      }

      let objSrcFile = lstSrcFile?.[0];

      let result = await writeFile(new File([objSrcFile.blobContent], path.basename(src)), dest, objSrcFile.payload, config);

      if (!result) {
        return false;
      }

      return true;
    } catch (error) {
      if (PkgConfig.blnIsInTestMode) {
        console.error(error);
      }
      return false;
    }
  }

  /**
   *
   * @param {string} targetPath
   * @param {{strPartitionName:string}} [config]
   * @returns {Promise<boolean>}
   */
  async function rm(targetPath, config = { strPartitionName: null }) {
    try {
      const strTablePartitionName = !config?.strPartitionName ? PkgConfig.lstPartitionName?.[0] : config?.strPartitionName;
      const dbConnection = await getConnection();

      if (!dbConnection) {
        throw new Error("Can't get the db connection!");
      }

      let result = await DatabaseIDB.findOne(dbConnection, strTablePartitionName, targetPath, pathIndexNames);
      if (!result) {
        throw new Error("Can't found target path!");
      }
      if (!result.intFileType) {
        throw new Error("Can't resolve file\\folder!");
      }

      if (result.intFileType === intFileType.folder) {
        let lstChildren = [];
        await DatabaseIDB.cursor(
          dbConnection,
          strTablePartitionName,
          (primaryKey, key, value) => {
            if (path.isInclude(key, targetPath) && String(path.parentRootPath) !== String(key)) {
              lstChildren.push(primaryKey);
            }
          },
          parentIndexNames
        );

        for (let i = 0; i < lstChildren.length; i++) {
          await DatabaseIDB.destroy(dbConnection, strTablePartitionName, lstChildren[i]);
        }

        if (String(result.strParentFolder) !== String(path.parentRootPath) && String(result.strFileName) !== String(path.rootPath)) {
          await DatabaseIDB.destroy(dbConnection, strTablePartitionName, result.id);
        }
      } else {
        if (!result.id) {
          throw new Error("Can't located file!");
        }

        await DatabaseIDB.destroy(dbConnection, strTablePartitionName, result.id);
      }
      return true;
    } catch (error) {
      if (PkgConfig.blnIsInTestMode) {
        console.error(error);
      }
      return false;
    }
  }

  /**
   *
   * @param {string} oldPath
   * @param {string} newPath
   * @param {{strPartitionName:string}} [config]
   * @returns {Promise<boolean>}
   */
  async function rename(oldPath, newPath, config = { strPartitionName: null }) {
    try {
      if (path.dirname(oldPath) !== path.dirname(newPath)) {
        throw new Error("dirname doesn't matched!");
      }

      const strTablePartitionName = !config?.strPartitionName ? PkgConfig.lstPartitionName?.[0] : config?.strPartitionName;
      const dbConnection = await getConnection();

      if (!dbConnection) {
        throw new Error("Can't get the db connection!");
      }

      let newPathResult = await DatabaseIDB.findOne(dbConnection, strTablePartitionName, newPath, pathIndexNames);
      if (newPathResult) {
        throw new Error("newPath already exist!");
      }

      let oldPathResult = await DatabaseIDB.findOne(dbConnection, strTablePartitionName, oldPath, pathIndexNames);

      if (!oldPathResult) {
        throw new Error("Can't found old path!");
      }

      if (!oldPathResult.intFileType) {
        throw new Error("Can't resolve file\\folder!");
      }

      if (oldPathResult.intFileType === intFileType.folder && path.basename(newPath).includes(".")) {
        throw new Error("New folder name can't includes extension!");
      }

      if (oldPathResult.intFileType === intFileType.file && !path.basename(newPath).includes(".")) {
        throw new Error("New file must includes extension!");
      }

      if (oldPathResult.intFileType === intFileType.folder) {
        let lstChildren = [];
        await DatabaseIDB.cursor(
          dbConnection,
          strTablePartitionName,
          (primaryKey, key, value) => {
            if (path.isInclude(key, oldPath)) {
              lstChildren.push({ id: primaryKey, strParentFolder: value.strParentFolder, strAbsolutePath: value.strAbsolutePath });
            }
          },
          parentIndexNames
        );

        for (let i = 0; i < lstChildren.length; i++) {
          await DatabaseIDB.update(
            dbConnection,
            strTablePartitionName,
            {
              strParentFolder: String(lstChildren[i].strParentFolder).replace(oldPath, newPath),
              strAbsolutePath: String(lstChildren[i].strAbsolutePath).replace(oldPath, newPath),
            },
            lstChildren[i].id
          );
        }

        await DatabaseIDB.update(dbConnection, strTablePartitionName, { strFileName: path.basename(newPath), strAbsolutePath: newPath }, oldPathResult.id);
      } else {
        if (!oldPathResult.id) {
          throw new Error("Can't located old file!");
        }

        await DatabaseIDB.update(dbConnection, strTablePartitionName, { strFileName: path.basename(newPath), strAbsolutePath: newPath }, oldPathResult.id);
      }

      return true;
    } catch (error) {
      if (PkgConfig.blnIsInTestMode) {
        console.error(error);
      }
      return false;
    }
  }

  /**
   *
   * @param {string} targetPath
   * @param {{strPartitionName:string}} [config]
   * @returns {Promise<boolean>}
   */
  async function downloadFile(targetPath, config = { strPartitionName: null }) {
    try {
      let result = await open(targetPath, config);
      if (!result) {
        return false;
      }

      const element = document.createElement("a");
      const file = result;
      element.href = URL.createObjectURL(file);
      element.download = file.name;
      document.body.appendChild(element);
      element.click();

      return true;
    } catch (error) {
      if (PkgConfig.blnIsInTestMode) {
        console.error(error);
      }
      return false;
    }
  }

  /**
   *
   * @param {string} targetPath
   * @param {{strPartitionName:string, strDownloadName:string}} [config]
   * @returns {Promise<boolean>}
   */
  async function downloadFolder(targetPath, config = { strPartitionName: null, strDownloadName: null }) {
    try {
      let result = await readdir(targetPath, config);
      if (!result) {
        return false;
      }

      let zip = new JSZip();
      for (let i = 0; i < result.length; i++) {
        let strFolderPath = !String(result[i].strAbsolutePath).split(path.rootPath + "/")?.[1]
          ? path.rootPath
          : String(result[i].strAbsolutePath).split(path.rootPath + "/")?.[1];
        let strFilePath = !String(result[i].strAbsolutePath).split(path.rootPath + "/")?.[1]
          ? path.rootPath
          : String(result[i].strAbsolutePath).split(path.rootPath + "/")[1];
        if (result[i].intFileType === intFileType.folder) {
          //zip.folder(String(result[i].strFileName).split(path.rootPath + "/")[1]);
          zip.folder(strFolderPath);
        } else {
          zip.file(strFilePath, result[i].blobContent);
        }
      }

      let zipFolder = await zip.generateAsync({ type: "blob" });

      const element = document.createElement("a");
      const dtm = new Date()
        .toLocaleString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "2-digit",
          hour: "2-digit",
          hour12: true,
          hourCycle: "h12",
        })
        .replaceAll("/", ".")
        .replace(", ", "-")
        .replaceAll(" ", "");
      let strFolderName = !String(targetPath).split(path.rootPath + "/")?.[1]
        ? `${config?.strDownloadName ? config?.strDownloadName : "root"}-${dtm}`
        : String(targetPath).split(path.rootPath + "/")?.[1] + "-" + dtm;

      let newFileZipped = await convertBlobToFile(zipFolder, strFolderName + ".zip");
      if (!newFileZipped) {
        throw new Error("Can't create new zipped folder");
      }
      element.href = URL.createObjectURL(newFileZipped);
      element.download = strFolderName + ".zip";
      document.body.appendChild(element);
      element.click();

      return true;
    } catch (error) {
      if (PkgConfig.blnIsInTestMode) {
        console.error(error);
      }
      return false;
    }
  }

  /**
   *
   * @param {File} file
   * @returns {Promise<boolean>}
   */
  async function downloadFileExternal(file) {
    try {
      const element = document.createElement("a");
      element.href = URL.createObjectURL(file);
      element.download = file.name;
      document.body.appendChild(element);
      element.click();

      return true;
    } catch (error) {
      if (PkgConfig.blnIsInTestMode) {
        console.error(error);
      }
      return false;
    }
  }

  /**
   *
   * @param {{strAbsolutePath: string, blobContent: ArrayBuffer , intFileType: number}[]} lstFilesInfo
   * @param {{strRootPath: string, strDownloadName:string}} [config]
   * @returns {Promise<boolean>}
   */
  async function downloadFolderExternal(lstFilesInfo, config = { strRootPath: null, strDownloadName: null }) {
    try {
      let zip = new JSZip();
      let targetPath = !config?.strRootPath ? "folder" : config?.strRootPath;
      for (let i = 0; i < lstFilesInfo.length; i++) {
        if (i === 0 && !config?.strRootPath) {
          targetPath = lstFilesInfo[i].strAbsolutePath;
        }
        let strFolderPath = !String(lstFilesInfo[i].strAbsolutePath).split(path.rootPath + "/")?.[1]
          ? path.rootPath
          : String(lstFilesInfo[i].strAbsolutePath).split(path.rootPath + "/")?.[1];
        let strFilePath = !String(lstFilesInfo[i].strAbsolutePath).split(path.rootPath + "/")?.[1]
          ? path.rootPath
          : String(lstFilesInfo[i].strAbsolutePath).split(path.rootPath + "/")[1];
        if (lstFilesInfo[i].intFileType === intFileType.folder) {
          //zip.folder(String(lstFilesInfo[i].strFileName).split(path.rootPath + "/")[1]);
          zip.folder(strFolderPath);
        } else {
          zip.file(strFilePath, lstFilesInfo[i].blobContent);
        }
      }

      let zipFolder = await zip.generateAsync({ type: "blob" });

      const element = document.createElement("a");
      const dtm = new Date()
        .toLocaleString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "2-digit",
          hour: "2-digit",
          hour12: true,
          hourCycle: "h12",
        })
        .replaceAll("/", ".")
        .replace(", ", "-")
        .replaceAll(" ", "");
      let strFolderName = !String(targetPath).split(path.rootPath + "/")?.[1]
        ? `${config?.strDownloadName ? config?.strDownloadName : "root"}-${dtm}`
        : String(targetPath).split(path.rootPath + "/")?.[1] + "-" + dtm;

      let newFileZipped = await convertBlobToFile(zipFolder, strFolderName + ".zip");
      if (!newFileZipped) {
        throw new Error("Can't create new zipped folder");
      }
      element.href = URL.createObjectURL(newFileZipped);
      element.download = strFolderName + ".zip";
      document.body.appendChild(element);
      element.click();

      return true;
    } catch (error) {
      if (PkgConfig.blnIsInTestMode) {
        console.error(error);
      }
      return false;
    }
  }

  /**
   *
   * @param {Blob} blob
   * @returns {false | File}
   */
  function convertBlobToFile(blob, fileName = "newFile") {
    try {
      return new File([blob], fileName);
    } catch (error) {
      if (PkgConfig.blnIsInTestMode) {
        console.error(error);
      }
      return false;
    }
  }

  /**
   *
   * @param {ArrayBuffer} arrayBuffer
   * @returns {false | File}
   */
  function convertArrayBufferToFile(arrayBuffer) {
    try {
      return new File([arrayBuffer], "newFile");
    } catch (error) {
      if (PkgConfig.blnIsInTestMode) {
        console.error(error);
      }
      return false;
    }
  }

  /**
   *
   * @param {string} contentText
   * @returns {Promise<false | File>}
   */
  async function createFileFromText(contentText, fileName) {
    try {
      const fileContent = contentText;

      const blob = await new Blob([fileContent], { type: "text/plain" });
      return await new File([blob], fileName);
    } catch (error) {
      if (PkgConfig.blnIsInTestMode) {
        console.error(error);
      }
      return false;
    }
  }

  /**
   *
   * @param {File} file
   * @returns {Promise<false | string>}
   */
  async function convertFileToString(file) {
    try {
      return new TextDecoder().decode(await file.arrayBuffer());
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  /**
   *
   * @param {ArrayBuffer} arrayBuffer
   * @returns {false | string}
   */
  function convertArrayBufferToString(arrayBuffer) {
    try {
      return new TextDecoder().decode(arrayBuffer);
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  /**
   *
   * @param {Blob} blob
   * @returns {Promise<false | string>}
   */
  async function convertBlobToString(blob) {
    try {
      return new TextDecoder().decode(await blob.arrayBuffer());
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  /**
   *
   * @param {string} str
   * @returns  {Promise<false | ArrayBuffer>}
   */
  async function convertStringToArrayBuffer(str) {
    try {
      return new TextEncoder().encode(str);
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  return {
    writeFile: writeFile,
    mkdir: mkdir,
    exists: exists,
    read: read,
    open: open,
    readdir: readdir,
    readdirInfo: readdirInfo,
    readdirBase: readdirBase,
    readdirBaseInfo: readdirBaseInfo,
    readPath: readPath,
    copyFile: copyFile,
    overwriteFile: overwriteFile,
    rm: rm,
    rename: rename,
    updatePayload: updatePayload,
    downloadFile: downloadFile,
    downloadFolder: downloadFolder,
    downloadFileExternal: downloadFileExternal,
    downloadFolderExternal: downloadFolderExternal,
    convertBlobToFile: convertBlobToFile,
    convertArrayBufferToFile: convertArrayBufferToFile,
    createFileFromText: createFileFromText,
    convertFileToString: convertFileToString,
    convertArrayBufferToString: convertArrayBufferToString,
    convertBlobToString: convertBlobToString,
    convertStringToArrayBuffer: convertStringToArrayBuffer,
  };
}
