import { Alert, ClientFunction } from "@cbmisorg/client-app";
import * as appFunctions from "../../../../appHelper/appFunctions";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import objAppRouting from "../../../../appHelper/routing/appRouting";
import { generateQueries } from "../../../../appHelper/appQuery/GenerateQuery";
import { App_ID, aaiot_System_ID, globalKeyZero, objUserRole } from "../../../../appHelper/appVariables";

const strPageInfo = "@src/components/public/login/controller/CtrlLogin.js";
const tblUser = generateQueries("tblUser");

export const CtrlJoinTrainee = (appState, appDispatch) => {
  const lang = appState?.clientInfo?.strDataLanguage || appState?.clientInfo?.strLanguage;
  // const jsnLoginConfig = objSystemInfo?.jsnLoginConfig;

  return {
    initData:
      ({ isLoading, setIsLoading, state, setState, fields, getNewCatInfo }) =>
      async () => {
        try {
          if (isLoading) {
            //Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning");
            return;
          }

          setIsLoading(true);
          state.blnIsInitDone = true;
          const catFromHook = await getNewCatInfo();
          const objCat = catFromHook || appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);

          let lstCountryOption = [];
          let objCountryOptionLang = {};

          let lstDegreeOption = [];
          let objDegreeOptionLang = {};

          let lstEmploymentOption = [];
          let objEmploymentOptionLang = {};

          let lstMajorOption = [];
          let objMajorOptionLang = {};

          let lstAcademicYearOption = [];
          let objAcademicYearOptionLang = {};

          let lstGPAOption = [];
          let objGPAOptionLang = {};

          let lstGenderOption = [];
          let objGenderOptionLang = {};

          Object.entries(objCat?.Root?.country || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstCountryOption.push({ key: value?.id, value: value?.id });
              objCountryOptionLang[value?.id] = value?.value;
            }
          });
          Object.entries(objCat?.Root?.degree || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstDegreeOption.push({ key: value?.id, value: value?.id });
              objDegreeOptionLang[value?.id] = value?.value;
            }
          });
          Object.entries(objCat?.Root?.employment || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstEmploymentOption.push({ key: value?.id, value: value?.id });
              objEmploymentOptionLang[value?.id] = value?.value;
            }
          });
          Object.entries(objCat?.Root?.major || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstMajorOption.push({ key: value?.id, value: value?.id });
              objMajorOptionLang[value?.id] = value?.value;
            }
          });
          Object.entries(objCat?.Root?.academicYear || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstAcademicYearOption.push({ key: value?.id, value: value?.id });
              objAcademicYearOptionLang[value?.id] = value?.value;
            }
          });
          Object.entries(objCat?.Root?.gpa || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstGPAOption.push({ key: value?.id, value: value?.id });
              objGPAOptionLang[value?.id] = value?.value;
            }
          });
          Object.entries(objCat?.Root?.gender || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstGenderOption.push({ key: value?.id, value: value?.id });
              objGenderOptionLang[value?.id] = value?.value;
            }
          });

          fields.bigMajorID.setOptions(lstMajorOption, objMajorOptionLang);
          fields.bigDegreeID.setOptions(lstDegreeOption, objDegreeOptionLang);
          fields.bigGender.setOptions(lstGenderOption, objGenderOptionLang);
          fields.bigEmploymentID.setOptions(lstEmploymentOption, objEmploymentOptionLang);
          fields.bigCountryOfCitizenID.setOptions(lstCountryOption, objCountryOptionLang);
          fields.bigCountryOfResidenceID.setOptions(lstCountryOption, objCountryOptionLang);
          fields.bigAcademicYearID.setOptions(lstAcademicYearOption, objAcademicYearOptionLang);
          fields.bigGpaID.setOptions(lstGPAOption, objGPAOptionLang);

          setIsLoading(false);
          setState({ ...state });
        } catch (error) {
          setIsLoading(false);
          appFunctions.logMessage(strPageInfo, error, "routeMngRequestedJoinCtrl-Error");
        }
      },
    onChangeCountry:
      ({ fields }) =>
      (event) => {
        const bigCountryID = event?.target?.id;

        const objCat = appFunctions.getCategoryCache();

        let lstUniversityOption = [];
        let objUniversityOptionLang = {};

        Object.entries(objCat?.Root?.country?.[appFunctions.appCategoryGetKey(objCat?.Root?.country, bigCountryID)]?.university || {}).forEach(([key, value]) => {
          if (key !== "id" && key !== "value") {
            lstUniversityOption.push({ key: value?.id, value: value?.id });
            objUniversityOptionLang[value?.id] = value?.value;
          }
        });

        lstUniversityOption.push({ key: "other", value: "other" });
        objUniversityOptionLang["other"] = dictionary.shared.btn.otherBtn;
        
        fields.strUniversityName.setOptions(lstUniversityOption, objUniversityOptionLang);
      },

    addJoinTraining:
      ({ fields, isLoading, setIsLoading, navigate, location, blnIsStartUp }) =>
      async () => {
        try {
          if (!fields.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
            return;
          }
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
            return;
          }
          setIsLoading(true);

          let bigID = ClientFunction.generateID(10);

          const result = await tblUser(strPageInfo, appState, "aaiotItcUserAdd", {
            objectInput: {
              bigAppID: App_ID,
              bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID || aaiot_System_ID,
              bigUserID: bigID,

              strUserEmail: fields?.strUserEmail?.getValue(),
              strUserPhone: globalKeyZero + fields?.strUserPhone?.getValue(),
              strUserPassword: fields?.strUserPassword?.getValue(),

              jsnUserInfo: {
                strFullName: { eng: fields?.strFullName?.getValue(), arb: fields?.strFullName?.getValue() },
                bigCountryOfCitizenID: fields?.bigCountryOfCitizenID?.getValue()?.key,
                bigCountryOfResidenceID: fields?.bigCountryOfResidenceID?.getValue()?.key,
                strMajor: fields?.bigMajorID?.getValue()?.key,
                bigEmploymentID: fields?.bigEmploymentID?.getValue()?.key,
                bigDegreeID: fields?.bigDegreeID?.getValue()?.key,
                bigGender: fields?.bigGender?.getValue()?.key,
                bigAcademicYearID: fields?.bigAcademicYearID?.getValue()?.key,
                strUniversityName: fields?.strUniversityName?.getValue()?.key,
                bigGpaID: fields?.bigGpaID?.getValue()?.key,

                intUserRoleFK: objUserRole?.Student,
              },
              blnIsStudent: blnIsStartUp ? false : true,
              blnIsStartUp: blnIsStartUp ? true : false,

              lstStatisticFelid:blnIsStartUp? ["intCountJoinEntrepreneur", "intCountJoinEntrepreneurAll"]:  ["intCountJoinStudent", "intCountJoinStudentAll"],
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            if(dictionary?.shared?.alertFetchNote?.[result?.response]){
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.[result?.response]?.[lang], "warning");

            }else{
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning");
            }
            setIsLoading(false);
            return;
          }
          setIsLoading(false);
          fields.resetField();
          appState.clientInfo.blnIsUserLoggedIn = true;
          appState.userInfo = result.response;
          appDispatch();
          navigate(objAppRouting?.Account?.url, { replace: true });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "addCategory-ERROR", error);
          setIsLoading(false);
        }
      },
  };
};
