"use client";
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Container,
  Grid,
  Line,
  Loader,
  Modal,
  ModalBody,
  ModalHeader,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionTab,
  AccordionDetails,
} from "@cbmisorg/client-app";
import { FormData, FormField } from "../../../../appHelper/formApi/FormAPI";
import { App_Dark_Color, App_Primary_Color, App_Dark_orange, App_Second_Color, App_Dark_blue, App_orang_color } from "../../../../appHelper/appColor";
import { appLangOrientation, dictionary } from "../../../../appHelper/appLanguage/dictionary";
import mngAnnouncementCtrl from "./mngAnnouncement.controller";
import Uploader from "../../../sharedUi/uploader/Uploader";
import { AppContext } from "../../../../configuration/contextapi/AppContext";
import * as appVariables from "../../../../appHelper/appVariables";
import useCheckCat from "../../../../appHelper/appHook/useCheckCat";
import ShredHeader from "../../../sharedUi/header/ShredHeader";
import { getDateUSFormat } from "../../../../appHelper/appFunctions";

const objAnnouncementType = {
  all: "All",
  meeting: "Meeting",
  notification: "Notification",
};

export default function MngAnnouncementView({ blnIsViewLiveSession = false }) {
  useCheckCat();

  const { appState, appDispatch } = useContext(AppContext);
  const lang = appState?.clientInfo?.strLanguage;
  const labels = blnIsViewLiveSession ? dictionary.components.mngAnnouncement?.session : dictionary.components.mngAnnouncement?.Announcement;

  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    lstAnnouncement: [],
    bigSystemID: null,
    blnIsFetchDone: false,
    blnOpenDialog: false,
    blnIsAdd: true,
    intEditIndex: 0,
    lstFile: [],

    intCounterID: 0,
  });

  const fldSort = FormData({
    strType: {
      strControl: "list",
      lstOption: Object.values(objAnnouncementType).map((item) => ({ key: item, value: item })),
      value: blnIsViewLiveSession
        ? { key: objAnnouncementType.meeting, value: objAnnouncementType.meeting }
        : { key: objAnnouncementType.notification, value: objAnnouncementType.notification },
    },
  });
  const fields = FormData({
    strAnnouncementTitle: { strControl: "text", objValidations: { required: true } },
    strAnnouncementBody: { strControl: "textarea" },
    blnIsMeeting: {
      strControl: "checkbox",
      lstOption: [{ key: "true", value: "true" }],
      value: blnIsViewLiveSession ? [{ key: "true", value: "true" }] : [],
      objOptionLang: dictionary.formapi.blnIsMeeting.options,
    },
    strMeetingLink: { strControl: "text", objLanguage: false },
    blnSpecificCountry: { strControl: "checkbox", lstOption: [{ key: "true", value: "true" }], objOptionLang: dictionary.formapi.blnSpecificCountry.options },
    lstCountryID: { strControl: "multiplelist" },
    blnSpecificUniversity: { strControl: "checkbox", lstOption: [{ key: "true", value: "true" }], objOptionLang: dictionary.formapi.blnSpecificUniversity.options },
    bigCountryID: { strControl: "list" },
    lstUniversityID: { strControl: "multiplelist" },
    blnSpecificAcademicYear: { strControl: "checkbox", lstOption: [{ key: "true", value: "true" }], objOptionLang: dictionary.formapi.blnSpecificAcademicYear.options },
    intAcademicYear: { strControl: "list" },
    blnWithTimeLimit: {
      strControl: "checkbox",
      lstOption: [{ key: "true", value: "true" }],
      value: blnIsViewLiveSession ? [{ key: "true", value: "true" }] : [],
      objOptionLang: dictionary.formapi.blnWithTimeLimit.options,
    },
    dtmTo: { strControl: "date", objLanguage: false },
    dtmFrom: { strControl: "date", objLanguage: false },
    strTime: { strControl: "text" },

    blnSpecificRole: { strControl: "checkbox", lstOption: [{ key: "true", value: "true" }], objOptionLang: dictionary.formapi.blnSpecificRole.options },
    lstUserRole: { strControl: "multiplelist" },
  });

  const upAssets = Uploader(state, setState, "lstFile", labels?.label?.uploadAttachFiles?.[lang], {
    fileType: appVariables.objUploadType.announcement.id,
    blnIsMultiple: true,
  });
  const handlers = mngAnnouncementCtrl({ appState, appDispatch });

  const getAnnouncementFromDB = handlers.getAnnouncementFromDB({ state, setState, isLoading, setIsLoading, fields });
  const addNewAnnouncement = handlers.addNewAnnouncement({ state, setState, isLoading, setIsLoading, fields, upAssets: upAssets.uploadHandler });
  const editExistingAnnouncement = handlers.editExistingAnnouncement({ state, setState, isLoading, setIsLoading, fields, upAssets: upAssets.uploadHandler });
  const deleteAnnouncement = handlers.deleteAnnouncement({ state, setState, isLoading, setIsLoading });
  const openModal = handlers.openModal({ state, setState, fields, blnIsViewLiveSession });
  const closeModal = handlers.closeModal({ state, setState });
  const changeUniversityOption = handlers.changeUniversityOption({ fields });

  useEffect(() => {
    if (!state.blnIsFetchDone) {
      getAnnouncementFromDB();
    }
  }, []);

  return (
    <>
      <ShredHeader title={labels?.title?.[lang]} lang={lang} blnBottomBar={false} />

      <Container>
        {!isLoading ? null : <Loader color={App_Dark_Color} backdrop={true} />}

        <Grid container justify="space-between">
          <Grid item alignItems="center">
            <Button label={dictionary.shared.btn.addBtn?.[lang]} onClick={openModal(true, 0, {})} />
          </Grid>
        </Grid>

        <Grid container justify="center">
          <Grid item xs="12">
            {state.lstAnnouncement?.map((objMessage, intIndex) => {
              if (intIndex === 0) {
                state.intCounterID = 0;
              }
              if (fldSort.strType.getValue()?.key !== objAnnouncementType.all) {
                if (fldSort.strType.getValue()?.key === objAnnouncementType.meeting && !objMessage?.blnIsMeeting) {
                  return null;
                } else if (fldSort.strType.getValue()?.key === objAnnouncementType.notification && objMessage?.blnIsMeeting) {
                  return null;
                }
              }

              ++state.intCounterID;

              return (
                <Accordion dir={lang === "arb" ? "rtl" : "ltr"} key={intIndex}>
                  <AccordionTab sx={{ border: `1px solid var(--cbmis-primary)` }}>
                    <AccordionSummary>
                      <Grid container>
                        {!objMessage?.blnWithTimeLimit ? null : (
                          <Grid item xs="12">
                            <Typography as="body2" color={App_orang_color}>
                              {objMessage.strTime?.[lang]
                                ? objMessage.strTime?.[lang]
                                : `${getDateUSFormat(objMessage?.dtmFrom)} - ${getDateUSFormat(objMessage?.dtmTo)}`}
                            </Typography>
                          </Grid>
                        )}
                        <Grid item xs="12">
                          <Typography as="body2">{objMessage.strAnnouncementTitle?.[lang]}</Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container justify={"center"}>
                        <Grid item xs="12">
                          <Typography as="body2">{objMessage.strAnnouncementBody?.[lang]}</Typography>
                        </Grid>

                        <Grid xs="12" container justify="center" spacing={3}>
                          {!objMessage?.blnIsMeeting ? null : (
                            <Grid item>
                              <Button icon="liveTv" size="sm" color={App_Primary_Color} onClick={() => window.open(objMessage?.strMeetingLink, "_blank")} />
                            </Grid>
                          )}
                          <Grid item>
                            <Button icon="edit" size="sm" color={App_Dark_blue} onClick={openModal(false, intIndex, objMessage)} />
                          </Grid>
                          <Grid item>
                            <Button icon="delete" size="sm" color={App_Dark_orange} onClick={deleteAnnouncement(intIndex)} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </AccordionTab>
                </Accordion>
              );
            })}

            {state.lstAnnouncement?.length ? null : (
              <Grid container justify="center" sx={{ height: "30vh", alignContent: "center" }}>
                <Grid item>
                  <Typography as="subtitle2">{labels.label.noAnnouncement?.[lang]}</Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Modal size="xl" open={state.blnOpenDialog} eventClose={closeModal}>
          <ModalHeader>
            <Typography as="subtitle2" className="textcenter">
              {state.blnIsAdd ? labels.label.addAnnouncement?.[lang] : labels.label.updateAnnouncement?.[lang]}
            </Typography>
          </ModalHeader>
          <ModalBody>
            <Grid container>
              <Grid item xs={12} sx={{ [`border${appLangOrientation?.[lang]}`]: `4px solid ${App_Primary_Color}` }} my-2>
                <Typography as="subtitle2" sx={{ color: App_Primary_Color }}>
                  {labels.label.announcementInfo?.[lang]}
                </Typography>
              </Grid>

              <Grid item xs={12} my-1>
                <FormField objHandler={fields.strAnnouncementTitle} iconColor={App_Second_Color} icon="subject" dir={lang === "arb" ? "rtl" : "ltr"} />
              </Grid>

              <Grid item xs={12} my-1>
                <FormField objHandler={fields.strAnnouncementBody} iconColor={App_Second_Color} icon="documentScannerRounded" dir={lang === "arb" ? "rtl" : "ltr"} />
              </Grid>

              {/* <Grid item xs={12} md={6} mt-2>
                <FormField objHandler={fields.blnIsMeeting} iconColor={App_Second_Color} />
              </Grid> */}

              {!fields.blnIsMeeting.getValue()?.length ? null : (
                <>
                  <Grid item xs={12} mt-2>
                    <FormField objHandler={fields.strMeetingLink} iconColor={App_Second_Color} icon="link" inputDir="ltr" />
                  </Grid>
                </>
              )}

              {fields.blnIsMeeting.getValue()?.length ? null : (
                <Grid item xs={12}>
                  <FormField iconColor={App_Second_Color} objHandler={fields.blnWithTimeLimit} />
                </Grid>
              )}
              {!fields.blnWithTimeLimit.getValue()?.length && !fields.blnIsMeeting.getValue()?.length ? null : (
                <>
                  <Grid item xs={12} md={6}>
                    <FormField objHandler={fields.dtmFrom} type="datetime-local" iconColor={App_Second_Color} />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    {/* <input onFocus={} */}
                    <FormField objHandler={fields.dtmTo} type="datetime-local" iconColor={App_Second_Color} />
                  </Grid>

                  <Grid item xs={12}>
                    <FormField objHandler={fields.strTime} iconColor={App_Second_Color} dir={lang === "arb" ? "rtl" : "ltr"} />
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <Grid container justify="center">
                  <Grid item>{upAssets.JSX}</Grid>
                </Grid>
              </Grid>

              <Line my-4 sx={{ width: "100%" }} />

              <Grid item xs={12} sx={{ [`border${appLangOrientation?.[lang]}`]: `4px solid ${App_Primary_Color}` }} mb-2>
                <Typography as="subtitle2" sx={{ color: App_Primary_Color }}>
                  {labels.label.targetGroup?.[lang]}
                </Typography>
              </Grid>

              <Grid item xs={12} mt-4>
                <FormField objHandler={fields.blnSpecificCountry} iconColor={App_Second_Color} dir={lang === "arb" ? "rtl" : "ltr"} />
              </Grid>
              {!fields.blnSpecificCountry.getValue()?.length ? null : (
                <Grid item xs={12}>
                  <FormField objHandler={fields.lstCountryID} iconColor={App_Second_Color} icon="LocationOn" dir={lang === "arb" ? "rtl" : "ltr"} />
                </Grid>
              )}

              <Grid item xs={12} mt-4>
                <FormField objHandler={fields.blnSpecificUniversity} iconColor={App_Second_Color} dir={lang === "arb" ? "rtl" : "ltr"} />
              </Grid>
              {!fields.blnSpecificUniversity.getValue()?.length ? null : (
                <>
                  <Grid item xs={12} md={6}>
                    <FormField
                      objHandler={fields.bigCountryID}
                      iconColor={App_Second_Color}
                      icon="notListedLocation"
                      onChange={changeUniversityOption}
                      dir={lang === "arb" ? "rtl" : "ltr"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormField objHandler={fields.lstUniversityID} iconColor={App_Second_Color} icon="business" />
                  </Grid>
                </>
              )}

              <Grid item xs={12} mt-4>
                <FormField objHandler={fields.blnSpecificAcademicYear} iconColor={App_Second_Color} dir={lang === "arb" ? "rtl" : "ltr"} />
              </Grid>
              {!fields.blnSpecificAcademicYear.getValue()?.length ? null : (
                <Grid item xs={12}>
                  <FormField objHandler={fields.intAcademicYear} iconColor={App_Second_Color} icon="schedule" dir={lang === "arb" ? "rtl" : "ltr"} />
                </Grid>
              )}

              <Grid item xs={12} mt-4>
                <FormField objHandler={fields.blnSpecificRole} iconColor={App_Second_Color} dir={lang === "arb" ? "rtl" : "ltr"} />
              </Grid>
              {!fields.blnSpecificRole.getValue()?.length ? null : (
                <Grid item xs={12}>
                  <FormField objHandler={fields.lstUserRole} iconColor={App_Second_Color} icon="personPinCircle" dir={lang === "arb" ? "rtl" : "ltr"} />
                </Grid>
              )}

              <Grid item xs={12} my-5 justify="center">
                <Button
                  label={state.blnIsAdd ? dictionary.shared.btn.addBtn?.[lang] : dictionary.shared.btn.editBtn?.[lang]}
                  onClick={state.blnIsAdd ? addNewAnnouncement : editExistingAnnouncement}
                />
              </Grid>
            </Grid>
          </ModalBody>
        </Modal>
      </Container>
    </>
  );
}
