import React, { useContext, useRef, useState } from "react";
import * as appVariables from "../../../appHelper/appVariables";
import * as appFunctions from "../../../appHelper/appFunctions";
import { Alert, Grid, Icon, Modal, ModalBody, ModalHeader, Paper, Typography } from "@cbmisorg/client-app";
import { ShowIcon, iconGenerate } from "@cbmisorg/indexdb-api";
import { App_Dark_orange, App_Second_Color, App_Dark_yellow, App_Light_Bluewood, App_Beige_Color4, App_Primary_Color } from "../../../appHelper/appColor";
import { fetchFile } from "../../../appHelper/appData/appFetch";
import { AppContext } from "../../../configuration/contextapi/AppContext";
import { dictionary } from "../../../appHelper/appLanguage/dictionary";
const strPageInfo = "@src/components/sharedUi/uploader/Uploader.js";

let objValidationExtension = {
  IMAGE: ["ai ", "apng", "avif", "bmp", "gif", "ico", "jpg", "jpeg", "jfif", "pjpeg", "pjp", "png", "svg", "tif", "webp", "jpe", "jif", "jfi", "tiff", "psd", "ico"],
  VIDEO: ["264", "avi", "f4a", "f4b", "f4p", "f4v", "flv", "gifv", "h264", "m2t", "m2ts", "m2v", "m4p", "m4v", "mkv", "mod", "mov", "mp2", "mp4", "mpe", "mpeg", "mpg", "mpv", "mts", "ogg", "ogv", "prproj", "qt", "swf", "tp", "vob", "webm", "wmv"],
  AUDIO: ["mp3", "aac", "ogg", "oga", "mogg", "flac", "alac", "wav", "aiff", "dsd", "pcm", "wma", "m4a"],
  DOCUMENT: ["abw", "djvu", "doc", "docm", "docx", "dot", "dotm", "dotx", "epub", "ibooks", "jw", "md", "odt", "opx", "ott", "pages", "pdf", "pmd", "pot", "potx", "pps", "ppsx", "ppt", "pptm", "pptx", "prn", "prproj", "ps", "pub", "pwi", "rtf", "sdd", "sdw", "sldm", "sxw", "txt", "word", "wp5", "wpd", "wps", "xps"],
  SPREADSHEET: ["csv", "csvs", "cwk", "dat", "dbf", "dex", "dif", "numbers", "ods", "ots", "prn", "sdc", "slk", "sxc", "tsv", "xla", "xlam", "xll", "xlm", "xlsb", "xls", "xlsm", "xlsx", "xltm", "xltx"],
  ARCHIVE: ["7z", "7zip", "bar", "cab", "deb", "gzip", "ipa", "pak", "pkg", "rar", "rpm", "tar", "tar.bz2", "tar.gz", "tgz", "webarchive", "xap", "zip", "zipx"],
  FILE: ["7z", "7zip", "bar", "cab", "deb", "gzip", "ipa", "pak", "pkg", "rar", "rpm", "tar", "tar.bz2", "tar.gz", "tgz", "webarchive", "xap", "zip", "zipx", "ai ", "apng", "avif", "bmp", "gif", "ico", "jpg", "jpeg", "jfif", "pjpeg", "pjp", "png", "svg", "tif", "webp", "264", "avi", "f4a", "f4b", "f4p", "f4v", "flv", "gifv", "h264", "m2t", "m2ts", "m2v", "m4p", "m4v", "mkv", "mod", "mov", "mp2", "mp4", "mpe", "mpeg", "mpg", "mpv", "mts", "ogg", "ogv", "prproj", "qt", "swf", "tp", "vob", "webm", "wmv", "mp3", "aac", "ogg", "oga", "mogg", "flac", "alac", "wav", "aiff", "dsd", "pcm", "wma", "m4a", "abw", "djvu", "doc", "docm", "docx", "dot", "dotm", "dotx", "epub", "ibooks", "jw", "md", "odt", "opx", "ott", "pages", "pdf", "pmd", "pot", "potx", "pps", "ppsx", "ppt", "pptm", "pptx", "prn", "prproj", "ps", "pub", "pwi", "rtf", "sdd", "sdw", "sldm", "sxw", "txt", "word", "wp5", "wpd", "wps", "xps", "csv", "csvs", "cwk", "dat", "dbf", "dex", "dif", "numbers", "ods", "ots", "prn", "sdc", "slk", "sxc", "tsv", "xla", "xlam", "xll", "xlm", "xls", "xlsm", "xlsx", "xltm", "xltx"],
};
const english = /[^\u0020-\u007E]/;
/**
 *
 * @param {*} state
 * @param {*} setState
 * @param {string} strFieldName
 * @param {{blnShowUploadBtn?: boolean, blnShowDeleteBtn?: boolean, blnIsMultiple?: boolean, blnForceNewName?: boolean, strSpecificName?: string, fileType?: string, objBuilderMode?: object }} config
 * @returns
 */
export default function Uploader(
  state,
  setState,
  strFieldName,
  label = dictionary.shared.ui.uploadFilesBtn,
  config = {
    blnShowUploadBtn: true,
    blnShowDeleteBtn: true,
    blnIsMultiple: false,
    blnForceNewName: false,
    strSpecificName: null,
    fileType: null,
    objBuilderMode: null,
    title: "",
    file: "",
  },
  icon = null,
  blnLabel = true,
  sx = null
) {
  const { appState } = useContext(AppContext);
  const lang = appState?.clientInfo?.strLanguage;
  const id = useRef({
    inputID: "inputUploader-" + String(appFunctions.generateRandomString(6)),
    inputKey: "inputUploaderKey-" + String(appFunctions.generateRandomString(6)),
    containerID: "containerUploader" + String(appFunctions.generateRandomString(6)),
  });

  const [stateUploader, setStateUploader] = useState({ blnIsOpen: false, blnIsOpenBuilderDialog: false });

  if (!config) {
    config = {
      blnShowUploadBtn: true,
      blnShowDeleteBtn: true,
      blnIsMultiple: true,
      blnForceNewName: false,
      fileType: null,
      objBuilderMode: null,
    };
  }
  if (config?.blnIsMultiple === undefined || config?.blnIsMultiple === null) {
    config.blnIsMultiple = true;
  }
  if (config?.blnShowUploadBtn === undefined || config?.blnShowUploadBtn === null) {
    config.blnShowUploadBtn = true;
  }
  if (config?.blnShowDeleteBtn === undefined || config?.blnShowDeleteBtn === null) {
    config.blnShowDeleteBtn = true;
  }
  if (config?.blnForceNewName === undefined || config?.blnForceNewName === null) {
    config.blnForceNewName = false;
  }

  const objFileInfo = appVariables?.objUploadType?.[config?.fileType];
  const blnBuilderMode = config?.objBuilderMode && Object.entries(config?.objBuilderMode || {}).length;

  const onDeleteFile = (intIndexFile) => (event) => {
    state[strFieldName].splice(intIndexFile, 1);

    setState({ ...state });
  };

  const onAddFiles = (strCat) => (event) => {
    let lstFiles = event.target.files;
    lstFiles = Array.from(lstFiles);

    let objCatNumFile = {};
    if (strCat && blnBuilderMode) {
      for (let j = 0; j < state[strFieldName].length; j++) {
        if (typeof objCatNumFile?.[state[strFieldName][j]?.cat] === "number") {
          ++objCatNumFile[state[strFieldName][j]?.cat];
        } else {
          objCatNumFile[state[strFieldName][j]?.cat] = 1;
        }
      }
    }

    let blnIsSizeExceed = false;
    let lstFileNameSizeExceed = [];
    for (let i = 0; i < lstFiles.length; i++) {
      if (Number(Number(lstFiles[i]?.size) / Math.pow(1024, 2)) > Number(objFileInfo?.sizeMb)) {
        blnIsSizeExceed = true;
        lstFileNameSizeExceed.push(lstFiles[i].name);
        continue;
      }
      if (english.test(String(lstFiles[i].name)) || config?.blnForceNewName) {
        let strExtension = String(lstFiles[i].name).split(".").pop().toLowerCase();
        let newName = String(config?.strSpecificName ? config?.strSpecificName : appFunctions.generateRandomStringSecure(10)) + "." + strExtension;
        lstFiles[i] = new File([lstFiles[i]], newName, lstFiles[i]);
      }
      lstFiles[i].blnIsNew = true;
      lstFiles[i].bSize = lstFiles[i]?.size || 0;
      lstFiles[i].dtm = appFunctions.getDateUSFormat(new Date(), true);
      lstFiles[i].cat = strCat ? String(strCat) : "";
      lstFiles[i].lbl = strCat && blnBuilderMode ? config?.objBuilderMode?.[strCat]?.jsnLabel || "" : "";

      if (!config?.blnIsMultiple) {
        state[strFieldName] = [lstFiles[i]];
      } else {
        if (strCat && blnBuilderMode) {
          if (typeof objCatNumFile[strCat] === "number") {
            ++objCatNumFile[strCat];
          } else {
            objCatNumFile[strCat] = 1;
          }

          let lstFileSameName = state[strFieldName].filter((file) => String(file?.name) === String(lstFiles[i].name));
          if (lstFileSameName?.length > 0) {
            let strExtension = String(lstFiles[i].name).split(".");
            let strExt = strExtension.pop().toLowerCase();
            let strName = strExtension.pop().toLowerCase();
            let newName = `${strName}-${strCat ? strCat : appFunctions.generateRandomStringSecure(4)}.${strExt}`;
            lstFiles[i] = new File([lstFiles[i]], newName, lstFiles[i]);
            lstFiles[i].blnIsNew = true;
            lstFiles[i].bSize = lstFiles[i]?.size || 0;
            lstFiles[i].dtm = appFunctions.getDateUSFormat(new Date(), true);
            lstFiles[i].cat = strCat ? String(strCat) : "";
            lstFiles[i].lbl = strCat && blnBuilderMode ? config?.objBuilderMode?.[strCat]?.jsnLabel || "" : "";
          }

          if (Number(objCatNumFile?.[strCat]) > Number(config?.objBuilderMode?.[strCat]?.intNumFile)) {
            blnIsSizeExceed = false;
            // Alert.viewAlert(`Sorry, but the max number of files you can upload for ${config?.objBuilderMode?.[strCat]?.jsnLabel?.[lang]} is ${config?.objBuilderMode?.[strCat]?.intNumFile}!`, "error");
            break;
          } else {
            state[strFieldName].push(lstFiles[i]);
          }
        } else {
          state[strFieldName].push(lstFiles[i]);
        }
      }
    }

    if (blnIsSizeExceed) {
      // Alert.viewAlert(`File ${lstFileNameSizeExceed.join(", ")} exceed max size ${objFileInfo?.sizeMb} mb!`, "error");
    }

    setState({ ...state });
  };

  const downloadFile =
    (objUploadFileInfo, blnIsNew = false) =>
    async (event) => {
      try {
        if (blnIsNew) {
          appFunctions.downloadFile(objUploadFileInfo);
        } else {
          let newFile = new File([await (await fetch(String(objUploadFileInfo.path).includes(appVariables.App_Server_Url_DownloadFiles) ? objUploadFileInfo.path : appVariables.App_Server_Url_DownloadFiles + objUploadFileInfo.path)).blob()], objUploadFileInfo?.name);
          appFunctions.downloadFile(newFile);
        }
      } catch {
        Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cantDownloadFile?.[lang], "warning");
      }
    };

  const uploadFileHandler = async (subDirPath = "") => {
    try {
      if (!config?.fileType) {
        return true;
      }

      if (!state?.[strFieldName]?.length) {
        return true;
      }

      let lstUploadFile = [];
      for (let i = 0; i < state?.[strFieldName].length; i++) {
        if (state?.[strFieldName]?.[i]?.blnIsNew) {
          state[strFieldName][i].id = i;
          lstUploadFile.push(state?.[strFieldName]?.[i]);
        }
      }

      if (!lstUploadFile?.length) {
        return true;
      }
      const uploadResult = await fetchFile(strPageInfo, appState, lstUploadFile, objFileInfo.fileType, subDirPath, true);

      if (!uploadResult) {
        Alert.viewAlert(dictionary.shared.alertFetchNote.cantUploadFile?.[appState?.clientInfo?.strLanguage], "warning");
        return false;
      }

      for (let i = 0; i < lstUploadFile.length; i++) {
        if (uploadResult?.[i] && state?.[strFieldName]?.[i]) {
          state?.[strFieldName]?.splice(lstUploadFile[i].id, 1, {
            path: String(uploadResult[i].newFileFullPath).split(appVariables?.App_Server_Url_DownloadFiles).pop(),
            name: uploadResult[i].fileNameToSaveInDB,
            bSize: lstUploadFile[i].bSize,
            dtm: appFunctions.getDateUSFormat(new Date(), true),
            cat: lstUploadFile[i].cat,
            lbl: lstUploadFile[i].lbl,
          });
        }
      }

      return true;
    } catch (error) {
      appFunctions.logMessage(strPageInfo, error, "uploadFileHandler-ERROR");
      return false;
    }
  };

  const closeDialog = (strFieldName) => () => {
    setStateUploader({ ...stateUploader, [strFieldName]: false });
  };

  const openDialog = (strFieldName) => () => {
    setStateUploader({ ...stateUploader, [strFieldName]: true });
  };

  const jsxFileViewer = (file, intIndex) => {
    const blnIsImg = objValidationExtension?.IMAGE.includes(String(file?.name).split(".")?.pop()?.toLowerCase()) && String(file?.name).split(".")?.pop()?.toLowerCase() !== "svg";
    const pathFile = String(file.path).includes(appVariables.App_Server_Url_DownloadFiles) ? file.path : appVariables.App_Server_Url_DownloadFiles + file.path;

    return (
      <Paper outline={1} elevation={0} my-2 p-1 sx={{ "&hover": { background: "#dfebf7" }, borderRadius: "0.7em", border: `1px solid #157c8c` }}>
        <Grid container key={String(file?.name) + intIndex} title={file?.name || appFunctions.generateRandomString(6)} justify="center" p-0>
          <Grid item xs={12} md={2}>
            {blnIsImg ? (
              <img src={file?.blnIsNew ? URL.createObjectURL(file) : pathFile} alt={file?.name} style={{ maxHeight: "50px", maxWidth: "50px" }} />
            ) : (
              <ShowIcon imageName={iconGenerate.getIconForFile(file?.name || "")} width="38px" />
            )}
          </Grid>
          <Grid item xs="12" md={3} mt-3>
            <Typography as="caption" color={"#1f1f1f"} sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
              {file?.name}
            </Typography>
          </Grid>
          <Grid xs="12" md="0" item p-0>
            {!file?.blnIsNew ? null : <Icon icon="fiberNewOutlined" sx={{ color: App_Dark_yellow, background: "#fff", fontSize: "30px", padding: "0px !important" }} p-0 />}
          </Grid>
          <Grid item xs="12" md={2} mt-3>
            <Typography as="caption" color={"#1f1f1f"}>
              {Number(file?.bSize / Math.pow(1024, 1)).toFixed(2)} KB
            </Typography>
          </Grid>
          <Grid item xs="12" md={2} mt-3>
            <Typography as="caption" color={"#1f1f1f"}>
              {file?.dtm}
            </Typography>
          </Grid>
          <Grid item xs={6} md={1} mt-2 px-2>
            {!config.blnShowDeleteBtn ? null : (
              <Icon title="Remove" icon="close" sx={{ color: App_Dark_orange, background: "#e8e8e8", fontSize: "22px", padding: "0px 2px 4px 2px" }} onClick={onDeleteFile(intIndex)} />
            )}
          </Grid>
          <Grid item xs={6} md={1} mt-2 px-2>
            {!file?.path && !file?.blnIsNew ? null : (
              <Icon title="download" icon="download" sx={{ color: "#3872b0", background: "#e8e8e8", fontSize: "22px", padding: "0px 2px 4px 2px" }} onClick={downloadFile(file, file?.blnIsNew)} />
            )}
          </Grid>

          <Grid xs="0" md={1} item  p-0>
            {!file?.blnIsNew ? null : <Icon icon="fiberNewOutlined" sx={{ color: App_Dark_yellow, background: "#fff", fontSize: "30px", padding: "0px !important" }} p-0 />}
          </Grid>
        </Grid>
      </Paper>
    );
  };

  return {
    uploadHandler: uploadFileHandler,
    JSX: (
      <React.Fragment key={id.current.containerID}>
        {!config.blnShowUploadBtn ? null : (
          <>
            <Grid container p-0 sx={sx}>
              <Grid item p-0 sx={{ borderRadius: "3em", background: App_Beige_Color4, color: "#fff", boxShadow: "-1px 1px 3px #333" }} {...(blnBuilderMode ? { onClick: openDialog("blnIsOpenBuilderDialog") } : {})}>
                {blnBuilderMode ? (
                  <Grid
                    item
                    p-0
                    pr-1
                    sx={{
                      borderRadius: "3em 0 0 3em",
                      cursor: "pointer",
                      "&hover": { background: "#188c9e" },
                    }}
                    title="Upload Files"
                  >
                    <Icon
                      sx={{
                        fontSize: "32px",
                        padding: "0px 2.3px 4px 1px",
                        boxShadow: "1px 0 2px #333",
                        color: "#fff",
                        background: "#097585",
                        "&hover": {
                          background: "#097585",
                        },
                      }}
                      title="Upload Files"
                      icon="fileUpload"
                      onClick={(event) => {
                        event?.stopPropagation();
                      }}
                    />

                    <Typography as="caption" sx={{ fontSize: "16px", marginTop: "6px", fontWeight: "bold" }}>{label?.[lang]}</Typography>
                  </Grid>
                ) : (
                  <label htmlFor={id.current.inputID}>
                    <input id={id.current.inputID} key={id.current.inputKey} type="file" hidden multiple={config.blnIsMultiple} accept={(objFileInfo?.accept === "IMAGE"? "capture=camera,": "") + objValidationExtension?.[objFileInfo?.accept] ? "." + objValidationExtension?.[objFileInfo?.accept]?.join(",.") : objFileInfo?.accept} onChange={onAddFiles(null)} />
                    <Grid container p-0>
                      <Grid
                        item
                        p-0
                        sx={{
                          "&hover": {
                            background: App_Second_Color,
                            borderRadius: !blnLabel ? "3em" : lang === "arb" ? "0 3em 3em 0 " : "3em 0 0 3em",
                            cursor: "pointer",
                          },
                        }}
                        title={label?.[lang]}
                      >
                        <Icon
                          sx={{
                            fontSize: "32px",
                            margin: "auto",
                            border: `1px solid ${App_Second_Color}`,
                          }}
                          title={config?.title || `${lang === "arb" ? "تحميل" : "upload"} ${label?.[lang] || ""}`}
                          color="primary"
                          icon={icon || "fileUpload"}
                          onClick={(event) => {
                            event?.stopPropagation();
                          }}
                          px-1
                        />
                        {!blnLabel ? null : (
                          <Typography as="caption" color="primary" px-2>
                            {label?.[lang] ? `${label?.[lang]}` : ""}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </label>
                )}
                {!blnLabel ? null : (
                  <Typography
                    as="caption"
                    sx={{
                      textAlign: "center",
                      width: "50px",
                      height: "36px",
                      lineHeight: "38px",
                      background: App_Beige_Color4,
                      "&hover": {
                        background: App_Second_Color,
                        borderRadius: lang === "arb" ? "3em 0 0 3em" : "0 3em 3em 0 ",
                        cursor: "pointer",
                      },
                    }}
                    style={{
                      borderInlineStart: `1px solid ${App_Primary_Color}`,
                      color: App_Primary_Color,
                    }}
                    pt-1
                    px-2
                    title={`${lang === "arb" ? "عرض" : ""} ${label?.[lang]} ${lang !== "arb" ? "Preview" : ""}`}
                    onClick={openDialog(blnBuilderMode ? "blnIsOpenBuilderDialog" : "blnIsOpen")}
                  >
                    {String(state?.[strFieldName]?.length) || "0"} {state?.[strFieldName]?.length > 1 ? config?.file || dictionary.shared.ui.filesNum?.[lang] : config?.file || dictionary.shared.ui.fileNum?.[lang]}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </>
        )}

        <Modal open={stateUploader.blnIsOpen} eventClose={closeDialog("blnIsOpen")}>
          <ModalHeader>{dictionary.shared.ui.mngFiles?.[lang]}</ModalHeader>
          <ModalBody>
            <Grid container justify="center">
              <Grid item xs={12}>
                {!state?.[strFieldName] || !state?.[strFieldName]?.length ? (
                  <Grid container item my-10 justify="center">
                    <Grid item>
                      <Typography as="subtitle1">{dictionary.shared.ui.noFileYet?.[lang]}</Typography>
                    </Grid>
                  </Grid>
                ) : (
                  state?.[strFieldName]?.map?.((file, intIndex) => {
                    return jsxFileViewer(file, intIndex);
                  })
                )}
              </Grid>
            </Grid>
          </ModalBody>
        </Modal>

        {!blnBuilderMode ? null : (
          <Modal open={stateUploader.blnIsOpenBuilderDialog} eventClose={closeDialog("blnIsOpenBuilderDialog")}>
            <ModalHeader>{dictionary.shared.ui.uploadFiles?.[lang]}</ModalHeader>
            <ModalBody>
              {Object.entries(config?.objBuilderMode || {})?.map?.(([id, objValue], intIndex) => {
                return (
                  <Grid container justify="space-between" key={id + "-cat"}>
                    <Grid item>
                      <label htmlFor={id + "-uploadInput"}>
                        <input id={id + "-uploadInput"} type="file" hidden multiple={config.blnIsMultiple} accept={(objFileInfo?.accept === "IMAGE"? "capture=camera,": "") + objValidationExtension?.[objValue?.strValid || objFileInfo?.accept] ? "." + objValidationExtension?.[objValue?.strValid || objFileInfo?.accept].join(",.") : objFileInfo?.accept} onChange={onAddFiles(id)} />
                        <Grid container p-0>
                          <Grid
                            item
                            p-0
                            pr-1
                            sx={{
                              borderRadius: "3em",
                              cursor: "pointer",
                              boxShadow: "1px 0 2px #333",
                              "&hover": { background: "#188c9e" },
                              background: "#1d8696",
                            }}
                            title="Upload Files"
                          >
                            <Icon
                              sx={{
                                fontSize: "32px",
                                padding: "0px 2.3px 4px 1px",
                                boxShadow: "1px 0 2px #333",
                                color: "#fff",
                                background: "#097585",
                                "&hover": { background: "#097585" },
                              }}
                              title="Upload Files"
                              icon="fileUpload"
                              onClick={(event) => {
                                event?.stopPropagation();
                              }}
                            />

                            <Typography sx={{ fontSize: "14px", marginTop: "7px", fontWeight: "bold", color: "#fff" }}>
                              &nbsp;&nbsp;{objValue?.jsnLabel?.[lang]} ({objValue?.intNumFile})&nbsp;&nbsp;
                            </Typography>
                          </Grid>
                        </Grid>
                      </label>
                    </Grid>
                    <Grid item xs={12} p-0>
                      <Grid container key={id + "-uploading-" + intIndex} my-3>
                        <Grid item>
                          <Typography as="body2" px-2>
                            {objValue?.jsnLabel?.[lang]}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ border: `2px solid ${App_Light_Bluewood}`, minHeight: "60px" }}>
                          {!state?.[strFieldName] || !state?.[strFieldName]?.length ? (
                            <Grid container item my-10 justify="center">
                              <Grid item>
                                <Typography as="subtitle1">{dictionary.shared.ui.noFileYet?.[lang]}</Typography>
                              </Grid>
                            </Grid>
                          ) : (
                            state?.[strFieldName]?.map?.((file, intIndex) => {
                              if (String(file?.cat) !== String(id)) {
                                return null;
                              }
                              return jsxFileViewer(file, intIndex);
                            })
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </ModalBody>
          </Modal>
        )}
      </React.Fragment>
    ),
  };
}
