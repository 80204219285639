import * as appColor from "./appColor";

export const appStyle = {
    title1:{
        borderInlineStart: `5px solid ${appColor?.App_Primary_Color} !important`,
        paddingInlineStart:"8px !important",
        color:appColor.App_Primary_Color,
        fontFamily:"'Hand Font', 'Reem Kufi', sans-serif !important"

    },
    title2: {
        textAlign: "center !important",
        // wordSpacing:"4px",
        position:"absolute",
        // top:"-18px", 
        left:"50% !important",
        // background:appColor?.App_Light_Color,
        color:appColor.App_Primary_Color,
        transform:"translateX(-50%) !important",
        // whiteSpace: "nowrap !important",
        fontFamily:"'Hand Font', 'Reem Kufi', sans-serif !important"


      },
    accordionSummary:{
        background: "transparent !important",
        borderBottom:`3px solid ${appColor?.App_Second_Color} !important`,
        color:`${appColor?.App_Primary_Color} !important`,
     },
     accordionDetails:{
       backgroundColor:`${appColor?.App_Beige_Color4} !important`,
       borderRadius:"0 0 8px 8px !important",
       boxShadow: "none !important",
     },
     titleContainer:{
        background:appColor.App_Primary_Color,
        padding:"1px !important"
    },
}
