import React, { useContext, useEffect, useState } from "react";
import ShredHeader from "../../sharedUi/header/ShredHeader";
import { Alert, Container, Grid, useParams } from "@cbmisorg/client-app";
import { dictionary } from "../../../appHelper/appLanguage/dictionary";
import { AppContext } from "../../../configuration/contextapi/AppContext";
import useCheckSystemInfo from "../../../appHelper/appHook/useCheckSystemInfo";
import { logMessage } from "../../../appHelper/appFunctions";
import { App_Server_Url_DownloadFiles } from "../../../appHelper/appVariables";
import NoResultImg from "../../sharedUi/noResultImg/NoResultImg";

const strPageInfo = "@srccomponentspublicpublicVideoesPublicVideoes.js";

function TrainingVideo() {
  const { getNewSystemInfo } = useCheckSystemInfo();
  const { appState } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [stateInfo, setStateInfo] = useState({
    blnIsInitDone: false,
    selectedVideoInfo: null,
  });
  const lang = appState?.clientInfo?.strLanguage;
  const { strUrlVedio } = useParams();

  const getSystemInfo = async ({ getNewSystemInfo }) => {
    try {
      if (isLoading) {
        Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
        return;
      }

      setIsLoading(true);
      const result = await getNewSystemInfo();

      stateInfo.blnIsInitDone = true;
      if (!Array.isArray(result?.jsnSystemConfig?.lstAdvVideos) || !result?.jsnSystemConfig?.lstAdvVideos?.length) {
        stateInfo.selectedVideoInfo = null;
      } else {
        stateInfo.selectedVideoInfo = result?.jsnSystemConfig?.lstAdvVideos?.filter((video) => video?.strVedioUrl === strUrlVedio)[0];

        if (!stateInfo.selectedVideoInfo) {
          stateInfo.selectedVideoInfo = null;
        }
      }
      setStateInfo({ ...stateInfo });
      setIsLoading(false);
    } catch (error) {
      logMessage(strPageInfo, "getNewSystemInfo-ERROR", error);
    }
  };

  useEffect(() => {
    if (!stateInfo?.blnIsInitDone) {
      getSystemInfo({ getNewSystemInfo });
    }
  }, []);

  return (
    <React.Fragment>
      <ShredHeader title={stateInfo?.selectedVideoInfo?.strVedioTitle?.[lang] || stateInfo?.selectedVideoInfo?.strVedioTitle?.["arb"] || stateInfo?.selectedVideoInfo?.strVedioTitle?.["eng"] || ""} />
      <Container>
        <Grid container justify={"center"} spacing={3}>
          {
            stateInfo?.selectedVideoInfo?.lstVideos?.length?(
              stateInfo?.selectedVideoInfo?.lstVideos?.map((video, index) => (
                <Grid item xs="12" key={index}>
                  <video controls className="videoTraining">
                    <source src={App_Server_Url_DownloadFiles + video?.path} type="video/mp4" />
                  </video>
                </Grid>
              ))
            ):(
              <Grid item xs="12">
                <NoResultImg />
              </Grid>
            )
          }
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default TrainingVideo;
