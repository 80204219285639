import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../configuration/contextapi/AppContext";
import { Container, Grid, Icon, Paper, Typography, useLocation, useNavigate, Button, Modal, ModalHeader, ModalBody, Loader } from "@cbmisorg/client-app";
import { App_Primary_Color } from "../../../../appHelper/appColor";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import ShredHeader from "../../../sharedUi/header/ShredHeader";
import { FormData, FormField } from "../../../../appHelper/formApi/FormAPI";
import { CtrlAviates } from "./controller/ctrlActivities";
import { appStyle } from "../../../../appHelper/appStyle";
import Uploader from "../../../sharedUi/uploader/Uploader";
import { App_Server_Url_DownloadFiles, objUploadType } from "../../../../appHelper/appVariables";

const labels = dictionary?.components?.manageActivates;

function SaveActivity() {
  const { appState, appDispatch } = useContext(AppContext);
  const lang = appState.clientInfo.strLanguage;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useLocation();
  const [stateInfo, setStateInfo] = useState({
    blnIsInitDone: false,
    modalOpen: false,
    listPoint: [],
    blnIsAdded: false,
    lstMainImg: [],
    lstImages: [],
    lstFile: [],
  });

  const uploaderMainImg = Uploader(stateInfo, setStateInfo, "lstMainImg", labels?.mainimage, {
    fileType: objUploadType.aaiot.id,
    blnIsMultiple: false,
    blnForceNewName: true,
  });

  const uploaderImages = Uploader(stateInfo, setStateInfo, "lstImages", labels?.activatIamge, {
    fileType: objUploadType.aaiot.id,
    blnIsMultiple: true,
    blnForceNewName: true,
  });

  const uploaderFile = Uploader(stateInfo, setStateInfo, "lstFile", labels?.activatFile, {
    fileType: objUploadType.aaiot.id,
    blnIsMultiple: false,
    blnForceNewName: true,
  });
  const handlers = CtrlAviates(appState, appDispatch, isLoading, setIsLoading, stateInfo, setStateInfo, navigate);

  const fields = FormData({
    title: {
      strControl: "text",
      objValidations: { required: true },
    },
    linkUrl: {
      strControl: "text",
      objLanguage: false,
    },
    linkLabel: {
      strControl: "text",
    },
    blnHomePage: {
      strControl: "checkbox",
      objOptionLang: false,
      lstOption: [
        {
          key: 1,
          value: labels?.blnHome?.[lang],
        },
      ],
    },
    blnAddFile: {
      strControl: "radio",
      objOptionLang: false,
      lstOption: [
        {
          key: 1,
          value: labels?.blnAddLink?.[lang],
        },
        {
          key: 2,
          value: labels?.blnAddFile?.[lang],
        },
        {
          key: 0,
          value: labels?.blnAddNone?.[lang],
        },
      ],
    },
    source: {
      strControl: "text",
      objValidations: { required: true },
    },
  });

  const fieldPoints = FormData({
    strItemPoint: {
      strControl: "textarea",
      objValidations: { required: true },
    },
  });

  function handleSave() {
    if (!state?.selected) {
      handlers.funAdd(fields, uploaderMainImg, uploaderImages, uploaderFile);
    } else {
      handlers.funEdit(fields, state?.selected, uploaderMainImg, uploaderImages, uploaderFile);
    }
  }
  useEffect(() => {
    if (state?.selected) {
      const activityInfo = state?.selected;
      fields?.title?.setValue(activityInfo?.jsnActivityInfo?.title);
      fields?.linkLabel?.setValue(activityInfo?.jsnActivityInfo?.link?.label);
      fields?.blnHomePage?.setValue(activityInfo?.jsnActivityInfo?.blnHomePage ? [1] : 0);
      fields?.blnAddFile?.setValue(String(activityInfo?.jsnActivityInfo?.link?.intTypeLink));
      fields?.source?.setValue(activityInfo?.jsnActivityInfo?.source);
      if (activityInfo?.jsnActivityInfo?.link?.url === `${App_Server_Url_DownloadFiles}${stateInfo?.lstFile?.[0]?.path}`) {
        fields?.linkUrl?.setValue("");
      } else {
        fields?.linkUrl?.setValue(activityInfo?.jsnActivityInfo?.link?.url);
      }
      setStateInfo({
        ...stateInfo,
        lstMainImg: activityInfo?.jsnActivityInfo?.lstMainImg || [],
        lstImages: activityInfo?.jsnActivityInfo?.lstImages || [],
        lstFile: activityInfo?.jsnActivityInfo?.link?.lstFile || [],
        listPoint: activityInfo?.jsnActivityInfo?.listPoint || [],
      });
    }
  }, []);

  const onChangeAddFileOpt = () => {
    if (fields?.linkUrl?.getValue() === `${App_Server_Url_DownloadFiles}${stateInfo?.lstFile?.[0]?.path}`) {
      fields?.linkUrl?.setValue("");
    }
  };

  return (
    <React.Fragment>
      <ShredHeader title={!state?.selected ? labels?.addTitle?.[lang] : labels?.editTitle?.[lang]} blnBottomBar={false} lang={lang} />
      <Container dir="lrt" py-3>
        {isLoading ? <Loader backdrop={true} color={App_Primary_Color} /> : null}
        <Grid container justify={"center"}>
          <Grid item xs="12">
            <Paper sx={{ minHeight: "86vh", maxHeight: "87vh", overflowY: "auto" }} p-0 className="cardContainer">
              <Grid container p-2 spacing={2}>
                <Grid item xs={12}>
                  <Typography as="caption" sx={appStyle?.title1}>
                    {labels?.activatInfo?.[lang]}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormField objHandler={fields.title} icon="title" />
                </Grid>
                <Grid item xs={12}>
                  <FormField objHandler={fields.source} icon="source" />
                </Grid>
                <Grid item xs={5} sm={4} md={3} pb-0>
                  <FormField objHandler={fields.blnAddFile} onChange={onChangeAddFileOpt} dir={lang === "arb" ? "rtl" : "ltr"} />
                </Grid>
                {fields.blnAddFile?.getValue()?.key === 1 ? (
                  <Grid item xs="7" sm={8} md={9} justify={"center"} container alignSelf={"baseline"} pt-0>
                    <Grid item xs={12} sm={6} md={5} alignSelf={"baseline"} py-0>
                      <FormField objHandler={fields.linkUrl} className={`inputLeft inputLeft${lang}`} py-0 />
                    </Grid>
                    <Grid item xs={12} sm={6} md={7} alignSelf={"baseline"} pb-0 pt-1>
                      <FormField objHandler={fields.linkLabel} py-0 />
                    </Grid>
                  </Grid>
                ) : fields.blnAddFile?.getValue()?.key === 2 ? (
                  <Grid item xs="7" sm={8} md={9} justify={"center"} container>
                    <Grid item xs={12} sm={6} md="3">
                      {uploaderFile?.JSX}
                    </Grid>
                    <Grid item xs={12} sm={6} md={9}>
                      <FormField objHandler={fields.linkLabel} py-0 label={labels?.labelFile} />
                    </Grid>
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <FormField objHandler={fields.blnHomePage} dir={lang === "arb" ? "rtl" : "ltr"} />
                </Grid>
              </Grid>
              <Grid container p-2 spacing={2}>
                <Grid item container>
                  <Grid item>
                    <Typography as="caption" sx={appStyle?.title1}>
                      {labels?.activatContent?.[lang]}
                    </Typography>
                  </Grid>
                  <Grid item px-4>
                    <Icon icon="addCircle" color={App_Primary_Color} onClick={handlers?.addContent} />
                  </Grid>
                </Grid>
                {stateInfo?.listPoint?.map((item, ind) => {
                  return (
                    <React.Fragment>
                      <Grid item xs="12" pb-0 sx={{ justifyContent: "end" }}>
                        <Icon icon="delete" color="error" onClick={() => handlers?.deleteContentSave(ind)} px-2 />
                        <Icon icon="edit" color="primary" onClick={() => handlers?.editContent(fieldPoints, ind)} px-2 />
                      </Grid>
                      <Grid item xs={12} key={ind} pt-0>
                        <Paper elevation={0} outline={1}>
                          {item?.[lang]}
                        </Paper>
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </Grid>
              <Grid item xs="12" container>
                <Grid item>
                  <Typography as="caption" sx={appStyle?.title1}>
                    {labels?.activatIamge?.[lang]}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container p-2 spacing={2} justify={"center"}>
                <Grid item >
                  {uploaderMainImg?.JSX}
                </Grid>

                {!stateInfo?.lstMainImg?.length ? null : (
                  <Grid item >
                    {uploaderImages?.JSX}
                  </Grid>
                )}
              </Grid>
              <Grid container pt-3 spacing={2} justify={"center"}>
                <Grid item xs="10" sm="4" md="3">
                  <Button color="primary" size="sm" label={dictionary?.shared?.btn?.save?.[lang]} sx={{ minWidth: "100%" }} onClick={handleSave} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <Modal open={stateInfo?.modalOpen} eventClose={handlers?.funCloseModal}>
        <ModalHeader>{labels?.resetPassword?.[lang]}</ModalHeader>
        <ModalBody>
          <Grid container spacing={3}>
            <Grid item xs={12} sx={{ alignSelf: "flex-start" }}>
              <FormField objHandler={fieldPoints.strItemPoint} dir="ltr" />
            </Grid>
            <Grid item xs="12">
              <Button color="primary" size="sm" label={dictionary?.shared?.btn?.save?.[lang]} mx-auto onClick={() => handlers?.editAddContentSave(fieldPoints)} px-3 />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default SaveActivity;
