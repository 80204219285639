import { Alert } from "@cbmisorg/client-app";
import * as appFunctions from "../../../../../appHelper/appFunctions";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import { generateQueries, objTableAttributes } from "../../../../../appHelper/appQuery/GenerateQuery";
import { aaiot_System_ID, objSupportTransStatus, objTrainingToSupport } from "../../../../../appHelper/appVariables";

const strPageInfo = "@src/components/stakeholders/Sponsor/accountSponsor/controler/CtrAccountSponsor.js";

const tblPartner = generateQueries("tblPartner");
const tblStatistic = generateQueries("tblStatistic");

export const CtrlAccountSponsor = (appState, appDispatch) => {
  const lang = appState?.clientInfo?.strLanguage;

  let handler = {
    initData:
      ({ isLoading, setIsLoading, state, setState, fields }) =>
      async () => {
        try {
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
            return;
          }

          state.blnIsInitDone = true;
          setIsLoading(true);

          const result = await tblPartner(strPageInfo, appState, "aaiotAppFindOne", {
            arrAttributes: objTableAttributes?.tblPartner?.full,
            objectCondition: { bigPartnerID: appState?.userInfo?.bigUserID, blnIsActive: true, blnIsDeleted: false },
          });
          if (!result?.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cantFindRecords?.[lang], "warning");
            setIsLoading(false);
            return;
          }

          state.objPartner = appFunctions?.checkParseObject(result?.response);
          state.objPartner.jsnSupportTrans = state?.objPartner?.jsnSupportTrans?.reverse();
          state.objPartnerCount = appFunctions.getSponsorTypeAccordingToTraineeNum(state?.objPartner?.intManagerTrainCount);

          const objCat = appFunctions.getCategoryCache();
          let lstSponsorFullStackTypeOption = [];
          let objSponsorFullStackTypeOptionLang = {};
          let lstSponsorManagerTypeOption = [];
          let objSponsorManagerTypeOptionLang = {};

          const fullstackKey = objTrainingToSupport?.fullstack?.key;
          const managerKey = objTrainingToSupport?.manager?.key;

          Object.entries(objCat?.Root?.trainingType?.[fullstackKey]?.sponser || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstSponsorFullStackTypeOption.push({ key: key, value: key });
              objSponsorFullStackTypeOptionLang[key] = {
                eng: value?.value?.eng + " - " + value?.studentNumber?.value?.eng + " " + dictionary?.components?.public?.join?.trainingCustomNumber?.eng,
                arb: value?.value?.arb + " - " + value?.studentNumber?.value?.arb + " " + dictionary?.components?.public?.join?.trainingCustomNumber?.arb +  " بتكلفة " + handler?.getPartnerTransPlanTotalPrice(key),
              };
            }
          });

          Object.entries(objCat?.Root?.trainingType?.[managerKey]?.sponser || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstSponsorManagerTypeOption.push({ key: key, value: key });
              objSponsorManagerTypeOptionLang[key] = {
                eng: value?.value?.eng + " - " + value?.studentNumber?.value?.eng + " " + dictionary?.components?.public?.join?.managerCustomNumber?.eng,
                arb: value?.value?.arb + " - " + value?.studentNumber?.value?.arb + " " + dictionary?.components?.public?.join?.managerCustomNumber?.arb,
              };
            }
          });

          fields?.strSponsorTrainingType?.setOptions(
            Object.entries(objTrainingToSupport)
              .filter(([key, value]) => value?.key !== objTrainingToSupport.manager_fullstack.key)
              .map(([key, value]) => ({ key: value?.key, value: value?.key })),
            {
              [fullstackKey]: objCat?.Root?.trainingType?.[fullstackKey]?.value,
              [managerKey]: objCat?.Root?.trainingType?.[managerKey]?.value,
            }
          );
          fields?.strSponsorFullStackCatType?.setOptions(lstSponsorFullStackTypeOption, objSponsorFullStackTypeOptionLang);
          fields?.strSponsorManagerCatType?.setOptions(lstSponsorManagerTypeOption, objSponsorManagerTypeOptionLang);

          setIsLoading(false);
          setState({ ...state });
        } catch (error) {
          setIsLoading(false);
          appFunctions.logMessage(strPageInfo, error, "routeMngRequestedJoinCtrl-Error");
        }
      },

    dilogeHistoryModal:
      ({ state, setState }) =>
      () => {
        try {
          state.blnOpenHistoryModal = !state?.blnOpenHistoryModal;
          setState({ ...state });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "routeMngRequestedJoinCtrl-Error");
        }
      },
    dilogeContributionModal:
      ({ state, setState, fields }) =>
      (selected = null) =>
      () => {
        try {
          state.blnIsContributionOpen = !state?.blnIsContributionOpen;

          state.lstReceipt = [];
          state.selectedTrans = selected;
          fields.controller.resetForm();

          const strSponsorTypeFullstack = appFunctions.getSponsorTypeAccordingToTraineeNum(selected?.intTraineesCount || state.objPartner?.intTraineesCount);
          const strSponsorTypeManager = appFunctions.getSponsorTypeAccordingToTraineeNum(selected?.intTraineesCount || state.objPartner?.intManagerTrainCount, objTrainingToSupport.manager.key);

          fields.strSponsorTrainingType.setValue(state.objPartner?.blnIsManagerSponsor ? objTrainingToSupport.manager.key : objTrainingToSupport.fullstack.key);
          // fields.strSponsorFullStackCatType.setValue(strSponsorTypeFullstack);
          // fields.strSponsorManagerCatType.setValue(strSponsorTypeManager);

          //state.lstReceipt = Array.isArray(state?.objPartner?.jsnSupportTrans?.lstReceipt) ? state?.objPartner?.jsnSupportTrans?.lstReceipt : [];

          setState({ ...state });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "routeMngRequestedJoinCtrl-Error");
        }
      },
    dilogeImgModal:
      ({ state, setState }) =>
      (lstImg = null, typeImg = null) => {
        try {
          state.blnOpenImgeModal = !state.blnOpenImgeModal;
          state.selectedLstImg = lstImg;
          state.selectedImgType = typeImg;
          setState({ ...state });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "routeMngRequestedJoinCtrl-Error");
        }
      },
    handelSubmit:
      ({ isLoading, setIsLoading, state, setState, fields, uploaderReceipt }) =>
      (intSupportTransStatus = objSupportTransStatus.Send_New) =>
      async () => {
        try {
          if (!fields?.managerCustomNumber?.getValue()) {
            fields?.managerCustomNumber?.setValue(fields.managerNumber?.getValue()?.key || "0");
          }
          if (!fields?.trainingCustomNumber?.getValue()) {
            fields?.trainingCustomNumber?.setValue(fields.trainingNumber?.getValue()?.key || "0");
          }
          if (!state.lstReceipt?.length) {
            fields?.intReceivedAmount?.controller?.setError("intReceivedAmount", dictionary?.formapi?.required?.[lang]);
          }

          if (!fields.isFormValid()) {
            //Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
            //return;
          }

          if (isLoading) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.fetchData_InProgress?.[lang], "warning");
            return;
          }

          setIsLoading(true);

          let jsnSupportTrans = Array.isArray(state.objPartner?.jsnSupportTrans) ? JSON.parse(JSON?.stringify(state.objPartner?.jsnSupportTrans))?.reverse() : [];

          if (state.lstReceipt?.length) {
            const resultUploadReceipt = await uploaderReceipt?.uploadHandler("supportTransReceipt");
            if (!resultUploadReceipt) {
              setIsLoading(false);
              return;
            }
          }

          const strSponsorTrainingType = state.objPartner?.blnIsManagerSponsor ? objTrainingToSupport.manager.key : objTrainingToSupport.fullstack.key; //fields.strSponsorTrainingType.getValue()?.key;

          const strSponsorFullStackCatType =fields.strSponsorFullStackCatType?.getValue()?.key || appFunctions.getSponsorTypeAccordingToTraineeNum(state?.selectedTrans?.intTraineesCount || state.objPartner?.intTraineesCount); //fields.strSponsorFullStackCatType?.getValue()?.key;
          const strSponsorManagerCatType = fields.strSponsorFullStackCatType?.getValue()?.key || appFunctions.getSponsorTypeAccordingToTraineeNum(
            state?.selectedTrans?.intTraineesCount  || state.objPartner?.intManagerTrainCount,
            objTrainingToSupport.manager.key
          ); //fields.strSponsorManagerCatType?.getValue()?.key;

          const objCat = appFunctions.getCategoryCache();

          let intCountStudentNum = 0;
          let intPriceSystem = 0;
          if (strSponsorTrainingType === objTrainingToSupport?.manager?.key) {
            intCountStudentNum = Number(objCat?.Root?.trainingType?.[strSponsorTrainingType]?.sponser?.[strSponsorManagerCatType]?.studentNumber?.value?.eng) || 0;
            intPriceSystem = Number(objCat?.Root?.trainingType?.[strSponsorTrainingType]?.price?.value?.eng) || 0;
          } else {
            intCountStudentNum = Number(objCat?.Root?.trainingType?.[strSponsorTrainingType]?.sponser?.[strSponsorFullStackCatType]?.studentNumber?.value?.eng) || 0;
            intPriceSystem = Number(objCat?.Root?.trainingType?.[strSponsorTrainingType]?.price?.value?.eng) || 0;
          }

          if(!state.selectedTrans){
            jsnSupportTrans.push({
              id: appFunctions.generateID(6),
              dtmCreatedDate: new Date().toUTCString(),
              dtmUpdatedDate: new Date().toUTCString(),
              strTrainingType: strSponsorTrainingType,
              intSupportTransStatus: intSupportTransStatus,
              intTraineesCount: intCountStudentNum,
              intTrainingProgPrice: intPriceSystem,
              lstReceipt: state.lstReceipt,
              lstReceiptCatch: [],
              intReceivedAmount: fields?.intReceivedAmount?.getValue() || null,
            });
          }else{
          const transIndex = jsnSupportTrans?.findIndex((item) => item.id === state?.selectedTrans?.id);
          jsnSupportTrans[transIndex] = {
            ...state?.selectedTrans,
            dtmUpdatedDate: new Date().toUTCString(),
            strTrainingType: strSponsorTrainingType,
            intSupportTransStatus: intSupportTransStatus,
            intTraineesCount: intCountStudentNum,
            intTrainingProgPrice: intPriceSystem,
            lstReceipt: state.lstReceipt,
            lstReceiptCatch: [],
          };
          }

          let intTraineesCount_Old = state.objPartner?.intTraineesCount;
          let intManagerTrainCount_Old = state.objPartner?.intManagerTrainCount;

          let intTraineesCount = state.objPartner?.intTraineesCount;
          let intManagerTrainCount = state.objPartner?.intManagerTrainCount;

          for (let i = 0; i < jsnSupportTrans.length; i++) {
            if (appFunctions.getDifferenceBetweenTwoDate(jsnSupportTrans?.[i]?.dtmCreatedDate, new Date(), "month") <= 4) {
              if (String(jsnSupportTrans?.[i]?.intSupportTransStatus) === String(objSupportTransStatus?.Approved)) {
                if (String(jsnSupportTrans?.[i]?.strTrainingType) === String(objTrainingToSupport.manager?.key)) {
                  if (jsnSupportTrans?.[i]?.intTraineesCount > intManagerTrainCount) {
                    intManagerTrainCount = jsnSupportTrans?.[i]?.intTraineesCount;
                  }
                } else {
                  if (jsnSupportTrans?.[i]?.intTraineesCount > intTraineesCount) {
                    intTraineesCount = jsnSupportTrans?.[i]?.intTraineesCount;
                  }
                }
              }
            }
          }

          if (intTraineesCount === intTraineesCount_Old && intManagerTrainCount === intManagerTrainCount_Old) {
            for (let i = 0; i < jsnSupportTrans.length; i++) {
              if (String(jsnSupportTrans?.[i]?.intSupportTransStatus) === String(objSupportTransStatus?.Approved)) {
                if (String(jsnSupportTrans?.[i]?.strTrainingType) === String(objTrainingToSupport.manager?.key)) {
                  if (jsnSupportTrans?.[i]?.intTraineesCount > intManagerTrainCount) {
                    intManagerTrainCount = jsnSupportTrans?.[i]?.intTraineesCount;
                  }
                } else {
                  if (jsnSupportTrans?.[i]?.intTraineesCount > intTraineesCount) {
                    intTraineesCount = jsnSupportTrans?.[i]?.intTraineesCount;
                  }
                }
              }
            }
          }

          const result = await tblPartner(strPageInfo, appState, "aaiotAppUpdate", {
            objectCondition: {
              bigPartnerID: state?.objPartner?.bigPartnerID,
            },
            objectInput: {
              jsnSupportTrans: jsnSupportTrans,
              intSupportTransStatus: intSupportTransStatus,
              intTraineesCount: intTraineesCount_Old !== intTraineesCount ? intTraineesCount : undefined,
              intManagerTrainCount: intManagerTrainCount_Old !== intManagerTrainCount ? intManagerTrainCount : undefined,
              dtmUpdatedDate: new Date().toUTCString(),
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.cant_Update_Record?.[lang], "warning");
            setIsLoading(false);
            return;
          }

          await tblStatistic(strPageInfo, appState, "aaiotAppIncDecInt", {
            objectCondition: { bigSystemID: appState?.userInfo?.bigSystemID || aaiot_System_ID },
            arrAttributes: strSponsorTrainingType === objTrainingToSupport.manager.key ? ["intCountTransPartnerManager"] : ["intCountTransPartnerFullstack"],
          });

          fields?.resetField();
          state.objPartner.jsnSupportTrans = JSON.parse(JSON.stringify(jsnSupportTrans))?.reverse();
          state.lstReceipt = [];
          state.blnIsContributionOpen = false;
          setIsLoading(false);

          setState({ ...state });
          Alert.viewAlert(dictionary.shared.alertFetchNote.submit_Successfully?.[lang], "success");
        } catch (error) {
          setIsLoading(false);
          appFunctions.logMessage(strPageInfo, error, "routeMngRequestedJoinCtrl-Error");
        }
      },
    getPartnerTransPlanTotalPrice:
      (strSponsorType) => {
        try {
          const objCat = appFunctions.getCategoryCache();
          // const objPartnerInfo = state.objPartner;
          const strSponsorTrainingType = objTrainingToSupport.fullstack?.key;

          // const strSponsorFullStackCatType = fields.strSponsorFullStackCatType?.getValue()?.key;
          // const strSponsorManagerCatType = fields.strSponsorManagerCatType?.getValue()?.key;

          const intCountStudentNumSystem =  Number(objCat?.Root?.trainingType?.[strSponsorTrainingType]?.sponser?.[strSponsorType]?.studentNumber?.value?.eng) || 0
          
            // Number(
            //   objCat?.Root?.trainingType?.[strSponsorTrainingType]?.sponser?.[strSponsorTrainingType === objTrainingToSupport.manager?.key ? strSponsorManagerCatType : strSponsorFullStackCatType]
            //     ?.studentNumber?.value?.eng
            // ) || 0;
          const priceSystem = Number(objCat?.Root?.trainingType?.[strSponsorTrainingType]?.price?.value?.eng);

          
          return ((priceSystem || 0) * (intCountStudentNumSystem || 0)).toLocaleString();
        } catch {
          return 0;
        }
      },
  };

  return handler;
};
