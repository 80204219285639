import { Alert, Confirmation } from "@cbmisorg/client-app";
import * as XLSX from "xlsx";
import * as appFunctions from "../../../../appHelper/appFunctions";
import { generateQueries, objTableAttributes } from "../../../../appHelper/appQuery/GenerateQuery";
import * as appVariables from "../../../../appHelper/appVariables";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
const tblPartnerFetch = generateQueries("tblPartner");
const strPageInfo = "@src/components/stakeholders/admin/mngSupportTrans/mngSupportTrans.controller.js";

export const strTransactionNeedToModify = "TransactionNeedToModify";
export default function mngSupportTransCtrl({ appState, appDispatch }) {
  const lang = appState?.clientInfo?.strLanguage;

  const handler = {
    initData:
      ({ isLoading, setIsLoading, state, setState, fields, strTrainingType }) =>
      async () => {
        try {
          if (isLoading) {
            return;
          }

          state.blnIsFetchDone = true;
          setIsLoading(true);

          const result = await tblPartnerFetch(strPageInfo, appState, "aaiotAppFindAll", {
            arrAttributes: objTableAttributes.tblPartner.full,
            objectCondition: {
              blnIsConfirmed: true,
              blnIsActive: true,
              blnIsDeleted: false,
            },
          });
          if (!result.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.cantFindRecords?.[lang], "warning");
            setIsLoading(false);
            return;
          }

          const lstPartner = Array.isArray(result?.response) ? result?.response : [];

          //[{id: 123456, dtmCreatedDate: "", dtmUpdatedDate: "", strTrainingType: "", intTraineesCount: 0, intTrainingProgPrice: 0, lstReceipt:[], lstReceiptCatch:[], intSupportTransStatus: 1 }]
          let lstPartnerTrans = [];
          let objPartnerInfo = {};
          let intNumNew = 0;
          let intWaitingNum = 0;
          let intApprovedNum = 0;
          let intNumReject = 0;
          for (let i = 0; i < lstPartner.length; i++) {
            objPartnerInfo[lstPartner?.[i]?.bigPartnerID] = { ...objPartnerInfo[lstPartner?.[i]?.bigPartnerID] };
            objPartnerInfo[lstPartner?.[i]?.bigPartnerID] = { ...lstPartner?.[i] };

            const lstTransToSort = Array.isArray(lstPartner[i]?.jsnSupportTrans) ? lstPartner[i]?.jsnSupportTrans : [];
            for (let j = 0; j < lstTransToSort.length; j++) {
              if (String(lstTransToSort?.[j]?.strTrainingType) !== String(strTrainingType)) {
                continue;
              }

              lstPartnerTrans.push({
                ...lstTransToSort[j],
                bigPartnerID: lstPartner?.[i]?.bigPartnerID,
              });

              if (String(lstTransToSort?.[j]?.intSupportTransStatus) === String(appVariables.objSupportTransStatus.Send_New)) {
                ++intNumNew;
              }
              if (
                String(lstTransToSort?.[j]?.intSupportTransStatus) === String(appVariables.objSupportTransStatus.Under_Process) ||
                String(lstTransToSort?.[j]?.intSupportTransStatus) === String(appVariables.objSupportTransStatus.Send_New)
              ) {
                ++intWaitingNum;
              }
              if (String(lstTransToSort?.[j]?.intSupportTransStatus) === String(appVariables.objSupportTransStatus.Approved)) {
                ++intApprovedNum;
              }
              if (String(lstTransToSort?.[j]?.intSupportTransStatus) === String(appVariables.objSupportTransStatus.Rejected)) {
                ++intNumReject;
              }
            }
          }

          lstPartnerTrans.sort((prev, next) => new Date(next?.dtmCreatedDate || new Date()).getTime() - new Date(prev?.dtmCreatedDate || new Date()).getTime());

          state.lstTrans = lstPartnerTrans;
          state.objPartnerInfo = objPartnerInfo;

          state.intNumNew = intNumNew;
          state.intWaitingNum = intWaitingNum;
          state.intApprovedNum = intApprovedNum;
          state.intNumReject = intNumReject;

          const objCat = appFunctions.getCategoryCache();
          let lstSponsorFullStackTypeOption = [];
          let objSponsorFullStackTypeOptionLang = {};
          let lstSponsorManagerTypeOption = [];
          let objSponsorManagerTypeOptionLang = {};

          const fullstackKey = appVariables.objTrainingToSupport?.fullstack?.key;
          const managerKey = appVariables.objTrainingToSupport?.manager?.key;

          Object.entries(objCat?.Root?.trainingType?.[fullstackKey]?.sponser || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstSponsorFullStackTypeOption.push({ key: key, value: key });
              objSponsorFullStackTypeOptionLang[key] = {
                eng: value?.value?.eng + " - " + value?.studentNumber?.value?.eng + " " + dictionary?.components?.public?.join?.trainingCustomNumber?.eng,
                arb: value?.value?.arb + " - " + value?.studentNumber?.value?.arb + " " + dictionary?.components?.public?.join?.trainingCustomNumber?.arb,
              };
            }
          });

          Object.entries(objCat?.Root?.trainingType?.[managerKey]?.sponser || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstSponsorManagerTypeOption.push({ key: key, value: key });
              objSponsorManagerTypeOptionLang[key] = {
                eng: value?.value?.eng + " - " + value?.studentNumber?.value?.eng + " " + dictionary?.components?.public?.join?.managerCustomNumber?.eng,
                arb: value?.value?.arb + " - " + value?.studentNumber?.value?.arb + " " + dictionary?.components?.public?.join?.managerCustomNumber?.arb,
              };
            }
          });

          fields?.strSponsorTrainingType?.setOptions(
            Object.entries(appVariables.objTrainingToSupport)
              .filter(([key, value]) => value.key !== appVariables.objTrainingToSupport.manager_fullstack.key)
              .map(([key, value]) => ({ key: value.key, value: value.key })),
            {
              [fullstackKey]: objCat?.Root?.trainingType?.[fullstackKey]?.value,
              [managerKey]: objCat?.Root?.trainingType?.[managerKey]?.value,
            }
          );
          // fields?.strSponsorFullStackCatType?.setOptions(lstSponsorFullStackTypeOption, objSponsorFullStackTypeOptionLang);
          fields?.strSponsorManagerCatType?.setOptions(lstSponsorManagerTypeOption, objSponsorManagerTypeOptionLang);
          if (strTrainingType === appVariables.objTrainingToSupport?.fullstack?.key) {
            fields?.strSponsorFullStackCatType?.setOptions(lstSponsorFullStackTypeOption, objSponsorFullStackTypeOptionLang);
          } else if (strTrainingType === appVariables.objTrainingToSupport?.manager?.key) {
            fields?.strSponsorFullStackCatType?.setOptions(lstSponsorManagerTypeOption, objSponsorManagerTypeOptionLang);
          }

          setIsLoading(false);
          setState({ ...state });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "ERROR-initData");
          setIsLoading(false);
        }
      },

    openEditDialog:
      ({ state, setState, fields, strTrainingType }) =>
      (objTransInfo, intTranIndex) =>
      async () => {
        try {
          if (String(objTransInfo?.intSupportTransStatus) === String(appVariables?.objSupportTransStatus?.Send_New)) {
            let jsnSupportTrans = state.objPartnerInfo?.[objTransInfo?.bigPartnerID]?.jsnSupportTrans;
            let idx = jsnSupportTrans.findIndex((trans) => String(trans?.id) === String(objTransInfo?.id));

            if (idx > -1) {
              jsnSupportTrans[idx].intSupportTransStatus = appVariables?.objSupportTransStatus?.Under_Process;
              const result = await tblPartnerFetch(strPageInfo, appState, "aaiotAppUpdate", {
                objectCondition: {
                  bigPartnerID: objTransInfo?.bigPartnerID,
                },
                objectInput: {
                  jsnSupportTrans: jsnSupportTrans,
                  intSupportTransStatus: appVariables?.objSupportTransStatus?.Under_Process,
                  dtmUpdatedDate: new Date().toUTCString(),
                },
              });
              if (result.blnIsRequestSuccessful) {
                state.objPartnerInfo[objTransInfo?.bigPartnerID].jsnSupportTrans = jsnSupportTrans;
                state.lstTrans[intTranIndex].intSupportTransStatus = appVariables?.objSupportTransStatus?.Under_Process;
              }
            }
          }

          state.blnOpenEditModal = true;
          state.objSelectedPartnerInfo = state?.objPartnerInfo?.[objTransInfo?.bigPartnerID];
          state.intEditIndex = intTranIndex;

          state.lstPaymentVoucher = [];
          state.lstReceiptVoucher = [];
          fields.controller.resetForm();

          const strSponsorTypeFullstack = appFunctions.getSponsorTypeAccordingToTraineeNum(objTransInfo?.intTraineesCount);
          const strSponsorTypeManager = appFunctions.getSponsorTypeAccordingToTraineeNum(objTransInfo?.intTraineesCount, appVariables.objTrainingToSupport.manager.key);

          fields.strSponsorTrainingType.setValue(objTransInfo?.strTrainingType);
          fields.strSponsorFullStackCatType.setValue(strTrainingType === appVariables?.objTrainingToSupport?.fullstack?.key ? strSponsorTypeFullstack : strSponsorTypeManager);
          fields.strSponsorManagerCatType.setValue(strSponsorTypeManager);
          state.lstPaymentVoucher = Array.isArray(objTransInfo?.lstReceipt) ? objTransInfo?.lstReceipt : [];
          state.lstReceiptVoucher = Array.isArray(objTransInfo?.lstReceiptCatch) ? objTransInfo?.lstReceiptCatch : [];

          setState({ ...state });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "openEditDialog-ERROR");
        }
      },

    updateSupportTranToDB:
      ({ state, setState, isLoading, setIsLoading, fields, uploaderPayment, uploaderReceipt }) =>
      (intSupportTransStatus = appVariables.objSupportTransStatus.Approved, blnNeedModify = false) =>
      async () => {
        try {
          if (isLoading) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.fetchData_InProgress?.[lang], "warning");
            return;
          }

          setIsLoading(true);

          const bigPartnerID = state.lstTrans?.[state?.intEditIndex]?.bigPartnerID;
          const objPartnerInfo = state.objPartnerInfo?.[bigPartnerID];

          let jsnSupportTrans = Array.isArray(objPartnerInfo?.jsnSupportTrans) ? objPartnerInfo?.jsnSupportTrans : [];

          let idx = jsnSupportTrans.findIndex((trans) => String(trans?.id) === String(state.lstTrans?.[state.intEditIndex]?.id));

          if (idx < 0) {
            setIsLoading(false);
            return;
          }

          //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region upload file
          if (state.lstPaymentVoucher?.length) {
            const resultUploadPayment = await uploaderPayment?.uploadHandler("supportTransPayment");
            if (!resultUploadPayment) {
              setIsLoading(false);
              return;
            }
          }

          if (state.lstReceiptVoucher?.length) {
            const resultUploadReceipt = await uploaderReceipt?.uploadHandler("supportTransReceipt");
            if (!resultUploadReceipt) {
              setIsLoading(false);
              return;
            }
          }
          //#endregion
          //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region change counters
          let intWaitingNum = state.intWaitingNum;
          let intApprovedNum = state.intApprovedNum;
          let intNumReject = state.intNumReject;

          if (
            String(jsnSupportTrans?.[idx]?.intSupportTransStatus) === String(appVariables.objSupportTransStatus?.Send_New) ||
            String(jsnSupportTrans?.[idx]?.intSupportTransStatus) === String(appVariables.objSupportTransStatus?.Under_Process)
          ) {
            --intWaitingNum;
          }
          if (String(jsnSupportTrans?.[idx]?.intSupportTransStatus) === String(appVariables.objSupportTransStatus?.Rejected)) {
            --intNumReject;
          }
          if (String(jsnSupportTrans?.[idx]?.intSupportTransStatus) === String(appVariables.objSupportTransStatus?.Approved)) {
            --intApprovedNum;
          }
          if (String(intSupportTransStatus) === String(appVariables.objSupportTransStatus?.Send_New) || String(intSupportTransStatus) === String(appVariables.objSupportTransStatus?.Under_Process)) {
            ++intWaitingNum;
          }
          if (String(intSupportTransStatus) === String(appVariables.objSupportTransStatus?.Rejected)) {
            ++intNumReject;
          }
          if (String(intSupportTransStatus) === String(appVariables.objSupportTransStatus?.Approved)) {
            ++intApprovedNum;
          }
          //#endregion
          //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region update db

          const strSponsorTrainingType = jsnSupportTrans[idx].strTrainingType; //fields.strSponsorTrainingType.getValue()?.key;

          const strSponsorCatType_Modify = fields.strSponsorFullStackCatType?.getValue()?.key;
          const strSponsorManagerCatType = fields.strSponsorManagerCatType?.getValue()?.key;
          const strSponsorTypeFullstack = appFunctions.getSponsorTypeAccordingToTraineeNum(jsnSupportTrans[idx]?.intTraineesCount);
          const strSponsorTypeManager = appFunctions.getSponsorTypeAccordingToTraineeNum(jsnSupportTrans[idx]?.intTraineesCount, appVariables.objTrainingToSupport.manager.key);
          const strSponsorMainCatType = appFunctions.getSponsorTypeAccordingToTraineeNum(
            strSponsorTrainingType === appVariables.objTrainingToSupport?.manager?.key ? objPartnerInfo?.intManagerTrainCount : objPartnerInfo?.intTraineesCount,
            strSponsorTrainingType
          );

          const objCat = appFunctions.getCategoryCache();

          let intCountStudentNum = 0;
          let intPriceSystem = 0;
          let intCountStudent_Modify = 0;
          if (strSponsorTrainingType === appVariables.objTrainingToSupport?.manager?.key) {
            intCountStudent_Modify = Number(objCat?.Root?.trainingType?.[strSponsorTrainingType]?.sponser?.[strSponsorCatType_Modify]?.studentNumber?.value?.eng) || 0;
            intCountStudentNum = Number(objCat?.Root?.trainingType?.[strSponsorTrainingType]?.sponser?.[strSponsorTypeManager]?.studentNumber?.value?.eng) || 0;
            intPriceSystem = Number(objCat?.Root?.trainingType?.[strSponsorTrainingType]?.price?.value?.eng) || 0;
          } else {
            intCountStudent_Modify = Number(objCat?.Root?.trainingType?.[strSponsorTrainingType]?.sponser?.[strSponsorCatType_Modify]?.studentNumber?.value?.eng) || 0;
            intCountStudentNum = Number(objCat?.Root?.trainingType?.[strSponsorTrainingType]?.sponser?.[strSponsorTypeFullstack]?.studentNumber?.value?.eng) || 0;
            intPriceSystem = Number(objCat?.Root?.trainingType?.[strSponsorTrainingType]?.price?.value?.eng) || 0;
          }

          if (blnNeedModify) {
            intCountStudentNum = Number(fields.trainingNumberAdmin?.getValue());
            intPriceSystem = Number(fields.intTrainingProgPrice?.getValue()) / intCountStudentNum;
          }

          jsnSupportTrans[idx].dtmUpdatedDate = new Date().toUTCString();
          // jsnSupportTrans[idx].strTrainingType = strSponsorTrainingType;
          jsnSupportTrans[idx].intSupportTransStatus = intSupportTransStatus;
          jsnSupportTrans[idx].strSupportCatType = strSponsorMainCatType;
          jsnSupportTrans[idx].intTraineesCount = intCountStudentNum;
          jsnSupportTrans[idx].intTrainingProgPrice = intPriceSystem;
          jsnSupportTrans[idx].lstReceipt = state.lstPaymentVoucher;
          jsnSupportTrans[idx].lstReceiptCatch = state.lstReceiptVoucher;

          let intTraineesCount_Old = objPartnerInfo?.intTraineesCount;
          let intManagerTrainCount_Old = objPartnerInfo?.intManagerTrainCount;

          let intTraineesCount = objPartnerInfo?.intTraineesCount;
          let intManagerTrainCount = objPartnerInfo?.intManagerTrainCount;

          for (let i = 0; i < jsnSupportTrans.length; i++) {
            if (appFunctions.getDifferenceBetweenTwoDate(jsnSupportTrans?.[i]?.dtmCreatedDate, new Date(), "month") <= 4) {
              if (String(jsnSupportTrans?.[i]?.intSupportTransStatus) === String(appVariables.objSupportTransStatus?.Approved)) {
                if (String(jsnSupportTrans?.[i]?.strTrainingType) === String(appVariables.objTrainingToSupport.manager?.key)) {
                  if (jsnSupportTrans?.[i]?.intTraineesCount > intManagerTrainCount) {
                    intManagerTrainCount = jsnSupportTrans?.[i]?.intTraineesCount;
                  }
                } else {
                  if (jsnSupportTrans?.[i]?.intTraineesCount > intTraineesCount) {
                    intTraineesCount = jsnSupportTrans?.[i]?.intTraineesCount;
                  }
                }
              }
            }
          }

          if (intTraineesCount === intTraineesCount_Old && intManagerTrainCount === intManagerTrainCount_Old) {
            for (let i = 0; i < jsnSupportTrans.length; i++) {
              if (String(jsnSupportTrans?.[i]?.intSupportTransStatus) === String(appVariables.objSupportTransStatus?.Approved)) {
                if (String(jsnSupportTrans?.[i]?.strTrainingType) === String(appVariables.objTrainingToSupport.manager?.key)) {
                  if (jsnSupportTrans?.[i]?.intTraineesCount > intManagerTrainCount) {
                    intManagerTrainCount = jsnSupportTrans?.[i]?.intTraineesCount;
                  }
                } else {
                  if (jsnSupportTrans?.[i]?.intTraineesCount > intTraineesCount) {
                    intTraineesCount = jsnSupportTrans?.[i]?.intTraineesCount;
                  }
                }
              }
            }
          }

          if (intCountStudent_Modify > intTraineesCount) {
            intTraineesCount = intCountStudent_Modify;
          }
          if (intCountStudent_Modify > intManagerTrainCount) {
            intManagerTrainCount = intCountStudent_Modify;
          }

          const result = await tblPartnerFetch(strPageInfo, appState, "aaiotAppUpdate", {
            objectCondition: {
              bigPartnerID: bigPartnerID,
            },
            objectInput: {
              jsnSupportTrans: jsnSupportTrans,
              intSupportTransStatus: intSupportTransStatus,
              intTraineesCount: intTraineesCount_Old !== intTraineesCount ? intTraineesCount : undefined,
              intManagerTrainCount: intManagerTrainCount_Old !== intManagerTrainCount ? intManagerTrainCount : undefined,
              dtmUpdatedDate: new Date().toUTCString(),
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.cant_Update_Record?.[lang], "warning");
            setIsLoading(false);
            return;
          }
          //#endregion
          //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          state.objPartnerInfo[bigPartnerID].jsnSupportTrans = jsnSupportTrans;
          state.objPartnerInfo[bigPartnerID].intTraineesCount = intTraineesCount;
          state.objPartnerInfo[bigPartnerID].intManagerTrainCount = intManagerTrainCount;
          state.lstTrans[state.intEditIndex] = {
            ...state.lstTrans[state.intEditIndex],
            ...jsnSupportTrans[idx],
          };

          state.intWaitingNum = intWaitingNum;
          state.intApprovedNum = intApprovedNum;
          state.intNumReject = intNumReject;

          state.blnOpenEditModal = false;
          setState({ ...state });
          setIsLoading(false);

          Alert.viewAlert(dictionary.components.mngSupportTrans.transactionUpdated?.[lang], "success");
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "updateSupportTranToDB-ERROR");
        }
      },

    confirmUpdateStatus:
      ({ state, setState, isLoading, setIsLoading, fields, uploaderPayment, uploaderReceipt }) =>
      (intSupportTransStatus = appVariables.objSupportTransStatus.Approved, blnNeedModify = false) =>
      async () => {
        if (blnNeedModify) {
          fields.controller.isFormValid();

          if (
            !fields.transactionDecision.getValue()?.key ||
            !String(fields.trainingNumberAdmin.getValue())?.length ||
            Number.isNaN(Number(fields.trainingNumberAdmin.getValue())) ||
            !String(fields.intTrainingProgPrice.getValue())?.length ||
            Number.isNaN(Number(fields.intTrainingProgPrice.getValue()))
          ) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
            return;
          }

          if (!state.lstReceiptVoucher?.length) {
            Alert.viewAlert(dictionary.components.mngSupportTrans.uploadRecieptRequire?.[lang], "");
            return;
          }
        } else if (String(intSupportTransStatus) === String(appVariables.objSupportTransStatus.Approved)) {
          if (!state.lstReceiptVoucher?.length) {
            Alert.viewAlert(dictionary.components.mngSupportTrans.uploadRecieptRequire?.[lang], "");
            return;
          }
        }

        Confirmation.viewConfirmation(
          blnNeedModify
            ? dictionary.components.mngSupportTrans.confirmModify?.[lang]
            : String(intSupportTransStatus) === String(appVariables.objSupportTransStatus.Approved)
            ? dictionary.components.mngSupportTrans.confirmApprove?.[lang]
            : dictionary.components.mngSupportTrans.confirmReject?.[lang],
          handler.updateSupportTranToDB({ state, setState, isLoading, setIsLoading, fields, uploaderPayment, uploaderReceipt })(intSupportTransStatus, blnNeedModify),
          () => null,
          dictionary?.shared?.confirmationMsg?.yes?.[lang],
          dictionary?.shared?.confirmationMsg?.no?.[lang]
        );
      },

    getPartnerTransPlanTotalPrice:
      ({ objCat, state, fields }) =>
      () => {
        try {
          const bigPartnerID = state.lstTrans?.[state?.intEditIndex]?.bigPartnerID;
          const objPartnerInfo = state.objPartnerInfo?.[bigPartnerID];
          const strSponsorTrainingType = fields.strSponsorTrainingType?.getValue()?.key;

          const strSponsorFullStackCatType = fields.strSponsorFullStackCatType?.getValue()?.key;
          const strSponsorManagerCatType = fields.strSponsorManagerCatType?.getValue()?.key;

          const intCountStudentNumSystem =
            Number(
              objCat?.Root?.trainingType?.[strSponsorTrainingType]?.sponser?.[
                strSponsorTrainingType === appVariables.objTrainingToSupport.manager?.key ? strSponsorManagerCatType : strSponsorFullStackCatType
              ]?.studentNumber?.value?.eng
            ) || 0;
          const priceSystem = Number(objCat?.Root?.trainingType?.[strSponsorTrainingType]?.price?.value?.eng);

          return (priceSystem || 0) * (intCountStudentNumSystem || 0);
        } catch {
          return 0;
        }
      },

    sortResult:
      ({ state, fldSearch }) =>
      (objTrans, objPartnerInfo) => {
        try {
          if (
            String(appVariables.objSupportTransStatus?.Send_New) === String(objTrans?.intSupportTransStatus) ||
            String(appVariables.objSupportTransStatus?.Under_Process) === String(objTrans?.intSupportTransStatus)
          ) {
            if (String(state.bigCurrentTab) !== String(appVariables.objSupportTransStatus?.Send_New) && String(state.bigCurrentTab) !== String(appVariables.objSupportTransStatus?.Under_Process)) {
              return false;
            }
          } else {
            if (String(state.bigCurrentTab) !== String(objTrans?.intSupportTransStatus)) {
              return false;
            }
          }

          const strSearch = fldSearch?.strSearch?.getValue();
          if (strSearch) {
            if (String(objPartnerInfo?.jsnPartnerInfo?.strPlaceName)?.toLowerCase().trim().includes(String(strSearch)?.toLowerCase().trim())) {
              return true;
            } else if (String(objPartnerInfo?.jsnPartnerInfo?.strUserEmail)?.toLowerCase().trim().includes(String(strSearch)?.toLowerCase().trim())) {
              return true;
            } else if (String(objPartnerInfo?.jsnPartnerInfo?.strUserPhone)?.toLowerCase().trim().includes(String(strSearch)?.toLowerCase().trim())) {
              return true;
            } else if (String(appVariables.workSectorIDLabel?.[objPartnerInfo?.jsnPartnerInfo?.bigWorkSector]?.eng)?.toLowerCase().trim().includes(String(strSearch)?.toLowerCase().trim())) {
              return true;
            } else if (String(appVariables.workSectorIDLabel?.[objPartnerInfo?.jsnPartnerInfo?.bigWorkSector]?.arb)?.toLowerCase().trim().includes(String(strSearch)?.toLowerCase().trim())) {
              return true;
            } else if (String(strSearch).trim() === String(objTrans?.intTraineesCount).trim()) {
              return true;
            } else if (String(strSearch).trim() === String(objTrans?.intTraineesCount).trim() + " متدرب" && objTrans?.strTrainingType === appVariables?.objTrainingToSupport?.fullstack?.key) {
              return true;
            } else if (
              (String(strSearch).trim() === String(objTrans?.intTraineesCount).trim() + " اداري" || String(strSearch).trim() === String(objTrans?.intTraineesCount).trim() + " إداري") &&
              objTrans?.strTrainingType === appVariables?.objTrainingToSupport?.manager?.key
            ) {
              return true;
            } else if (
              (String(strSearch).trim() === "جديد" || String(strSearch).toLowerCase().trim() === "new") &&
              String(appVariables.objSupportTransStatus?.Send_New) === String(objTrans?.intSupportTransStatus)
            ) {
              return true;
            }

            return false;
          }

          return true;
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "ERROR-sortResult");
          return true;
        }
      },

    getPartnerSponsorPlanDetail:
      ({ objCat }) =>
      (objPartnerInfo, objTrans) => {
        let objDetail = {
          strFullstackTrainingLabel: "",
          strFullstackSponsorCatLabel: "",
          strFullstackSponsorType: "",
          intFullstackNumTrainee: 0,
          intFullstackNumTraineeSystem: 0,
          intFullstackPrice: 0,
          colorFullstack: "#111",

          strManagerTrainingLabel: "",
          strManagerSponsorCatLabel: "",
          strManagerSponsorType: "",
          intManagerNumTrainee: 0,
          intManagerNumTraineeSystem: 0,
          intManagerPrice: 0,
          colorManager: "#111",
        };

        const fullstackKey = appVariables.objTrainingToSupport?.fullstack?.key;
        const managerKey = appVariables.objTrainingToSupport?.manager?.key;

        if (objTrans?.strTrainingType === appVariables?.objTrainingToSupport?.fullstack?.key) {
          const strSponsorType = appFunctions.getSponsorTypeAccordingToTraineeNum(objTrans?.intTraineesCount);

          if (!strSponsorType) {
            return objDetail;
          }
          objDetail.intFullstackNumTrainee = objTrans?.intTraineesCount;
          objDetail.intFullstackNumTraineeSystem = Number(objCat?.Root?.trainingType?.[fullstackKey]?.sponser?.[strSponsorType]?.studentNumber?.value?.eng) || 0;
          objDetail.strFullstackSponsorCatLabel = objCat?.Root?.trainingType?.[fullstackKey]?.sponser?.[strSponsorType]?.value?.[lang];
          objDetail.strFullstackTrainingLabel = objCat?.Root?.trainingType?.[fullstackKey]?.value?.[lang];
          objDetail.strFullstackSponsorType = strSponsorType;
          objDetail.intFullstackPrice = Number(objCat?.Root?.trainingType?.[fullstackKey]?.price?.value?.eng);
          if (strSponsorType === appVariables.objSponserCat.main.key) {
            objDetail.colorFullstack = "#13bf3b";
          } else if (strSponsorType === appVariables.objSponserCat.golden.key) {
            objDetail.colorFullstack = "#f7d000";
          } else if (strSponsorType === appVariables.objSponserCat.silver.key) {
            objDetail.colorFullstack = "#bebebe";
          } else if (strSponsorType === appVariables.objSponserCat.bronze.key) {
            objDetail.colorFullstack = "#d3883e";
          }

          return objDetail;
        }

        if (objTrans?.strTrainingType === appVariables?.objTrainingToSupport?.manager?.key) {
          const strSponsorType = appFunctions.getSponsorTypeAccordingToTraineeNum(objTrans?.intTraineesCount, appVariables.objTrainingToSupport.manager.key);
          if (!strSponsorType) {
            return objDetail;
          }
          objDetail.intManagerNumTrainee = objTrans?.intTraineesCount;
          objDetail.intFullstackNumTraineeSystem = Number(objCat?.Root?.trainingType?.[managerKey]?.sponser?.[strSponsorType]?.studentNumber?.value?.eng) || 0;
          objDetail.strManagerSponsorCatLabel = objCat?.Root?.trainingType?.[managerKey]?.sponser?.[strSponsorType]?.value?.[lang];
          objDetail.strManagerTrainingLabel = objCat?.Root?.trainingType?.[managerKey]?.value?.[lang];
          objDetail.strManagerSponsorType = strSponsorType;
          objDetail.intManagerPrice = Number(objCat?.Root?.trainingType?.[managerKey]?.price?.value?.eng);
          if (strSponsorType === appVariables.objSponserCat.main.key) {
            objDetail.colorFullstack = "#13bf3b";
          } else if (strSponsorType === appVariables.objSponserCat.golden.key) {
            objDetail.colorFullstack = "#f7d000";
          } else if (strSponsorType === appVariables.objSponserCat.silver.key) {
            objDetail.colorFullstack = "#bebebe";
          } else if (strSponsorType === appVariables.objSponserCat.bronze.key) {
            objDetail.colorFullstack = "#d3883e";
          }

          return objDetail;
        }
        return objDetail;
      },

    getAddedBeforeLabel:
      ({ state }) =>
      (objTracking) => {
        try {
          const dtmCreatedDate = objTracking?.dtmCreatedDate;
          let intAddedBefore = Math.round(appFunctions.getDifferenceBetweenTwoDate(dtmCreatedDate, new Date().toUTCString(), "day"));

          let strLabel = "";
          if (intAddedBefore > 0) {
            strLabel = dictionary.components.mngPotentialSponsorship.days?.[lang];
            if (intAddedBefore === 2) {
              strLabel = dictionary.components.mngPotentialSponsorship.twoDay?.[lang];
            } else if (intAddedBefore < 2) {
              strLabel = dictionary.components.mngPotentialSponsorship.day?.[lang];
            }
          }

          if (strLabel && intAddedBefore) {
            return `( ${dictionary.components.mngPotentialSponsorship?.before?.[lang]} ${(intAddedBefore < 3 ? "" : intAddedBefore) + " " + strLabel} )`;
          }

          intAddedBefore = Math.round(appFunctions.getDifferenceBetweenTwoDate(dtmCreatedDate, new Date().toUTCString(), "hour"));
          if (intAddedBefore > 0) {
            strLabel = dictionary.components.mngPotentialSponsorship.hour?.[lang];
          }

          if (strLabel && intAddedBefore) {
            return `( ${dictionary.components.mngPotentialSponsorship?.before?.[lang]} ${(intAddedBefore < 3 ? "" : intAddedBefore) + " " + strLabel} )`;
          }

          intAddedBefore = Math.floor(appFunctions.getDifferenceBetweenTwoDate(dtmCreatedDate, new Date().toUTCString(), "minute"));
          if (intAddedBefore > 0) {
            strLabel = dictionary.components.mngPotentialSponsorship.minute?.[lang];
          }
          if (strLabel && intAddedBefore) {
            return `( ${dictionary.components.mngPotentialSponsorship?.before?.[lang]} ${(intAddedBefore < 3 ? "" : intAddedBefore) + " " + strLabel} )`;
          }

          return `( ${dictionary.components.mngPotentialSponsorship?.now?.[lang]} )`;
        } catch {
          return "-";
        }
      },

    exportExcelFile:
      ({ state }) =>
      () => {
        var workbook = XLSX.utils.book_new();
        var worksheet_data = [
          lang === "arb"
            ? ["الرقم التعريفي", "تاريخ الإنشاء", "تاريخ التحديث", "نوع التدريب", "عدد المتدربين", "نوع الدعم", "سعر التدريب (لكل متدرب)", "حالة العملية", "إيصال الدفع", "إيصال الاستلام"]
            : ["id", "Created Date", "Updated Date", "Training Type", "Trainees Count", "Support Type", "Training Price (per trainee)", "Transaction Status", "Payment Voucher", "Receipt Voucher"],
        ];

        (state?.lstTrans || [])?.forEach((child) => {
          if (child?.intSupportTransStatus === appVariables.objSupportTransStatus?.Approved) {
            if (child?.lstReceipt) {
              child.PaymentVoucher = appVariables.App_Server_Url_DownloadFiles + child?.lstReceipt?.[0]?.path;
              delete child?.lstReceipt;
            }
            if (child?.lstReceiptCatch) {
              child.ReceiptVoucher = appVariables.App_Server_Url_DownloadFiles + child?.lstReceiptCatch?.[0]?.path;
              delete child?.lstReceiptCatch;
            }
            child.intSupportTransStatus = dictionary?.components?.account?.objStatus?.[child.intSupportTransStatus]?.[lang];
            delete child?.bigPartnerID;
            delete child?.strTrainingType;

            child.strSupportCatType = dictionary?.shared?.sponserLabelCat?.[child.strSupportCatType]?.[lang];

            worksheet_data?.push(Object?.values(child));
          }
        });
        var worksheet = XLSX.utils.aoa_to_sheet(worksheet_data);
        worksheet["!autofilter"] = { ref: "F1" };
        workbook.SheetNames.push("support_transction");
        workbook.Sheets["support_transction"] = worksheet;
        XLSX.writeFile(workbook, `support_transction_${appFunctions.getDateUSFormat(appFunctions?.getCurrentDate())}.xlsx`);
      },
    onChangeTraneeCount:
      ({ objCat, fields, strTrainingType }) =>
      (e) => {
        try {
          if (fields.trainingNumberAdmin?.getValue() && !isNaN(fields.trainingNumberAdmin?.getValue())) {
            const priceSystem = Number(objCat?.Root?.trainingType?.[strTrainingType]?.price?.value?.eng);
            fields.intTrainingProgPrice?.setValue(priceSystem * Number(fields.trainingNumberAdmin?.getValue()));
          } else {
            fields.intTrainingProgPrice?.setValue("");
          }
        } catch (error) {}
      },
  };

  return handler;
}

/*
strTrainingType: String(trainingType).toLowerCase();
intTraineesCount : trainingNumber,
intTrainingProgPrice : trainingNumber * objTrainingFromCat?.price?.value?.[lang],
*/

///tblPartnershipTrack
