import React from "react";
import { Button, Container, Grid, Paper, Typography, useNavigate } from "@cbmisorg/client-app";
import * as appColor from "../../../../../appHelper/appColor";
import heroposter from "../../../../../assets/images/heroImg.jpg";

import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import objAppRouting from "../../../../../appHelper/routing/appRouting";
import { generatePath } from "react-router-dom";

const labels = dictionary?.components?.public?.landingPage?.home?.hero;
// const video = "https://aaiot.s3.eu-central-1.amazonaws.com/hero2.mp4";

const lstVideos = [
  "https://aaiot.s3.eu-central-1.amazonaws.com/hero2.mp4",
  "https://static.vecteezy.com/system/resources/previews/033/955/269/mp4/digital-city-concept-artificial-intelligence-technology-in-smart-city-free-video.mp4",
  "https://static.vecteezy.com/system/resources/previews/002/143/037/mp4/3d-rendering-brain-of-artificial-intelligence-hardware-free-video.mp4",
  "https://static.vecteezy.com/system/resources/previews/036/142/824/mp4/ai-artificial-intelligence-technology-smart-robot-ai-artificial-intelligence-by-enter-command-prompt-for-generates-something-futuristic-technology-transformation-chatbot-assistant-secretary-free-video.mp4",
  "https://static.vecteezy.com/system/resources/previews/042/383/337/mp4/ai-generated-green-natural-eco-friendly-tree-and-computer-technology-on-an-abstract-high-tech-futuristic-background-of-microchips-and-computer-circuit-boards-with-transistors-free-video.mp4",
  "https://static.vecteezy.com/system/resources/previews/004/747/810/mp4/technology-artificial-intelligence-concept-free-video.mp4",
  "https://static.vecteezy.com/system/resources/previews/002/020/907/mp4/fingerprint-scanning-futuristic-technology-free-video.mp4",
  "https://static.vecteezy.com/system/resources/previews/001/797/448/mp4/futuristics-mesh-human-ai-and-vr-faces-free-video.mp4",
  "https://static.vecteezy.com/system/resources/previews/004/827/218/mp4/abstract-technology-big-data-background-concept-motion-of-digital-data-flow-transferring-of-big-data-transfer-and-storage-of-data-sets-block-chain-server-hi-speed-internet-free-video.mp4",
  "https://static.vecteezy.com/system/resources/previews/004/827/214/mp4/technology-internet-of-things-concept-free-video.mp4",
  "https://static.vecteezy.com/system/resources/previews/004/827/055/mp4/finger-print-scanning-identification-system-biometric-authorizations-and-approval-concept-of-the-future-of-security-and-password-control-through-fingerprints-in-an-advanced-technological-future-free-video.mp4",
  "https://static.vecteezy.com/system/resources/previews/004/827/598/mp4/artificial-intelligence-concept-free-video.mp4",
];
const classes = {
  title: {
    sm: {
      fontSize: "var(--cbmis-h5-font-size)!important",
    },
  },
  background: {
    backgroundRepeat: "no-repeat !important",
    backgroundPosition: "center !important",
    backgroundSize: "cover !important",
    width: "100% !important",
    height: "99vh !important",
    sm: {
      height: "auto !important",
      minHeight: "180px",
    },
  },
  backgroundBlack: {
    "&before": {
      content: "",
      position: "absolute",
      width: "100%",
      height: "100%",
      minHeight: "200px !important",
      display: "block",
      // background: "#000",
      opacity: ".5",
      borderRadius: "0",
      background: `linear-gradient(90deg, hsla(50, 68%, 93%, 1) 0%, ${appColor.App_orang_color} 50%, ${appColor.App_Primary_Color} 100%)`,
    },
  },
};

function Hero({ lang, blnVideo, appState, appDispatch }) {
  const navigate = useNavigate();
  const goToReg = (url) => ()=>{
    navigate(generatePath(url, { trainingType: "fullstack" }));
  };
  return (
    <React.Fragment>
      <Container sx={{ ...classes?.background }} m-0>
        <video autoPlay muted loop playsInline controls={false} preload="none" className="heroVideo" poster={heroposter}>
          <source src={lstVideos[1]} type="video/mp4" />
        </video>
        <Grid container justify="center" className="hightFull" sx={classes?.backgroundBlack}>
          <Grid item xs="12" md="10" lg="9" alignSelf="center">
            <Container py-2>
              <Grid container justify="center" spacing={3}>
                <Grid item xs="12" pt-sm-10 pt-xs-10>
                  <Typography as="h3" color="light" sx={classes?.title} className="textcenter fontHand lineUp inset">
                    {labels?.slide4?.title?.[lang]}
                  </Typography>
                </Grid>
                <Grid item xs="0" md="12">
                  <Typography as="h6" color="light" className="textcenter lineUp">
                    {labels?.slide4?.body?.[lang]}
                  </Typography>
                </Grid>
                <Grid item xs="0" md="12" container justify={"center"} sx={{ position: "absolute", top: "100%" }} mt-10 spacing={2}>
                  <Grid item xs="6" sx={{ alignSelf: "stretch" }}>
                    <Paper elevation={0} outline={1} sx={{ borderRadius: "1rem", height: "100%", background: "", borderColor: "var(--cbmis-primaty)" }} p-0>
                      <Grid container justify={"center"} py-2>
                        <Grid item xs="7">
                          <img src={require("../../../../../assets/images/Commercial.png")} alt="company" width={"90%"} />
                        </Grid>
                        <Grid item xs="12">
                          <Typography as="subtitle2" color="primary" className="textcenter">
                            {labels?.company?.title?.[lang]}
                          </Typography>
                        </Grid>
                        <Grid item xs="12">
                          <Typography as="body2" color="primary" className="textcenter">
                            {labels?.company?.subTitle?.[lang]}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Button size="sm" px-2 label={labels?.company?.button?.[lang]} sx={{ borderRadius: "1rem" }} onClick={goToReg(objAppRouting?.Public_Join_Partners?.url)}/>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs="6" sx={{ alignSelf: "stretch" }}>
                    <Paper elevation={0} outline={1} sx={{ borderRadius: "1rem", height: "100%", background: "", borderColor: "var(--cbmis-primaty)" }} p-0>
                      <Grid container justify={"center"} py-2>
                        <Grid item xs="7">
                          <img src={require("../../../../../assets/images/it-training.png")} alt="training" width={"90%"} />
                        </Grid>
                        <Grid item xs="12">
                          <Typography as="subtitle2" color="primary" className="textcenter">
                            {labels?.training?.title?.[lang]}
                          </Typography>
                        </Grid>
                        <Grid item xs="12">
                          <Typography as="body2" color="primary" className="textcenter">
                            {labels?.training?.subTitle?.[lang]}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Button size="sm" px-2 label={labels?.training?.button?.[lang]} sx={{ borderRadius: "1rem" }}  onClick={goToReg(objAppRouting?.Join_Trainee?.url)}/>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Container>
      <Grid container justify={"center"}>
        <Grid item xs="12" md="0">
          <Container>
            {/* <Grid container justify={"center"}>
              <Grid item xs="12">
                <Grid
                  container
                  justify={"center"}
                  sx={{ borderRadius: "12px", boxShadow: `0 0 6px 0 #07456f !important`, background: "#fefefe !important", border: "1px solid var(--cbmis-primary) !important" }}
                  py-6
                >
                  <Grid item xs="12">
                    <Typography as="subtitle2" color="dark">
                      {labels?.slide4?.body?.[lang]}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid> */}
            <Grid container justify={"center"}>
              <Grid item xs="12">
                <Typography as="subtitle2" color="dark" className="textcenter">
                  {labels?.slide4?.body?.[lang]}
                </Typography>
              </Grid>
              <Grid item xs="6" sx={{ alignSelf: "stretch" }}>
                <Paper elevation={0} outline={1} sx={{ borderRadius: "1rem", height: "100%" }} p-0>
                  <Grid container justify={"center"} py-2>
                    <Grid item xs="12">
                      <img src={require("../../../../../assets/images/Commercial.png")} alt="company" width={"90%"} />
                    </Grid>
                    <Grid item xs="12">
                      <Typography as="subtitle2" color="primary" className="textcenter">
                        {labels?.company?.title?.[lang]}
                      </Typography>
                    </Grid>
                    <Grid item xs="12">
                      <Typography as="body2" color="primary">
                        {labels?.company?.subTitle?.[lang]}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button size="sm" px-2 label={labels?.company?.button?.[lang]} sx={{ borderRadius: "1rem" }} onClick={goToReg(objAppRouting?.Public_Join_Partners?.url)} />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs="6" sx={{ alignSelf: "stretch" }}>
                <Paper elevation={0} outline={1} sx={{ borderRadius: "1rem", height: "100%" }} p-0>
                  <Grid container justify={"center"} py-2>
                    <Grid item xs="12">
                      <img src={require("../../../../../assets/images/it-training.png")} alt="training" width={"90%"} />
                    </Grid>
                    <Grid item xs="12">
                      <Typography as="subtitle2" color="primary" className="textcenter">
                        {labels?.training?.title?.[lang]}
                      </Typography>
                    </Grid>
                    <Grid item xs="12">
                      <Typography as="body2" color="primary">
                        {labels?.training?.subTitle?.[lang]}
                      </Typography>
                    </Grid>
                    <Grid item xs="12">
                      <Typography as="subtitle2" color="primary"></Typography>
                    </Grid>
                    <Grid item>
                      <Button size="sm" px-2 label={labels?.training?.button?.[lang]} sx={{ borderRadius: "1rem" }} onClick={goToReg(objAppRouting?.Public_Join_Partners?.url)}/>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Hero;
