import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, AccordionTab, Box, Button, Container, Glass, Grid, Icon, Paper, Typography, useNavigate } from "@cbmisorg/client-app";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import achievements from "../../../../../assets/images/achievements.jpg";
import { appStyle } from "../../../../../appHelper/appStyle";
import * as appColor from "../../../../../appHelper/appColor";
import curvyLines from "../../../../../assets/images/curvyLines.png";

const labels = dictionary?.components?.public?.landingPage?.home?.achievements;

const classes = {
    sideBox: {
      backgroundColor: "#d7e9f775",
      backgroundImage: `url(${curvyLines})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right",
    },
  };

function Achievements({ lang, appState }) {
  return (
    <React.Fragment>
      <Grid container py-10 my-8 id="achievements" px-0 spacing={0} sx={classes?.sideBox}>
        <Grid item xs="12" container justify="center">
          <Container pb-10>
            <Grid container spacing={2}>
              <Grid item xs="12" pb-10 mb-5>
                <Typography as="h4" sx={appStyle?.title2}>
                  {labels?.title?.[lang]}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs="12" className="accordionTab_landingPage" dir={lang === "arb" ? "rtl" : "ltr"}>
                <Accordion mx-0>
                  {labels?.lst?.map((item, index) => {
                    return (
                      <AccordionTab my-1 key={index}>
                        <AccordionSummary expandIcon={<Icon icon="checkCircle" />} py-3 px-3 sx={appStyle?.accordionSummary}>
                          <Typography as="body1" px-2 color={appColor?.App_Dark_Color}>
                            {item?.title?.[lang]}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={appStyle?.accordionDetails}>
                          <Typography as="body2">{item?.describe?.[lang]}</Typography>
                        </AccordionDetails>
                      </AccordionTab>
                    );
                  })}
                </Accordion>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Achievements;
