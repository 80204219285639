import React, { useContext, useState } from "react";
import { AppContext } from "../../../../configuration/contextapi/AppContext";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import { Accordion, AccordionDetails, AccordionSummary, AccordionTab, Avatar, Button, Container, Grid, Icon, Line, Paper, Typography, useNavigate } from "@cbmisorg/client-app";
import AnnouncementDisplay from "../../admin/mngAnnouncement/mngAnnouncement.display";
import * as appFunctions from "../../../../appHelper/appFunctions";
import * as appColor from "../../../../appHelper/appColor";

import RouteProfile from "../../shared/profile/RouteProfile";
import objAppRouting from "../../../../appHelper/routing/appRouting";
import UploaderReader from "../../../sharedUi/uploader/UploadRead";

//#region style
const classes = {
  readMore: {
    overflow: "hidden !important",
    whiteSpace: "nowrap !important",
    wordWrap: "break-word !important",
    lineHeight: "1.5 !important",
    lineClamp: "5 !important",
    height: `calc(15px * 1.5 * 1)`,
    maxHeight: `calc(15px * 1.5 * 5)`,
    transition: "max-height .5s ease !important",
    textOverflow: "ellipsis !important",
  },
  announcementPaper: {
    boxShadow: "none !important",
    borderRadius: "calc(20 * 1px) !important",
    backgroundColor: "#ffffff55 !important",
    border: `1px solid ${appColor.App_Primary_Color}`,
  },
  card: {
    position: "relative !important",
    display: "block !important",
    height: "100% !important",
    borderRadius: "calc(20 * 1px) !important",
    overflow: "hidden !important",
    textDecoration: "none !important",
    boxShadow: "none !important",
    padding: "0 !important",
    cursor: "pointer !important",
    backgroundColor: "#DFF5FF !important",
  },
  cardImage: {
    width: "100%",
    minHeight: "250px",
    height: "auto",
    maxHeight: "250px",
  },
  cardOverlay: {
    position: "absolute",
    bottom: "0",
    left: "0",
    right: "0",
    zIndex: "1",
    borderRadius: "calc(20 * 1px)",
    backgroundColor: "#fff",
    transform: "translateY(100%)",
    transition: ".2s ease-in-out",
  },
  cardHeader: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    padding: "1em",
    borderRadius: "calc(20 * 1px) 0 0 0",
    backgroundColor: "#fff",
    transform: "translateY(-100%)",
    transition: ".2s ease-in-out",
  },
  cardArc: {
    width: "80px",
    height: "80px",
    position: "absolute",
    bottom: "100%",
    right: "0",
    zIndex: 1,
  },
  cardTitle: {
    margin: "0 0 .3em",
    color: "#6A515E",
  },
  cardDescription: {
    padding: "0 2em 2em",
    margin: 0,
    color: appColor.App_Dark_blue,
    fontFamily: "MockFlowFont",
    display: " -webkit-box",
    webkitBoxOrient: "vertical",
    webkitLineClamp: 3,
    overflow: "hidden",
  },
  statusBadge: {
    fontSize: "8px",
    padding: "0 3px",
    borderRadius: "20px",
    color: "#fff",
    margin: "0px 1px 0px",
  },
};
//#endregion


const objSevesesId = {
  profile: 1,
  resume: 2,
  portfolio: 3,
  employment: 4,
  freelance: 5,
  training: 6,
  startup: 7,
  capstone: 8,
  schedule: 9,
};
const label = dictionary?.components?.account;
function AccountEntrepreneur() {
  const { appState } = useContext(AppContext);
  const lang = appState?.clientInfo?.strLanguage;
  const navigate = useNavigate();
  const [state, setState] = useState({
    indexCardOpen: -1,
    objConfig: {
      blnNewServices: false,
      blnNewTraining: true,
    },
    lstServices: [
      {
        id: objSevesesId?.profile,
        title: { eng: "Profile", arb: "المعلومات الشخصية" },
        dec: {
          eng: "Managing personal information, previous work experiences, and creating an electronic resume",
          arb: "إدارة المعلومات الشخصية وخبرات العمل السابقة وإنشاء سيرة ذاتية إلكترونية",
        },
        icon: "accountCircle",
        img: "https://cdni.iconscout.com/illustration/premium/thumb/user-profile-3465537-2928727.png?f=webp",
        url: objAppRouting?.profile?.url,
      },
      {
        id: objSevesesId?.resume,
        title: { eng: "Resume", arb: "السيرة الذاتية" },
        dec: {
          eng: "Providing multiple templates for creating a standout resume distinguished by clarity, eye-catching formatting, and a focus on key achievements and skills.",
          arb: "توفير عدة قوالب لإنشاء سيرة ذاتية مميزة تتميز بالوضوح والتنسيق الملفت للنظر والتركيز على الإنجازات والمهارات الأساسية.",
        },
        icon: "ArticleOutlined",
        img: "https://cdni.iconscout.com/illustration/premium/thumb/male-and-female-developers-working-on-app-testing-4487012-3738419.png?f=webp",
      },
      {
        id: objSevesesId?.portfolio,
        title: { eng: "Portfolio", arb: "حقيبة المشاريع" },
        dec: {
          eng: "Managing and presenting all completed projects to improve job opportunities",
          arb: "إدارة وعرض جميع المشاريع المنجزة من أجل تحسين فرص الدخول لسوق العمل",
        },
        icon: "workOutline",
        img: "https://www.someka.net/wp-content/uploads/2023/12/Competency-Assessment-Someka-Blog-S04.png",
      },
      {
        id: objSevesesId?.employment,
        title: { eng: "Employment Requests", arb: "طلبات التوظيف" },
        dec: {
          eng: "Displaying all job requests submitted to me by local and international programming companies",
          arb: "عرض جميع طلبات التوظيف المقدمة من قبل شركات البرمجة المحليه والخارجية",
        },
        icon: "BadgeOutlined",
        img: "https://cdni.iconscout.com/illustration/premium/thumb/online-job-search-5015545-4185620.png?f=webp",
      },
      {
        id: objSevesesId?.freelance,
        title: { eng: "Freelance Projects", arb: "المشاريع المستقلة" },
        dec: {
          eng: "Viewing all projects and tasks proposed by individuals and programming companies",
          arb: "عرض جميع المشاريع والمهام البرمجية المطروحة من قبل الأفراد وشركات البرمجة",
        },
        icon: "TapAndPlay",
        img: "https://www.lauruss.com/wp-content/uploads/2022/02/Top-ASP.NET-Development-Company.svg",
      },
      {
        id: objSevesesId?.training,
        title: { eng: "Training, Development", arb: "التدريب والتطوير" },
        dec: {
          eng: "View the full stack training for building a secure application that operates on all devices and operating systems",
          arb: "عرض محتوى التدريب الشامل لإنشاء تطبيق واحد آمن يعمل على جميع الأجهزة وأنظمة التشغيل",
        },
        icon: "AutoStoriesOutlined",
        img: "https://cdni.iconscout.com/illustration/premium/thumb/employee-training-3839613-3202628.png?f=webp",
      },
      {
        id: objSevesesId?.startup,
        title: { eng: "Startup Hub", arb: "دعم رواد الاعمال" },
        dec: {
          eng: "Accessing the integrated platform designed to support entrepreneurs and startups in creating and managing various applications",
          arb: "الدخول للمنصة المتكاملة المصممة لدعم رواد الأعمال و الشركات الناشئة في إنشاء وإدارة مختلف التطبيقات",
        },
        icon: "EmojiObjectsOutlined",
        img: "https://files.readme.io/a399f0f-IL.svg",
      },
      {
        id: objSevesesId?.capstone,
        title: { eng: "Capstone Project", arb: "مشروع التخرج" },
        dec: {
          eng: "The platform provides all the tools and technologies necessary to complete graduation projects quickly and professionally",
          arb: "توفر المنصة جميع الأدوات والتقنيات التي تضمن إنجاز مشاريع التخرج بفترة قصيرة وبإحترافية",
        },
        icon: "SchoolOutlined",
        img: "https://media.licdn.com/dms/image/D5612AQG9MJe3YRtEGw/article-cover_image-shrink_720_1280/0/1674712621668?e=2147483647&v=beta&t=uHifyl4O2FVRn_98LsDRs5w4Wabtz2gWJKSGmKo8bIc",
      },
      {
        id: objSevesesId?.schedule,
        title: { eng: "Task schedule", arb: "إدارة المهام" },
        dec: {
          eng: "It help you break your goals down into smaller, achievable tasks and prioritize the most important tasks to make the best use of your time and resources.",
          arb: "فهو يساعدك على تقسيم أهدافك إلى مهام أصغر يمكن تحقيقها وتحديد أولويات المهام الأكثر أهمية لتحقيق أقصى استفادة من وقتك ومواردك.",
        },
        icon: "today",
        img: "https://cdni.iconscout.com/illustration/premium/thumb/the-services-management-3947371-3266473.png?f=webp",
      },
    ],
    lstTrainings: [
      {
        title: { eng: "Basic programming", arb: "أساسيات البرمجة" },

        icon: "ArticleOutlined",
        img: "https://static.jobscan.co/blog/uploads/Resume-Margins-300x343.jpg",
      },
      {
        title: { eng: "Portfolio", arb: "حقيبة المشاريع" },
        dec: {
          eng: "Managing and presenting all completed projects to improve job opportunities",
          arb: "إدارة وعرض جميع المشاريع المنجزة من أجل تحسين فرص الدخول لسوق العمل",
        },
        icon: "workOutline",
        img: "https://i0.wp.com/showitbetter.co/wp-content/uploads/2022/09/cover-architecture-templates-9.jpg?fit=750%2C750&ssl=1",
      },
    ],

    lstNotification: [],
    sectionSelectedKey: "account",
  });

  const { objAnnouncement, getIsLiveMeetingLive, goToExternalMeeting, getIsMeetingAvailable } = AnnouncementDisplay();

  const handelMouseEnter = (index) => () => {
    state.indexCardOpen = index;
    setState({ ...state });
  };

  const handelMouseleve = (index) => () => {
    state.indexCardOpen = -1;

    setState({ ...state });
  };

  return (
    <React.Fragment>
      {state?.sectionSelectedKey === "account" ? (
        <Container my-auto>
           {!(objAnnouncement.lstNotification || [])?.length ? null : (
          <Grid container spacing={3}>
            {!(objAnnouncement.lstNotification || [])?.filter((msg) => !msg?.strAnnouncementBody?.arb && !msg?.strAnnouncementBody?.eng)?.length ? null : (
              <Grid item xs="12">
                <Paper sx={classes?.announcementPaper} mx-2 px-0 py-0 >
                  <Grid container>
                    <Grid item xs="12" container >
                      <Grid item xs="12">
                        {(objAnnouncement.lstNotification || [])
                          ?.filter((msg) => !msg?.strAnnouncementBody?.arb && !msg?.strAnnouncementBody?.eng)
                          ?.map((msg, intIndex) => (
                            <Typography as="body2" pb-1>
                              {msg?.strAnnouncementTitle?.[lang]}
                            </Typography>
                          ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}
            {!(objAnnouncement.lstNotification || [])?.filter((msg) => msg?.strAnnouncementBody?.arb || msg?.strAnnouncementBody?.eng)?.length ? null : (
              <Grid item xs="12">
                <Paper sx={classes?.announcementPaper} mx-2 p-0>
                  <Grid container>
                    <Grid item xs="12" container>
                      <Grid item xs="12" py-0>
                        <Typography as="subtitle2" color={appColor.App_Primary_Color} my-0 py-0></Typography>
                      </Grid>
                      <Grid item xs="12">
                        <Accordion dir={lang === "arb" ? "rtl" : "ltr"} className="account-accordion">
                          {(objAnnouncement.lstNotification || [])
                            ?.filter((msg) => msg?.strAnnouncementBody?.arb || msg?.strAnnouncementBody?.eng)
                            ?.map((msg, intIndex) => (
                              <AccordionTab sx={{ background: "transparent", boxShadow: "none !important" }} key={intIndex} px-0>
                                <AccordionSummary
                                  expandIcon={<Icon icon="campaign" color={appColor.App_Primary_Color} />}
                                  sx={{ borderColor: "#fefef", boxShadow: "none !important", "&checked": { background: "red !important" } }}
                                  py-1
                                >
                                  <Typography as="body2" pt-0>
                                    {msg?.strAnnouncementTitle?.[lang]}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails px-3 sx={{ background: "#fff !important" }}>
                                  <Grid container>
                                    <Grid item xs="12">
                                      <Typography as="caption">{msg?.strAnnouncementBody?.[lang]}</Typography>
                                    </Grid>
                                    {!msg?.lstFile?.length ? null : (
                                      <Grid item xs={12} p-2>
                                        <UploaderReader lstFiles={msg?.lstFile} />
                                      </Grid>
                                    )}
                                  </Grid>
                                </AccordionDetails>
                              </AccordionTab>
                            ))}
                        </Accordion>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}
          </Grid>
        )}

<Grid container spacing={3} px-1>
          {(objAnnouncement.lstMeeting || [])
            .filter((liveSession) => getIsLiveMeetingLive(liveSession) || getIsMeetingAvailable(liveSession))
            .map((liveSession, intIndex) => {
              return (
                <Grid item xs="12" md={objAnnouncement.lstMeeting.length > 2 ? 4 : 12} key={intIndex}>
                  <Paper key={intIndex} sx={classes?.announcementPaper}>
                    <Grid container spacing={2} px-3 m-auto justify={"center"}>
                      <Grid item xs="12" py-0>
                        <Typography as="subtitle2" color={appColor.App_Primary_Color} my-0 p-0>
                          {label?.liveSession?.[lang]}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} container my-auto>
                        <Grid item xs="12">
                          <Typography as="caption" sx={{ fontWeight: "500" }}>
                            {liveSession?.strAnnouncementTitle?.[lang]}
                          </Typography>
                        </Grid>
                        <Grid item xs="12">
                          <Typography as="overline" color={appColor.App_Primary_Color}>
                            {liveSession?.strTime?.[lang]
                              ? liveSession?.strTime?.[lang]
                              : `${appFunctions.getDateUSFormat(appFunctions.getCurrentDate(liveSession?.dtmFrom).toLocaleString(), true)} - ${appFunctions.getDateUSFormat(
                                  appFunctions.getCurrentDate(liveSession?.dtmTo).toLocaleString())}`}
                          </Typography>
                        </Grid>
                        <Grid item xs="12" pb-0>
                          <Typography as="caption" sx={{ height: `calc(15px * 1.5 * 3)`, overflow: "hidden" }}>
                            {liveSession?.strAnnouncementBody?.[lang]}
                          </Typography>
                        </Grid>
                        {!liveSession?.lstFile?.length ? null : (
                          <Grid item xs={12} px-2 pt-0 mt-0>
                            <UploaderReader lstFiles={liveSession?.lstFile} />
                          </Grid>
                        )}
                        {!getIsLiveMeetingLive(liveSession) ? null : (
                          <Grid item alignItems="center" px-0 m-auto>
                            <Button size="sm" label={label?.joinBtn?.[lang]} sx={classes.bgColor} onClick={goToExternalMeeting(liveSession?.strMeetingLink)} m-0 />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              );
            })}
        </Grid>
        <Line />
          <Line />

          <React.Fragment>
            <Grid container alignSelf="start" justify="flex-start" spacing="2">
              <Grid item xs={"12"}>
                <Grid container justify="flex-start" spacing={3}>
                  {state?.lstServices?.map((service, ind) => {
                    if (appState?.userInfo?.blnIsManager && !appState?.userInfo?.blnIsStartUp && !appState?.userInfo?.blnIsStudent && service?.id === objSevesesId?.capstone) return null;
                    return (
                      <Grid item xs={12} sm="6" md="4" lg="3" key={ind}>
                        <Paper sx={classes?.card} className="serv-card-account">
                          <img src={service.img} style={classes?.cardImage} alt="" />
                          <div
                            style={classes?.cardOverlay}
                            className={`cardOverlay ${state?.indexCardOpen === ind ? "cardOverlayHovered" : ""}`}
                            onMouseEnter={handelMouseEnter(ind)}
                            onMouseLeave={handelMouseleve(ind)}
                          >
                            <div style={classes?.cardHeader} className="cardHeader">
                              <svg style={classes?.cardArc} className="cardArc">
                                <path />
                              </svg>
                              <Grid container>
                                <Grid item xs="3" md="auto">
                                  <Avatar className="servBgColor" sx={{ width: "35px", height: "35px" }} onTouchEnd={state?.indexCardOpen === ind ? handelMouseleve(ind) : handelMouseEnter(ind)}>
                                    <Icon icon={service?.icon} color="#fff" size="30px" />
                                  </Avatar>
                                </Grid>
                                <Grid item xs="8" mt-1>
                                  <Typography as="body2" sx={classes?.cardTitle}>
                                    {service?.title?.[lang]}
                                  </Typography>
                                </Grid>
                                {state?.indexCardOpen !== ind ? null : (
                                  <Grid item xs="1" sx={{ justifySelf: "end", zIndex: 1 }}>
                                    <Icon
                                      icon="openInNew"
                                      color="primary"
                                      disabled={!service?.url}
                                      onClick={() => {
                                        if (service?.url) {
                                          navigate(service?.url);
                                        }
                                      }}
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            </div>
                            <Grid container>
                              <Grid item xs="12">
                                <Typography as="caption" sx={classes?.cardDescription}>
                                  {service?.dec?.[lang]}
                                </Typography>
                              </Grid>
                            </Grid>
                          </div>
                        </Paper>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
          <Grid container pb-10>
            <Grid item xs={12} pb-5 />
          </Grid>
        </Container>
      ) : (
        <RouteProfile header={false} />
      )}
    </React.Fragment>
  );
}

export default AccountEntrepreneur;
