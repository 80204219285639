import { Alert, Confirmation } from "@cbmisorg/client-app";
import * as appFunctions from "../../../../../appHelper/appFunctions";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import { generateQueries } from "../../../../../appHelper/appQuery/GenerateQuery";
import objAppRouting from "../../../../../appHelper/routing/appRouting";
import { App_ID, App_Server_Url_DownloadFiles, aaiot_System_ID, } from "../../../../../appHelper/appVariables";

const strPageInfo = "@src/components/stakeholders/shared/manageMembers/controler/CtrlManageMembers.js";

const tblActivity = generateQueries("tblActivity");

export const CtrlAviates = (appState, appDispatch, isLoading, setIsLoading, stateInfo, setStateInfo, navigate = null) => {
  const lang = appState?.clientInfo?.strLanguage;
  const bigSystemID = appState?.userInfo?.bigSystemID || aaiot_System_ID;
  const objSystemInfo = appState?.clientInfo?.objSystemInfo;

  let handler = {
    getActivity: async () => {
      try {
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
          return;
        }

        setIsLoading(true);
        const result = await tblActivity(strPageInfo, appState, "aaiotAppFindAll", {
          arrAttributes: ["bigActivityID", "jsnActivityInfo", "dtmCreatedDate"],
          objectCondition: { bigSystemID: bigSystemID, blnIsActive: true, blnIsDeleted: false , blnIsArchive: false},
        });
        if (!result?.blnIsRequestSuccessful) {
          appFunctions.logMessage(result?.response, "");
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cantFindRecords?.[lang], "warning");

          setIsLoading(false);
          return;
        }

        stateInfo.blnIsInitDone = true;
        let lstTmp = Array.isArray(result?.response) ? result?.response : objSystemInfo?.lstActivities;

        stateInfo.listMain = lstTmp;
        stateInfo.listRead = lstTmp;
        stateInfo.listRead = stateInfo.listRead?.reverse()
        appState.clientInfo.objSystemInfo.lstActivities = lstTmp
        appDispatch()

        setIsLoading(false);
        setStateInfo({ ...stateInfo });
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
        setIsLoading(false);
      }
    },
    getActivityArchaic: async () => {
      try {
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
          return;
        }

        setIsLoading(true);
        const result = await tblActivity(strPageInfo, appState, "aaiotAppFindAll", {
          arrAttributes: ["bigActivityID", "jsnActivityInfo", "dtmCreatedDate"],
          objectCondition: { bigSystemID: bigSystemID, blnIsArchive: true, blnIsDeleted: false, blnIsActive:true },
        });
        if (!result?.blnIsRequestSuccessful) {
          appFunctions.logMessage(result?.response, "");
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cantFindRecords?.[lang], "warning");
          setIsLoading(false);
          return;
        }

        stateInfo.blnIsInitDone = true;
        let lstTmp = Array.isArray(result?.response) ? result?.response : [];

        stateInfo.listMain = lstTmp;
        stateInfo.listRead = lstTmp;
        stateInfo.listRead = stateInfo.listRead?.reverse()

        setIsLoading(false);
        setStateInfo({ ...stateInfo });
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
        setIsLoading(false);
      }
    },
    searchActivity: (e) => {
      const value = e.target.value;
      if (!value) {
        stateInfo.listRead = stateInfo.listMain;
      } else {
        stateInfo.listRead = stateInfo.listMain.filter((item) => String(item?.jsnActivityInfo?.title?.eng).includes(value) || String(item?.jsnActivityInfo?.title?.arb).includes(value));
      }
      setStateInfo({ ...stateInfo });
    },
    addContent: () => {
      stateInfo.listPoint.push({});
      setStateInfo({
        ...stateInfo,
        modalOpen: true,
        blnIsAdded: true,
        indexSelected: stateInfo.listPoint.length - 1,
      });
    },
    editContent: (field, index) => {
      setStateInfo({
        ...stateInfo,
        modalOpen: true,
        blnIsAdded: false,
        indexSelected: index,
      });

      field.strItemPoint.setValue(stateInfo?.listPoint?.[index]);
    },
    funCloseModal: () => {
      setStateInfo({
        ...stateInfo,
        modalOpen: false,
        blnIsAdded: false,
        indexSelected: null,
      });
    },

    editAddContentSave: (field) => {
      if (!field.isFormValid()) {
        Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
        return;
      }
      stateInfo.listPoint[stateInfo?.indexSelected] = field?.strItemPoint.getValue();
      stateInfo.modalOpen = false;
      setStateInfo({ ...stateInfo });
      field.resetField();
    },
    deleteContentSave: (index) => {
      const fun = () => {
        stateInfo.listPoint = stateInfo?.listPoint?.filter((_, ind) => ind !== index);
        setStateInfo({ ...stateInfo });
      };
      Confirmation.viewConfirmation(
        dictionary?.shared?.confirmationMsg?.deleteItem?.[lang],
        fun,
        () => null,
        dictionary?.shared?.confirmationMsg?.yes?.[lang],
        dictionary?.shared?.confirmationMsg?.no?.[lang]
      );
    },
    funArchaic: (item) => {
      const fun = async () => {
        try {
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
            return;
          }
          setIsLoading(true);

          const result = await tblActivity(strPageInfo, appState, "aaiotAppUpdate", {
            objectInput: {
              blnIsArchive: true,
              dtmUpdatedDate: new Date().toISOString(),
            },
            objectCondition: {
              bigSystemID: item?.bigSystemID || bigSystemID,
              bigActivityID: item?.bigActivityID,
            },
          });

          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            setIsLoading(false);
            return;
          }
          setIsLoading(false);
          stateInfo.listMain = stateInfo.listMain?.filter((ele) => ele?.bigActivityID !== item?.bigActivityID);
          stateInfo.listRead = stateInfo.listMain;
          setStateInfo({ ...stateInfo, blnIsInitDone: true });
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
          setIsLoading(false);
        }
      };

      Confirmation.viewConfirmation(
        dictionary?.shared?.confirmationMsg?.archaic?.[lang],
        fun,
        () => null,
        dictionary?.shared?.confirmationMsg?.yes?.[lang],
        dictionary?.shared?.confirmationMsg?.no?.[lang]
      );
    },
    funRepublic: (item) => {
      const fun = async () => {
        try {
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
            return;
          }
          setIsLoading(true);

          const result = await tblActivity(strPageInfo, appState, "aaiotAppUpdate", {
            objectInput: {
              blnIsArchive: false,
              dtmUpdatedDate: new Date().toISOString(),
            },
            objectCondition: {
              bigSystemID: item?.bigSystemID || bigSystemID,
              bigActivityID: item?.bigActivityID,
            },
          });

          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            setIsLoading(false);
            return;
          }
          setIsLoading(false);
          stateInfo.listMain = stateInfo.listMain?.filter((ele) => ele?.bigActivityID !== item?.bigActivityID);
          stateInfo.listRead = stateInfo.listMain;
          setStateInfo({ ...stateInfo, blnIsInitDone: true });
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
          setIsLoading(false);
        }
      };

      Confirmation.viewConfirmation(
        dictionary?.shared?.confirmationMsg?.publish?.[lang],
        fun,
        () => null,
        dictionary?.shared?.confirmationMsg?.yes?.[lang],
        dictionary?.shared?.confirmationMsg?.no?.[lang]
      );
    },
    funDelete: (item) => {
      const fun = async () => {
        try {
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
            return;
          }
          setIsLoading(true);

          const result = await tblActivity(strPageInfo, appState, "aaiotAppUpdate", {
            objectInput: {
              blnIsActive: false,
              blnIsDeleted: true,
              dtmUpdatedDate: new Date().toISOString(),
            },
            objectCondition: {
              bigSystemID: item?.bigSystemID || bigSystemID,
              bigActivityID: item?.bigActivityID,
            },
          });

          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            setIsLoading(false);
            return;
          }
          setIsLoading(false);
          stateInfo.listMain = stateInfo.listMain?.filter((ele) => ele?.bigActivityID !== item?.bigActivityID);
          stateInfo.listRead = stateInfo.listMain;
          setStateInfo({ ...stateInfo, blnIsInitDone: true });
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
          setIsLoading(false);
        }
      };

      Confirmation.viewConfirmation(
        dictionary?.shared?.confirmationMsg?.deleteActivity?.[lang],
        fun,
        () => null,
        dictionary?.shared?.confirmationMsg?.yes?.[lang],
        dictionary?.shared?.confirmationMsg?.no?.[lang]
      );
    },

    funAdd: async (fields, uploaderMainImg, uploaderImages, uploaderFile) => {
      try {
        if (!fields.isFormValid()) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang],"")
          return;
        }
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
          return;
        }
        setIsLoading(true)
        if (!stateInfo?.lstMainImg?.length) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.requeioUploadImage?.[lang]);
          setIsLoading(false);
          return;
        }
        const mainImg = await uploaderMainImg?.uploadHandler();
        let lstImages = null;
        if (!mainImg) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.requeioUploadImage?.[lang]);
          setIsLoading(false);
          return;
        }
        if (stateInfo?.lstImages?.length) {
          lstImages = await uploaderImages?.uploadHandler();
        }

        let lstFile = null;
        if (stateInfo?.lstFile?.length) {
          lstFile = await uploaderFile?.uploadHandler();
        }
        
        const bigID = appFunctions.clientFunction.generateID(10);
        const result = await tblActivity(strPageInfo, appState, "aaiotAppAdd", {
          objectInput: {
            bigActivityID: bigID,
            bigSystemID: appState?.userInfo?.bigSystemID,
            bigAppID: App_ID,
            jsnActivityInfo: {
              title: fields?.title?.getValue(),
              listPoint: stateInfo?.listPoint,
              blnHomePage: fields?.blnHomePage?.getValue()?.length,
              source: fields?.source.getValue(),
              link: {
                label: fields?.linkLabel?.getValue(),
                url: fields?.linkUrl?.getValue(),
                lstFile: lstFile?stateInfo?.lstFile:[],
              },
              lstMainImg: mainImg?stateInfo?.lstMainImg:[],
              lstImages: lstImages?stateInfo?.lstImages:[],
            },
          },
        });

        if (!result?.blnIsRequestSuccessful) {
          appFunctions.logMessage(result?.response, "");
          setIsLoading(false);
          return;
        }
        
        setIsLoading(false);
        setStateInfo({ ...stateInfo, blnIsInitDone: true });
        Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
        navigate(objAppRouting?.ManageActivates?.url);
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "addActivate-ERROR", error);
        setIsLoading(false)
      }
    },
    funEdit: async (fields, selected, uploaderMainImg, uploaderImages, uploaderFile) => {
      try {
        if (!fields.isFormValid()) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang],"")
          return;
        }
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
          return;
        }
        setIsLoading(true)
        if (!stateInfo?.lstMainImg?.length) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.requeioUploadImage?.[lang]);
          setIsLoading(false);
          return;
        }
        const mainImg = await uploaderMainImg?.uploadHandler();
        let lstImages = null;
        if (!mainImg) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.requeioUploadImage?.[lang]);
          setIsLoading(false);
          return;
        }
        if (stateInfo?.lstImages?.length) {
          lstImages = await uploaderImages?.uploadHandler();
        }      
        
        let lstFile = null;
        if (stateInfo?.lstFile?.length && String(fields?.blnAddFile?.getValue()?.key)==="2") {
          lstFile = await uploaderFile?.uploadHandler();
        }
        const result = await tblActivity(strPageInfo, appState, "aaiotAppUpdate", {
          objectInput: {
            jsnActivityInfo: {
              title: fields?.title?.getValue(),
              listPoint: stateInfo?.listPoint,
              blnHomePage: fields?.blnHomePage?.getValue()?.length,
              source: fields?.source.getValue(),
              link: {
                label: fields?.linkLabel?.getValue(),
                url:String(fields?.blnAddFile?.getValue()?.key)==="2"? `${App_Server_Url_DownloadFiles}${stateInfo?.lstFile?.[0]?.path}`:fields?.linkUrl?.getValue(),
                lstFile: lstFile?stateInfo?.lstFile:[],
                intTypeLink : fields?.blnAddFile?.getValue()?.key
              },
              lstMainImg: mainImg?stateInfo?.lstMainImg:[],
              lstImages: lstImages?stateInfo?.lstImages:[],
            },
            dtmUpdatedDate: new Date().toISOString(),
          },
          objectCondition: {
            bigSystemID: appState?.userInfo?.bigSystemID,
            bigActivityID: selected?.bigActivityID,
          },
        });

        if (!result?.blnIsRequestSuccessful) {
          appFunctions.logMessage(result?.response, "");
          setIsLoading(false);
          return;
        }
        setIsLoading(false);
        setStateInfo({ ...stateInfo, blnIsInitDone: true });
        appDispatch()

        
        Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
        navigate(objAppRouting?.ManageActivates?.url);
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "addActivate-ERROR", error);
        setIsLoading(false)
      }
    },
  };

  return handler;
};
