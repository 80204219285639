import { Button, Container, Grid, Typography, useNavigate } from '@cbmisorg/client-app';
import React, { useContext } from 'react'
import { AppContext } from '../../../configuration/contextapi/AppContext';
import ShredHeader from '../../sharedUi/header/ShredHeader';
import { dictionary } from '../../../appHelper/appLanguage/dictionary';
import { App_Text_Color } from '../../../appHelper/appColor';
import waitingImg from "../../../assets/images/waiting.png";
import objAppRouting from '../../../appHelper/routing/appRouting';

function WaitingTrainingPage() {
    const navigate = useNavigate()
    const {appState} = useContext(AppContext)
    const lang = appState?.clientInfo?.strLanguage;

    return (
      <React.Fragment>
        <ShredHeader lang={lang} blnBottomBar={false}/>
        <Container py-3 sx={{overflowY:"none"}}>
          <Grid container justify={'center'} spacing={1}>
            <Grid item xs="12">
                <Typography as="h4" color="primary">{dictionary?.components?.waiting?.titleTraining?.[lang]}</Typography>
            </Grid>
            <Grid item xs="12">
                <Typography as="h6" color={App_Text_Color}>{dictionary?.components?.waiting?.subTitle?.[lang]}</Typography>
            </Grid>
            <Grid item xs="12">
                <Typography as="h6" color={App_Text_Color}>{dictionary?.components?.waiting?.waite?.[lang]}...</Typography>
            </Grid>
            <Grid item xs="12" md="8" lg="6">
                <img src={waitingImg} alt={dictionary?.components?.waiting?.titleTraining?.[lang]} width={"95%"}/>
            </Grid>
            <Grid item xs="12" container p-0>
              <Grid item>
                  <Button size="sm" label={dictionary?.components?.waiting?.btn?.[lang]} icon="home" onClick={()=>navigate(objAppRouting?.Public_LandingPage?.url)}/>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    )
  }

export default WaitingTrainingPage