import React, { useContext, useEffect, useState } from "react";
import { BtnGoToTop, ClientDirection } from "@cbmisorg/client-app";
import { AppContext } from "../../../../configuration/contextapi/AppContext";

import Header from "./sections/Header";
import Hero from "./sections/Hero";
import ParallaxScrollingApp from "../../../sharedUi/ParallaxScrollingApp";
import Footer from "./sections/Footer";
import OurGoal from "./sections/OurGoal";
import ContactUs from "./sections/ContactUs";
import Aboutus from "./sections/Aboutus";
import Association from "./sections/Association";
import Events from "./sections/Events";
import MessageOfSheikhMuhammad from "./sections/MessageOfSheikhMuhammad";
import useCheckSystemInfo from "../../../../appHelper/appHook/useCheckSystemInfo";
import Partners from "./sections/Partners";
import ITC from "./sections/ITC";
import Achievements from "./sections/Achievements";

function Home() {
  const { getNewSystemInfo } = useCheckSystemInfo();

  const { appState, appDispatch } = useContext(AppContext);

  const lang = appState?.clientInfo?.strLanguage || appState?.clientInfo?.strDataLanguage;

  const [stateInfo, setStateInfo] = useState({ intRefreshCounter: 0 });
  const closeDrawer = () => {
    ++stateInfo.intRefreshCounter;
    setStateInfo({ ...stateInfo });
  };

  useEffect(() => {
    ClientDirection.setDirection(lang === "arb" ? "rtl" : "ltr");
    appDispatch();
  }, [lang]);

  useEffect(() => {
    intData();
  }, []);

  const intData = async () => {
    if (!appState?.clientInfo?.objSystemInfo || !Object.keys(appState?.clientInfo?.objSystemInfo)?.length) {
      appState.clientInfo.objSystemInfo = await getNewSystemInfo();
      appDispatch();
    }
  };

  return (
    <>
      <Header intRefreshCounter={stateInfo?.intRefreshCounter} appState={appState} appDispatch={appDispatch} closeDrawer={closeDrawer} />
      <Hero lang={lang} blnVideo={true} appState={appState} appDispatch={appDispatch} />
      <Aboutus lang={lang} appState={appState} />
      <Association lang={lang} />
      <OurGoal lang={lang} appState={appState} />
      <MessageOfSheikhMuhammad lang={lang} />

      <ParallaxScrollingApp />

      <ITC lang={lang} appState={appState} />
      <Achievements lang={lang} appState={appState} />

      <React.Fragment>
        <Partners lang={lang} appState={appState} />
      </React.Fragment>
      {!appState?.clientInfo?.objSystemInfo?.lstActivities?.length || window.innerWidth <= 768 ? null : (
        <React.Fragment>
          <ParallaxScrollingApp />
          <Events lang={lang} appState={appState} />
        </React.Fragment>
      )}
      <ParallaxScrollingApp />
      <ContactUs appState={appState} lang={lang} />

      <Footer lang={lang} appState={appState} />
      <BtnGoToTop className={lang === "arb" ? "arbLang" : ""} />
    </>
  );
}

export default Home;
