import React, { useContext } from 'react'
import {Container, Grid} from "@cbmisorg/client-app"
import AdvisorCard from './AdvisorCard/AdvisorCard'
import { AppContext } from '../../../../configuration/contextapi/AppContext'
import ShredHeader from '../../../sharedUi/header/ShredHeader'
import { dictionary } from '../../../../appHelper/appLanguage/dictionary'
import { useCheckUserLoginDevice } from '../../../../appHelper/appHook/useCheckUserLoginDevice '

const labels = dictionary?.components?.manageMembers

function Advisor() {
    const {appState} = useContext(AppContext)
    // useCheckUserLoginDevice()
    const userInfo = appState.userInfo
    const lang = appState.clientInfo.strLanguage

  return (
    <React.Fragment>
        <ShredHeader title={labels?.cardMember?.[lang]} blnBottomBar={false} lang={lang}/>
        <Container py-5>
          <Grid container justify={'center'} ss={{padding:"60px auto"}}>
        <Grid item xs="12" py-5>
          <AdvisorCard userInfo={userInfo} lang={lang}/>
        </Grid>
      </Grid>
      </Container>
    </React.Fragment>
  )
}

export default Advisor