import React, { useContext, useEffect, useState } from "react";
import { Button, Grid, Typography, Container, Paper, useNavigate, Loader, Modal, ModalHeader, ModalBody, Icon, Line } from "@cbmisorg/client-app";
import ShredHeader from "../../../sharedUi/header/ShredHeader";
import useCheckSystemInfo from "../../../../appHelper/appHook/useCheckSystemInfo";
import useCheckCat from "../../../../appHelper/appHook/useCheckCat";
import { AppContext } from "../../../../configuration/contextapi/AppContext";
import * as appVariables from "../../../../appHelper/appVariables";
import { FormData, FormField } from "../../../../appHelper/formApi/FormAPI";
import Uploader from "../../../sharedUi/uploader/Uploader";
import { App_Primary_Color } from "../../../../appHelper/appColor";
import * as appColor from "../../../../appHelper/appColor";
import crossImg from "../../../../assets/images/cross-red.png";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import { CtrlSponsorProfileInfo } from "./controler/CtrSponsorProfileInfo";

function SponsorProfileInfo() {
  useCheckSystemInfo();
  useCheckCat();
  const navigate = useNavigate();
  const { appState, appDispatch } = useContext(AppContext);
  const lang = appState?.clientInfo?.strLanguage || "arb";
  const labels = dictionary?.components?.manageMembers;
  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useState({
    blnIsInitDone: false,
    objPartner: {},
    listLogo: [],
  });

  const uploaderLogo = Uploader(
    state,
    setState,
    "listLogo",
    "",
    {
      blnIsMultiple: false,
      blnForceNewName: true,
      fileType: appVariables?.objUploadType?.["aaiot"].id,
      title: dictionary?.components?.public?.join?.logoNote?.[lang],
      file: dictionary?.components?.public?.join?.logo?.[lang],
    },
    "edit",
    false
  );

  const fields = FormData({
    bigWorkSector: {
      strControl: "list",
      objLanguage: false,
      objValidations: { required: true },
      lstOption: [
        { key: appVariables.workSectorID?.gov, value: dictionary?.components?.joinSymposium?.gov?.[lang] },
        { key: appVariables.workSectorID?.prvt, value: dictionary?.components?.joinSymposium?.prvt?.[lang] },
      ],
    },
    strPlaceName: { strControl: "text", objLanguage: false, objValidations: { required: true } },
    strURL: { strControl: "text", objLanguage: false },
    blnViewLogo: {
      strControl: "checkbox",
      objLanguage: false,
      lstOption: [
        { key: "true", value: { eng: "Add the sponsor's logo on the main page of the association", arb: "إضافة شعار الجهة الداعمة في الصفحة الرئيسية للجمعية" }?.[lang] },
      ],
    },
    strEmail: {
      strControl: "text",
      objLanguage: false,
      objValidations: { email: true },
    },
    strPhone: {
      strControl: "text",
      objLanguage: false,
      objValidations: { onlyNumbers: true, minLength: { condition: 8 }, maxLength: { condition: 8 } },
    },
  });

  const handlers = CtrlSponsorProfileInfo(appState, appDispatch);
  const initData = handlers?.initData({ isLoading, setIsLoading, state, setState, fields });
  const handelSubmit = handlers?.handelSubmit({ isLoading, setIsLoading, state, setState, fields, uploaderLogo });

  useEffect(() => {
    if (!state?.blnIsInitDone) {
      initData();
    }
  }, []);
  
  return (
    <React.Fragment>
      <ShredHeader title={labels?.sponserInforamtion?.[lang]} lang={lang} />
      <Container py-5 pb-xs-10 mb-xs-10 pb-sm-10 mb-sm-10>
        {isLoading ? <Loader backdrop={true} color={App_Primary_Color} /> : null}
        <Grid container justify={"center"} pb-xs-10 mb-xs-10 spacing={2}>
          <Grid item xs="12" md="10">
            <Paper px-0 py-2 elevation={4} outline={1} sx={{ borderRadius: "8px !important", borderColor: "var(--cbmis-dark)" }} className="cardContainer">
              <Grid container justify={"center"} spacing={2}>
                <Grid container justify={"center"}>
                  <Grid item>
                    <Grid container>
                      <Grid
                        item
                        sx={{
                          ".CbmisIconContainer": {
                            zIndex: -1,
                            width: "90px !important",
                            height: "90px !important",
                            borderRadius: "50% !important",
                            color: appColor.App_Primary_Color,
                          },
                        }}
                      >
                        {!state?.listLogo?.[0]?.blnIsNew && !state?.objPartner?.strLogoImg?.[0]?.path ? (
                          <Icon icon={"business"} />
                        ) : (
                          <img
                            src={
                              !state?.listLogo?.[0]?.blnIsNew && !state?.objPartner?.strLogoImg?.[0]?.path
                                ? crossImg
                                : state?.listLogo?.[0]?.blnIsNew
                                ? URL.createObjectURL(state?.listLogo?.[0])
                                : appVariables.App_Server_Url_DownloadFiles + state?.objPartner?.strLogoImg?.[0]?.path
                            }
                            alt={state?.objPartner?.jsnPartnerInfo?.strPlaceName}
                            title={state?.objPartner?.jsnPartnerInfo?.strPlaceName}
                            height={"100px"}
                            width={"100px"}
                            style={{ borderRadius: "50%", border: `1px solid ${appColor.App_Primary_Color}` }}
                          />
                        )}
                      </Grid>
                      <Grid item justify={"start"} sx={{ position: "absolute", bottom: "-6px" }}>
                        {uploaderLogo?.JSX}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={2} justify={"center"} mt-4>
                  <Grid item xs={12} md="6">
                    <FormField objHandler={fields.bigWorkSector} icon="Work" dir={lang === "arb" ? "rtl" : "ltr"} />
                  </Grid>
                  <Grid item xs={12} md="6">
                    <FormField objHandler={fields.strPlaceName} icon="business" dir={lang === "arb" ? "rtl" : "ltr"} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormField objHandler={fields.strURL} icon="link" dir={lang === "arb" ? "rtl" : "ltr"} />
                  </Grid>
                </Grid>
                <Line my-4 />
                <Grid container justify={"center"} mt-6>
                  <Grid item xs="12">
                    <FormField objHandler={fields.blnViewLogo} />
                  </Grid>
                </Grid>
                <Grid item xs="12" pt-5 pb-8>
                  <Button py-0 px-10 label={dictionary?.shared?.btn?.save?.[lang]} onClick={handelSubmit}/>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default SponsorProfileInfo;
