import * as appFunctions from "../../../../appHelper/appFunctions";
import { generateQueries } from "../../../../appHelper/appQuery/GenerateQuery";
const tblStatistic = generateQueries("tblStatistic");

const strPageInfo = "src/components/public/support/support.controller.js";
export default function supportCtrl({ appState, appDispatch }) {
  const handler = {
    initData: async () => {
      try {
        if (appState?.clientInfo?.blnCountSupport) {
          return;
        }
        const result = tblStatistic(strPageInfo, appState, "aaiotAppIncDecInt", {
          objectCondition: {
            bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID,
          },
          arrAttributes: ["intCountSupportClk"],
        });

        if (!result) {
          return;
        }
        appState.clientInfo.blnCountSupport = true;
        appDispatch();
      } catch (error) {
        appFunctions.logMessage(strPageInfo, error, "initData-ERROR");
      }
    },
  };

  return handler;
}