import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../configuration/contextapi/AppContext";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionTab,
  Avatar,
  Button,
  Container,
  Grid,
  Icon,
  Line,
  Loader,
  Modal,
  ModalBody,
  ModalHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextArea,
  Typography,
  useNavigate,
} from "@cbmisorg/client-app";
import AnnouncementDisplay from "../../admin/mngAnnouncement/mngAnnouncement.display";
import * as appColor from "../../../../appHelper/appColor";
import * as appFunctions from "../../../../appHelper/appFunctions";
import objAppRouting from "../../../../appHelper/routing/appRouting";
import UploaderReader from "../../../sharedUi/uploader/UploadRead";
import { CtrlAccountSponsor } from "./controler/CtrAccountSponsor";
import { App_Server_Url_DownloadFiles, objSponserCat, objSupportTransStatus, objUploadType } from "../../../../appHelper/appVariables";
import { FormData, FormField } from "../../../../appHelper/formApi/FormAPI";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import Uploader from "../../../sharedUi/uploader/Uploader";
import ContactUs from "../../../public/LandingPage/home/sections/ContactUs";
import PaperSection from "../../../sharedUi/paperSection/PaperSection";

//#region style
const classes = {
  readMore: {
    overflow: "hidden !important",
    whiteSpace: "nowrap !important",
    wordWrap: "break-word !important",
    lineHeight: "1.5 !important",
    lineClamp: "5 !important",
    height: `calc(15px * 1.5 * 1)`,
    maxHeight: `calc(15px * 1.5 * 5)`,
    transition: "max-height .5s ease !important",
    textOverflow: "ellipsis !important",
  },
  announcementPaper: {
    boxShadow: "none !important",
    borderRadius: "calc(20 * 1px) !important",
    backgroundColor: "#ffffff55 !important",
    border: `1px solid ${appColor.App_Primary_Color}`,
  },
  card: {
    position: "relative !important",
    display: "block !important",
    height: "100% !important",
    borderRadius: "calc(20 * 1px) !important",
    overflow: "hidden !important",
    textDecoration: "none !important",
    boxShadow: "none !important",
    padding: "0 !important",
    cursor: "pointer !important",
    backgroundColor: "#DFF5FF !important",
  },
  cardImage: {
    width: "100%",
    minHeight: "250px",
    height: "auto",
    maxHeight: "250px",
  },
  cardOverlay: {
    position: "absolute",
    bottom: "0",
    left: "0",
    right: "0",
    zIndex: "1",
    borderRadius: "calc(20 * 1px)",
    backgroundColor: "#fff",
    transform: "translateY(100%)",
    transition: ".2s ease-in-out",
  },
  cardHeader: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    padding: "1em",
    borderRadius: "calc(20 * 1px) 0 0 0",
    backgroundColor: "#fff",
    transform: "translateY(-100%)",
    transition: ".2s ease-in-out",
  },
  cardArc: {
    width: "80px",
    height: "80px",
    position: "absolute",
    bottom: "100%",
    right: "0",
    zIndex: 1,
  },
  cardTitle: {
    margin: "0 0 .3em",
    color: "#6A515E",
  },
  cardDescription: {
    padding: "0 2em 2em",
    margin: 0,
    color: appColor.App_Dark_blue,
    fontFamily: "MockFlowFont",
    display: " -webkit-box",
    webkitBoxOrient: "vertical",
    webkitLineClamp: 3,
    overflow: "hidden",
  },
  statusBadge: {
    fontSize: "8px",
    padding: "0 3px",
    borderRadius: "20px",
    color: "#fff",
    margin: "0px 1px 0px",
  },
};
//#endregion

const label = dictionary?.components?.account;
function AccountSponsor() {
  const { appState, appDispatch } = useContext(AppContext);
  const lang = appState?.clientInfo?.strLanguage;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    blnIsInitDone: false,
    objPartner: {},
    indexCardOpen: -1,
    selectedTrans: null,
    objConfig: {
      blnNewServices: false,
      blnNewTraining: true,
    },
    lstServices: [
      {
        title: { eng: "Sponsor Information", arb: "المعلومات الجهة الدعمة" },
        dec: {
          eng: "Information Management is responsible for supporting and controlling the display of the sponsor's logo on the homepage",
          arb: "إدارة المعلومات  الجهة الداعمة الدعم وضبط عرض شعارها على الصفحة الرئيسية",
        },
        icon: "accountCircle",
        img: require("../../../../assets/images/concept.png"),
        url: objAppRouting?.SponsorProfile?.url,
      },
      {
        title: { eng: "Profile", arb: "المعلومات الشخصية" },
        dec: {
          eng: "Managing personal information, previous work experiences, and creating an electronic resume",
          arb: "إدارة المعلومات الشخصية وخبرات العمل السابقة وإنشاء سيرة ذاتية إلكترونية",
        },
        icon: "accountCircle",
        img: "https://cdni.iconscout.com/illustration/premium/thumb/user-profile-3465537-2928727.png?f=webp",
        url: objAppRouting?.profile?.url,
      },
      {
        title: { eng: "Developers Hub", arb: "مركز المبرمجين" },
        dec: {
          eng: "This platform allows you to hire professional Kuwaiti programmers specialized in building a secure application that operates on any device or operating system",
          arb: "تتيح هذه المنصة إمكانية توظيف مجموعة من المبرمجين المحترفين الكويتيين و المتخصصين في بناء تطبيق واحد آمن يعمل على أي جهاز او نظام تشغيل",
        },
        icon: "ArticleOutlined",
        img: "https://www.lauruss.com/wp-content/uploads/2022/02/Top-ASP.NET-Development-Company.svg",
      },
      {
        title: { eng: "Job Portals", arb: "منصة الوظائف" },
        dec: {
          eng: "This job advertising platform enables you to manage all job postings under your company's name and gives you access to a wide range of qualified employees, facilitating easy selection according to the requirements of each position",
          arb: "تمكنك منصة الإعلان عن وظائف من إدارة جميع الوظائف المطروحة تحت اسم منشئتك كما وتمكنك من الوصول الى شريحة واسعة من الموظفين المؤهلين وسهولة إختيار الأنسب على حسب متطلبات كل وظيفة",
        },
        icon: "workOutline",
        img: "https://cdni.iconscout.com/illustration/premium/thumb/employee-searching-job-openings-2726133-2272618.png?f=webp",
      },
      {
        title: { eng: "Company as a Service", arb: "خدمات الشركات" },
        dec: {
          eng: "This platform provides comprehensive services for software companies, enabling them to efficiently and effectively manage their employees and technical projects, while ensuring the completion of their applications with minimal cost and effort",
          arb: "هذه المنصة توفر خدمات متكاملة لشركات البرمجة، تمكّنها من إدارة موظفيها ومشاريعها التقنية بكفاءة وفعالية عالية، مع ضمان إنجاز تطبيقاتها بأقل تكلفة وجهد",
        },
        icon: "BadgeOutlined",
        img: "https://doflex.io/wp-content/uploads/2022/10/booking-re%CC%81union-Modifie%CC%81.png",
      },
      {
        title: { eng: "Development Tools", arb: "أدوات تطوير التطبيقات" },
        dec: {
          eng: "A set of software tools and packages to help developers and programming companies design secure enterprise applications in less time and effort",
          arb: "مجموعة من الأدوات البرمجية لمساعدة المطورين وشركات البرمجة في تصميم تطبيقات آمنة بأقل وقت وجهد ",
        },
        icon: "TapAndPlay",
        img: "https://cdni.iconscout.com/illustration/premium/thumb/web-development-3454628-2918517.png",
      },
    ],

    lstNotification: [],
    sectionSelectedKey: "account",

    blnOpenHistoryModal: false,
    blnOpenImgeModal: false,
    blnIsContributionOpen: false,
    selectedLstImg: null,
    selectedImgType: "",
    lstReceipt: [],

    objPartnerCount: {},
  });
  // const lastTran = state?.objPartner?.jsnSupportTrans?.[state?.objPartner?.jsnSupportTrans?.length - 1];
  const objSystemConfig = appState?.clientInfo?.objSystemInfo?.jsnSystemConfig;
  const objContact = appState?.clientInfo?.objSystemInfo?.jsnContact;
  const blnHaveContactInfo = objContact?.strEmail || objContact?.strSupportEmail || objContact?.strPhoneNumber || objContact?.strSupportPhone || objContact?.strAddress;
  const uploaderReceipt = Uploader(state, setState, "lstReceipt", "", {
    fileType: objUploadType.aaiot.id,
    blnIsMultiple: false,
    blnForceNewName: true,
    file: label?.receipt?.[lang],
  });

  const handelMouseEnter = (index) => () => {
    state.indexCardOpen = index;
    setState({ ...state });
  };

  const handelMouseleve = (index) => () => {
    state.indexCardOpen = -1;

    setState({ ...state });
  };
  const fields = FormData({
    strSponsorTrainingType: { strControl: "list", objLanguage: false, objValidations: { required: true } },
    strSponsorFullStackCatType: { strControl: "list", objLanguage: false },
    strSponsorManagerCatType: { strControl: "list", objLanguage: false },
    blnUploadedWhatsapp: { strControl: "checkbox", objLanguage: false, lstOption: [{ key: "true", value: label?.whatsappIsUpload?.[lang] }] },
    // intReceivedAmount: { strControl: "text", objLanguage: false, objValidations: { onlyNumbers: true } },
  });

  const { objAnnouncement, getIsLiveMeetingLive, goToExternalMeeting, getIsMeetingAvailable } = AnnouncementDisplay();
  const handlers = CtrlAccountSponsor(appState, appDispatch);
  const initData = handlers?.initData({ isLoading, setIsLoading, state, setState, fields });
  const dilogeContributionModal = handlers?.dilogeContributionModal({ state, setState, fields });
  const dilogeImgModal = handlers?.dilogeImgModal({ state, setState });
  const handelSubmit = handlers?.handelSubmit({ isLoading, setIsLoading, state, setState, fields, uploaderReceipt });
  // const getPartnerTransPlanTotalPrice = handlers?.getPartnerTransPlanTotalPrice({ state, fields });

  useEffect(() => {
    if (!state?.blnIsInitDone) {
      initData();
    }
  }, []);

  return (
    <React.Fragment>
      <Container my-auto>
        {isLoading ? <Loader backdrop={true} color={appColor.App_Primary_Color} /> : null}
        {!(objAnnouncement.lstNotification || [])?.length ? null : (
          <Grid container spacing={3}>
            {!(objAnnouncement.lstNotification || [])?.filter((msg) => !msg?.strAnnouncementBody?.arb && !msg?.strAnnouncementBody?.eng)?.length ? null : (
              <Grid item xs="12">
                <Paper sx={classes?.announcementPaper} mx-2 px-0 py-1>
                  <Grid container>
                    <Grid item xs="12" container>
                      <Grid item xs="12">
                        {(objAnnouncement.lstNotification || [])
                          ?.filter((msg) => !msg?.strAnnouncementBody?.arb && !msg?.strAnnouncementBody?.eng)
                          ?.map((msg, intIndex) => (
                            <Typography as="body2" pb-1>
                              {msg?.strAnnouncementTitle?.[lang]}
                            </Typography>
                          ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}
            {!(objAnnouncement.lstNotification || [])?.filter((msg) => msg?.strAnnouncementBody?.arb || msg?.strAnnouncementBody?.eng)?.length ? null : (
              <Grid item xs="12">
                <Paper sx={classes?.announcementPaper} mx-2 p-0>
                  <Grid container>
                    <Grid item xs="12" container>
                      <Grid item xs="12" py-0>
                        <Typography as="subtitle2" color={appColor.App_Primary_Color} my-0 py-0></Typography>
                      </Grid>
                      <Grid item xs="12">
                        <Accordion dir={lang === "arb" ? "rtl" : "ltr"} className="account-accordion">
                          {(objAnnouncement.lstNotification || [])
                            ?.filter((msg) => msg?.strAnnouncementBody?.arb || msg?.strAnnouncementBody?.eng)
                            ?.map((msg, intIndex) => (
                              <AccordionTab sx={{ background: "transparent", boxShadow: "none !important" }} key={intIndex} px-0>
                                <AccordionSummary
                                  expandIcon={<Icon icon="campaign" color={appColor.App_Primary_Color} />}
                                  sx={{ borderColor: "#fefef", boxShadow: "none !important", "&checked": { background: "red !important" } }}
                                  py-1
                                >
                                  <Typography as="body2" pt-0>
                                    {msg?.strAnnouncementTitle?.[lang]}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails px-3 sx={{ background: "#fff !important" }}>
                                  <Grid container>
                                    <Grid item xs="12">
                                      <Typography as="caption">{msg?.strAnnouncementBody?.[lang]}</Typography>
                                    </Grid>
                                    {!msg?.lstFile?.length ? null : (
                                      <Grid item xs={12} p-2>
                                        <UploaderReader lstFiles={msg?.lstFile} />
                                      </Grid>
                                    )}
                                  </Grid>
                                </AccordionDetails>
                              </AccordionTab>
                            ))}
                        </Accordion>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}
          </Grid>
        )}
        {!state?.objPartner?.jsnSupportTrans?.length ? (
          <Grid container spacing={3}>
            <Grid item xs="12">
              <Paper sx={classes?.announcementPaper} mx-2 p-0>
                <Grid
                  container
                  justify={"space-between"}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  p-1
                  pb-2
                >
                  <Grid
                    item
                    container
                    alignItems={"center"}
                    sx={{
                      md: { flexWrap: "nowrap !important", alignItems: "flex-start !important" },
                      flexWrap: "wrap !important",
                      alignItems: "center !important",
                    }}
                  >
                    <Grid item xs="12" md="auto" sx={{ xs: { justifyContent: "center", paddingTop: 0, margin: "0", marginTop: "-5px" } }}>
                      <Typography
                        as="subtitle2"
                        color={appColor?.[state?.objPartnerCount]}
                        my-0
                        py-0
                        sx={{ border: `1px solid ${appColor?.[state?.objPartnerCount]}`, borderRadius: "15px" }}
                        mx-1
                        px-1
                      ></Typography>
                    </Grid>
                    <Grid item xs="12" md="auto">
                      <Typography as="body2" pt-0>
                        {label?.contribution?.[lang]}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs="12" sx={{ justifyContent: "center" }} pt-3 pb-0>
                    <Button
                      label={label?.donationBtn?.[lang]}
                      color={appColor.App_Primary_Color}
                      size="sm"
                      py-0
                      px-3
                      sx={{ borderRadius: "20px" }}
                      p-0
                      onClick={dilogeContributionModal()}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs="12">
              <Paper sx={classes?.announcementPaper} mx-2 p-0>
                <Grid container>
                  <Grid item xs="12" container>
                    <Grid item xs="12">
                      <Accordion dir={lang === "arb" ? "rtl" : "ltr"} className="account-accordion">
                        <AccordionTab sx={{ background: "transparent", boxShadow: "none !important" }} px-0>
                          <AccordionSummary sx={{ borderColor: "#fefef", boxShadow: "none !important", "&checked": { background: "red !important" } }} py-0>
                            <Grid
                              container
                              justify={"space-between"}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            >
                              <Grid
                                item
                                container
                                alignItems={"center"}
                                sx={{
                                  md: { flexWrap: "nowrap !important", alignItems: "flex-start !important" },
                                  flexWrap: "wrap !important",
                                  alignItems: "center !important",
                                }}
                              >
                                <Grid item xs="12" md="auto" sx={{ xs: { justifyContent: "center", paddingTop: 0, margin: "0", marginTop: "-5px" } }}>
                                  <Typography
                                    as="subtitle2"
                                    color={appColor?.[state?.objPartnerCount]}
                                    my-0
                                    py-0
                                    sx={{ border: `1px solid ${appColor?.[state?.objPartnerCount]}`, borderRadius: "15px" }}
                                    mx-1
                                    px-1
                                  >
                                    {!state?.objPartnerCount ? null : (
                                      <Grid container py-0 spacing={0} alignItems={"center"} sx={{ flexWrap: "nowrap", minWidth: "max-content !important" }}>
                                        <Grid item alignItems={"center"}>
                                          <Icon icon={state?.objPartnerCount !== objSponserCat?.main?.key ? "star" : "AutoAwesome"} />
                                        </Grid>
                                        <Grid item alignItems={"center"}>
                                          {dictionary?.shared?.sponserLabelCat[state?.objPartnerCount]?.[lang]}
                                        </Grid>
                                      </Grid>
                                    )}
                                  </Typography>
                                </Grid>
                                <Grid item xs="12" md="auto">
                                  <Typography as="body2" pt-0>
                                    {label?.contribution?.[lang]}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid item container xs="12" sx={{ justifyContent: "center" }} pt-3 pb-0>
                                <Button
                                  label={label?.donationBtn?.[lang]}
                                  color={appColor.App_Primary_Color}
                                  size="sm"
                                  sx={{ borderRadius: "20px" }}
                                  p-0
                                  onClick={dilogeContributionModal()}
                                />
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails px-3 sx={{ background: "#fff !important" }}>
                            <Line pb-3 />
                            <Grid container justify={"center"}>
                              <Grid item xs="12">
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>{label?.sponserInfo?.supportStatus?.[lang]}</TableCell>
                                      <TableCell>{label?.sponserInfo?.supportCat?.[lang]}</TableCell>
                                      <TableCell>{label?.sponserInfo?.studentNumber?.[lang]}</TableCell>
                                      <TableCell>{label?.sponserInfo?.amount?.[lang]}</TableCell>
                                      <TableCell>{label?.sponserInfo?.date?.[lang]}</TableCell>
                                      {state?.objPartner?.jsnSupportTrans?.length === 1 &&
                                      !state?.objPartner?.jsnSupportTrans[0]?.lstReceipt?.length &&
                                      !state?.objPartner?.jsnSupportTrans[0]?.lstReceiptCatch?.length ? null : (
                                        <TableCell></TableCell>
                                      )}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {(state?.objPartner?.jsnSupportTrans || [])?.map((item) => (
                                      <TableRow key={item?.id}>
                                        <TableCell headLabel={label?.sponserInfo?.supportStatus?.[lang]}>
                                          <Typography
                                            as="body2"
                                            color={
                                              item?.intSupportTransStatus === objSupportTransStatus?.Approved
                                                ? appColor.App_Dark_green
                                                : item?.intSupportTransStatus === objSupportTransStatus?.Approved
                                                ? "var(--cbmis-error)"
                                                : appColor.App_Primary_Color
                                            }
                                            className="textcenter"
                                          >
                                            {label.objStatus?.[item?.intSupportTransStatus]?.[lang]}
                                          </Typography>
                                        </TableCell>
                                        <TableCell headLabel={label?.sponserInfo?.supportCat?.[lang]}>
                                          <Typography
                                            as="body2"
                                            className="textcenter"
                                            color={appColor?.[appFunctions.getSponsorTypeAccordingToTraineeNum(item?.intTraineesCount, item?.strTrainingType)]}
                                          >
                                            {
                                              dictionary?.shared.sponserLabelCat?.[
                                                appFunctions.getSponsorTypeAccordingToTraineeNum(item?.intTraineesCount, item?.strTrainingType)
                                              ]?.[lang]
                                            }
                                          </Typography>
                                        </TableCell>
                                        <TableCell headLabel={label?.sponserInfo?.studentNumber?.[lang]}>
                                          <Typography as="body2" className="textcenter">
                                            {item?.intTraineesCount}
                                          </Typography>
                                        </TableCell>
                                        <TableCell headLabel={label?.sponserInfo?.amount?.[lang]}>
                                          <Typography as="body2" className="textcenter">
                                            {Number(item?.intReceivedAmount ? item?.intReceivedAmount : item?.intTrainingProgPrice * item?.intTraineesCount).toLocaleString()}
                                            {dictionary?.components?.mngSupportTrans?.currency?.[lang]}
                                          </Typography>
                                        </TableCell>
                                        <TableCell headLabel={label?.sponserInfo?.date?.[lang]}>
                                          <Typography as="body2" className="textcenter">
                                            {appFunctions.getDateUSFormat(item?.dtmCreatedDate)}
                                          </Typography>
                                        </TableCell>
                                        {!item?.lstReceipt?.length && !item?.lstReceiptCatch?.length ? (
                                          <TableCell>
                                            <Grid container justify={"center"}>
                                              <Grid item>
                                                <Button
                                                  label={dictionary?.shared?.btn?.update?.[lang]}
                                                  color={appColor.App_Primary_Color}
                                                  size="sm"
                                                  py-0
                                                  px-2
                                                  onClick={dilogeContributionModal(item)}
                                                />
                                              </Grid>
                                            </Grid>
                                          </TableCell>
                                        ) : (
                                          <TableCell py-3>
                                            <Grid container justify={"center"}>
                                              <Grid item xs="6" sm="auto">
                                                {!item?.lstReceipt?.length ? null : (
                                                  <Button label={label?.receipt?.[lang]} size="sm" p-0 onClick={() => dilogeImgModal(item?.lstReceipt, "receipt")} />
                                                )}
                                              </Grid>
                                              <Grid item xs="6" sm="auto">
                                                {!item?.lstReceiptCatch?.length ? null : (
                                                  <Button
                                                    label={label?.receiptCatch?.[lang]}
                                                    size="sm"
                                                    p-0
                                                    color="success"
                                                    onClick={() => dilogeImgModal(item?.lstReceiptCatch, "receiptCatch")}
                                                  />
                                                )}
                                              </Grid>
                                            </Grid>
                                          </TableCell>
                                        )}
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </AccordionTab>
                      </Accordion>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )}

        <Grid container spacing={3} px-1>
          {(objAnnouncement.lstMeeting || [])
            .filter((liveSession) => getIsLiveMeetingLive(liveSession) || getIsMeetingAvailable(liveSession))
            .map((liveSession, intIndex) => {
              return (
                <Grid item xs="12" md={objAnnouncement.lstMeeting.length > 2 ? 4 : 12} key={intIndex}>
                  <Paper key={intIndex} sx={classes?.announcementPaper}>
                    <Grid container spacing={2} px-3 m-auto justify={"center"}>
                      <Grid item xs="12" py-0>
                        <Typography as="subtitle2" color={appColor.App_Primary_Color} my-0 p-0>
                          {label?.liveSession?.[lang]}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} container my-auto>
                        <Grid item xs="12">
                          <Typography as="caption" sx={{ fontWeight: "500" }}>
                            {liveSession?.strAnnouncementTitle?.[lang]}
                          </Typography>
                        </Grid>
                        <Grid item xs="12">
                          <Typography as="overline" color={appColor.App_Primary_Color}>
                            {liveSession?.strTime?.[lang]
                              ? liveSession?.strTime?.[lang]
                              : `${appFunctions.getDateUSFormat(appFunctions.getCurrentDate(liveSession?.dtmFrom).toLocaleString(), true)} - ${appFunctions.getDateUSFormat(
                                  appFunctions.getCurrentDate(liveSession?.dtmTo).toLocaleString(),
                                  true
                                )}`}
                          </Typography>
                        </Grid>
                        <Grid item xs="12" pb-0>
                          <Typography as="caption" sx={{ height: `calc(15px * 1.5 * 3)`, overflow: "hidden" }}>
                            {liveSession?.strAnnouncementBody?.[lang]}
                          </Typography>
                        </Grid>
                        {!liveSession?.lstFile?.length ? null : (
                          <Grid item xs={12} px-2 pt-0 mt-0>
                            <UploaderReader lstFiles={liveSession?.lstFile} />
                          </Grid>
                        )}
                        {!getIsLiveMeetingLive(liveSession) ? null : (
                          <Grid item alignItems="center" px-0 m-auto>
                            <Button size="sm" label={label?.joinBtn?.[lang]} sx={classes.bgColor} onClick={goToExternalMeeting(liveSession?.strMeetingLink)} m-0 />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              );
            })}
        </Grid>
        <Line />

        <React.Fragment>
          <Grid container alignSelf="start" justify="flex-start" spacing="2">
            <Grid item xs={"12"}>
              <Grid container justify="flex-start" spacing={3}>
                {state?.lstServices?.map((service, ind) => (
                  <Grid item xs={12} sm="6" md="4" lg="3" key={ind}>
                    <Paper sx={classes?.card} className="serv-card-account">
                      <img src={service.img} style={classes?.cardImage} alt="" />
                      <div
                        style={classes?.cardOverlay}
                        className={`cardOverlay ${state?.indexCardOpen === ind ? "cardOverlayHovered" : ""}`}
                        onMouseEnter={handelMouseEnter(ind)}
                        onMouseLeave={handelMouseleve(ind)}
                      >
                        <div style={classes?.cardHeader} className="cardHeader">
                          <svg style={classes?.cardArc} className="cardArc">
                            <path />
                          </svg>
                          <Grid container>
                            <Grid item xs="3" md="auto">
                              <Avatar
                                className="servBgColor"
                                sx={{ width: "35px", height: "35px" }}
                                onTouchEnd={state?.indexCardOpen === ind ? handelMouseleve(ind) : handelMouseEnter(ind)}
                              >
                                <Icon icon={service?.icon} color="#fff" size="30px" />
                              </Avatar>
                            </Grid>
                            <Grid item xs="8" mt-1>
                              <Typography as="body2" sx={classes?.cardTitle}>
                                {service?.title?.[lang]}
                              </Typography>
                            </Grid>
                            {state?.indexCardOpen !== ind ? null : (
                              <Grid item xs="1" sx={{ justifySelf: "end", zIndex: 1 }}>
                                <Icon
                                  icon="openInNew"
                                  color="primary"
                                  disabled={!service?.url}
                                  onClick={() => {
                                    if (service?.url) {
                                      navigate(service?.url);
                                    }
                                  }}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </div>
                        <Grid container>
                          <Grid item xs="12">
                            <Typography as="caption" sx={classes?.cardDescription}>
                              {service?.dec?.[lang]}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
        <Grid container pb-10>
          <Grid item xs={12} pb-5 />
        </Grid>
      </Container>

      <Modal open={state?.blnOpenImgeModal} eventClose={dilogeImgModal}>
        <ModalHeader>{label?.[state?.selectedImgType]?.[lang]}</ModalHeader>
        <ModalBody>
          <Grid container>
            <Grid item xs="12">
              <img src={App_Server_Url_DownloadFiles + state?.selectedLstImg?.[0]?.path} alt={label?.[state?.selectedImgType]?.[lang]} width="100%" />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>

      <Modal open={state?.blnIsContributionOpen} eventClose={dilogeContributionModal()}>
        <ModalHeader>{label?.donationBtn?.[lang]}</ModalHeader>
        {objSystemConfig?.blnIsAutoPaid ? (
          <ModalBody>
            <Grid container justify={"center"} spacing={0}>
              <Grid item>
                <Typography as="caption" sx={{ fontWeight: "bold" }}>
                  {label?.thanksContribution?.[lang]}
                </Typography>
              </Grid>
            </Grid>
            <Line pt-3/>

            <Grid container justify={"center"}>
              {/* 
               <Grid item xs="12" container>
                <Grid item xs="12">
                  <FormField objHandler={fields.strSponsorTrainingType} icon={"topic"} dir={lang === "arb" ? "rtl" : "ltr"} />
                </Grid>
                {fields.strSponsorTrainingType?.getValue()?.key === objTrainingToSupport?.manager?.key ? null : (
                  <Grid item xs="12">
                    <FormField objHandler={fields.strSponsorFullStackCatType} icon={"code"} dir={lang === "arb" ? "rtl" : "ltr"} />
                  </Grid>
                )}
                {fields.strSponsorTrainingType?.getValue()?.key === objTrainingToSupport?.fullstack?.key ? null : (
                  <Grid item xs="12">
                    <FormField objHandler={fields.strSponsorManagerCatType} icon={"cases"} dir={lang === "arb" ? "rtl" : "ltr"} />
                  </Grid>
                )}
              </Grid> 
              */}
              <Grid item xs="12" pt-5 pb-3>
                <FormField objHandler={fields.strSponsorFullStackCatType} icon={"code"} dir={lang === "arb" ? "rtl" : "ltr"} />
              </Grid>
              {!fields?.strSponsorFullStackCatType?.getValue() ? null : (
                <Grid item xs="12" pb-3>
                  <PaperSection p-1>
                    <Grid container>
                      <Grid item xs="12">
                        <Typography as="caption" sx={{ fontWeight: "bold !important" }}>
                          {label?.strViewAccountBank?.[lang]}
                        </Typography>
                      </Grid>
                      <Grid item xs="12" pt-4>
                        <TextArea defaultValue={objSystemConfig?.strBankInfo?.[lang]} readOnly style={{borderColor:'#fff', resize:"none", color:"#0b394f", backgroundColor:"#cbe7fd", margin:"0" }} line="4"/>
                      </Grid>
                    </Grid>
                  </PaperSection>
                </Grid>
              )}
            </Grid>
            {!fields?.strSponsorFullStackCatType?.getValue() ? null : (
              <React.Fragment>
                <Grid container justify={"start"} py-2>
                  <Grid item>
                    <Typography as="caption" px-2>
                      {label?.receiptUpload?.[lang]}
                    </Typography>
                  </Grid>
                  <Grid item px-1 sx={{ justifySelf: "flex-end" }}>
                    {uploaderReceipt?.JSX}
                  </Grid>
                </Grid>
                <Grid container justify={"start"} py-2>
                  <Grid item>
                    <Typography as="caption" px-2>
                      {label?.whatsappUpload?.[lang]}
                    </Typography>
                    <Icon
                      icon="whatsApp"
                      color="#25d366"
                      onClick={() => {
                        window?.open(`https://wa.me/965${objContact?.strSupportPhone}`, "_blank");
                      }}
                    />
                  </Grid>
                  <Grid item px-1>
                    <FormField objHandler={fields.blnUploadedWhatsapp} dir={lang === "arb" ? "rtl" : "ltr"} />
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
            {!state?.lstReceipt?.length && !fields.blnUploadedWhatsapp?.getValue().length ? null : (
              <Grid container spacing={0} justify={"center"} pt-4>
                <Grid item>
                  <Button label={dictionary?.shared?.btn?.submit?.[lang]} onClick={handelSubmit(objSupportTransStatus?.Send_New)} px-3 size="sm" />
                </Grid>
              </Grid>
            )}
          </ModalBody>
        ) : (
          <ModalBody>
            <Grid container>
              <Grid item xs="12">
                <Typography as="caption">{label?.thanksContribution?.[lang]}</Typography>
              </Grid>
            </Grid>

            <Line pt-2 />
            <Grid container>
              <Grid item xs={blnHaveContactInfo ? "12" : "0"} container>
                <Grid item xs="12" container>
                  <Grid item>
                    <Typography as="body2">{label?.contactopt?.[lang]}</Typography>
                  </Grid>
                </Grid>
                {!objContact?.strEmail && !objContact?.strSupportEmail ? null : (
                  <Grid item xs="12" container>
                    <Grid item>
                      <Icon icon="email" color="primary" />
                    </Grid>
                    <Grid item xs="10">
                      <Typography as="caption">
                        <a href={`mailto:${objContact?.strEmail}`}>{objContact?.strEmail}</a>
                        {objContact?.strEmail && objContact?.strSupportEmail ? ` / ` : null}
                        <a href={`mailto:${objContact?.strSupportEmail}`}>{objContact?.strEmail}</a>
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {!objContact?.strPhoneNumber && !objContact?.strSupportPhone ? null : (
                  <Grid item xs="12" container>
                    <Grid item>
                      <Icon icon="phone" color="primary" />
                    </Grid>
                    <Grid item xs="10">
                      <Typography as="caption">
                        <a href={`tel:${objContact?.strPhoneNumber}`}>{objContact?.strPhoneNumber}</a>
                        {objContact?.strPhoneNumber && objContact?.strSupportPhone ? ` / ` : null}
                        <a href={`tel:${objContact?.strSupportPhone}`}>{objContact?.strSupportPhone}</a>
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {!objContact?.strAddress ? null : (
                  <Grid item xs="12" container>
                    <Grid item>
                      <Icon icon="LocationOn" color="primary" />
                    </Grid>
                    <Grid item xs="10">
                      <Typography as="caption">
                        <a>{objContact?.strAddress?.[lang]}</a>
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs="12">
                <Typography as="body2">{label?.contactopt2?.[lang]}</Typography>
              </Grid>
              <Grid item xs="12">
                <ContactUs appState={appState} lang={lang} blnFromSponser={true} submitClicked={dilogeContributionModal()} />
              </Grid>
            </Grid>
          </ModalBody>
        )}
      </Modal>
    </React.Fragment>
  );
}

export default AccountSponsor;
