import React, { useContext, useEffect, useInsertionEffect, useState } from "react";
import { Button, Grid, Typography, Container, Paper, Line, useNavigate, useLocation, Loader } from "@cbmisorg/client-app";
import useCheckSystemInfo from "../../../appHelper/appHook/useCheckSystemInfo";
import useCheckCat from "../../../appHelper/appHook/useCheckCat";
import { AppContext } from "../../../configuration/contextapi/AppContext";
import { dictionary } from "../../../appHelper/appLanguage/dictionary";
import ShredHeader from "../../sharedUi/header/ShredHeader";
import { FormData, FormField } from "../../../appHelper/formApi/FormAPI";
import { App_Primary_Color, App_Text_Color } from "../../../appHelper/appColor";
import { appStyle } from "../../../appHelper/appStyle";
import { globalKey } from "../../../appHelper/appVariables";
import { CtrlJoinManager } from "./controller/CtrlJoinManager";
import objAppRouting from "../../../appHelper/routing/appRouting";

function JoinManager() {
  useCheckSystemInfo();
  const searchParams = new URL(window.location.href);
  const extLang = searchParams.searchParams.get("lang") || null;
  const { getNewCatInfo } = useCheckCat();
  const location = useLocation();
  const navigate = useNavigate();
  const { appState, appDispatch } = useContext(AppContext);
  const appLanguage = appState?.clientInfo?.strLanguage || "arb";
  const labels = dictionary?.components?.public?.joinTrainee;
  const [isLoading, setIsLoading] = useState(false);
  const [stateInfo, setStateInfo] = useState({
    blnIsInitDone: false,
  });

  const fields = FormData({
    strUserEmail: { strControl: "text", objLanguage: false, objValidations: { required: true } },
    strUserPhone: { strControl: "text", objLanguage: false, objValidations: { required: true, onlyNumbers: true, minLength: { condition: 8 }, maxLength: { condition: 8 } } },
    strFullName: { strControl: "text", objLanguage: false, objValidations: { required: true } },
    strWorkGovPlace: { strControl: "text", objValidations: { required: true }, objLanguage: false },
    strPositionTitle: { strControl: "text", objValidations: { required: true }, objLanguage: false },

    bigMajorID: { strControl: "list", lstOption: [], objValidations: { required: true } },
    bigDegreeID: { strControl: "list", lstOption: [], objValidations: { required: true } },
    bigGender: { strControl: "list", lstOption: [], objValidations: { required: true } },
    strUserPassword: {
      strControl: "password",
      objLanguage: false,
      objValidations: { required: true, minLength: { condition: 6 } },
    },
    strConfirmPassword: {
      strControl: "password",
      objLanguage: false,
      objValidations: { required: true, minLength: { condition: 6 }, equal: { condition: "strUserPassword" } },
    },
  });

  const handlers = CtrlJoinManager(appState, appDispatch);
  const initData = handlers.initData({ isLoading, setIsLoading, state: stateInfo, setState: setStateInfo, fields, getNewCatInfo });
  const addJoinTraining = handlers.addJoinTraining({ fields, isLoading, setIsLoading, navigate, location });

  useEffect(() => {
    if (appState?.clientInfo?.blnIsUserLoggedIn) {
      navigate(objAppRouting?.Account?.url, { replace: true });
    }
    if (!stateInfo?.blnIsInitDone) {
      initData();
    }

    if(extLang){
      appState.clientInfo.strLanguage = extLang;
      appState.clientInfo.strDataLanguage = extLang;
      appDispatch()
    }
  }, []);

  return (
    <React.Fragment>
      <ShredHeader title={labels?.title?.[appLanguage]} lang={appLanguage}  blnBottomBar={false}/>
      <Container py-5 pb-xs-10 mb-xs-10 pb-sm-10 mb-sm-10>
        {isLoading ? <Loader backdrop={true} color={App_Primary_Color} /> : null}
        <Grid container justify={"center"} pb-xs-10 mb-xs-10>
          <Grid item xs="12" md="10">
            <Paper p-0 elevation={4} outline={1} sx={{ borderRadius: "8px !important", borderColor: "var(--cbmis-dark)" }} className="cardContainer">
              <Grid container justify={"center"} pt-3>
                <Grid item xs="12">
                  <Typography as="caption" color={App_Text_Color} sx={{ fontWeight: "500 !important", fontFamily: "Times New Roman !important", textAlign: "center !important" }}>
                    {labels?.subtitle?.[appLanguage]}
                  </Typography>
                </Grid>
              </Grid>
              <Line py-3 />
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography as="body2" sx={appStyle?.title1}>
                    {labels?.personalInfo?.[appLanguage]}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} justify={"center"}>
                <Grid item xs={12}>
                  <FormField objHandler={fields.strFullName} icon="person" dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    objHandler={fields.strUserPhone}
                    icon={appLanguage === "arb" ? "phone" : null}
                    endIcon={appLanguage !== "arb" ? "phone" : null}
                    endAdornment={appLanguage === "arb" ? globalKey : null}
                    adornment={appLanguage !== "arb" ? globalKey : null}
                    className={`inputLeft`}
                  />
                </Grid>
                <Grid item xs={12} sm="6" md="4">
                  <FormField objHandler={fields.bigGender} icon="Wc" dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                </Grid>
                <Grid item xs={12} sm="6" md="4">
                  <FormField objHandler={fields.bigDegreeID} icon="documentScannerRounded" dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                </Grid>
                <Grid item xs={12} sm="6" md="4">
                  <FormField objHandler={fields.bigMajorID} icon="book" dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                </Grid>
                <Grid item xs={12} sm="6">
                  <FormField objHandler={fields.strWorkGovPlace} icon="accountBalance" dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                </Grid>
                <Grid item xs={12} sm="6">
                  <FormField objHandler={fields.strPositionTitle} icon="work" dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                </Grid>
              </Grid>
              <Line py-3 />
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography as="body2" sx={appStyle?.title1}>
                    {labels?.registrationInfo?.[appLanguage]}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} justify={"center"}>
                <Grid item xs={12}>
                  <FormField objHandler={fields.strUserEmail} icon="email" className={`inputLeft inputLeft${appLanguage}`} />
                </Grid>

                <Grid item xs={12} md="6">
                  <FormField objHandler={fields.strUserPassword} icon="vpnKey" className={`inputLeft`} dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                </Grid>
                <Grid item xs={12} md="6">
                  <FormField objHandler={fields.strConfirmPassword} icon="vpnKey" className={`inputLeft`} dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                </Grid>
              </Grid>
              <Grid container justify={"center"}>
                <Grid item xs="12" pt-5 pb-8>
                  <Button py-0 px-10 label={labels?.btnReg?.[appLanguage]} onClick={addJoinTraining} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      
      </Container>
    </React.Fragment>
  );
}

export default JoinManager;
