import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../../configuration/contextapi/AppContext';
import { ClientFunction, Container, Grid, Icon, MenuItem, Paper, Typography,Loader, Checkbox, InfiniteScroll, } from '@cbmisorg/client-app';
import { App_Primary_Color } from '../../../../appHelper/appColor';
import { CtrlSubscription } from './controler/CtrlSubscription';
import ShredHeader from '../../../sharedUi/header/ShredHeader';
import { dictionary } from '../../../../appHelper/appLanguage/dictionary';
import NoResultImg from '../../../sharedUi/noResultImg/NoResultImg';
import excelIcon from '../../../../assets/images/excel-icon.png';

const classes={
  heading:{
    background:App_Primary_Color,
    color:"#fff"
  }
}
const labels = dictionary?.components?.supscrption

function RouteSubscription() {
  const { appState , appDispatch} = useContext(AppContext);
  const lang = appState.clientInfo.strLanguage
  const [isLoading, setIsLoading] = useState(false)
  const [stateInfo, setStateInfo] = useState({
    lstSubscription:[],
    blnIsInitDone:false,
    lstMailChecked:[]
  })

  const handlers = CtrlSubscription(appState, appDispatch,isLoading, setIsLoading,stateInfo, setStateInfo);

  useEffect(() => {
    if(!stateInfo?.blnIsInitDone){
      handlers?.getSubscription()
    }
  }, [])

  const handelDelete=(item)=>async()=>{
    if(item){
      await handlers.delateSubscriptionConfirmation(item)
    }
  }
  
  const ComponentItem=({item})=>{
    return(
      <Grid item xs="12" container key={item?.bigMailID}>
      <Grid item sx={{width:"3%",sm:{width:"8%"}}} p-0>
         <Checkbox options={[{key:item?.strSubscriptionEmail,value:""}]} 
                  checked={stateInfo.lstMailChecked?.includes(item?.strSubscriptionEmail)}
                  onChange={handlers?.checkSubscription}
                  />
      </Grid>
      <Grid item sx={{width:"97%", sm:{width:"92%"}}} p-0>
        <MenuItem sx={{width:"100%"}} 
          outlined
         >
          <Grid container justify={'space-between'} py-1 spacing={0}>
            <Grid item container spacing={0}>
              <Grid item xs="12" pb-0>
                 <Typography as="body2" pb-0>{item?.strSubscriptionEmail}</Typography>
              </Grid>
            </Grid>
           <Grid item container px-0 spacing={0}>
            <Grid item px-2>
              <Typography as="caption" sx={{textAlign:"end"}}>{ClientFunction?.getDateUSFormat(item?.dtmCreatedDate)}</Typography>
            </Grid>
            <Grid item>
              <Icon icon="delete" color="error" onClick={handelDelete(item)} />
            </Grid>
           </Grid>
          </Grid>
        </MenuItem>
      </Grid>
    </Grid>
    )
  }


  return (
    <React.Fragment>
      <ShredHeader title={labels?.title?.[lang]} lang={lang} blnBottomBar={false}/>
      <Container className="emailView" py-3>
      {
        isLoading?<Loader backdrop={true} color={App_Primary_Color}/>:null
      }
      {
        !stateInfo.lstSubscription?.length?null:(
          <Grid container justify={lang ==="arb"?'end':""}>
            <Grid item xs="12">
              <Typography as="body1">{`${labels?.numSubscriper?.[lang]} (${stateInfo.lstSubscription?.length})`}</Typography>
            </Grid>
          </Grid>
        )
      }
       <Grid container justify={'center'}>
          <Grid item xs="12">
            <Paper sx={{minHeight:"82vh",maxHeight:"82vh",overflowY:"auto"}} p-0>
              <Grid container sx={classes?.heading} justify={'space-between'}>
              <Grid item>
                {
                  !stateInfo.lstSubscription?.length?null:(
                    <Checkbox 
                      options={[{key:"0",value:""}]} 
                      onChange={handlers?.checkAll}
                      checked={stateInfo.lstMailChecked?.length}
                      checkMode={stateInfo?.lstMailChecked?.length === stateInfo?.lstSubscription?.length?"tick":"indeterminate"}

                    />
                  )
                }
              </Grid>
                <Grid item container>
                {
                  !stateInfo.lstSubscription?.length?null:(
                      <React.Fragment>
                        <Grid item>
                            <Icon icon="email" onClick={()=>window.open(`mailto:?bcc=${stateInfo?.lstMailChecked}`)} />
                        </Grid>
                        <Grid item xs="1" md="1" justify={"end"} mt-1>
                            <span onClick={handlers?.exportExcelFile}>
                              <img src={excelIcon} alt="" width={"27px"} style={{borderRadius:"4px"}}/>
                            </span>
                        </Grid>
                      </React.Fragment>
                  )
                }
                </Grid>

              </Grid>
              {
                !stateInfo?.lstSubscription?.length?(
                  <Grid container justify={'center'}>
                    <Grid item xs="12">
                      <NoResultImg type='subscription' />
                    </Grid>
                  </Grid>
                ):(
                  <Grid container>
                    {
                      stateInfo?.lstSubscription?.map(item=>{
                        return(
                         <ComponentItem item={item} />
                        )
                      })
                    }
                  </Grid>
                )
               }
            </Paper>
         </Grid>
       </Grid>
      </Container>

    </React.Fragment>
  )
}

export default RouteSubscription