import { useContext } from "react";
import { SecureRoute, ClientFunction } from "@cbmisorg/client-app";
import appRouting from "./appRouting";
import { AppContext } from "../../configuration/contextapi/AppContext";
import * as appVariables from "../appVariables";
import * as appFunctions from "../appFunctions";

function GetUserFeatures() {
  const { appState } = useContext(AppContext);
  appState.clientInfo.strLanguage = appState.clientInfo.strDataLanguage;

  const getUserFeature = () => {
    let appLocalStorage = appFunctions.getAppLocalStorage();

    if (appLocalStorage?.clientInfo?.blnIsUserLoggedIn && appLocalStorage?.clientInfo?.dtmLastLoggingIn) {
      let intLoginBefore = appFunctions.getDifferenceBetweenTwoDate(appLocalStorage?.clientInfo?.dtmLastLoggingIn, new Date(), "day");
      if (intLoginBefore >= 6.9) {
        if (appVariables.App_IsInTestingMode) {
          alert(intLoginBefore);
        }
        appFunctions.clearAppLocalStorage(appLocalStorage);
      }
    }

    return { userRole: appLocalStorage?.userInfo?.bigUserRoleID, userFeature: appLocalStorage?.userInfo?.lstUserFeature };
  };

  return getUserFeature();
}

export default SecureRoute.SecureRoute(appRouting, { userAuth: GetUserFeatures }, appRouting.Public_LandingPage.url, {
  strRoleType: "defaultRole",
  blnIsLocalStorageEncrypted: appVariables.App_LocalStorage_Is_Encrypted,
  strLocalStorageEncryptKey: appVariables.App_LocalStorage_Encrypted_KEY,
  blnIsTestingMode: appVariables.App_IsInTestingMode,
});