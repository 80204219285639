import { Alert, Confirmation } from "@cbmisorg/client-app";
import * as appFunctions from "../../../../../appHelper/appFunctions";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import { generateQueries } from "../../../../../appHelper/appQuery/GenerateQuery";
import { App_ID, globalKeyZero, objUserRole } from "../../../../../appHelper/appVariables";

const strPageInfo = "@src/components/stakeholders/shared/manageMembers/controler/CtrlProfile.js";

const tblUser = generateQueries("tblUser");

export const CtrlProfile = (appState, appDispatch) => {
  const lang = appState?.clientInfo?.strLanguage;

let handler = {
    initData:
      ({ isLoading, setIsLoading, state, setState, fields, fieldEmail, fieldPhone }) =>
      async () => {
        try {
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning");
            return;
          }

          setIsLoading(true);
          state.blnIsInitDone = true;

          const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);

          let lstCountryOption = [];
          let objCountryOptionLang = {};

          let lstDegreeOption = [];
          let objDegreeOptionLang = {};

          let lstEmploymentOption = [];
          let objEmploymentOptionLang = {};

          let lstMajorOption = [];
          let objMajorOptionLang = {};

          let lstAcademicYearOption = [];
          let objAcademicYearOptionLang = {};

          let lstGPAOption = [];
          let objGPAOptionLang = {};

          let lstGenderOption = [];
          let objGenderOptionLang = {};

          Object.entries(objCat?.Root?.country || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstCountryOption.push({ key: value?.id, value: value?.id });
              objCountryOptionLang[value?.id] = value?.value;
            }
          });
          Object.entries(objCat?.Root?.degree || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstDegreeOption.push({ key: value?.id, value: value?.id });
              objDegreeOptionLang[value?.id] = value?.value;
            }
          });
          Object.entries(objCat?.Root?.employment || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstEmploymentOption.push({ key: value?.id, value: value?.id });
              objEmploymentOptionLang[value?.id] = value?.value;
            }
          });
          Object.entries(objCat?.Root?.major || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstMajorOption.push({ key: value?.id, value: value?.id });
              objMajorOptionLang[value?.id] = value?.value;
            }
          });
          Object.entries(objCat?.Root?.academicYear || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstAcademicYearOption.push({ key: value?.id, value: value?.id });
              objAcademicYearOptionLang[value?.id] = value?.value;
            }
          });
          Object.entries(objCat?.Root?.gpa || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstGPAOption.push({ key: value?.id, value: value?.id });
              objGPAOptionLang[value?.id] = value?.value;
            }
          });
          Object.entries(objCat?.Root?.gender || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstGenderOption.push({ key: value?.id, value: value?.id });
              objGenderOptionLang[value?.id] = value?.value;
            }
          });

          const jsnUserInfo = appState?.userInfo?.jsnUserInfo;

          fields.bigMajorID.setOptions(lstMajorOption, objMajorOptionLang);
          fields.bigDegreeID.setOptions(lstDegreeOption, objDegreeOptionLang);
          fields.bigGender.setOptions(lstGenderOption, objGenderOptionLang);
          fields.bigEmploymentID.setOptions(lstEmploymentOption, objEmploymentOptionLang);
          fields.bigCountryOfCitizenID.setOptions(lstCountryOption, objCountryOptionLang);
          fields.bigCountryOfResidenceID.setOptions(lstCountryOption, objCountryOptionLang);
          fields.bigAcademicYearID.setOptions(lstAcademicYearOption, objAcademicYearOptionLang);
          fields.bigGpaID.setOptions(lstGPAOption, objGPAOptionLang);
          handler.onChangeCountry({ fields })({ target: { id: jsnUserInfo?.bigCountryOfResidenceID } });

          fields.strFullName.setValue(typeof jsnUserInfo?.strFullName === "object" ? jsnUserInfo?.strFullName?.[lang] : jsnUserInfo?.strFullName);
          fields.bigMajorID.setValue(jsnUserInfo?.strMajor);
          fields.bigDegreeID.setValue(jsnUserInfo?.bigDegreeID);
          fields.bigGender.setValue(jsnUserInfo?.bigGender);
          fields.bigEmploymentID.setValue(jsnUserInfo?.bigEmploymentID);
          fields.bigCountryOfCitizenID.setValue(jsnUserInfo?.bigCountryOfCitizenID);
          fields.bigCountryOfResidenceID.setValue(jsnUserInfo?.bigCountryOfResidenceID);
          fields.bigAcademicYearID.setValue(jsnUserInfo?.bigAcademicYearID);
          fields.bigGpaID.setValue(jsnUserInfo?.bigGpaID);
          fields.strUniversityName.setValue(jsnUserInfo?.strUniversityName);

          fields.strWorkGovPlace.setValue(jsnUserInfo?.strWorkPlace);
          fields.strPositionTitle.setValue(jsnUserInfo?.strPositionTitle);

          fieldEmail.strRestEmail.setValue(appState?.userInfo?.strUserEmail);
          fieldPhone.strUserPhone.setValue(appState?.userInfo?.strUserPhone?.slice(5));

          setIsLoading(false);
          setState({ ...state });
        } catch (error) {
          setIsLoading(false);
          appFunctions.logMessage(strPageInfo, error, "routeMngRequestedJoinCtrl-Error");
        }
      },
    onChangeCountry:
      ({ fields }) =>
      (event) => {
        const bigCountryID = event?.target?.id;

        const objCat = appFunctions.getCategoryCache();

        let lstUniversityOption = [];
        let objUniversityOptionLang = {};

        Object.entries(objCat?.Root?.country?.[appFunctions.appCategoryGetKey(objCat?.Root?.country, bigCountryID)]?.university || {}).forEach(([key, value]) => {
          if (key !== "id" && key !== "value") {
            lstUniversityOption.push({ key: value?.id, value: value?.id });
            objUniversityOptionLang[value?.id] = value?.value;
          }
        });

        lstUniversityOption.push({ key: "other", value: "other" });
        objUniversityOptionLang["other"] = dictionary.shared.btn.otherBtn;

        fields.strUniversityName.setOptions(lstUniversityOption, objUniversityOptionLang);
      },

    handelSubmit:
      ({ fields, isLoading, setIsLoading, navigate }) =>
      async () => {
        try {
          if (!fields.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
            return;
          }
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
            return;
          }
          setIsLoading(true);

          const newObj = {
            jsnUserInfo: {
              ...appState?.userInfo?.jsnUserInfo,
              strFullName: { eng: fields?.strFullName?.getValue(), arb: fields?.strFullName?.getValue() },
              bigCountryOfCitizenID: fields?.bigCountryOfCitizenID?.getValue()?.key,
              bigCountryOfResidenceID: fields?.bigCountryOfResidenceID?.getValue()?.key,
              strMajor: fields?.bigMajorID?.getValue()?.key,
              bigEmploymentID: fields?.bigEmploymentID?.getValue()?.key,
              bigDegreeID: fields?.bigDegreeID?.getValue()?.key,
              bigGender: fields?.bigGender?.getValue()?.key,
              bigAcademicYearID: fields?.bigAcademicYearID?.getValue()?.key,
              strUniversityName: fields?.strUniversityName?.getValue()?.key,
              bigGpaID: fields?.bigGpaID?.getValue()?.key,
              strWorkPlace: fields?.strWorkGovPlace?.getValue() || "",
              strPositionTitle: fields?.strPositionTitle?.getValue() || "",
            },
            dtmUpdatedDate: new Date().toUTCString(),
          };
          const result = await tblUser(strPageInfo, appState, "aaiotAppUserUpdate", {
            objectInput: newObj,
            objectCondition: {
              bigUserID: appState?.userInfo?.bigUserID,
              bigSystemID: appState?.userInfo?.bigSystemID,
              blnIsActive: true,
              blnIsDeleted: false,
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning");
            return;
          }
          setIsLoading(false);
          appState.userInfo.jsnUserInfo = newObj.jsnUserInfo;
          appDispatch();
          Alert.viewAlert(dictionary.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "handelSubmit-ERROR", error);
          setIsLoading(false);
        }
      },

    funRestPassword:
      ({ isLoading, setIsLoading, state, setState, fields }) =>
      () => {
        const fun = async () => {
          try {
            if (!fields.isFormValid()) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
              return;
            }
            if (isLoading) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
              return;
            }
            setIsLoading(true);

            const result = await tblUser(strPageInfo, appState, "aaiotAppUserResetPassword", {
              objectInput: {
                strUserPassword: fields?.strRestPassword?.getValue(),
              },
              objectCondition: {
                bigAppID: App_ID,
                bigSystemID: appState?.userInfo?.bigSystemID,
                bigUserID: appState.userInfo?.bigUserID,
                strUserEmail: appState?.userInfo?.strUserEmail,
                strUserPassword: fields?.strOldUserPassword?.getValue(),
              },
            });

            if (!result?.blnIsRequestSuccessful) {
              appFunctions.logMessage(result?.response, "");
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Update_Record?.[lang], "warning");
              setIsLoading(false);
              return;
            }
            setIsLoading(false);
            state.modalPasswordOpen = false;
            setState({ ...state, blnIsInitDone: true });
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
            fields?.resetField();
          } catch (error) {
            appFunctions.logMessage(strPageInfo, "funRestPassword-ERROR", error);
            setIsLoading(false);
          }
        };

        if (!fields.isFormValid()) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
          return;
        }
        Confirmation.viewConfirmation(
          dictionary?.shared?.confirmationMsg?.changePasswordInfo?.[lang],
          fun,
          () => null,
          dictionary?.shared?.confirmationMsg?.yes?.[lang],
          dictionary?.shared?.confirmationMsg?.no?.[lang]
        );
      },
    funRestEmail:
      ({ isLoading, setIsLoading, state, setState, fields }) =>
      () => {
        const fun = async () => {
          try {
            if (!fields.isFormValid()) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
              return;
            }
            if (isLoading) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
              return;
            }
            if (String(appState?.userInfo?.strUserEmail).toLowerCase().trim() === String(fields?.strRestEmail?.getValue()).toLowerCase().trim()) {
              state.modalEmailOpen = false;
              setState({ ...state, blnIsInitDone: true });
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
              return;
            }

            setIsLoading(true);
            const resultRead = await tblUser(strPageInfo, appState, "aaiotAppUserOne", {
              arrAttributes: ["bigUserID"],
              objectCondition: {
                bigAppID: App_ID,
                bigSystemID: appState?.userInfo?.bigSystemID,
                strUserEmail: fields?.strRestEmail?.getValue(),
              },
            });

            if (resultRead.blnIsRequestSuccessful && resultRead?.response?.bigUserID && !Number.isNaN(Number(resultRead?.response?.bigUserID))) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote.email_Already_Used?.[lang], "");
              setIsLoading(false);
              return;
            }

            const result = await tblUser(strPageInfo, appState, "aaiotAppUserUpdate", {
              objectInput: {
                strUserEmail: fields?.strRestEmail?.getValue(),
              },
              objectCondition: {
                bigAppID: App_ID,
                bigSystemID: appState?.userInfo?.bigSystemID,
                bigUserID: appState?.userInfo?.bigUserID,
              },
            });

            if (!result?.blnIsRequestSuccessful) {
              appFunctions.logMessage(result?.response, "");
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Update_Record?.[lang], "warning");
              setIsLoading(false);
              return;
            }
            setIsLoading(false);
            appState.userInfo.strUserEmail = fields?.strRestEmail?.getValue();
            state.modalEmailOpen = false;
            setState({ ...state, blnIsInitDone: true });
            appDispatch();
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
          } catch (error) {
            appFunctions.logMessage(strPageInfo, "funRestEmail-ERROR", error);
            setIsLoading(false);
          }
        };

        if (!fields.isFormValid()) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
          return;
        }
        Confirmation.viewConfirmation(
          dictionary?.shared?.confirmationMsg?.changeEmailInfo?.[lang],
          fun,
          () => null,
          dictionary?.shared?.confirmationMsg?.yes?.[lang],
          dictionary?.shared?.confirmationMsg?.no?.[lang]
        );
      },

    funRestPhone:
      ({ isLoading, setIsLoading, state, setState, fields }) =>
      () => {
        const fun = async () => {
          try {
            if (!fields.isFormValid()) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
              return;
            }
            if (isLoading) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
              return;
            }
            if (
              String(appState?.userInfo?.strUserPhone).toLowerCase().trim() ===
              String(globalKeyZero + fields?.strUserPhone?.getValue())
                .toLowerCase()
                .trim()
            ) {
              state.modalPhoneOpen = false;
              setState({ ...state, blnIsInitDone: true });
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
              return;
            }

            setIsLoading(true);
            const resultRead = await tblUser(strPageInfo, appState, "aaiotAppUserOne", {
              arrAttributes: ["bigUserID"],
              objectCondition: {
                bigSystemID: appState?.userInfo?.bigSystemID,
                strUserPhone: globalKeyZero + String(fields?.strUserPhone?.getValue()).trim(),
              },
            });

            if (resultRead?.blnIsRequestSuccessful && resultRead?.response?.bigUserID && !Number.isNaN(Number(resultRead?.response?.bigUserID))) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote.phone_Already_Used?.[lang], "");
              setIsLoading(false);
              return;
            }

            const result = await tblUser(strPageInfo, appState, "aaiotAppUserUpdate", {
              objectInput: {
                strUserPhone: globalKeyZero + fields?.strUserPhone?.getValue(),
              },
              objectCondition: {
                bigSystemID: appState?.userInfo?.bigSystemID,
                bigUserID: appState?.userInfo?.bigUserID,
              },
            });
            if (!result?.blnIsRequestSuccessful) {
              appFunctions.logMessage(result?.response, "");
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Update_Record?.[lang], "warning");
              setIsLoading(false);
              return;
            }

            setIsLoading(false);
            appState.userInfo.strUserPhone = globalKeyZero + String(fields?.strUserPhone?.getValue()).trim();
            state.modalPhoneOpen = false;
            setState({ ...state, blnIsInitDone: true });
            appDispatch();
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
          } catch (error) {
            appFunctions.logMessage(strPageInfo, "funRestPhone-ERROR", error);
            setIsLoading(false);
          }
        };
        if (!fields.isFormValid()) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
          return;
        }
        Confirmation.viewConfirmation(
          dictionary?.shared?.confirmationMsg?.changePhoneInfo?.[lang],
          fun,
          () => null,
          dictionary?.shared?.confirmationMsg?.yes?.[lang],
          dictionary?.shared?.confirmationMsg?.no?.[lang]
        );
      },

    funCloseModal:
      ({ state, setState }) =>
      () => {
        setState({ ...state, modalPasswordOpen: false, modalEmailOpen: false, modalPhoneOpen: false });
      },
    getPhoneGlobalKey: (bigCountryID) => {
      try {
        const objCat = appFunctions.getCategoryCache();
        return appFunctions.appCategoryGetValue(objCat?.Root?.country, bigCountryID, "phoneCode", true);
      } catch {
        return "";
      }
    },
  };

  return handler;
};
