import { Alert } from "@cbmisorg/client-app";
import * as appFunctions from "../../../../../appHelper/appFunctions";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import { generateQueries, objTableAttributes } from "../../../../../appHelper/appQuery/GenerateQuery";
import { aaiot_System_ID, objSupportTransStatus, objTrainingToSupport } from "../../../../../appHelper/appVariables";

const strPageInfo = "@src/components/stakeholders/Sponsor/sponsorProfileInfo/controler/CtrSponsorProfileInfo.js";

const tblPartner = generateQueries("tblPartner");
const tblStatistic = generateQueries("tblStatistic");

export const CtrlSponsorProfileInfo = (appState, appDispatch) => {
  const lang = appState?.clientInfo?.strLanguage;

  let handler = {
    initData:
      ({ isLoading, setIsLoading, state, setState, fields }) =>
      async () => {
        try {
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
            return;
          }

          state.blnIsInitDone = true;
          setIsLoading(true);

          const result = await tblPartner(strPageInfo, appState, "aaiotAppFindOne", {
            arrAttributes: objTableAttributes?.tblPartner?.full,
            objectCondition: { bigPartnerID: appState?.userInfo?.bigUserID, blnIsActive: true, blnIsDeleted: false },
          });
          if (!result?.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cantFindRecords?.[lang], "warning");
            setIsLoading(false);
            return;
          }
          state.objPartner = result.response
          fields?.strPlaceName?.setValue(state.objPartner?.jsnPartnerInfo?.strPlaceName);
          fields?.bigWorkSector?.setValue(state.objPartner?.jsnPartnerInfo?.bigWorkSector);
          fields?.strURL?.setValue(state.objPartner?.jsnPartnerInfo?.strURL);
          
          if (state.objPartner?.blnVewLogo) {
            fields?.blnViewLogo?.setValue(["true"]);
          }
          setIsLoading(false);
          setState({ ...state });
        } catch (error) {
          setIsLoading(false);
          appFunctions.logMessage(strPageInfo, error, "routeMngRequestedJoinCtrl-Error");
        }
      },

      handelSubmit:
      ({ isLoading, setIsLoading, state, setState, fields, uploaderLogo }) =>
      async () => {
        try {
          if (fields?.strURL?.getValue() && !appFunctions.isValidUrl(fields?.strURL?.getValue())) {
            fields?.strURL?.controller?.setError("strURL", dictionary?.shared?.formValidationsMsg?.url);
            return;
          }
          if (!fields.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
            return;
          }

          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
            return;
          }
          setIsLoading(true);

          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region upload file
          const resultUploadFile = await uploaderLogo?.uploadHandler();
          if (!resultUploadFile) {
            setIsLoading(false);
            return;
          }
          //#endregion
          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region set new values to cache (state)

          state.objPartner.jsnPartnerInfo.strPlaceName = fields?.strPlaceName?.getValue();
          state.objPartner.jsnPartnerInfo.bigWorkSector = fields?.bigWorkSector?.getValue()?.key;
          state.objPartner.jsnPartnerInfo.strURL = fields?.strURL?.getValue();
          state.objPartner.blnVewLogo = fields?.blnViewLogo?.getValue()? true: false
          state.objPartner.strLogoImg = state?.listLogo?.length ? state?.listLogo : state.objPartner?.strLogoImg
          //#region update partner info

          const result = await tblPartner(strPageInfo, appState, "aaiotAppUpdate", {
            objectInput: {
              jsnPartnerInfo: state.objPartner.jsnPartnerInfo,
              dtmUpdatedDate: new Date().toISOString(),

              blnVewLogo: state?.objPartner?.blnVewLogo,
              strLogoImg: state.objPartner?.strLogoImg,
            },
            objectCondition: {
              bigAppID: state.objPartner?.bigAppID,
              bigSystemID: state.objPartner?.bigSystemID,
              bigPartnerID: state.objPartner?.bigPartnerID,
              blnIsActive: true,
              blnIsDeleted: false,
            },
          });
          
          if (!result?.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning");
            setIsLoading(false);
            return;
          }
          //#endregion
          setIsLoading(false);
  

          setState({ ...state });
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "updatePartnerInfoAndUserInfoDB-ERROR", error);
          setIsLoading(false);
        }
      },
  };

  return handler;
};
