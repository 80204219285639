import { Alert, ClientFunction, Confirmation } from "@cbmisorg/client-app";
import * as appFunctions from "../../../../appHelper/appFunctions";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import objAppRouting from "../../../../appHelper/routing/appRouting";
import { generateQueries } from "../../../../appHelper/appQuery/GenerateQuery";
import { App_ID, aaiot_System_ID, globalKeyZero, objPartnerStatus, objSupportTransStatus, objTrainingToSupport, objUserRole } from "../../../../appHelper/appVariables";

const strPageInfo = "@src/components/public/login/controller/CtrlLogin.js";
const tblPartner = generateQueries("tblPartner");
const tblStatistic = generateQueries("tblStatistic");

export const CtrlJoinPartner = ({ appState, appDispatch, isLoading, setIsLoading, navigate, state, setState, trainingType }) => {
  const lang = appState?.clientInfo?.strDataLanguage || appState?.clientInfo?.strLanguage;

  const handler = {
    initData: async ({ checkCatHookValidity, checkSysHookValidity, fields }) => {
      if (isLoading) {
        Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
        return;
      }
      setIsLoading(true);
      await checkSysHookValidity();
      await checkCatHookValidity();

      const objCat = appFunctions.getCategoryCache();
      const objSystemConfig = appState?.clientInfo?.objSystemInfo?.jsnSystemConfig;

      let lstSponserOption = [];
      let objSponserOptionLang = {};
      const objTrainingFromCat = objCat?.Root?.trainingType?.[String(trainingType).toLowerCase()];
      if (objTrainingFromCat) {
        Object.entries(objTrainingFromCat?.sponser || {}).forEach(([key, value]) => {
          if (key !== "id" && key !== "value" && objSystemConfig?.objTrainingSponserConfig?.[String(trainingType).toLowerCase()]?.lstSponser?.includes(value?.id)) {
            lstSponserOption.push({
              key: value?.studentNumber?.value?.[lang],
              value: value?.id,
            });
            objSponserOptionLang[value?.id] = dictionary?.shared?.sponserCat?.[key](value?.studentNumber?.value?.[lang], objTrainingFromCat?.price?.value?.[lang]);
          }
        });
      }

      fields.trainingNumber?.setOptions(lstSponserOption, objSponserOptionLang);

      state.blnIsInitDone = true;
      setState({ ...state });
      setIsLoading(false);
      appDispatch();
    },
    addPartnered: async (fields, uploaderLogo) => {
      try {
        if (!fields.isFormValid()) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
          return;
        }

        const trainingNumber = fields.trainingNumber?.getValue()?.key;

        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
          return;
        }
        setIsLoading(true);

        let bigID = ClientFunction.generateID(10);

        const resultUpload = await uploaderLogo?.uploadHandler();
        if (!resultUpload) {
          setIsLoading(false);
          return;
        }

        // const objCat = appFunctions.getCategoryCache();
        // const objTrainingFromCat = objCat?.Root?.trainingType?.[String(trainingType).toLowerCase()];        
        const objInput = {
          bigAppID: App_ID,
          bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID || aaiot_System_ID,
          bigUserID: bigID,

          strUserEmail: fields?.strEmail?.getValue(),
          strUserPhone: globalKeyZero + fields?.strPhone?.getValue(),
          strUserPassword: fields?.strUserPassword?.getValue(),

          strURL: fields?.strURL?.getValue(),
          intUserRoleFK: objUserRole?.Sponsor,

          strFullName: fields?.strFullName?.getValue(),
          bigWorkSector: fields?.bigWorkSector?.getValue()?.key,
          strPlaceName: fields?.strPlaceName?.getValue(),

          intManagerTrainCount: 0,
          intTraineesCount: 0,
          blnIsConfirmed: !appState?.clientInfo?.objSystemInfo?.jsnSystemConfig?.blnIsConfiremSponer? true: false,
          blnVewLogo: false,
          strLogoImg: [],
          bigPartnerStatusID: !appState?.clientInfo?.objSystemInfo?.jsnSystemConfig?.blnIsConfiremSponer? objPartnerStatus.Approved : objPartnerStatus.New,

          blnIsFullstackSponsor: trainingType === objTrainingToSupport?.fullstack?.key,
          blnIsManagerSponsor: trainingType === objTrainingToSupport?.manager?.key,
          jsnSupportTrans: [],
        
          lstStatisticFelid: ["intCountJoinPartner", "intCountJoinSponsorAll"],
        };
        if (trainingType === objTrainingToSupport?.fullstack?.key) {
          objInput.intTraineesCount = trainingNumber;
        } else if (trainingType === objTrainingToSupport?.manager?.key) {
          objInput.intManagerTrainCount = trainingNumber;
        }

        const result = await tblPartner(strPageInfo, appState, "aaiotPartnerAdd", {
          objectInput: objInput,
        });
        if (!result?.blnIsRequestSuccessful) {
          if (dictionary?.shared?.alertFetchNote?.[result?.response]) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.[result?.response]?.[lang], "warning");
          } else {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning");
          }
          setIsLoading(false);
          return;
        }
        setIsLoading(false);
        appState.userInfo = result?.response;
        appState.clientInfo.blnIsUserLoggedIn = true;
        appDispatch();
        navigate(objAppRouting?.Account?.url, { replace: true });
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "addPartnered-ERROR", error);
        setIsLoading(false);
      }
    },
    countRegSponsorClk: async () => {
      try {
        if (appState?.clientInfo?.blnCountClkRegSponsor) {
          return;
        }
        const result = tblStatistic(strPageInfo, appState, "aaiotAppIncDecInt", {
          objectCondition: {
            bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID || aaiot_System_ID,
          },
          arrAttributes: ["intSponsorRegClk"],
        });

        if (!result) {
          return;
        }
        appState.clientInfo.blnCountClkRegSponsor = true;
        appDispatch();
      } catch (error) {
        appFunctions.logMessage(strPageInfo, error, "countRegSponsorClk-ERROR");
      }
    },
  };

  return handler;
};
