import { Button, Container, Grid, Paper, Typography, useNavigate } from "@cbmisorg/client-app";
import React from "react";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import objAppRouting from "../../../../../appHelper/routing/appRouting";
import * as appColor from "../../../../../appHelper/appColor";
import { App_Server_Url_DownloadFiles, objTrainingToSupport } from "../../../../../appHelper/appVariables";
import { generatePath } from "react-router-dom";

//!IMPORTANT CHANGE item?.strLogoImg TO  appVariables.App_Server_Url_DownloadFiles + item?.strLogoImg?.[0]?.path
function Partners({ lang, appState }) {
  const labels = dictionary?.components?.public?.landingPage?.home?.partners;
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Grid container pb-10 px-0 spacing={0} id="partners">
        <Grid item xs="12" container justify="center">
          <Container py-10>
            <Grid container justify="center" spacing={3} pb-sm-8 pb-md-8 pb-lg-8 pb-xl-8>
              <Grid item xs="12" pb-5>
                <Typography as="h4" color="primary">
                  {labels?.title?.[lang]}
                </Typography>
              </Grid>
              <Grid item xs="12" md="11">
                <Typography as="body2">{labels?.p1?.[lang]}</Typography>
              </Grid>
              {!appState?.clientInfo?.objSystemInfo?.lstPartner?.length ? null : (
                <Grid item xs="12" md="11">
                  <Typography as="body2">{labels?.p2?.[lang]}</Typography>
                </Grid>
              )}
            </Grid>
            {!appState?.clientInfo?.objSystemInfo?.lstPartner?.length ? null : (
              <Grid container spacing={3} justify="center">
                <Grid item xs="12" justify="center">
                  <Paper className="partnerPaper" sx={{ width: "100%", overflowX: "auto" }} elevation={0} p-0>
                    <Grid
                      container
                      justify={"center"}
                      spacing={2}
                      sx={{ sm: { flexWrap: "nowrap", justifyContent: appState?.clientInfo?.objSystemInfo?.lstPartner?.length === 1 ? "center" : "flex-start !important" } }}
                    >
                      {[...appState?.clientInfo?.objSystemInfo?.lstPartner]?.map((item, index) => {
                        return (
                          <Grid item key={index}>
                            <Paper
                              elevation={0}
                              outline={0}
                              title={item?.jsnPartnerInfo?.strPlaceName}
                              onClick={() => {
                                if (item?.jsnPartnerInfo?.strURL) {
                                  window.open(item?.jsnPartnerInfo.strURL, { traget: "_blank" });
                                }
                              }}
                              sx={item?.jsnPartnerInfo?.strURL ? { cursor: "pointer" } : {}}
                            >
                              <img
                                src={App_Server_Url_DownloadFiles + item?.strLogoImg?.[0]?.path}
                                alt={item?.jsnPartnerInfo?.title}
                                title={item?.jsnPartnerInfo?.title}
                                height={"100px"}
                              />
                            </Paper>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            )}
            <Grid container justify="center">
              <Grid item xs="12">
                <Button
                  label={labels?.btn?.[lang]}
                  color="primary"
                  sx={{ border: `2px solid ${appColor.App_orang_color} !important` }}
                  onClick={() => navigate(generatePath(objAppRouting?.Public_Join_Partners?.url, { trainingType: objTrainingToSupport?.fullstack?.key }))}
                />
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Partners;
