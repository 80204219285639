import { Alert, ClientFunction } from "@cbmisorg/client-app";
import * as appFunctions from "../../../../../appHelper/appFunctions";
import * as appVariables from "../../../../../appHelper/appVariables";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import { generateQueries } from "../../../../../appHelper/appQuery/GenerateQuery";

const strPageInfo = "@src/components/stackholders/admin/mngCategory/controller/CrtlMngCategory.js";

const tblCategory = generateQueries("tblCategory");

export const controller = (appState, appDispatch, isLoading, setIsLoading, state, setState, navigate) => {
  const lang = appState?.clientInfo?.strLanguage;

  let handler = {
    getAllCategory: async ({ fields }) => {
      try {
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning");
          return;
        }

        state.blnIsInitDone = true;
        setIsLoading(true);

        const result = await tblCategory(strPageInfo, appState, "aaiotAppFindAll", {
          arrAttributes: ["bigID", "strName", "jsnValue", "bigParentID", "jsnParentSeries", "blnIsDeleted", "blnIsActive"],
          objectCondition: {},
        });

        if (!result?.blnIsRequestSuccessful) {
          Alert.viewAlert(result?.response, "warning");
          setIsLoading(false);
          return;
        }

        let lstCategoryTmp = Array.isArray(result?.response) ? result?.response : [];

        let lstOptionParentID = [];
        for (let i = 0; i < lstCategoryTmp?.length; i++) {
          lstOptionParentID.push({ key: lstCategoryTmp[i].bigID, value: lstCategoryTmp[i].strName });
        }

        state.lstCategory = lstCategoryTmp;

        setIsLoading(false);
        fields.bigCatParentID.setOptions(lstOptionParentID);
        setState({ ...state });
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "getAllCategory-ERROR", error);
        setIsLoading(false);
      }
    },

    addCategory:
      ({ fields }) =>
      async () => {
        try {
          if (!fields.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.invalid_Field?.[lang], "error");
            return;
          }

          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning");
            return;
          }

          setIsLoading(true);

          let bigID = ClientFunction.generateID(10);
          const result = await tblCategory(strPageInfo, appState, "aaiotAppAdd", {
            objectInput: {
              bigID: bigID,
              strName: fields.strCatName.getValue(),
              jsnValue: fields.jsnValue.getValue(),
              bigParentID: fields.bigCatParentID.getValue()?.key,
              jsnParentSeries: getParentStructure(Number(fields.bigCatParentID.getValue()?.key), state.lstCategory, 6187058577, true),
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            Alert.viewAlert(result?.response, "warning");
            setIsLoading(false);
            return;
          }

          await handler.getAllCategory({ fields });

          let lstOptionParentID = [];
          for (let i = 0; i < state.lstCategory?.length; i++) {
            lstOptionParentID.push({ key: state.lstCategory[i].bigID, value: state.lstCategory[i].strName });
          }
          fields.bigCatParentID.setOptions(lstOptionParentID);

          state.blnOpenSaveDialog = false;

          fields.resetField();
          setIsLoading(false);
          setState({ ...state });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "addCategory-ERROR", error);
          setIsLoading(false);
        }
      },

    editCategory:
      ({ fields }) =>
      async () => {
        try {
          if (!fields.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.invalid_Field?.[lang], "error");
            return;
          }

          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning");
            return;
          }

          setIsLoading(true);
  
          
          const result = await tblCategory(strPageInfo, appState, "aaiotAppUpdate", {
            objectInput: {
              strName: fields.strCatName.getValue(),
              jsnValue: fields.jsnValue.getValue(),
              bigParentID: fields.bigCatParentID.getValue()?.key,
              dtmUpdatedDate: new Date(),
            },
            objectCondition: {
              bigID: state.bigCurrentID,
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            Alert.viewAlert(result?.response, "warning");
            setIsLoading(false);
            return;
          }

          await handler.getAllCategory({ fields });

          let lstCatNeedUpdateParentSeries = [
            {
              strName: fields.strCatName.getValue(),
              bigID: state.bigCurrentID,
              bigParentID: Number(fields.bigCatParentID.getValue()?.key),
            },
          ];
          for (let i = 0; i < state.lstCategory.length; i++) {
            if (JSON.stringify(state.lstCategory[i]?.jsnParentSeries).includes(state.bigCurrentID)) {
              lstCatNeedUpdateParentSeries.push(state.lstCategory[i]);
            }
          }
          await handler.updateParentSeries(lstCatNeedUpdateParentSeries);

          let lstOptionParentID = [];
          for (let i = 0; i < state.lstCategory.length; i++) {
            lstOptionParentID.push({ key: state.lstCategory[i].bigID, value: state.lstCategory[i].strName });
          }
          state.blnOpenSaveDialog = false;

          fields.resetField();
          fields.bigCatParentID.setOptions(lstOptionParentID);
          setIsLoading(false);
          setState({ ...state });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "editCategory-ERROR", error);
          setIsLoading(false);
        }
      },

    updateParentSeries: async (lstCatNeedUpdateParentSeries = null) => {
      try {
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning");
          return;
        }

        setIsLoading(true);

        if (lstCatNeedUpdateParentSeries) {
          for (let i = 0; i < lstCatNeedUpdateParentSeries.length; i++) {
            const result = await tblCategory(strPageInfo, appState, "aaiotAppUpdate", {
              objectInput: {
                jsnParentSeries: getParentStructure(lstCatNeedUpdateParentSeries[i]?.bigID, state.lstCategory, 6187058577, false),
                dtmUpdatedDate: appFunctions.getSpecificDateFromNow("after", "second", 2),
              },
              objectCondition: {
                bigID: lstCatNeedUpdateParentSeries[i]?.bigID,
              },
            });

            if (!result?.blnIsRequestSuccessful) {
              Alert.viewAlert(result?.response, "warning");
              setIsLoading(false);
              return;
            }
          }
        } else {
          for (let i = 0; i < state.lstCategory.length; i++) {
            const result = await tblCategory(strPageInfo, appState, "aaiotAppUpdate", {
              objectInput: {
                jsnParentSeries: getParentStructure(state.lstCategory[i]?.bigID, state.lstCategory, 6187058577, true),
                dtmUpdatedDate: appFunctions.getSpecificDateFromNow("after", "second", 2),
              },
              objectCondition: {
                bigID: state.lstCategory[i]?.bigID,
              },
            });

            if (!result?.blnIsRequestSuccessful) {
              Alert.viewAlert(result?.response, "warning");
              setIsLoading(false);
              return;
            }
          }
        }

        setIsLoading(false);
        setState({ ...state });
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "updateParentSeries-ERROR", error);
        setIsLoading(false);
      }
    },
  };

  return handler;
};

const getParentStructure = (bigID, lstCategory, Cat_Root_ID, blnLogicAdd = false) => {
  function getParentInfo(bigParentID) {
    let objParentCat = null;

    for (let i = 0; i < lstCategory.length; i++) {
      if (String(lstCategory[i]?.bigID) === String(bigParentID)) {
        objParentCat = lstCategory[i];
        break;
      }
    }

    return objParentCat;
  }

  let lstCatParentSeries = [];
  let objStartCat = lstCategory.filter((cat) => String(cat?.bigID) === String(bigID));
  if (!objStartCat || objStartCat?.length < 1) {
    return [];
  }
  objStartCat = objStartCat?.[0];

  if (blnLogicAdd) {
    lstCatParentSeries.push([objStartCat?.bigID, objStartCat?.strName]);
  }

  //#region set cat parent series
  let bigPrevParentID = objStartCat.bigParentID;
  let i = 0;
  while (i < 50) {
    ++i;

    let objParentCat = getParentInfo(bigPrevParentID);

    if (!objParentCat) {
      break;
    }

    lstCatParentSeries.push([objParentCat?.bigID, objParentCat?.strName]);
    bigPrevParentID = objParentCat?.bigParentID;

    if (String(objParentCat?.bigID) === String(Cat_Root_ID) || String(objParentCat?.bigID) === String(0)) {
      break;
    }
  }
  //#endregion

  ///////////////////////////////////////////////////////////////////////////////////////////

  return lstCatParentSeries;
};

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
