import React, { useContext, useEffect, useState } from "react";
import useCheckSystemInfo from "../../../../appHelper/appHook/useCheckSystemInfo";
import { AppContext } from "../../../../configuration/contextapi/AppContext";
import { CtrlMngSystem } from "./controler/CtrlMngSystem";
import ShredHeader from "../../../sharedUi/header/ShredHeader";
import { dictionary, appLanguage } from "../../../../appHelper/appLanguage/dictionary";
import { FormData, FormField } from "../../../../appHelper/formApi/FormAPI";
import { Button, Container, Grid, Line, Loader, Modal, ModalBody, ModalHeader, Paper, Typography } from "@cbmisorg/client-app";
import { App_Primary_Color } from "../../../../appHelper/appColor";
import { appStyle } from "../../../../appHelper/appStyle";
import useCheckCat from "../../../../appHelper/appHook/useCheckCat";
import { objTrainingToSupport, objUploadType } from "../../../../appHelper/appVariables";
import Uploader from "../../../sharedUi/uploader/Uploader";

const labels = dictionary?.components?.manageSystem;

function ManageSystem() {
  const { getNewSystemInfo } = useCheckSystemInfo();
  const { getNewCatInfo } = useCheckCat();
  const { appState } = useContext(AppContext);

  const lang = appState?.clientInfo?.strLanguage;
  const [isLoading, setIsLoading] = useState(false);
  const [stateInfo, setStateInfo] = useState({
    blnIsInitDone: false,
    systemInfo: null,
    selectedTrainingInfo: null,
    blnOpenModalSponser: false,
    lstVideo: [],
  });
  const uploaderVideo = Uploader(stateInfo, setStateInfo, "lstVideo", "", {
    fileType: objUploadType.video.id,
    blnIsMultiple: false,
    blnForceNewName: true,
    file: labels?.video?.[lang],
  });

  const fields = FormData({
    lstTrainingToSupport: {
      strControl: "checkbox",
      objLanguage: false,
      lstOption: [],
    },
    blnIsConfiremSponer: {
      strControl: "checkbox",
      objLanguage: false,
      lstOption: [
        { key: "true", value: labels?.blnIsConfiremSponer?.[lang] },
      ],
    },
    blnIsAutoPaid: {
      strControl: "radio",
      objLanguage: false,
      objValidations: { required: true },
      lstOption: [
        { key: "true", value: labels?.blnIsAutoPaid?.[lang] },
        { key: "false", value: labels?.blnIsAaiotPaid?.[lang] },
      ],
    },

    strBankInfo: {
      strControl: "textarea",
      objValidations: { required: true },
    },

    blnShowManagerTrainingOption: {
      strControl: "checkbox",
      objLanguage: false,
      lstOption: [{ key: "true", value: dictionary.formapi?.blnShowManagerTrainingOption?.options?.true?.[lang] }],
    },
    blnShowFullstacTrainingOption: {
      strControl: "checkbox",
      objLanguage: false,
      lstOption: [{ key: "true", value: dictionary.formapi?.blnShowFullstacTrainingOption?.options?.true?.[lang] }],
    },
  });

  const fieldTraining = FormData({
    lstSponser: {
      strControl: "checkbox",
      objLanguage: false,
      lstOption: [],
    },
    strVedioTitle: {
      strControl: "text",
      objLanguage: appLanguage
    },
  });

  const fieldsContact = FormData({
    strPhoneNumber: {
      strControl: "text",
      objLanguage: false,
    },
    strEmail: {
      strControl: "text",
      objLanguage: false,
    },
    strSupportEmail: {
      strControl: "text",
      objLanguage: false,
    },
    strSupportPhone: {
      strControl: "text",
      objLanguage: false,
    },
    strXLink: {
      strControl: "text",
      objLanguage: false,
    },
    strFacebookLink: {
      strControl: "text",
      objLanguage: false,
    },
    strInstgramLink: {
      strControl: "text",
      objLanguage: false,
    },
    strLinkedInLink: {
      strControl: "text",
      objLanguage: false,
    },
    strAddress: {
      strControl: "textarea",
    },

    strFormEmail: {
      strControl: "text",
    },
  });

  const handlers = CtrlMngSystem(appState, isLoading, setIsLoading, stateInfo, setStateInfo);
  const handleSubmit = handlers.handleSubmit({ fields, fieldsContact });
  const handleSubmitSponserModal = handlers.handleSubmitSponserModal({ fieldTraining, uploaderVideo });
  const handleCloseSponserModal = handlers.handleCloseSponserModal({ fieldTraining, stateInfo, setStateInfo });

  useEffect(() => {
    if (!stateInfo?.blnIsInitDone) {
      handlers.getSystemInfo({ fields, fieldTraining, fieldsContact, getNewSystemInfo, checkCatHookValidity: getNewCatInfo });
    }
  }, []);

  return (
    <React.Fragment>
      <ShredHeader title={labels?.title?.[lang]} lang={lang} blnBottomBar={false} />
      <Container py-3>
        {isLoading ? <Loader backdrop={true} color={App_Primary_Color} /> : null}
        <Grid container justify={"center"} spacing={3}>
          <Grid item xs="12">
            <Paper className="cardContainer">
              <Grid container spacing={2} py-0>
                <Grid item xs="12">
                  <Typography as="subtitle1" color="primary" sx={appStyle?.title1}>
                    {labels?.trainingConfig?.[lang]}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs="12">
                  <FormField objHandler={fields.lstTrainingToSupport} />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs="12">
                  <FormField objHandler={fields?.blnIsAutoPaid} py-1 value={stateInfo?.systemInfo?.jsnSystemConfig?.blnIsAutoPaid ? "true" : "false"} />
                </Grid>
                <Grid item xs="12">
                  <FormField objHandler={fields?.strBankInfo} py-1 line={10} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container justify={"center"} spacing={3}>
          <Grid item xs="12">
            <Paper className="cardContainer">
              <Grid container spacing={2} py-0>
                <Grid item xs="12">
                  <Typography as="subtitle1" color="primary" sx={appStyle?.title1}>
                    {labels?.SponercConfig?.[lang]}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs="12">
                  <FormField objHandler={fields.blnIsConfiremSponer} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container justify={"center"} spacing={3}>
          <Grid item xs="12">
            <Paper className="cardContainer">
              <Grid container spacing={2} py-0>
                <Grid item xs="12">
                  <Typography as="subtitle1" color="primary" sx={appStyle?.title1}>
                    {labels?.contact?.[lang]}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs="12">
                  <FormField objHandler={fieldsContact?.strEmail} />
                </Grid>
                <Grid item xs="12">
                  <FormField objHandler={fieldsContact?.strPhoneNumber} />
                </Grid>
                <Grid item xs="12">
                  <FormField objHandler={fieldsContact?.strAddress} line={8} dir={lang === "arb" ? "rtl" : "ltr"} />
                </Grid>
              </Grid>
              <Line py-3 />

              <Grid container spacing={2} py-0>
                <Grid item xs="12">
                  <Typography as="subtitle1" color="primary" sx={appStyle?.title1}>
                    {labels?.contactSponser?.[lang]}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs="12">
                  <FormField objHandler={fieldsContact?.strSupportEmail} />
                </Grid>
                <Grid item xs="12">
                  <FormField objHandler={fieldsContact?.strSupportPhone} />
                </Grid>
              </Grid>
              <Line py-3 />

              <Grid container spacing={2} py-0>
                <Grid item xs="12">
                  <Typography as="subtitle1" color="primary" sx={appStyle?.title1}>
                    {labels?.social?.[lang]}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs="12">
                  <FormField objHandler={fieldsContact?.strFacebookLink} />
                </Grid>
                <Grid item xs="12">
                  <FormField objHandler={fieldsContact?.strXLink} />
                </Grid>
                <Grid item xs="12">
                  <FormField objHandler={fieldsContact?.strLinkedInLink} />
                </Grid>
                <Grid item xs="12">
                  <FormField objHandler={fieldsContact?.strInstgramLink} />
                </Grid>
              </Grid>
              {/* <Line py-3 />
              <Grid container spacing={2} py-0>
                <Grid item xs="12">
                  <Typography as="subtitle1" color="primary" sx={appStyle?.title1}>
                    {labels?.contactForm?.[lang]}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs="12">
                  <FormField objHandler={fieldsContact?.strFormEmail} />
                </Grid>
              </Grid> */}
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={2} justify={"center"}>
          <Grid item xs="10" sm="4" md="3">
            <Button color="primary" size="sm" label={dictionary?.shared?.btn?.save?.[lang]} sx={{ minWidth: "100%" }} onClick={handleSubmit} />
          </Grid>
        </Grid>
      </Container>

      <Modal open={stateInfo?.blnOpenModalSponser} eventClose={handleCloseSponserModal}>
        <ModalHeader>{stateInfo?.selectedTrainingInfo?.value?.[lang]}</ModalHeader>
        <ModalBody>
          <Grid container spacing={3}>
            <Grid item xs="12">
              <Typography as="body2" sx={appStyle?.title1}>
                {labels?.catTrainingSponser?.[lang]}
              </Typography>
            </Grid>
            <Grid item xs="12">
              <FormField objHandler={fieldTraining?.lstSponser} />
            </Grid>
          </Grid>
          <Line />
          <Grid container spacing={3}>
            <Grid item xs="12">
              <Typography as="body2" sx={appStyle?.title1}>
                {labels?.trainingPublicVedio?.[lang]}
              </Typography>
            </Grid>
            <Grid item xs="12">
              <FormField objHandler={fieldTraining?.strVedioTitle} />
            </Grid>
            <Grid item xs="12" container justify={"space-between"}>
              <Grid item xs="8">
                <Typography as="caption">
                  {labels?.videoUpload?.[lang]}
                </Typography>
              </Grid>
              <Grid item mx-xs-auto mx-sm-auto px-1>
                {uploaderVideo?.JSX}
              </Grid>
            </Grid>
          </Grid>
          <Line />
          <Grid container spacing={3}>
            <Grid item xs="12">
              <Typography as="body2" sx={appStyle?.title1}>
                {labels?.accountVisibilityOptions?.[lang]}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs="12">
              {stateInfo?.selectedTrainingInfo?.key === objTrainingToSupport?.manager?.key ? (
                <FormField objHandler={fields?.blnShowManagerTrainingOption} />
              ) : (
                <FormField objHandler={fields?.blnShowFullstacTrainingOption} />
              )}
            </Grid>
          </Grid>
          <Grid container justify={"center"}>
            <Grid item xs="12">
              <Button color="primary" px-3 size="sm" label={dictionary?.shared?.btn?.save?.[lang]} onClick={handleSubmitSponserModal} />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default ManageSystem;
