import { createContext } from "react";
import { AppProvider } from "@cbmisorg/client-app";

import * as appVariables from "../../appHelper/appVariables";

export const AppContext = createContext({
  /**
   * @type {typeof appState}
   */ 
  appState: {},
  appDispatch: () => undefined,
});

const appState = {
  clientInfo: {
    dtmLastRequest: "",
    blnIsUserLoggedIn: false,
    blnIsAppStateLive: false,
    strLanguage: "arb",
    strDataLanguage: "arb",
    blnClientOnline: true,
    strAppName: appVariables.App_Name,
    strAppVersion: appVariables?.App_Version,
    objSystemInfo: {},

    appPermissionState: {
      cameraPermission: { user: false, system: false },
      locationPermission: { user: false, system: false },
      microphonePermission: { user: false, system: false },
      notificationsPermission: { user: false, system: false },
    },

    appFontSize: "lg",

    dtmLastLoggingIn: "",
    dtmLastCheckedSystemInfo: "",
  },
  userInfo: {
    bigUserID: 0,
    bigSystemID: appVariables?.aaiot_System_ID,
    strFullName: "",
    strUserEmail: "",
    strUserPhone: "",
    strUsername: "",
    bigUserRoleID: appVariables.Role_Public,
    bigCountryID: 0,
    lstUserFeature: [],
    txtLoginKeys: [],
    txtLoginIP: [],
    strLastLoginUserAgent: "",
    dtmLastLogin: "",
  },
  cacheInfo: {
    objSymposiumInfo: {},
  },
};

export default AppProvider(
  appState,
  AppContext,
  appVariables.App_LocalStorage_Name,
  appVariables.App_Version,
  appVariables.App_LocalStorage_Is_Encrypted,
  appVariables.App_LocalStorage_Encrypted_KEY,
  appVariables.App_IsInTestingMode
);
