import { Alert, Confirmation } from "@cbmisorg/client-app";
import * as appFunctions from "../../../../../appHelper/appFunctions";
import * as appVariables from "../../../../../appHelper/appVariables";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import { generateQueries, objTableAttributes } from "../../../../../appHelper/appQuery/GenerateQuery";
import { aaiot_System_ID, globalKeyZero } from "../../../../../appHelper/appVariables";

const strPageInfo = "@src/component/public/advertisementSystem/view/contact/controler/CtrlPartner.js";

const tblUser = generateQueries("tblUser");
const tblPartner = generateQueries("tblPartner");
// const tblStatistic = generateQueries("tblStatistic");

export const CtrlPartner = (appState, isLoading, setIsLoading, stateInfo, setStateInfo) => {
  const lang = appState?.clientInfo?.strLanguage;
  const bigSystemID = appState?.userInfo?.bigSystemID || aaiot_System_ID;

  let handler = {
    initData: async ({ fields, fldSupportTrans, strTrainingType }) => {
      try {
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
          return;
        }
        stateInfo.blnIsInitDone = true;
        setIsLoading(true);
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //#region get data from DB
        const result = await tblPartner(strPageInfo, appState, "aaiotAppFindAll", {
          arrAttributes: objTableAttributes?.tblPartner?.full,
          objectCondition: { bigSystemID: bigSystemID, blnIsActive: true, blnIsDeleted: false },
        });
        if (!result?.blnIsRequestSuccessful) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cantFindRecords?.[lang], "warning");
          setIsLoading(false);
          return;
        }
        const lstPartner = Array.isArray(result?.response) ? result?.response : [];
        let lstTmp = [];
        let intNewNum = 0;
        let intApprovedNum = 0;
        let intRejectedNum = 0;
        for (let i = 0; i < lstPartner.length; i++) {
          if (lstPartner?.[i]?.blnIsManagerSponsor && strTrainingType === appVariables.objTrainingToSupport.manager?.key) {
            if (String(lstPartner?.[i]?.bigPartnerStatusID) === String(appVariables?.objPartnerStatus.New)) {
              ++intNewNum;
            }
            if (String(lstPartner?.[i]?.bigPartnerStatusID) === String(appVariables?.objPartnerStatus.Approved)) {
              ++intApprovedNum;
            }
            if (String(lstPartner?.[i]?.bigPartnerStatusID) === String(appVariables?.objPartnerStatus.Rejected)) {
              ++intRejectedNum;
            }
            lstTmp.push(lstPartner[i]);
          } else if (lstPartner?.[i]?.blnIsFullstackSponsor && strTrainingType === appVariables.objTrainingToSupport.fullstack.key) {
            if (String(lstPartner?.[i]?.bigPartnerStatusID) === String(appVariables?.objPartnerStatus.New)) {
              ++intNewNum;
            }
            if (String(lstPartner?.[i]?.bigPartnerStatusID) === String(appVariables?.objPartnerStatus.Approved)) {
              ++intApprovedNum;
            }
            if (String(lstPartner?.[i]?.bigPartnerStatusID) === String(appVariables?.objPartnerStatus.Rejected)) {
              ++intRejectedNum;
            }
            lstTmp.push(lstPartner[i]);
          }
        }
        stateInfo.intNewNum = intNewNum;
        stateInfo.intApprovedNum = intApprovedNum;
        stateInfo.intRejectedNum = intRejectedNum;
        lstTmp = lstTmp.reverse();
        stateInfo.listPartner = lstTmp;
        //#endregion
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //#region set field options
        const objCat = appFunctions.getCategoryCache();
        let lstSponsorFullStackTypeOption = [];
        let objSponsorFullStackTypeOptionLang = {};
        let lstSponsorManagerTypeOption = [];
        let objSponsorManagerTypeOptionLang = {};

        const fullstackKey = appVariables.objTrainingToSupport?.fullstack?.key;
        const managerKey = appVariables.objTrainingToSupport?.manager?.key;

        Object.entries(objCat?.Root?.trainingType?.[fullstackKey]?.sponser || {}).forEach(([key, value]) => {
          if (key !== "id" && key !== "value") {
            lstSponsorFullStackTypeOption.push({ key: key, value: key });
            objSponsorFullStackTypeOptionLang[key] = {
              eng: value?.value?.eng + " - " + value?.studentNumber?.value?.eng + " " + dictionary?.components?.public?.join?.trainingCustomNumber?.eng,
              arb: value?.value?.arb + " - " + value?.studentNumber?.value?.arb + " " + dictionary?.components?.public?.join?.trainingCustomNumber?.arb,
            };
          }
        });

        Object.entries(objCat?.Root?.trainingType?.[managerKey]?.sponser || {}).forEach(([key, value]) => {
          if (key !== "id" && key !== "value") {
            lstSponsorManagerTypeOption.push({ key: key, value: key });
            objSponsorManagerTypeOptionLang[key] = {
              eng: value?.value?.eng + " - " + value?.studentNumber?.value?.eng + " " + dictionary?.components?.public?.join?.managerCustomNumber?.eng,
              arb: value?.value?.arb + " - " + value?.studentNumber?.value?.arb + " " + dictionary?.components?.public?.join?.managerCustomNumber?.arb,
            };
          }
        });

        let lstTrainingTypeOptions = Object.entries(appVariables.objTrainingToSupport || {}).map(([key, value]) => ({ key: value?.key, value: value?.key }));
        let objTrainingTypeOptionsLang = {
          [fullstackKey]: objCat?.Root?.trainingType?.[fullstackKey]?.value,
          [managerKey]: objCat?.Root?.trainingType?.[managerKey]?.value,
          [appVariables.objTrainingToSupport?.manager_fullstack?.key]: {
            eng: objCat?.Root?.trainingType?.[fullstackKey]?.value?.eng + " + " + objCat?.Root?.trainingType?.[managerKey]?.value?.eng,

            arb: objCat?.Root?.trainingType?.[fullstackKey]?.value?.arb + " + " + objCat?.Root?.trainingType?.[managerKey]?.value?.arb,
          },
        };

        fields?.strSponsorTrainingType?.setOptions(lstTrainingTypeOptions, objTrainingTypeOptionsLang);
        fields?.strSponsorFullStackCatType?.setOptions(lstSponsorFullStackTypeOption, objSponsorFullStackTypeOptionLang);
        fields?.strSponsorManagerCatType?.setOptions(lstSponsorManagerTypeOption, objSponsorManagerTypeOptionLang);

        let currentOpt = {};
        fldSupportTrans?.strSponsorTrainingType?.setOptions(
          Object.entries(appVariables.objTrainingToSupport)
            .filter(([key, value]) => value.key !== appVariables.objTrainingToSupport.manager_fullstack.key)
            .map(([key, value]) => {
              return { key: value.key, value: value.key };
            }),
          objTrainingTypeOptionsLang
        );
        fldSupportTrans?.strSponsorFullStackCatType?.setOptions(lstSponsorFullStackTypeOption, objSponsorFullStackTypeOptionLang);
        fldSupportTrans?.strSponsorManagerCatType?.setOptions(lstSponsorManagerTypeOption, objSponsorManagerTypeOptionLang);
        fldSupportTrans.strSponsorTrainingType.setValue(strTrainingType);

        //#endregion
        setIsLoading(false);
        setStateInfo({ ...stateInfo });
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "initData-ERROR", error);
        setIsLoading(false);
      }
    },

    sortPartnerResult: (objPartner) => {
      try {
        if (String(stateInfo?.bigCurrentTab) !== String(objPartner?.bigPartnerStatusID)) {
          return false;
        }

        return true;
      } catch (error) {
        appFunctions.logMessage(strPageInfo, error, "ERROR-sortPartnerResult");
        return true;
      }
    },

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //#region update partner information
    openEditPartnerInfoModal:
      ({ fields }) =>
      (item, intIndex) =>
      () => {
        try {
          stateInfo.blnOpenEditPartnerInfoModal = true;
          stateInfo.intEditPartnerInfoIndex = intIndex;

          stateInfo.listLogo = Array.isArray(item?.strLogoImg) ? item?.strLogoImg : [];

          fields.controller.resetForm();

          fields?.strPlaceName?.setValue(item?.jsnPartnerInfo?.strPlaceName);
          fields?.bigWorkSector?.setValue(item?.jsnPartnerInfo?.bigWorkSector);
          fields?.strURL?.setValue(item?.jsnPartnerInfo?.strURL);
          fields?.strFullName?.setValue(item?.jsnPartnerInfo?.strFullName);
          fields?.strEmail?.setValue(item?.jsnPartnerInfo?.strUserEmail);
          fields?.strPhone?.setValue(String(item?.jsnPartnerInfo?.strUserPhone)?.slice(5));
          if (item?.blnVewLogo) {
            fields?.blnViewLogo?.setValue(["true"]);
          }

          const sponsorTrainingTypeKey =
            item?.blnIsManagerSponsor && item?.blnIsFullstackSponsor
              ? appVariables.objTrainingToSupport.manager_fullstack.key
              : item?.blnIsManagerSponsor
              ? appVariables.objTrainingToSupport.manager.key
              : item?.blnIsFullstackSponsor
              ? appVariables.objTrainingToSupport.fullstack.key
              : null;

          const strSponsorTypeFullstack = appFunctions.getSponsorTypeAccordingToTraineeNum(item?.intTraineesCount);
          const strSponsorTypeManager = appFunctions.getSponsorTypeAccordingToTraineeNum(item?.intManagerTrainCount);

          fields.strSponsorTrainingType.setValue(sponsorTrainingTypeKey);
          fields.strSponsorFullStackCatType.setValue(strSponsorTypeFullstack);
          fields.strSponsorManagerCatType.setValue(strSponsorTypeManager);
          setStateInfo({ ...stateInfo });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "openEditPartnerInfoModal-ERROR", error);
        }
      },

    updatePartnerInfoAndUserInfoDB:
      ({ fields, uploaderLogo }) =>
      async () => {
        try {
          if (fields?.strURL?.getValue() && !appFunctions.isValidUrl(fields?.strURL?.getValue())) {
            fields?.strURL?.controller?.setError("strURL", dictionary?.shared?.formValidationsMsg?.url);
            return;
          }
          if (!fields.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
            return;
          }

          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
            return;
          }
          setIsLoading(true);

          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region upload file
          const resultUploadFile = await uploaderLogo?.uploadHandler();
          if (!resultUploadFile) {
            setIsLoading(false);
            return;
          }
          //#endregion
          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region set new values to cache (state)

          stateInfo.listPartner[stateInfo?.intEditPartnerInfoIndex].jsnPartnerInfo.strFullName = fields?.strFullName?.getValue();
          stateInfo.listPartner[stateInfo?.intEditPartnerInfoIndex].jsnPartnerInfo.strUserEmail = fields?.strEmail?.getValue();
          stateInfo.listPartner[stateInfo?.intEditPartnerInfoIndex].jsnPartnerInfo.strUserPhone = globalKeyZero + fields?.strPhone?.getValue();

          stateInfo.listPartner[stateInfo?.intEditPartnerInfoIndex].jsnPartnerInfo.bigWorkSector = fields?.bigWorkSector?.getValue()?.key;
          stateInfo.listPartner[stateInfo?.intEditPartnerInfoIndex].jsnPartnerInfo.strPlaceName = fields?.strPlaceName?.getValue();
          stateInfo.listPartner[stateInfo?.intEditPartnerInfoIndex].jsnPartnerInfo.strURL = fields?.strURL?.getValue();

          stateInfo.listPartner[stateInfo?.intEditPartnerInfoIndex].blnVewLogo = !fields?.blnViewLogo?.getValue()?.length ? false : true;
          stateInfo.listPartner[stateInfo?.intEditPartnerInfoIndex].strLogoImg = resultUploadFile ? stateInfo?.listLogo : stateInfo.listPartner[stateInfo?.intEditPartnerInfoIndex]?.strLogoImg;

          //update sponsor category and training to support
          const strSponsorTrainingType = fields.strSponsorTrainingType.getValue()?.key;

          let blnIsFullstackSponsor = false;
          let blnIsManagerSponsor = false;
          if (strSponsorTrainingType === appVariables.objTrainingToSupport.fullstack.key) {
            blnIsFullstackSponsor = true;
          } else if (strSponsorTrainingType === appVariables.objTrainingToSupport.manager.key) {
            blnIsManagerSponsor = true;
          } else if (strSponsorTrainingType === appVariables.objTrainingToSupport.manager_fullstack.key) {
            blnIsFullstackSponsor = true;
            blnIsManagerSponsor = true;
          }

          const objCat = appFunctions.getCategoryCache();

          let intFullstackCountStudentNum = 0;
          let intManagerCountStudentNum = 0;

          const strSponsorFullStackCatType = fields.strSponsorFullStackCatType?.getValue()?.key;
          const strSponsorManagerCatType = fields.strSponsorManagerCatType?.getValue()?.key;

          if (blnIsFullstackSponsor) {
            intFullstackCountStudentNum = Number(objCat?.Root?.trainingType?.[appVariables.objTrainingToSupport.fullstack.key]?.sponser?.[strSponsorFullStackCatType]?.studentNumber?.value?.eng) || 0;
          }
          if (blnIsManagerSponsor) {
            intManagerCountStudentNum = Number(objCat?.Root?.trainingType?.[appVariables.objTrainingToSupport.manager.key]?.sponser?.[strSponsorManagerCatType]?.studentNumber?.value?.eng) || 0;
          }
          stateInfo.listPartner[stateInfo?.intEditPartnerInfoIndex].blnIsFullstackSponsor = blnIsFullstackSponsor;
          stateInfo.listPartner[stateInfo?.intEditPartnerInfoIndex].blnIsManagerSponsor = blnIsManagerSponsor;
          stateInfo.listPartner[stateInfo?.intEditPartnerInfoIndex].intTraineesCount = intFullstackCountStudentNum;
          stateInfo.listPartner[stateInfo?.intEditPartnerInfoIndex].intManagerTrainCount = intManagerCountStudentNum;
          //#endregion
          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region get user info
          const resultUserInfo = await tblUser(strPageInfo, appState, "aaiotAppFindOne", {
            arrAttributes: ["bigUserID", "bigAppID", "bigSystemID", "jsnUserInfo", "strUserEmail", "strUserPhone"],
            objectCondition: {
              bigSystemID: bigSystemID,
              bigUserID: stateInfo?.listPartner?.[stateInfo?.intEditPartnerInfoIndex]?.bigPartnerID,
            },
          });
          if (!resultUserInfo?.blnIsRequestSuccessful || Number.isNaN(Number(resultUserInfo?.response?.bigUserID))) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cantFindRecords?.[lang], "warning");
            setIsLoading(false);
            return;
          }
          let objUserInfo = resultUserInfo?.response;
          const objPartnerInfo = stateInfo.listPartner[stateInfo?.intEditPartnerInfoIndex];
          //#endregion
          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region update user info
          if (
            objUserInfo?.strUserEmail !== String(objPartnerInfo.jsnPartnerInfo.strUserEmail).toLowerCase().trim() ||
            objUserInfo?.strUserPhone !== String(objPartnerInfo.jsnPartnerInfo.strUserPhone).trim() ||
            objUserInfo?.jsnUserInfo?.strFullName !== objPartnerInfo.jsnPartnerInfo.strFullName
          ) {
            const resultUpdateUserInfo = await tblUser(strPageInfo, appState, "aaiotAppUserUpdate", {
              objectInput: {
                strUserEmail: objUserInfo?.strUserEmail === String(objPartnerInfo.jsnPartnerInfo.strUserEmail).toLowerCase().trim() ? undefined : objPartnerInfo.jsnPartnerInfo.strUserEmail,
                strUserPhone: objUserInfo?.strUserPhone === String(objPartnerInfo.jsnPartnerInfo.strUserPhone).trim() ? undefined : globalKeyZero + objPartnerInfo.jsnPartnerInfo.strUserPhone,
                jsnUserInfo:
                  objUserInfo?.jsnUserInfo?.strFullName === objPartnerInfo.jsnPartnerInfo.strFullName
                    ? undefined
                    : {
                        ...objUserInfo.jsnUserInfo,
                        strFullName: objPartnerInfo.jsnPartnerInfo.strFullName,
                      },
              },
              objectCondition: {
                bigAppID: objUserInfo?.bigAppID,
                bigSystemID: objUserInfo?.bigSystemID,
                bigUserID: objUserInfo?.bigUserID,
              },
            });
            if (!resultUpdateUserInfo?.blnIsRequestSuccessful) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalidLoginInfo?.[lang], "warning");
              setIsLoading(false);
              return;
            }
          }
          //#endregion
          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region update partner info

          const result = await tblPartner(strPageInfo, appState, "aaiotAppUpdate", {
            objectInput: {
              jsnPartnerInfo: objPartnerInfo?.jsnPartnerInfo,

              blnIsFullstackSponsor: objPartnerInfo?.blnIsFullstackSponsor,
              blnIsManagerSponsor: objPartnerInfo?.blnIsManagerSponsor,

              intTraineesCount: objPartnerInfo?.intTraineesCount,
              intManagerTrainCount: objPartnerInfo?.intManagerTrainCount,
              dtmUpdatedDate: new Date().toISOString(),

              blnVewLogo: objPartnerInfo?.blnVewLogo,
              strLogoImg: objPartnerInfo?.strLogoImg,
            },
            objectCondition: {
              bigAppID: objPartnerInfo?.bigAppID,
              bigSystemID: objPartnerInfo?.bigSystemID,
              bigPartnerID: objPartnerInfo?.bigPartnerID,
              blnIsActive: true,
              blnIsDeleted: false,
            },
          });

          if (!result?.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning");
            setIsLoading(false);
            return;
          }
          //#endregion
          setIsLoading(false);
          stateInfo.blnOpenEditPartnerInfoModal = false;
          stateInfo.blnOpenChangeStatusModal = false;

          setStateInfo({ ...stateInfo });
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "updatePartnerInfoAndUserInfoDB-ERROR", error);
          setIsLoading(false);
        }
      },
    //#endregion

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //#region update partner status

    openChangeStatusModal:
      ({ fields }) =>
      (item, intIndex) =>
      async () => {
        try {
          if (!item?.blnIsSeen) {
            const result = await tblPartner(strPageInfo, appState, "aaiotAppUpdate", {
              objectInput: {
                blnIsSeen: true,
                dtmUpdatedDate: new Date().toISOString(),
              },
              objectCondition: {
                bigAppID: item?.bigAppID,
                bigSystemID: item?.bigSystemID,
                bigPartnerID: item?.bigPartnerID,
              },
            });

            if (!result?.blnIsRequestSuccessful) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning");
              setIsLoading(false);
              return;
            }
            stateInfo.listPartner[intIndex].blnIsSeen = true;
            item.blnIsSeen = true;
          }

          stateInfo.blnOpenChangeStatusModal = true;
          stateInfo.intEditPartnerInfoIndex = intIndex;
          stateInfo.objSelectedPartner = item;

          stateInfo.listLogo = Array.isArray(item?.strLogoImg) ? item?.strLogoImg : [];

          fields.controller.resetForm();

          fields?.strPlaceName?.setValue(item?.jsnPartnerInfo?.strPlaceName);
          fields?.bigWorkSector?.setValue(item?.jsnPartnerInfo?.bigWorkSector);
          fields?.strURL?.setValue(item?.jsnPartnerInfo?.strURL);
          fields?.strFullName?.setValue(item?.jsnPartnerInfo?.strFullName);
          fields?.strEmail?.setValue(item?.jsnPartnerInfo?.strUserEmail);
          fields?.strPhone?.setValue(String(item?.jsnPartnerInfo?.strUserPhone)?.slice(5));
          if (item?.blnVewLogo) {
            fields?.blnViewLogo?.setValue(["true"]);
          }

          const sponsorTrainingTypeKey =
            item?.blnIsManagerSponsor && item?.blnIsFullstackSponsor
              ? appVariables.objTrainingToSupport.manager_fullstack.key
              : item?.blnIsManagerSponsor
              ? appVariables.objTrainingToSupport.manager.key
              : item?.blnIsFullstackSponsor
              ? appVariables.objTrainingToSupport.fullstack.key
              : null;

          const strSponsorTypeFullstack = appFunctions.getSponsorTypeAccordingToTraineeNum(item?.intTraineesCount);
          const strSponsorTypeManager = appFunctions.getSponsorTypeAccordingToTraineeNum(item?.intManagerTrainCount);

          fields.strSponsorTrainingType.setValue(sponsorTrainingTypeKey);
          fields.strSponsorFullStackCatType.setValue(strSponsorTypeFullstack);
          fields.strSponsorManagerCatType.setValue(strSponsorTypeManager);

          setStateInfo({ ...stateInfo });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "openEditPartnerInfoModal-ERROR", error);
        }
      },

    updatePartnerStatusDB:
      ({ fields, uploaderLogo }) =>
      (bigPartnerStatusID) =>
      async () => {
        try {
          if (!bigPartnerStatusID) {
            return;
          }
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
            return;
          }
          setIsLoading(true);

          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region upload file
          const resultUploadFile = await uploaderLogo?.uploadHandler();
          if (!resultUploadFile) {
            setIsLoading(false);
            return;
          }
          //#endregion
          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region update statistics
          let intNewNum = stateInfo.intNewNum;
          let intApprovedNum = stateInfo.intApprovedNum;
          let intRejectedNum = stateInfo.intRejectedNum;
          if (String(stateInfo?.listPartner?.[stateInfo?.intEditPartnerInfoIndex]?.bigPartnerStatusID) === String(appVariables?.objPartnerStatus.New)) {
            --intNewNum;
          }
          if (String(stateInfo?.listPartner?.[stateInfo?.intEditPartnerInfoIndex]?.bigPartnerStatusID) === String(appVariables?.objPartnerStatus.Approved)) {
            --intApprovedNum;
          }
          if (String(stateInfo?.listPartner?.[stateInfo?.intEditPartnerInfoIndex]?.bigPartnerStatusID) === String(appVariables?.objPartnerStatus.Rejected)) {
            --intRejectedNum;
          }
          if (String(bigPartnerStatusID) === String(appVariables?.objPartnerStatus.New)) {
            ++intNewNum;
          }
          if (String(bigPartnerStatusID) === String(appVariables?.objPartnerStatus.Approved)) {
            ++intApprovedNum;
          }
          if (String(bigPartnerStatusID) === String(appVariables?.objPartnerStatus.Rejected)) {
            ++intRejectedNum;
          }
          //#endregion
          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region set new values to cache (state)

          stateInfo.listPartner[stateInfo?.intEditPartnerInfoIndex].bigPartnerStatusID = bigPartnerStatusID;
          stateInfo.listPartner[stateInfo?.intEditPartnerInfoIndex].blnIsConfirmed = String(bigPartnerStatusID) === String(appVariables.objPartnerStatus.Approved);
          stateInfo.listPartner[stateInfo?.intEditPartnerInfoIndex].blnVewLogo = !fields?.blnViewLogo?.getValue()?.length ? false : true;
          stateInfo.listPartner[stateInfo?.intEditPartnerInfoIndex].strLogoImg = resultUploadFile ? stateInfo?.listLogo : stateInfo.listPartner[stateInfo?.intEditPartnerInfoIndex]?.strLogoImg;
          const objPartnerInfo = stateInfo.listPartner[stateInfo?.intEditPartnerInfoIndex];
          //#endregion

          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region update partner info

          const result = await tblPartner(strPageInfo, appState, "aaiotAppUpdate", {
            objectInput: {
              bigPartnerStatusID: objPartnerInfo?.bigPartnerStatusID,
              blnIsConfirmed: objPartnerInfo?.blnIsConfirmed,
              blnVewLogo: objPartnerInfo?.blnVewLogo,
              strLogoImg: objPartnerInfo?.strLogoImg,
              dtmUpdatedDate: new Date().toISOString(),
            },
            objectCondition: {
              bigAppID: objPartnerInfo?.bigAppID,
              bigSystemID: objPartnerInfo?.bigSystemID,
              bigPartnerID: objPartnerInfo?.bigPartnerID,
            },
          });

          if (!result?.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning");
            setIsLoading(false);
            return;
          }
          //#endregion
          stateInfo.intNewNum = intNewNum;
          stateInfo.intApprovedNum = intApprovedNum;
          stateInfo.intRejectedNum = intRejectedNum;

          stateInfo.blnOpenChangeStatusModal = false;

          setIsLoading(false);
          setStateInfo({ ...stateInfo });
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "updatePartnerStatusDB-ERROR", error);
          setIsLoading(false);
        }
      },

    confirmUpdatePartnerStatusDB:
      ({ fields, uploaderLogo }) =>
      (bigPartnerStatusID) =>
      () => {
        Confirmation.viewConfirmation(
          bigPartnerStatusID === appVariables.objPartnerStatus.Rejected ? dictionary?.components?.managePartner?.confirmReject?.[lang] : dictionary?.components?.managePartner?.confirmApprove?.[lang],
          handler.updatePartnerStatusDB({ fields, uploaderLogo })(bigPartnerStatusID),
          () => null,
          dictionary?.shared?.confirmationMsg?.yes?.[lang],
          dictionary?.shared?.confirmationMsg?.no?.[lang]
        );
      },
    //#endregion

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //#region partner financial transaction handling
    openSupportTransLogDialog:
      ({ stateInfo, setStateInfo }) =>
      (intIndex) =>
      () => {
        try {
          stateInfo.blnOpenTransLogModal = true;
          stateInfo.intPartnerToLogIndex = intIndex;

          setStateInfo({ ...stateInfo });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "ERROR-openSupportTransLogDialog");
        }
      },

    openSupportTransEditDialog:
      ({ stateInfo, setStateInfo, fldSupportTrans }) =>
      (intIndex, blnIsEdit) =>
      () => {
        try {
          stateInfo.blnOpenEditTransModal = true;
          stateInfo.blnEditTransOperationUpdate = blnIsEdit;
          stateInfo.intEditTransIndex = intIndex;

          stateInfo.lstPaymentVoucher = [];
          stateInfo.lstReceiptVoucher = [];
          fldSupportTrans.controller.resetForm();

          const objPartnerInfo = stateInfo?.listPartner?.[stateInfo?.intPartnerToLogIndex];

          const objTrans = objPartnerInfo?.jsnSupportTrans?.[intIndex];

          if (blnIsEdit) {
            const strSponsorTypeFullstack = appFunctions.getSponsorTypeAccordingToTraineeNum(objTrans?.intTraineesCount);
            const strSponsorTypeManager = appFunctions.getSponsorTypeAccordingToTraineeNum(objTrans?.intTraineesCount);

            fldSupportTrans.strSponsorTrainingType.setValue(objTrans?.strTrainingType);
            fldSupportTrans.strSponsorFullStackCatType.setValue(strSponsorTypeFullstack);
            fldSupportTrans.strSponsorManagerCatType.setValue(strSponsorTypeManager);
            fldSupportTrans.trainingNumberAdmin.setValue(objTrans?.intTraineesCount);
            fldSupportTrans.intTrainingProgPrice.setValue(objTrans?.intTrainingProgPrice * objTrans?.intTraineesCount);

            fldSupportTrans.intSupportTransStatus.setValue(objTrans?.intSupportTransStatus || appVariables?.objSupportTransStatus.Under_Process);

            stateInfo.lstPaymentVoucher = Array.isArray(objTrans?.lstReceipt) ? objTrans?.lstReceipt : [];
            stateInfo.lstReceiptVoucher = Array.isArray(objTrans?.lstReceiptCatch) ? objTrans?.lstReceiptCatch : [];
          } else {
            fldSupportTrans.intSupportTransStatus.setValue(appVariables?.objSupportTransStatus.Send_New);
          }

          setStateInfo({ ...stateInfo });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "ERROR-openSupportTransEditDialog");
        }
      },

    updatePartnerSupportTransDB:
      ({ stateInfo, setStateInfo, isLoading, setIsLoading, fldSupportTrans, uploaderPayment, uploaderReceipt }) =>
      async () => {
        try {
          if (!fldSupportTrans.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
            return;
          }

          if (isLoading) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.fetchData_InProgress?.[lang], "warning");
            return;
          }
          setIsLoading(true);

          //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region upload file
          if (stateInfo.lstPaymentVoucher?.length) {
            const resultUploadPayment = await uploaderPayment?.uploadHandler("supportTransPayment");
            if (!resultUploadPayment) {
              setIsLoading(false);
              return;
            }
          }

          if (stateInfo.lstReceiptVoucher?.length) {
            const resultUploadReceipt = await uploaderReceipt?.uploadHandler("supportTransReceipt");
            if (!resultUploadReceipt) {
              setIsLoading(false);
              return;
            }
          }
          //#endregion
          //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region set data
          const objPartnerInfo = stateInfo.listPartner?.[stateInfo?.intPartnerToLogIndex];
          let jsnSupportTrans = Array.isArray(objPartnerInfo?.jsnSupportTrans) ? objPartnerInfo?.jsnSupportTrans : [];

          const strSponsorTrainingType = fldSupportTrans.strSponsorTrainingType.getValue()?.key;

          const strSponsorFullStackCatType = fldSupportTrans.strSponsorFullStackCatType?.getValue()?.key;
          const strSponsorManagerCatType = fldSupportTrans.strSponsorManagerCatType?.getValue()?.key;
          const strSponsorMainCatType = strSponsorTrainingType === appVariables.objTrainingToSupport?.manager?.key? strSponsorManagerCatType :strSponsorFullStackCatType ;
          const objCat = appFunctions.getCategoryCache();

          let intCountStudentNum = 0;
          let intPriceSystem = 0;

          if (strSponsorTrainingType === appVariables.objTrainingToSupport?.manager?.key) {
            intCountStudentNum =
              Number(fldSupportTrans.trainingNumberAdmin?.getValue()) ||
              Number(objCat?.Root?.trainingType?.[strSponsorTrainingType]?.sponser?.[strSponsorManagerCatType]?.studentNumber?.value?.eng) ||
              0;
            intPriceSystem = Number(fldSupportTrans.intTrainingProgPrice?.getValue() / intCountStudentNum) || Number(objCat?.Root?.trainingType?.[strSponsorTrainingType]?.price?.value?.eng) || 0;
          } else {
            intCountStudentNum =
              Number(fldSupportTrans.trainingNumberAdmin?.getValue()) ||
              Number(objCat?.Root?.trainingType?.[strSponsorTrainingType]?.sponser?.[strSponsorFullStackCatType]?.studentNumber?.value?.eng) ||
              0;
            intPriceSystem = Number(fldSupportTrans.intTrainingProgPrice?.getValue() / intCountStudentNum) || Number(objCat?.Root?.trainingType?.[strSponsorTrainingType]?.price?.value?.eng) || 0;
          }

          if (stateInfo.blnEditTransOperationUpdate) {
            jsnSupportTrans[stateInfo?.intEditTransIndex].dtmUpdatedDate = new Date().toUTCString();
            jsnSupportTrans[stateInfo?.intEditTransIndex].strTrainingType = strSponsorTrainingType;
            jsnSupportTrans[stateInfo?.intEditTransIndex].intTraineesCount = intCountStudentNum;
            jsnSupportTrans[stateInfo?.intEditTransIndex].strSupportCatType = strSponsorMainCatType;
            jsnSupportTrans[stateInfo?.intEditTransIndex].intSupportTransStatus = fldSupportTrans.intSupportTransStatus.getValue()?.key;
            jsnSupportTrans[stateInfo?.intEditTransIndex].intTrainingProgPrice = intPriceSystem;
            jsnSupportTrans[stateInfo?.intEditTransIndex].lstReceipt = stateInfo?.lstPaymentVoucher;
            jsnSupportTrans[stateInfo?.intEditTransIndex].lstReceiptCatch = stateInfo?.lstReceiptVoucher;
          } else {
            jsnSupportTrans.push({
              id: appFunctions.generateID(6),
              dtmCreatedDate: new Date().toUTCString(),
              dtmUpdatedDate: new Date().toUTCString(),
              strTrainingType: strSponsorTrainingType,
              intTraineesCount: intCountStudentNum,
              intTrainingProgPrice: intPriceSystem,
              intSupportTransStatus: fldSupportTrans.intSupportTransStatus.getValue()?.key,
              lstReceipt: stateInfo?.lstPaymentVoucher,
              lstReceiptCatch: stateInfo?.lstReceiptVoucher,
            });
          }

          let intTraineesCount_Old = objPartnerInfo?.intTraineesCount;
          let intManagerTrainCount_Old = objPartnerInfo?.intManagerTrainCount;

          let intTraineesCount = objPartnerInfo?.intTraineesCount;
          let intManagerTrainCount = objPartnerInfo?.intManagerTrainCount;

          for (let i = 0; i < jsnSupportTrans.length; i++) {
            if (appFunctions.getDifferenceBetweenTwoDate(jsnSupportTrans?.[i]?.dtmCreatedDate, new Date(), "month") <= 4) {
              if (String(jsnSupportTrans?.[i]?.intSupportTransStatus) === String(appVariables.objSupportTransStatus?.Approved)) {
                if (String(jsnSupportTrans?.[i]?.strTrainingType) === String(appVariables.objTrainingToSupport.manager?.key)) {
                  if (jsnSupportTrans?.[i]?.intTraineesCount > intManagerTrainCount) {
                    intManagerTrainCount = jsnSupportTrans?.[i]?.intTraineesCount;
                  }
                } else {
                  if (jsnSupportTrans?.[i]?.intTraineesCount > intTraineesCount) {
                    intTraineesCount = jsnSupportTrans?.[i]?.intTraineesCount;
                  }
                }
              }
            }
          }

          if (intTraineesCount === intTraineesCount_Old && intManagerTrainCount === intManagerTrainCount_Old) {
            for (let i = 0; i < jsnSupportTrans.length; i++) {
              if (String(jsnSupportTrans?.[i]?.intSupportTransStatus) === String(appVariables.objSupportTransStatus?.Approved)) {
                if (String(jsnSupportTrans?.[i]?.strTrainingType) === String(appVariables.objTrainingToSupport.manager?.key)) {
                  if (jsnSupportTrans?.[i]?.intTraineesCount > intManagerTrainCount) {
                    intManagerTrainCount = jsnSupportTrans?.[i]?.intTraineesCount;
                  }
                } else {
                  if (jsnSupportTrans?.[i]?.intTraineesCount > intTraineesCount) {
                    intTraineesCount = jsnSupportTrans?.[i]?.intTraineesCount;
                  }
                }
              }
            }
          }

          //#endregion
          //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region update DB
          const result = await tblPartner(strPageInfo, appState, "aaiotAppUpdate", {
            objectCondition: {
              bigPartnerID: stateInfo.listPartner?.[stateInfo?.intPartnerToLogIndex]?.bigPartnerID,
            },
            objectInput: {
              jsnSupportTrans: jsnSupportTrans,
              intTraineesCount: intTraineesCount_Old !== intTraineesCount ? intTraineesCount : undefined,
              intManagerTrainCount: intManagerTrainCount_Old !== intManagerTrainCount ? intManagerTrainCount : undefined,
              dtmUpdatedDate: new Date().toUTCString(),
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.cant_Update_Record?.[lang], "warning");
            setIsLoading(false);
            return;
          }
          // if (!stateInfo?.blnEditTransOperationUpdate && fldSupportTrans.intSupportTransStatus.getValue()?.key === appVariables?.objSupportTransStatus?.Under_Process) {
            // await tblStatistic(strPageInfo, appState, "aaiotAppIncDecInt", {
            //   objectCondition: { bigSystemID: bigSystemID },
            //   arrAttributes: strSponsorTrainingType === appVariables.objTrainingToSupport.manager.key ? ["intCountTransPartnerManager"] : ["intCountTransPartnerFullstack"],
            // });
          // }
          //#endregion

          stateInfo.listPartner[stateInfo?.intPartnerToLogIndex].jsnSupportTrans = jsnSupportTrans;
          stateInfo.listPartner[stateInfo?.intPartnerToLogIndex].intManagerTrainCount = intManagerTrainCount;
          stateInfo.listPartner[stateInfo?.intPartnerToLogIndex].intTraineesCount = intTraineesCount;

          stateInfo.blnOpenEditTransModal = false;

          setIsLoading(false);
          setStateInfo({ ...stateInfo });
          if (stateInfo.blnEditTransOperationUpdate) {
            Alert.viewAlert(dictionary.components.mngSupportTrans.transactionUpdatedInfo?.[lang], "success");
          } else {
            Alert.viewAlert(dictionary.components.mngSupportTrans.transactionAdded?.[lang], "success");
          }
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "ERROR-updatePartnerSupportTransDB");
          setIsLoading(false);
        }
      },

    getPartnerSponsorPlanDetail:
      ({ objCat }) =>
      (objPartnerInfo) => {
        let objDetail = {
          strFullstackTrainingLabel: "",
          strFullstackSponsorCatLabel: "",
          strFullstackSponsorType: "",
          intFullstackNumTrainee: 0,
          intFullstackNumTraineeSystem: 0,
          intFullstackPrice: 0,
          colorFullstack: "#111",

          strManagerTrainingLabel: "",
          strManagerSponsorCatLabel: "",
          strManagerSponsorType: "",
          intManagerNumTrainee: 0,
          intManagerNumTraineeSystem: 0,
          intManagerPrice: 0,
          colorManager: "#111",
        };

        const fullstackKey = appVariables.objTrainingToSupport?.fullstack?.key;
        const managerKey = appVariables.objTrainingToSupport?.manager?.key;

        if (objPartnerInfo?.blnIsFullstackSponsor) {
          const strSponsorType = appFunctions.getSponsorTypeAccordingToTraineeNum(objPartnerInfo?.intTraineesCount);

          if (!strSponsorType) {
            return objDetail;
          }
          objDetail.intFullstackNumTrainee = objPartnerInfo?.intTraineesCount;
          objDetail.intFullstackNumTraineeSystem = Number(objCat?.Root?.trainingType?.[fullstackKey]?.sponser?.[strSponsorType]?.studentNumber?.value?.eng) || 0;
          objDetail.strFullstackSponsorCatLabel = objCat?.Root?.trainingType?.[fullstackKey]?.sponser?.[strSponsorType]?.value?.[lang];
          objDetail.strFullstackTrainingLabel = objCat?.Root?.trainingType?.[fullstackKey]?.value?.[lang];
          objDetail.strFullstackSponsorType = strSponsorType;
          objDetail.intFullstackPrice = Number(objCat?.Root?.trainingType?.[fullstackKey]?.price?.value?.eng) || 0;
          if (strSponsorType === appVariables.objSponserCat.main.key) {
            objDetail.colorFullstack = "#13bf3b";
          } else if (strSponsorType === appVariables.objSponserCat.golden.key) {
            objDetail.colorFullstack = "#f7d000";
          } else if (strSponsorType === appVariables.objSponserCat.silver.key) {
            objDetail.colorFullstack = "#bebebe";
          } else if (strSponsorType === appVariables.objSponserCat.bronze.key) {
            objDetail.colorFullstack = "#d3883e";
          }

          return objDetail;
        }

        if (objPartnerInfo?.blnIsManagerSponsor) {
          const strSponsorType = appFunctions.getSponsorTypeAccordingToTraineeNum(objPartnerInfo?.intManagerTrainCount, appVariables.objTrainingToSupport.manager.key);
          if (!strSponsorType) {
            return objDetail;
          }
          objDetail.intManagerNumTrainee = objPartnerInfo?.intManagerTrainCount;
          objDetail.intFullstackNumTraineeSystem = Number(objCat?.Root?.trainingType?.[managerKey]?.sponser?.[strSponsorType]?.studentNumber?.value?.eng) || 0;
          objDetail.strManagerSponsorCatLabel = objCat?.Root?.trainingType?.[managerKey]?.sponser?.[strSponsorType]?.value?.[lang];
          objDetail.strManagerTrainingLabel = objCat?.Root?.trainingType?.[managerKey]?.value?.[lang];
          objDetail.strManagerSponsorType = strSponsorType;
          objDetail.intManagerPrice = Number(objCat?.Root?.trainingType?.[managerKey]?.price?.value?.eng) || 0;
          if (strSponsorType === appVariables.objSponserCat.main.key) {
            objDetail.colorManager = "#13bf3b";
          } else if (strSponsorType === appVariables.objSponserCat.golden.key) {
            objDetail.colorManager = "#f7d000";
          } else if (strSponsorType === appVariables.objSponserCat.silver.key) {
            objDetail.colorManager = "#bebebe";
          } else if (strSponsorType === appVariables.objSponserCat.bronze.key) {
            objDetail.colorManager = "#d3883e";
          }

          return objDetail;
        }
        return objDetail;
      },

    getPartnerTransPlanTotalPrice:
      ({ objCat, stateInfo, fldSupportTrans }) =>
      () => {
        try {
          const objPartnerInfo = stateInfo.listPartner?.[stateInfo?.intPartnerToLogIndex];
          const strSponsorTrainingType = fldSupportTrans.strSponsorTrainingType?.getValue()?.key;

          const strSponsorFullStackCatType = fldSupportTrans.strSponsorFullStackCatType?.getValue()?.key;
          const strSponsorManagerCatType = fldSupportTrans.strSponsorManagerCatType?.getValue()?.key;

          const intCountStudentNumSystem =
            Number(
              objCat?.Root?.trainingType?.[strSponsorTrainingType]?.sponser?.[
                strSponsorTrainingType === appVariables.objTrainingToSupport.manager?.key ? strSponsorManagerCatType : strSponsorFullStackCatType
              ]?.studentNumber?.value?.eng
            ) || 0;
          const priceSystem = Number(objCat?.Root?.trainingType?.[strSponsorTrainingType]?.price?.value?.eng);

          return (priceSystem || 0) * (intCountStudentNumSystem || 0);
        } catch {
          return 0;
        }
      },

    getAddedBeforeLabel: (dtmDate) => {
      try {
        const dtmCreatedDate = dtmDate;
        let intAddedBefore = Math.round(appFunctions.getDifferenceBetweenTwoDate(dtmCreatedDate, new Date().toUTCString(), "day"));

        let strLabel = "";
        if (intAddedBefore > 0) {
          strLabel = dictionary.components.mngPotentialSponsorship.days?.[lang];
          if (intAddedBefore === 2) {
            strLabel = dictionary.components.mngPotentialSponsorship.twoDay?.[lang];
          } else if (intAddedBefore < 2) {
            strLabel = dictionary.components.mngPotentialSponsorship.day?.[lang];
          }
        }

        if (strLabel && intAddedBefore) {
          return `( ${dictionary.components.mngPotentialSponsorship?.before?.[lang]} ${(intAddedBefore < 3 ? "" : intAddedBefore) + " " + strLabel} )`;
        }

        intAddedBefore = Math.round(appFunctions.getDifferenceBetweenTwoDate(dtmCreatedDate, new Date().toUTCString(), "hour"));
        if (intAddedBefore > 0) {
          strLabel = dictionary.components.mngPotentialSponsorship.hour?.[lang];
        }

        if (strLabel && intAddedBefore) {
          return `( ${dictionary.components.mngPotentialSponsorship?.before?.[lang]} ${(intAddedBefore < 3 ? "" : intAddedBefore) + " " + strLabel} )`;
        }

        intAddedBefore = Math.floor(appFunctions.getDifferenceBetweenTwoDate(dtmCreatedDate, new Date().toUTCString(), "minute"));
        if (intAddedBefore > 0) {
          strLabel = dictionary.components.mngPotentialSponsorship.minute?.[lang];
        }
        if (strLabel && intAddedBefore) {
          return `( ${dictionary.components.mngPotentialSponsorship?.before?.[lang]} ${(intAddedBefore < 3 ? "" : intAddedBefore) + " " + strLabel} )`;
        }

        return `( ${dictionary.components.mngPotentialSponsorship?.now?.[lang]} )`;
      } catch {
        return "-";
      }
    },
    onChangeCatType:
      ({ objCat, fldSupportTrans, strTrainingType }) =>
      (e) => {
        try {
          if (fldSupportTrans.strSponsorFullStackCatType?.getValue()?.key || fldSupportTrans.strSponsorManagerCatType?.getValue()?.key) {
            const strSponsorFullStackCatType = fldSupportTrans.strSponsorFullStackCatType?.getValue()?.key;
            const strSponsorManagerCatType = fldSupportTrans.strSponsorManagerCatType?.getValue()?.key;
            const priceSystem = Number(objCat?.Root?.trainingType?.[strTrainingType]?.price?.value?.eng);

            const intCountStudentNumSystem =
              Number(
                objCat?.Root?.trainingType?.[strTrainingType]?.sponser?.[strTrainingType === appVariables.objTrainingToSupport.manager?.key ? strSponsorManagerCatType : strSponsorFullStackCatType]
                  ?.studentNumber?.value?.eng
              ) || 0;
            fldSupportTrans.trainingNumberAdmin?.setValue(intCountStudentNumSystem);
            fldSupportTrans.intTrainingProgPrice?.setValue(priceSystem * intCountStudentNumSystem);
          } else {
            fldSupportTrans.trainingNumberAdmin?.setValue("");
            fldSupportTrans.intTrainingProgPrice?.setValue("");
          }
        } catch (error) {}
      },
    onChangeTraneeCount:
      ({ objCat, fldSupportTrans, strTrainingType }) =>
      (e) => {
        try {
          if (fldSupportTrans.trainingNumberAdmin?.getValue() && !isNaN(fldSupportTrans.trainingNumberAdmin?.getValue())) {
            const priceSystem = Number(objCat?.Root?.trainingType?.[strTrainingType]?.price?.value?.eng);
            fldSupportTrans.intTrainingProgPrice?.setValue(priceSystem * Number(fldSupportTrans.trainingNumberAdmin?.getValue()));
          } else {
            fldSupportTrans.intTrainingProgPrice?.setValue("");
          }
        } catch (error) {}
      },
    //#endregion
  };

  return handler;
};
