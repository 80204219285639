import React, { useContext } from "react";
import { AppContext } from "../../../../configuration/contextapi/AppContext";
import AdminAccount from "../../admin/account/Account";
import AccountEntrepreneur from "../../Entrepreneur/accountEntrepreneur/AccountEntrepreneur";
import AccountSponsor from "../../Sponsor/accountSponsor/AccountSponsor";
import { objUserRole } from "../../../../appHelper/appVariables";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import ShredHeader from "../../../sharedUi/header/ShredHeader";
import useCheckCat from "../../../../appHelper/appHook/useCheckCat";
import useCheckSystemInfo from "../../../../appHelper/appHook/useCheckSystemInfo";
import { ClientDirection } from "@cbmisorg/client-app";

function Account() {
  useCheckCat()
  useCheckSystemInfo()
  const { appState , appDispatch} = useContext(AppContext);
  const lang = appState?.clientInfo?.strLanguage;

  const handelChangeLang = () => {
    if (lang==="arb") {
      ClientDirection.setDirection("ltr");
      appState.clientInfo.strLanguage = "eng";
      appState.clientInfo.strDataLanguage = "eng";
      appDispatch();
    }else{
      ClientDirection.setDirection("rtl");
      appState.clientInfo.strLanguage = "arb";
      appState.clientInfo.strDataLanguage = "arb";
      appDispatch();
    }
  };




  return (
    <React.Fragment>
      <ShredHeader title={dictionary?.components?.public?.footer?.account?.[lang]} lang={lang} blnHome={true} blnLang={true}  blnBack={false} changLang={handelChangeLang}/>

      {appState?.userInfo?.jsnUserInfo?.intUserRoleFK === objUserRole?.Sponsor ? (
        <AccountSponsor />
      ) : appState?.userInfo?.jsnUserInfo?.intUserRoleFK === objUserRole?.Student ? (
        <AccountEntrepreneur />
      ) : (
        <AdminAccount />
      )}
    </React.Fragment>
  );
}

export default Account;
