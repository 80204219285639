import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../configuration/contextapi/AppContext";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import { Badge, Container, Grid, Icon, Typography, useNavigate, Box, Alert } from "@cbmisorg/client-app";
import objAppRouting from "../../../../appHelper/routing/appRouting";
import { getNextStrURL, logMessage } from "../../../../appHelper/appFunctions";
import useCheckSystemInfo from "../../../../appHelper/appHook/useCheckSystemInfo";
import useCheckCat from "../../../../appHelper/appHook/useCheckCat";
import { generateQueries } from "../../../../appHelper/appQuery/GenerateQuery";
import { generatePath } from "react-router-dom";
import { objTrainingToSupport } from "../../../../appHelper/appVariables";

const strPageInfo = "@/src/components/stakeholders/admin/account/Account.js";
const tblStatistic = generateQueries("tblStatistic");

let defTrainingNav = [
  {
    icon: "laptop",
    label: dictionary?.components?.mngTraingNav?.fullStackTraining,
    url: generatePath(objAppRouting?.Manage_Training?.url, { strTrainingType: objTrainingToSupport?.fullstack?.key }),
    featureID: objAppRouting?.Manage_Training?.featureID,
    badge: ["intCountJoinStudent", "intCountJoinPartnerFullstack", "intCountTransPartnerFullstack"],
  },
  {
    icon: "work",
    label: dictionary?.components?.mngTraingNav?.managersTraining,
    url: generatePath(objAppRouting?.Manage_Training?.url, { strTrainingType: objTrainingToSupport?.manager?.key }),
    featureID: objAppRouting?.Manage_Training?.featureID,
    badge: ["intCountJoinManager", "intCountJoinPartnerManager", "intCountTransPartnerManager"],
  },
];

const mainNav = [
  {
    icon: "business",
    label: dictionary?.components?.mngTraingNav?.startup,
    url: generatePath(objAppRouting?.manage_Taining_info?.url, { strTrainingType: "startup" }),
    featureID: objAppRouting?.manage_Taining_info?.featureID,
    badge: "intCountJoinEntrepreneur",
  },
  {
    icon: "ContactMail",
    label: dictionary?.components?.contact?.mngTitle,
    url: objAppRouting?.Contact?.url,
    featureID: objAppRouting?.Contact?.featureID,
    badge: "intCountMail",
  },
  { icon: "Subscriptions", label: dictionary?.components?.supscrption?.title, url: objAppRouting?.Subscription?.url, featureID: objAppRouting?.Subscription?.featureID },
  {
    icon: "FactCheck",
    label: dictionary?.components?.manageActivates?.mngTitle,
    url: objAppRouting?.ManageActivates?.url,
    featureID: objAppRouting?.ManageActivates?.featureID,
  },
  {
    icon: "campaign",
    label: dictionary?.components?.mngAnnouncement?.Announcement?.title,
    url: objAppRouting?.MngAnnouncement?.url,
    featureID: objAppRouting?.MngAnnouncement?.featureID,
    // badge: "intCountJoinEntrepreneur",
  },
  {
    icon: "liveTv",
    label: dictionary?.components?.mngAnnouncement?.session?.title,
    url: objAppRouting?.MngMeeting?.url,
    featureID: objAppRouting?.MngMeeting?.featureID,
    badge: "intCountMeeting",
  },
  {
    icon: "Assistant",
    label: dictionary?.components?.manageStatistic?.title,
    url: objAppRouting?.ManageStatistic?.url,
    featureID: objAppRouting?.ManageStatistic?.featureID,
  },

  { icon: "AccountCircle", label: dictionary?.components?.manageMembers?.profile, url: objAppRouting?.profile?.url, featureID: objAppRouting?.profile?.featureID },
  {
    icon: "category",
    label: dictionary?.components?.manageCategories?.title,
    url: objAppRouting?.ManageCategories?.url,
    featureID: objAppRouting?.ManageCategories?.featureID,
  },
  { icon: "VideoLibrary", label: dictionary?.components?.advertisingVideoManagement?.title, url: objAppRouting?.AdvertisingVideoManagement?.url, featureID: objAppRouting?.AdvertisingVideoManagement?.featureID },
  { icon: "Settings", label: dictionary?.components?.manageSystem?.title, url: objAppRouting?.ManageSystem?.url, featureID: objAppRouting?.ManageSystem?.featureID },
];

function AdminAccount() {
  useCheckCat();

  const navigate = useNavigate();
  const { appState, appDispatch } = useContext(AppContext);
  // useCheckUserLoginDevice()
  const { getNewSystemInfo } = useCheckSystemInfo({ notHook: true, isGetCount: true });
  const lang = appState?.clientInfo?.strLanguage;
  const lstUserFeature = appState?.userInfo?.lstUserFeature || [];
  const [isLoading, setIsLoading] = useState(false);

  let trainingNav =
    !lstUserFeature?.includes(objAppRouting?.Manage_Partner?.featureID) &&
    !lstUserFeature?.includes(objAppRouting?.mngSupportTrans?.featureID) &&
    !lstUserFeature?.includes(objAppRouting?.manage_Taining_info?.featureID) &&
    lstUserFeature?.includes(objAppRouting?.Mng_Potential_Partner?.featureID)
      ? appState?.clientInfo?.objSystemInfo?.jsnSystemConfig?.blnShowManagerTrainingOption && appState?.clientInfo?.objSystemInfo?.jsnSystemConfig?.blnShowFullstacTrainingOption
        ? [
            {
              icon: "uploadFile",
              label: dictionary?.components?.mngPotentialSponsorship?.titleFullStack,
              url: generatePath(objAppRouting?.Mng_Potential_Partner?.url, { strTrainingType: objTrainingToSupport?.fullstack?.key }),
              featureID: objAppRouting?.Mng_Potential_Partner?.featureID,
            },
            {
              icon: "uploadFile",
              label: dictionary?.components?.mngPotentialSponsorship?.titleManager,
              url: generatePath(objAppRouting?.Mng_Potential_Partner?.url, { strTrainingType: objTrainingToSupport?.fullstack?.key }),
              featureID: objAppRouting?.Mng_Potential_Partner?.featureID,
            },
          ]
        : appState?.clientInfo?.objSystemInfo?.jsnSystemConfig?.blnShowFullstacTrainingOption
        ? [
            {
              icon: "uploadFile",
              label: dictionary?.components?.mngPotentialSponsorship?.titleFullStack,
              url: generatePath(objAppRouting?.Mng_Potential_Partner?.url, { strTrainingType: objTrainingToSupport?.fullstack?.key }),
              featureID: objAppRouting?.Mng_Potential_Partner?.featureID,
            },
          ]
        : appState?.clientInfo?.objSystemInfo?.jsnSystemConfig?.blnShowManagerTrainingOption
        ? [
            {
              icon: "uploadFile",
              label: dictionary?.components?.mngPotentialSponsorship?.titleManager,
              url: generatePath(objAppRouting?.Mng_Potential_Partner?.url, { strTrainingType: objTrainingToSupport?.fullstack?.key }),
              featureID: objAppRouting?.Mng_Potential_Partner?.featureID,
            },
          ]
        : []
      : appState?.clientInfo?.objSystemInfo?.jsnSystemConfig?.blnShowManagerTrainingOption && appState?.clientInfo?.objSystemInfo?.jsnSystemConfig?.blnShowFullstacTrainingOption
      ? defTrainingNav
      : appState?.clientInfo?.objSystemInfo?.jsnSystemConfig?.blnShowFullstacTrainingOption
      ? defTrainingNav.slice(0, 1)
      : appState?.clientInfo?.objSystemInfo?.jsnSystemConfig?.blnShowManagerTrainingOption
      ? defTrainingNav.slice(1, 2)
      : [];

  useEffect(() => {
    const accountUrl = getNextStrURL();
    if (accountUrl !== objAppRouting?.Account?.url) {
      navigate(accountUrl, { replace: true });
    }
    getNewSystemInfo(appDispatch);
  }, []);

  const functionClickedNav = (item) => async () => {
    navigate(item.url);
    if (!item?.badge || Array.isArray(item.badge)) {
      return;
    }
    if (isLoading) {
      Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning");
      return;
    }

    setIsLoading(true);
    const result = await tblStatistic(strPageInfo, appState, "aaiotAppUpdate", {
      objectInput: {
        [item?.badge]: 0,
        dtmUpdatedDate: new Date().toUTCString(),
      },
      objectCondition: {
        bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID || appState?.userInfo.bigSystemID,
      },
    });
    if (!result?.blnIsRequestSuccessful) {
      logMessage(result?.response, "");
    }
    setIsLoading(false);
  };

  const funGetArrayBadge = (array = []) => {
    let sum = 0;
    for (let index = 0; index < array.length; index++) {
      sum += Number(appState?.clientInfo?.objSystemInfo?.[array[index]]) || 0;
    }
    return sum;
  };

  return (
    <React.Fragment>
      <Container py-10>
        <Grid container justify={"center"} spacing={1}>
          {[...trainingNav, ...mainNav]
            ?.filter((item) => lstUserFeature?.includes(item?.featureID) || !item?.featureID)
            ?.map((item, index) => {
              return (
                <Grid item xs="6" sm="4" md="3" lg="2" key={index} className="navAccount">
                  <Box className="boxNav" onClick={functionClickedNav(item)}>
                    <Grid container justify={"center"}>
                      <Grid item xs="12">
                        {item?.badge && (appState?.clientInfo?.objSystemInfo?.[item?.badge] || funGetArrayBadge(item?.badge)) ? (
                          <Badge content={!Array.isArray(item?.badge) ? appState?.clientInfo?.objSystemInfo?.[item?.badge] : funGetArrayBadge(item?.badge)}>
                            <Icon icon={item?.icon} color="primary" />
                          </Badge>
                        ) : (
                          <Icon icon={item?.icon} color="primary" />
                        )}
                      </Grid>
                      <Grid item xs="12">
                        <Typography as="caption" className="textcenter">
                          {item?.label?.[lang]}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              );
            })}
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default AdminAccount;
