import { BtnGoToTop, Button, ClientDirection, Container, Glass, Grid, Icon, Line, Paper, Typography, useNavigate, useParams } from "@cbmisorg/client-app";
import React, { useContext, useEffect } from "react";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import { AppContext } from "../../../../configuration/contextapi/AppContext";
import ShredHeader from "../../../sharedUi/header/ShredHeader";
import useCheckSystemInfo from "../../../../appHelper/appHook/useCheckSystemInfo";
import * as appColor from "../../../../appHelper/appColor";
import objAppRouting from "../../../../appHelper/routing/appRouting";
import supportCtrl from "../controller/supportCtrl";
import PaperSection from "../../../sharedUi/paperSection/PaperSection";
import curvyLines from "../../../../assets/images/curvyLines.png";
import { generatePath } from "react-router-dom";

const sponsorColor = [appColor?.golden, appColor?.silver, appColor?.bronze];
const classes = {
  main: {
    background: "url(https://waya.media/wp-content/uploads/sites/3/2023/12/website-30.jpeg)",
    minHeight: "200px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  sideBox: {
    backgroundColor: "#fff",
    backgroundImage: `url(${curvyLines})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
    border: "1px solid #ddd",
  },
};

function Support() {
  const { getNewSystemInfo } = useCheckSystemInfo();
  const { appState, appDispatch } = useContext(AppContext);
  const lang = appState?.clientInfo?.strLanguage || "arb";
  const navigate = useNavigate();
  const { trainingType } = useParams();

  const labels = dictionary?.components?.public?.landingPage?.home?.support?.[String(trainingType)?.toLowerCase()];

  useEffect(() => {
    ClientDirection.setDirection(lang === "arb" ? "rtl" : "ltr");
  }, [lang]);

  const handlers = supportCtrl({ appState, appDispatch });

  const intData = async () => {
    if (!appState?.clientInfo?.objSystemInfo || !Object.keys(appState?.clientInfo?.objSystemInfo)?.length) {
      appState.clientInfo.objSystemInfo = await getNewSystemInfo();
    }
    await handlers.initData();
  };

  useEffect(() => {
    intData();
  }, []);
  const handelChangeLang = () => {
    ClientDirection.setDirection(lang === "arb" ? "rtl" : "ltr");
    appState.clientInfo.strLanguage = lang === "arb" ? "eng" : "arb";
    appState.clientInfo.strDataLanguage = lang === "arb" ? "eng" : "arb";
    appDispatch();
  };

  return (
    <React.Fragment>
      <ShredHeader title={labels?.title?.[lang]} lang={lang} blnBack={false} blnHome={false} blnBottomBar={false} blnLogo={true} blnLang={true} changLang={handelChangeLang} blnLangStart={true} />
      <Container sx={classes?.main} width={"fluid"} p-3>
        <Paper elevation={0} outline={0} p-0 mt-10 sx={{ width: "auto !important" }}>
          <Glass sx={{ height: "auto", position: "relative", padding: "0", margin: "0", width: "100%" }}>
            <Grid container spacing={2} justify={"center"} py-0>
              <Grid item xs="12">
                <Typography as="h4" color="light" sx={{ textAlign: "center !important" }}>
                  {labels?.subtitle1?.[lang]}
                </Typography>
              </Grid>
              <Grid item xs="12" pt-0>
                <Typography as="h4" color="light" sx={{ textAlign: "center !important" }}>
                  {labels?.country?.[lang]}
                </Typography>
              </Grid>
            </Grid>
          </Glass>
        </Paper>
      </Container>
      <Container py-5>
        <Grid container id="ourGoal" px-0 spacing={0} pb-10>
          <Grid item xs="12" container justify="center">
            <Container>
              <Grid container spacing={3}>
                <Grid item xs="12">
                  <Typography as="subtitle1" mx-auto>
                    {labels?.subtitle2?.[lang]}
                  </Typography>
                </Grid>
                <Grid item xs="12">
                  <Typography as="body2">{labels?.about?.[lang]}</Typography>
                </Grid>
                <Grid item xs="12">
                  <Paper
                    elevation={0}
                    outline={0}
                    style={{
                      background: `url(${labels?.strImage})`,
                      backgroundAttachment: "fixed",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <Grid container>
                      <Grid item xs="12">
                        <Paper elevation={0} outline={1} p-0>
                          <Glass sx={{ height: "auto", position: "relative", padding: "0", margin: "0", width: "100%" }}>
                            <Grid container spacing={3} p-3>
                              <Grid item m-auto px-0>
                                <Typography as="subtitle1" color={"#fff"} pb-5 sx={{ textAlign: "center !important", fontWeight: "600 !important" }} className="textCenter">
                                  {labels?.training?.[lang]}
                                </Typography>
                              </Grid>
                              {labels?.lstAbout?.map((item, index) => {
                                return (
                                  <Grid item xs="12" container alignItems={"flex-start"}>
                                    <Grid ite xs="1" sm="auto" alignSelf={"flex-start"} className="app-point" pt-3>
                                      <Icon icon="circle" color="#fff" />
                                    </Grid>
                                    <Grid item xs="11" key={index} alignSelf={"flex-start"}>
                                      <Typography as="body2" color="#fff" sx={{ textAlign: "justify !important" }}>
                                        {item?.[lang]}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                );
                              })}
                              <Grid item xs="12" container justify={"center"}>
                                <Grid item>
                                  <Typography as="subtitle1" color={"#fff"}>
                                    {labels?.btnFile?.[lang]}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <a href={labels?.fileUrl?.[lang]} download={true} target="_blank">
                                    <Button icon="fileCopyOutlined" color={appColor?.App_orang_color} />
                                  </a>
                                </Grid>
                                <Grid item>
                                  <a href={`${window?.location?.origin}${generatePath(objAppRouting?.Public_Initiative?.url,{strTrainingType: trainingType})}`}  target="_blank">
                                  <Button icon="public"  />
                                  </a>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Glass>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>

              <Grid container spacing={3} pt-5>
                <Grid item xs="12">
                  <Typography as="body2">{labels?.trainingPrice?.[lang]}</Typography>
                </Grid>
                <Grid item xs="12">
                  <PaperSection sx={classes?.sideBox}>
                    <Grid container justify={"center"} spacing={2} pb-3>
                      <Grid item>
                        <Icon icon="AutoAwesome" color={appColor?.main} />
                      </Grid>
                      <Grid item xs="12">
                        <Typography as="subtitle1" color={appColor.main}>
                          {labels?.lstSponser?.[0]?.title?.[lang]}
                        </Typography>
                      </Grid>
                      <Typography as="body2" className="textcenter">
                        {labels?.lstSponser?.[0]?.dec?.[lang]}
                      </Typography>
                    </Grid>
                  </PaperSection>
                </Grid>
                {labels?.lstSponser?.slice(1)?.map((item, index) => {
                  return (
                    <Grid item xs="12" sm="4" key={index}>
                      <PaperSection sx={classes?.sideBox}>
                        <Grid container justify={"center"} spacing={2}>
                          <Grid item>
                            <Icon icon="star" color={sponsorColor?.[index]} />
                          </Grid>
                          <Grid item xs="12">
                            <Typography as="subtitle1" color={sponsorColor?.[index]}>
                              {item?.title?.[lang]}
                            </Typography>
                          </Grid>
                          <Typography as="body2" className="textcenter">
                            {item?.dec?.[lang]}
                          </Typography>
                        </Grid>
                      </PaperSection>
                    </Grid>
                  );
                })}
                <Grid item xs="12">
                  <Typography as="body2">{labels?.p1?.[lang]}</Typography>
                </Grid>

                {!labels?.trainingNote ? null : (
                  <Grid item xs="12">
                    <Typography as="body2" sx={{ textAlign: "justify !important" }}>
                      <span style={{ color: appColor.App_orang_color }}>{labels?.note?.[lang]}</span>
                      {labels?.trainingNote?.[lang]}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid item xs="12">
                  <Typography as="body2">{labels?.p2?.[lang]}</Typography>
                </Grid>
              <Grid container justify={"center"} pt-4 pb-4>
                <Grid item>
                  <Button label={labels?.btn?.[lang]} onClick={() => navigate(generatePath(objAppRouting?.Public_Join_Partners?.url, { trainingType: trainingType }))} size="sm" py-0 px-3/>
                </Grid>
              </Grid>
              <Line />
              <Grid container spacing={2} pb-3>
                <Grid item>
                  <Typography as="caption">
                    {labels?.emailLabel?.[lang]}
                    &nbsp;
                    <a href={`mailto:${labels?.email}`} style={{ color: appColor.App_orang_color , textDecoration: "none" }}>
                      {labels?.email}
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Container>
      <BtnGoToTop className={lang === "arb" ? "arbLang" : ""} />
    </React.Fragment>
  );
}

export default Support;
