import { Alert, ClientFunction, Confirmation } from "@cbmisorg/client-app";
import * as appFunctions from "../../../../appHelper/appFunctions";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import objAppRouting from "../../../../appHelper/routing/appRouting";
import { generateQueries } from "../../../../appHelper/appQuery/GenerateQuery";
import { App_ID, globalKeyZero, objUploadType } from "../../../../appHelper/appVariables";

const strPageInfo = "@src/components/public/login/controller/CtrlLogin.js";
const tblJoinSymposium = generateQueries("tblJoinSymposium")
const tblSystem = generateQueries("tblSystem")

export const CtrlJoinToSymposium = (appState, appDispatch, isLoading, setIsLoading, navigate, stateInfo, setStateInfo) => {
  const lang = appState?.clientInfo?.strDataLanguage ||  appState?.clientInfo?.strLanguage;
  const objSystemInfo = appState?.clientInfo?.objSystemInfo;
  // const jsnLoginConfig = objSystemInfo?.jsnLoginConfig;

  return {
    getRequested:
    async () => {
      try {
        if (appState.cacheInfo.objSymposiumInfo?.bigID && appState.cacheInfo.objSymposiumInfo?.strEmail) {
          return;
        }
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
          return;
        }
        setIsLoading(true);
        const result = await tblJoinSymposium(strPageInfo,appState,"aaiotAppFindOne",
        {
          arrAttributes:["bigID","strEmail","strPhone","jsnInfo","blnIsApproved"],
          objectCondition:{
            bigAppID: App_ID,
            bigSystemID: objSystemInfo?.bigSystemID,
            strEmail: String(appState?.cacheInfo?.objSymposiumInfo?.strEmail),
            blnIsActive:true,
            blnIsDeleted:false,
          }
        });

        if(result.response?.bigID){
            if(result.response?.blnIsApproved){
              navigate(objAppRouting?.symposiumCard?.url, { replace: true })
            }else{
              navigate(objAppRouting?.WaitingTraining?.url, { replace: true })
            }
          }
          setIsLoading(false)
          appState.cacheInfo.objSymposiumInfo = result?.response
          appDispatch()
          return
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "addCategory-ERROR", error);
        setIsLoading(false);
      }
    },
    addRequested:
    async (fields,uploaderGovID,uploaderRest) => {
      try {

        if (!fields.isFormValid()) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang],"")
          return;
        }
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
          return;
        }
        setIsLoading(true);
        const resultFindEmail = await tblJoinSymposium(strPageInfo,appState,"aaiotAppFindOne",
        {
          arrAttributes:["bigID","strEmail","strPhone","jsnInfo","blnIsApproved"],
          objectCondition:{
            bigAppID: App_ID,
            bigSystemID: objSystemInfo?.bigSystemID,
            strEmail: String(fields?.strEmail?.getValue()).trim(" "),
            blnIsActive:true,
            blnIsDeleted:false,
          }
        });

        if(resultFindEmail.response?.bigID){
          if(String(resultFindEmail.response?.strPhone) === String(globalKeyZero + fields?.strPhone?.getValue().trim(" "))){
            Confirmation?.viewConfirmation(dictionary?.shared?.alertFetchNote?.email_Already_Used?.[lang],()=>{
              if(resultFindEmail.response?.blnIsApproved){
                navigate(objAppRouting?.symposiumCard?.url, { replace: true })
              }else{
                navigate(objAppRouting?.WaitingTraining?.url, { replace: true })
              }
            },dictionary?.shared?.confirmationMsg?.yesMove?.[lang]
              ,dictionary?.shared?.confirmationMsg?.no?.[lang]
            )
          }else{
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.email_Already_Used?.[lang])
          }
          setIsLoading(false)
          appState.cacheInfo.objSymposiumInfo = resultFindEmail?.response
          appDispatch()
          return
        }

        let bigID = ClientFunction.generateID(10);

        // const jsnSystemConfig= appState?.objSystemInfo?.jsnSystemConfig
        const govIDFile =  await uploaderGovID?.uploadHandler(objUploadType?.payment)
        const receiptFile = await uploaderRest?.uploadHandler(objUploadType?.payment)
        const result = await tblJoinSymposium(strPageInfo,appState,"aaiotAppAdd",
        {
          objectInput:{
            bigAppID: App_ID,
            bigSystemID: objSystemInfo?.bigSystemID,
            bigID:bigID,

            strEmail: fields?.strEmail?.getValue().trim(" "),
            strPhone: globalKeyZero + fields?.strPhone?.getValue().trim(" "),

            jsnInfo: {
              strFullName:fields?.strFullName?.getValue(),
              bigEmploymentID:fields?.bigEmploymentID?.getValue()?.key,
              strPositionTitle:fields?.strPositionTitle?.getValue(),
              bigWorkSector:fields?.bigWorkSector?.getValue()?.key,
              strWorkPlace:fields?.strWorksPlace?.getValue(),
              strGovIDFilePath:govIDFile && stateInfo?.listFileRestID?.[0]?`${objUploadType?.aaiot?.fileType}/${objUploadType?.payment}/${stateInfo?.listFileGovID?.[0]?.name}`:null,
              strReceiptFilePath:receiptFile && stateInfo?.listFileRestID?.[0]?`${objUploadType?.aaiot?.fileType}/${objUploadType?.payment}/${stateInfo?.listFileRestID?.[0]?.name}`:null,
            },
            blnIsApproved : false
          }
        });
        if (!result?.blnIsRequestSuccessful) {
          appFunctions.logMessage(result?.response, "");
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang],"warning")
          return;
        }
        appState.cacheInfo.objSymposiumInfo = result?.response
        appDispatch()

        const resultJoinToSymposiumIncCount = await tblSystem(strPageInfo,appState,"aaiotAppIncDecInt",
        {
          arrAttributes:["intCountJoinToSymposium"],
          objectCondition:{
            bigAppID:App_ID,
            bigSystemID:objSystemInfo?.bigSystemID,
            blnIsActive:true,blnIsDeleted:false,
          }
        });

        if (!resultJoinToSymposiumIncCount?.blnIsRequestSuccessful) {
          appFunctions.logMessage(result?.response, "");
          return;
        }


        setIsLoading(false);
        fields.resetField()
        Alert.viewAlert(dictionary?.shared?.alertFetchNote?.added_Successfully?.[lang], "success");
        navigate(objAppRouting?.WaitingTraining?.url, { replace: true })
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "addCategory-ERROR", error);
        setIsLoading(false);
      }
    },
  };
};
