import { useContext, useEffect } from "react";
import { ClientFunction } from "@cbmisorg/client-app";
import * as appVariables from "../../appHelper/appVariables";
import * as appFunctions from "../../appHelper/appFunctions";
import { AppContext } from "../../configuration/contextapi/AppContext";
import { generateQueries } from "../appQuery/GenerateQuery";
// import objAppRouting from "../routing/appRouting";

const strPageInfo = "@src/appHelper/hooks/useCheckUserLoginDevice .js";
const tblUser = generateQueries("tblUser")

export const useCheckUserLoginDevice = () => {
  const { appState } = useContext(AppContext);
  // const objSystemInfo = appState?.clientInfo?.objSystemInfo?.[appState?.clientInfo?.bigSystemID];
  // const jsnLoginConfig = objSystemInfo?.jsnLoginConfig;
  // const navigate = useNavigate();

  useEffect(() => {
    checkValidity();
  }, []);

  const checkValidity = () => {
    try {
      // if (!appState?.clientInfo?.blnIsUserLoggedIn || !jsnLoginConfig?.blnDeviceKey) {
      //   return;
      // }
       if (!appState?.clientInfo?.blnIsUserLoggedIn) {
        return;
      }

      let intLastChecked = ClientFunction.getDifferenceBetweenTwoDate(appState.clientInfo.dtmLastCheckLoginKey, new Date(), "hour");
      if (intLastChecked < appVariables.App_Login_HoursToCheck && !Number.isNaN(Number(intLastChecked))) {
        return;
      }

      checkIsUserLoginKeyActive();
    } catch (error) {}
  };

  const checkIsUserLoginKeyActive = async () => {
    try {
      const responseUser = await tblUser(strPageInfo,appState,"aaiotAppUserOne",{
        arrAttributes:["bigAppID","bigSystemID","bigUserID", "bigUserRoleID", "strUserPassword", "strUserEmail", "strUserPhone","strUsername","jsnUserInfo", "txtLoginKeys", "txtLoginIP","txtPushSubscription","intNumLoginAttempt","blnIsAccountLocked","dtmAccountLocked","blnAddToHomePage","blnIsDeleted","blnIsActive","blnIsCompleteReg","blnIsApproved","blnIsPaySubscribed","jsnPaySubscribed","dtmCreatedDate","dtmUpdatedDate"],
        objectCondition:{
          bigUserID: appState?.userInfo?.bigUserID,
          bigSystemID: appState?.userInfo?.bigSystemID,
          bigAppID: appState?.userInfo?.bigAppID,
        }
      })

      // if (!responseUser || !responseUser?.response || !responseUser?.response?.txtLoginKeys || !responseUser.blnIsRequestSuccessful) {
      //   return;
      // }

      // let arrLoginKeys = ClientFunction.checkParseObject(responseUser?.response?.txtLoginKeys);
      // arrLoginKeys = Array.isArray(arrLoginKeys) ? arrLoginKeys : [];

      const appLocalStorage = appFunctions.getAppLocalStorage();
      // let bigUserCurrentKeyLogin = appLocalStorage?.userInfo?.txtLoginKeys || appState?.userInfo?.txtLoginKeys;

      // if (bigUserCurrentKeyLogin && !arrLoginKeys.includes(Number(bigUserCurrentKeyLogin)) && !arrLoginKeys.includes(String(bigUserCurrentKeyLogin))) {
      //   appFunctions.clearAppLocalStorage(appState);
      //   navigate(objAppRouting?.Public_LandingPage?.url, { replace: true });
      // } else {
      //   appState.clientInfo.dtmLastCheckLoginKey = new Date();
      //   appFunctions.setAppLocalStorage(appState);
      //  }

      let jsnPaySubscribed = responseUser?.response?.jsnPaySubscribed ||  appLocalStorage?.userInfo?.jsnPaySubscribed || appState?.userInfo?.jsnPaySubscribed;
      let intLastCheckedSubscription = jsnPaySubscribed?.dtmSubscribedDeactivate &&  ClientFunction.blnIsTodayBiggerThanTheDate(jsnPaySubscribed?.dtmSubscribedDeactivate);

      appState.userInfo= responseUser.response

      if(intLastCheckedSubscription){
        const userUpdatedResponse = await tblUser(strPageInfo, appState,"aaiotAppUserUpdate",{
          objectInput:{
            jsnUserInfo:{
              ...appState?.userInfo?.jsnUserInfo,
            },
            blnIsCompleteReg:false,
            blnIsPaySubscribed: false,
          },
          objectCondition:{
            bigUserID: appState?.userInfo?.bigUserID,
            bigSystemID: appState?.userInfo?.bigSystemID,
            bigAppID: appState?.userInfo?.bigAppID,
          }
        })
        if (!userUpdatedResponse || !userUpdatedResponse.blnIsRequestSuccessful) {
          return;
        }
        appState.userInfo.jsnUserInfo.strNote =null;
        appState.userInfo.blnIsCompleteReg=false;
        appState.userInfo.blnIsPaySubscribed= false;
      }
      appState.clientInfo.dtmLastCheckLoginKey = new Date();
      appFunctions.setAppLocalStorage(appState);
    } catch (error) {
      appFunctions.logMessage(strPageInfo, "checkIsUserLoginKeyActive-ERROR", error);
    }
  };

  return null;
};
