import { ThemeProvider } from "@cbmisorg/client-app";
import './App.css';
import Router from "../../configuration/Router";
import AppProvider from "../../configuration/contextapi/AppContext"
import { App_Dark_Color, App_Primary_Color, App_Second_Color } from "../../appHelper/appColor";
import React from "react";

function App() {
  const theme = {
    primary: App_Primary_Color,
    secondary: App_Second_Color,
    dark: App_Dark_Color,
  }
  

  return (
    <div className="App">
      <AppProvider>
        <ThemeProvider theme={theme}>
            <Router />
        </ThemeProvider>
      </AppProvider>
    </div>
  );
}

export default App;
