import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../configuration/contextapi/AppContext";
import { Container, Grid, Icon, MenuItem, Typography, Loader, useNavigate, Button, Text, Line } from "@cbmisorg/client-app";
import { App_Primary_Color, App_Text_Color } from "../../../../appHelper/appColor";
import { CtrlAviates } from "./controller/ctrlActivities";
import ShredHeader from "../../../sharedUi/header/ShredHeader";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import objAppRouting from "../../../../appHelper/routing/appRouting";
import NoResultImg from "../../../sharedUi/noResultImg/NoResultImg";
import { getDateUSFormat } from "../../../../appHelper/appFunctions";

const labels = dictionary?.components?.manageActivates;

function ActivatesArchaic() {
  const navigate = useNavigate();
  const { appState , appDispatch} = useContext(AppContext);
  const lang = appState.clientInfo.strLanguage;
  const [isLoading, setIsLoading] = useState(false);
  const [stateInfo, setStateInfo] = useState({
    blnIsInitDone: false,
    listMain: [],
    listRead: [],
  });

  const handlers = CtrlAviates(appState,appDispatch, isLoading, setIsLoading, stateInfo, setStateInfo);
  const funDelete = (item) => handlers?.funDelete(item);
  const funRepublic = (item) => handlers?.funRepublic(item);

  useEffect(() => {
    if (!stateInfo?.blnIsInitDone) {
      handlers?.getActivityArchaic();
    }
  }, []);

  return (
    <React.Fragment>
      <ShredHeader title={labels?.archaicTitle?.[lang]} lang={lang} blnBottomBar={false} />
      <Container py-3>
        {isLoading ? <Loader backdrop={true} color={App_Primary_Color} /> : null}
        <Grid container justify={"space-between"}>
          <Grid item container spacing={0}>
            <Typography as="subtitle2">{`${labels?.number?.[lang]}: ${stateInfo?.listMain?.length}`}</Typography>
          </Grid>
        </Grid>
        <Line />
        <Grid container justify={"center"}>
          <Grid item xs="12">
            <Text placeholder={labels?.search?.[lang]} icon="search" dir={lang === "arb" ? "rtl" : "ltr"} onChange={handlers?.searchActivity} />
          </Grid>
        </Grid>
        {!stateInfo?.listRead?.length ? (
          <NoResultImg />
        ) : (
          <React.Fragment>
            <Grid container justify={"center"} spacing={2}>
              {stateInfo?.listRead?.map((item, index) => {
                return (
                  <Grid item xs="12" key={index}>
                    <MenuItem sx={{ width: "100%" }} headItem={<Icon icon={"circle"} pt-1 color="#aaa" />} outlined>
                      <Grid container justify={"space-between"}>
                        <Grid item xs="12"  md="8" container spacing={0}>
                          <Grid item xs="12" pb-0>
                            <Typography as="subtitle2" pb-0 color={App_Text_Color}>
                              {item?.jsnActivityInfo?.title?.[lang]}
                            </Typography>
                          </Grid>
                          <Grid item xs="12" pb-0>
                            <Typography as="caption" pb-0 color={App_Text_Color}>
                              {getDateUSFormat(item?.dtmCreatedDate)}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item xs="12" md="auto" container sx={{justifyContent:"center"}} spacing={2}>
                          <Grid item px-0>
                            <Button icon="delete" color={"error"} p-0 onClick={() => funDelete(item)} />
                          </Grid>
                          <Grid item px-0>
                            <Button icon="public" p-0 onClick={() => funRepublic(item)} />
                          </Grid>
                          <Grid item px-0>
                            <Button icon="visibility" p-0 onClick={() => navigate(objAppRouting?.Public_Events?.url + "/" + item?.bigActivityID, {state: {objActivty: item}})} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  </Grid>
                );
              })}
            </Grid>
          </React.Fragment>
        )}
      </Container>
    </React.Fragment>
  );
}

export default ActivatesArchaic;
