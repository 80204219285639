//#region ******************************************************* //
/*
 *This is the Router called from App.js
 */
//#endregion ************************************************* //

import React from "react";
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from "@cbmisorg/client-app";

import SecureRoute from "../appHelper/routing/SecureRoute";
import objAppRouting from "../appHelper/routing/appRouting";
import { ChangFontSize } from "../components/public/settings/ChangFontSize";

import RouteLandingPage from "../components/public/LandingPage/RouteLandingPage";
import Events from "../components/public/LandingPage/events/Events";
import Event from "../components/public/LandingPage/events/Event";
import RouteSettings from "../components/public/settings/RouteSettings";
import Account from "../components/stakeholders/shared/account/Account";
import Members from "../components/public/LandingPage/member/Members";
import RouteLogin from "../components/public/login/RouteLogin";
import JoinRequest from "../components/public/joinRequest/JoinRequest";
import RouteMessageOfSheikhMuhammad from "../components/public/LandingPage/MessageOfSheikhMuhammad/RouteMessageOfSheikhMuhammad";
import { getAppLocalStorage } from "../appHelper/appFunctions";
import ManageActivates from "../components/stakeholders/shared/manageActivates/ManageActivates";
import SaveActivity from "../components/stakeholders/shared/manageActivates/SaveActivity";
import RouteContact from "../components/stakeholders/shared/contact/RouteContact";
import RouteViewContact from "../components/stakeholders/shared/contact/RouteViewContact";
import RouteSubscription from "../components/stakeholders/shared/subscription/RouteSubscription";
import RouteMngRequestedJoin from "../components/stakeholders/shared/mngRequestedJoin/RouteMngRequestedJoin";
import ManageSystem from "../components/stakeholders/admin/manageSystrm/ManageSystem";
import RouteRequirementInfo from "../components/public/requirementInfo/RouteRequirementInfo";
import WaitingPage from "../components/public/waitingPage/WaitingPage";
import RouteaMnageMembers from "../components/stakeholders/shared/manageMembers/RouteaMnageMembers";
import RouteViewMember from "../components/stakeholders/shared/manageMembers/RouteViewMember";
import RouteProfile from "../components/stakeholders/shared/profile/RouteProfile";
import Advisor from "../components/stakeholders/shared/advisor/Advisor";
import ActivatesArchaic from "../components/stakeholders/shared/manageActivates/ActivatesArchaic";
import RouteMngRequestedActive from "../components/stakeholders/shared/mngRequesteAaitoSubscrip/RouteMngRequestedActive";
import RouteViewRequestedActive from "../components/stakeholders/shared/mngRequesteAaitoSubscrip/RouteViewRequestedActive";
import JoinToSymposium from "../components/public/joinToSymposium/JoinToSymposium";
import RouteaMnageReqTraining from "../components/stakeholders/admin/mngReqTraining/RouteaMnageReqTraining";
import RouteViewReqTraining from "../components/stakeholders/admin/mngReqTraining/RouteViewReqTraining";
import WaitingTrainingPage from "../components/public/waitingPage/WaitingTrainingPage";
import JoinPartner from "../components/public/joinPartner/JoinPartner";
import RouteMngPartner from "../components/stakeholders/shared/mngPartner/RouteMngPartner";
import Partners from "../components/public/LandingPage/partners/Partners";
import JoinTrainee from "../components/public/joinTrainee/JoinTrainee";
import JoinManager from "../components/public/joinManager/JoinManager";

import MngAnnouncement from "../components/stakeholders/admin/mngAnnouncement";
import MngMeeting from "../components/stakeholders/admin/mngMeeting";
import RouteMnageManagers from "../components/stakeholders/shared/manageManagers/RouteaMnageManagers";
import MngSupportTrans from "../components/stakeholders/admin/mngSupportTrans";

// import Registration from "../../src copy/components/public/registration/Registration";
import ForgetPassword from "../components/public/forgetPasswoed/ForgetPassword";
import Support from "../components/public/LandingPage/support/Support";
import Statistic from "../components/stakeholders/shared/statistic/Statistic";
import Initiative from "../components/public/initiative";
import RouteMngCategory from "../components/stakeholders/admin/mngCategory/RouteMngCategory";
import MngPotentialSponsorship from "../components/stakeholders/shared/mngPotentialSponsorship";
import { objTrainingToSupport } from "../appHelper/appVariables";
import MngSuggestedSponsor from "../components/stakeholders/shared/mngPotentialSponsorship/mngSuggestedSponsor/MngSuggestedSponsor";
import RouteTraining from "../components/stakeholders/shared/mngTraining/RouteTraining";
import RoutmMangTrainingInfo from "../components/stakeholders/shared/mngTraining/RoutmMangTrainingInfo";
import TrainingVideo from "../components/public/initiative/TrainingVideo";
import AdvertisingVideoManagement from "../components/stakeholders/admin/advertisingVideoManagement/AdvertisingVideoManagement";
import PublicVideoes from "../components/public/publicVideoes/PublicVideoes";
import SponsorProfileInfo from "../components/stakeholders/Sponsor/sponsorProfileInfo/SponsorProfileInfo";

// const RouteLandingPage  =lazy(()=>import('../components/public/LandingPage/RouteLandingPage'))
const locationState = getAppLocalStorage();
ChangFontSize(locationState?.clientInfo?.appFontSize);

function Router() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route errorElement={<React.Fragment />}>
        <Route path={objAppRouting.Public_LandingPage.url} element={<SecureRoute><RouteLandingPage /></SecureRoute>} />
        <Route path={objAppRouting.Public_Team.url} element={<SecureRoute><Members /></SecureRoute>} />

        <Route path={objAppRouting.Public_Events.url} element={<SecureRoute><Events /></SecureRoute>} />
        <Route path={objAppRouting.Public_Event.url} element={<SecureRoute><Event /></SecureRoute>} />

        <Route path={objAppRouting.Public_Support.url} element={<SecureRoute><Support /></SecureRoute>} />

        <Route path={objAppRouting.Partner.url} element={<SecureRoute><Partners /></SecureRoute>} />

        <Route path={objAppRouting.Public_Settings.url} element={<SecureRoute><RouteSettings /></SecureRoute>} />

        <Route path={objAppRouting.Public_JoinRequest.url} element={<SecureRoute><JoinRequest /></SecureRoute>} />
        <Route path={objAppRouting.RequirementInfo.url} element={<SecureRoute><RouteRequirementInfo /></SecureRoute>} />
        <Route path={objAppRouting.Join_Symposium.url} element={<SecureRoute><JoinToSymposium /></SecureRoute>} />

        <Route path={objAppRouting.Join_Startup.url} element={<SecureRoute><JoinTrainee blnIsStartUp={true} /></SecureRoute>} />

        <Route path={objAppRouting.Join_Trainee.url} element={<SecureRoute><JoinTrainee blnIsStartUp={false} /></SecureRoute>} />

        <Route path={objAppRouting.Join_Manager.url} element={<SecureRoute><JoinManager /></SecureRoute>} />

        <Route path={objAppRouting.Public_Join_Partners.url} element={<SecureRoute><JoinPartner /></SecureRoute>} />

        <Route path={objAppRouting.Waiting.url} element={<SecureRoute><WaitingPage /></SecureRoute>} />
        <Route path={objAppRouting.WaitingTraining.url} element={<SecureRoute><WaitingTrainingPage /></SecureRoute>} />

        <Route path={objAppRouting.Public_Login.url} element={<SecureRoute><RouteLogin /></SecureRoute>} />
        {/* <Route path={objAppRouting.Public_reg.url} element={<SecureRoute><Registration /></SecureRoute>} /> */}
        <Route path={objAppRouting.Public_RestPassword.url} element={<SecureRoute><ForgetPassword /></SecureRoute>} />

        <Route path={objAppRouting.Public_SheikMessage.url} element={<SecureRoute><RouteMessageOfSheikhMuhammad /></SecureRoute>} />

        <Route path={objAppRouting.Account.url} element={<SecureRoute><Account /></SecureRoute>} />

        <Route path={objAppRouting.ManageActivates.url} element={<SecureRoute><ManageActivates /></SecureRoute>} />
        <Route path={objAppRouting.ManageActivates_Archaic.url} element={<SecureRoute><ActivatesArchaic /></SecureRoute>} />
        <Route path={objAppRouting.SaveActivates.url} element={<SecureRoute><SaveActivity /></SecureRoute>} />

        <Route path={objAppRouting.Contact.url} element={<SecureRoute><RouteContact /></SecureRoute>} />
        <Route path={objAppRouting.viewEmail.url} element={<SecureRoute><RouteViewContact /></SecureRoute>} />
        <Route path={objAppRouting.Subscription.url} element={<SecureRoute><RouteSubscription /></SecureRoute>} />


        <Route path={objAppRouting.Mng_Potential_Partner.url} element={<SecureRoute><MngPotentialSponsorship /></SecureRoute>} />
        <Route path={objAppRouting.Manage_RequestActive.url} element={<SecureRoute><RouteMngRequestedActive /></SecureRoute>} />
        <Route path={objAppRouting.View_RequestActive.url} element={<SecureRoute><RouteViewRequestedActive /></SecureRoute>} />
                
        <Route path={objAppRouting.Manage_Training.url} element={<SecureRoute><RouteTraining /></SecureRoute>} />
        <Route path={objAppRouting.manage_Taining_info.url} element={<SecureRoute><RoutmMangTrainingInfo /></SecureRoute>} />
        <Route path={objAppRouting.Manage_Partner.url} element={<SecureRoute><RouteMngPartner /></SecureRoute>} />
        <Route path={objAppRouting.Manage_Partner_Support_Trans.url} element={<SecureRoute><MngSupportTrans /></SecureRoute>} />
        <Route path={objAppRouting.Mng_Join_Symposium.url} element={<SecureRoute><RouteaMnageReqTraining /></SecureRoute>} />
        <Route path={objAppRouting.View_Join_Symposium.url} element={<SecureRoute><RouteViewReqTraining /></SecureRoute>} />
        <Route path={objAppRouting.Mng_Suggested_Sponsor.url} element={<SecureRoute><MngSuggestedSponsor /></SecureRoute>} />
        <Route path={objAppRouting.Mng_Suggested_SponsorwithParm.url} element={<SecureRoute><MngSuggestedSponsor /></SecureRoute>} />

        <Route path={objAppRouting.ManageSystem.url} element={<SecureRoute><ManageSystem /></SecureRoute>} />
        <Route path={objAppRouting.AdvertisingVideoManagement.url} element={<SecureRoute><AdvertisingVideoManagement /></SecureRoute>} />
        <Route path={objAppRouting.manageMembers.url} element={<SecureRoute><RouteaMnageMembers /></SecureRoute>} />
        <Route path={objAppRouting.viewMember.url} element={<SecureRoute><RouteViewMember /></SecureRoute>} />
        <Route path={objAppRouting.ManageCategories.url} element={<SecureRoute><RouteMngCategory /></SecureRoute>} />
        <Route path={objAppRouting.profile.url} element={<SecureRoute><RouteProfile /></SecureRoute>} />
        <Route path={objAppRouting.CardMember.url} element={<SecureRoute><Advisor /></SecureRoute>} />
        <Route path={objAppRouting.MngAnnouncement.url} element={<SecureRoute><MngAnnouncement /></SecureRoute>} />
        <Route path={objAppRouting.MngMeeting.url} element={<SecureRoute><MngMeeting /></SecureRoute>} />

        <Route path={objAppRouting.ManageStatistic.url} element={<SecureRoute><Statistic /></SecureRoute>} />
        <Route path={objAppRouting.SponsorProfile.url} element={<SecureRoute><SponsorProfileInfo /></SecureRoute>} />

        <Route path={objAppRouting.Public_Initiative.url} element={<SecureRoute><Initiative /></SecureRoute>} />
        <Route path={objAppRouting.public_Training_Vedio.url} element={<SecureRoute><TrainingVideo /></SecureRoute>} />
        <Route path={objAppRouting.public_Vedioes.url} element={<SecureRoute><PublicVideoes /></SecureRoute>} />


      </Route>
    )
  );

  return <RouterProvider router={router} />;
}

export default Router;
