import React, { useEffect, useState } from "react";
import { AppBar, Button, GroupBar, ClientDirection, Typography, Nav, useLocation } from "@cbmisorg/client-app";
import logo from "../../../../../assets/images/logo.png";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import objAppRouting from "../../../../../appHelper/routing/appRouting";
import { App_Light_Color, App_Primary_Color } from "../../../../../appHelper/appColor";
import { getNextStrURL, scrollToElement } from "../../../../../appHelper/appFunctions";

function Header({ appState, appDispatch, navList = [], intRefreshCounter, closeDrawer }) {
  const [idSec, setIDSec] = useState("");

  const lang = appState?.clientInfo?.strLanguage;
  const location = useLocation();

  const handelChangeLang = (language) => {
    if (language !== lang) {
      ClientDirection.setDirection(lang === "arb" ? "rtl" : "ltr");
      appState.clientInfo.strLanguage = language;
      appState.clientInfo.strDataLanguage = language;
      appDispatch();
    }
  };

  const JXSButton = (
    <Button
      label={<Typography as="caption">{lang === "arb" ? "English" : "العربية"}</Typography>}
      size="sm"
      onClick={() => handelChangeLang(lang === "arb" ? "eng" : "arb")}
      mode="link"
      icon="translate"
      p-1
      sx={{ "&hover": { color: App_Primary_Color } }}
    />
  );

  const clamp = (value) => Math.max(0, value);

  const isBetween = (value, floor, ceil) => value >= floor && value <= ceil;
  const ids = ["aboutus", "aboutus2", "vision", "mission", "ourGoal", "association", "messageofSheikhMuhammad", "partners","achievements", "itc", "contactus", "events"];

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if ("pushState" in window.history && window.location.pathname === objAppRouting?.Public_LandingPage?.url && (window?.location?.hash || location?.state?.secID)) {
        window.history.pushState("", document.title, objAppRouting.Public_LandingPage.url);
      }
      const scroll = window.scrollY;
      const offset = 65;
      ids.forEach((id) => {
        const element = document.getElementById(id);
        if (element) {
          const rect = element.getBoundingClientRect();
          const top = clamp(rect.top + scroll - offset);
          const bottom = clamp(rect.bottom + scroll - offset);
          if (isBetween(scroll, top, bottom) && idSec !== `#${id}`) {
            setIDSec(`#${id}`);
          }
        }
      });
    });
  }, []);

  return (
    <React.Fragment>
      <AppBar
        justify={"start"}
        sideBarTitle={JXSButton}
        sideBarClose={false}
        sideBarPosition={lang === "arb" ? "right" : "left"}
        key={String(intRefreshCounter)}
        className="cbmis-app-bar-Limiter"
        dir={(lang === "arb" && Number(window.innerWidth) > 760) || (lang === "eng" && Number(window.innerWidth) < 760) ? "rtl" : "ltr"}
      >
        <GroupBar sxMode={"logo"} ssMode={"logo"}>
          <img src={logo} alt="" height={"55px"} style={{ maxWidth: "98%" }} />
        </GroupBar>
        <GroupBar sxMode="navbar" ssMode="none">
          <Nav px-2 label={<Typography>{dictionary?.components?.public?.footer?.account?.[lang]}</Typography>} path={getNextStrURL()} onClick={closeDrawer} />
          <Nav
            px-2
            label={<Typography>{dictionary.components?.public?.landingPage?.home?.aboutus?.title?.[lang]}</Typography>}
            menu={[
              <Nav
                className={idSec === "#aboutus" ? "primaryText" : ""}
                label={<Typography>{dictionary.components?.public?.landingPage?.home?.aboutus?.title?.[lang]}</Typography>}
                onClick={() => scrollToElement("#aboutus")}
              />,
              <Nav
                className={idSec === "#vision" || idSec === "#aboutus2" ? "primaryText" : ""}
                label={<Typography>{dictionary.components?.public?.landingPage?.home?.aboutus?.titleVision?.[lang]}</Typography>}
                onClick={() => scrollToElement("#aboutus2")}
              />,
              <Nav
                className={idSec === "#mission" ? "primaryText" : ""}
                label={<Typography>{dictionary.components?.public?.landingPage?.home?.aboutus?.titleMission?.[lang]}</Typography>}
                onClick={() => {scrollToElement("#aboutus2")}}
              />,
              <Nav
                className={idSec === "#association" ? "primaryText" : ""}
                label={<Typography>{dictionary.components?.public?.landingPage?.home?.association?.title?.[lang]}</Typography>}
                onClick={() => scrollToElement("#association")}
              />,
              <Nav
                className={idSec === "#ourGoal" ? "primaryText" : ""}
                label={<Typography>{dictionary.components?.public?.landingPage?.home?.ourGoal?.title?.[lang]}</Typography>}
                onClick={() => scrollToElement("#ourGoal")}
              />,
            ]}
          />
          <Nav
            className={idSec === "#messageofSheikhMuhammad" ? "primaryText" : ""}
            label={<Typography>{dictionary.components?.public?.footer?.presidentSpeech?.[lang]}</Typography>}
            onClick={() => scrollToElement("#messageofSheikhMuhammad")}
          />
          <Nav className={idSec === "#itc" ? "primaryText" : ""} label={<Typography>{dictionary.components?.public?.footer?.itc?.[lang]}</Typography>} onClick={() => scrollToElement("#itc")} />
          <Nav className={idSec === "#achievements" ? "primaryText" : ""} label={<Typography>{dictionary.components?.public?.footer?.achievements?.[lang]}</Typography>} onClick={() => scrollToElement("#achievements")} />
 
          <Nav
            className={idSec === "#partners" ? "primaryText" : ""}
            label={<Typography>{dictionary.components?.public?.footer?.partners?.[lang]}</Typography>}
            onClick={() => scrollToElement("#partners")}
          />
          <Nav
            className={idSec === "#events" ? "primaryText" : ""}
            px-2
            label={<Typography>{dictionary?.components?.public?.landingPage?.home?.events?.title?.[lang]}</Typography>}
            onClick={() => scrollToElement("#events")}
          />

          <Nav
            className={idSec === "#contactus" ? "primaryText" : ""}
            px-2
            label={<Typography>{dictionary?.components?.public?.landingPage?.home?.contactus?.title?.[lang]}</Typography>}
            onClick={() => scrollToElement("#contactus")}
          />
          <Nav px-2 label={<Typography>{dictionary?.components?.public?.landingPage?.members?.title?.[lang]}</Typography>} path={objAppRouting.Public_Team.url} />
          <Nav px-2 label={<Typography>{dictionary?.components?.public?.footer?.settings?.[lang]}</Typography>} path={objAppRouting.Public_Settings.url} />
        </GroupBar>

        <GroupBar sxMode="navbar" ssMode="none" position="end">
          {JXSButton}
        </GroupBar>

        <GroupBar sxMode="none" ssMode="sidebar" sx={{ color: App_Light_Color }}>
          <Nav
            className={idSec === "#aboutus" ? "primaryText" : ""}
            icon="AccountBalance"
            label={<Typography>{dictionary.components?.public?.landingPage?.home?.aboutus?.title?.[lang]}</Typography>}
            onClick={() => {
              scrollToElement("#aboutus");
              closeDrawer();
            }}
          />
          <Nav
            className={idSec === "#vision" || idSec === "#aboutus2" ? "primaryText" : ""}
            icon="RemoveRedEye"
            label={<Typography>{dictionary.components?.public?.landingPage?.home?.aboutus?.titleVision?.[lang]}</Typography>}
            onClick={() => {
              scrollToElement("#aboutus2");
              closeDrawer();
            }}
          />
          <Nav
            className={idSec === "#mission" ? "primaryText" : ""}
            icon="sms"
            label={<Typography>{dictionary.components?.public?.landingPage?.home?.aboutus?.titleMission?.[lang]}</Typography>}
            onClick={() => {
              scrollToElement("#aboutus2");
              closeDrawer();
            }}
          />
          <Nav
            className={idSec === "#association" ? "primaryText" : ""}
            icon="LibraryBooks"
            label={<Typography>{dictionary.components?.public?.landingPage?.home?.association?.title?.[lang]}</Typography>}
            onClick={() => {
              scrollToElement("#association");
              closeDrawer();
            }}
          />
          <Nav
            className={idSec === "#ourGoal" ? "primaryText" : ""}
            icon="Beenhere"
            label={<Typography>{dictionary.components?.public?.landingPage?.home?.ourGoal?.title?.[lang]}</Typography>}
            onClick={() => {
              scrollToElement("#ourGoal");
              closeDrawer();
            }}
          />
          <Nav
            className={idSec === "#messageofSheikhMuhammad" ? "primaryText" : ""}
            icon="work"
            label={<Typography>{dictionary.components?.public?.footer?.presidentSpeech?.[lang]}</Typography>}
            onClick={() => {
              scrollToElement("#messageofSheikhMuhammad");
              closeDrawer();
            }}
          />

          <Nav
            icon="ModelTraining"
            className={idSec === "#itc" ? "primaryText" : ""}
            label={<Typography>{dictionary.components?.public?.footer?.itc?.[lang]}</Typography>}
            onClick={() => {
              scrollToElement("#itc");
              closeDrawer();
            }}
          />
          <Nav
            icon="ModelTraining"
            className={idSec === "#achievements" ? "primaryText" : ""}
            label={<Typography>{dictionary.components?.public?.footer?.achievements?.[lang]}</Typography>}
            onClick={() => {
              scrollToElement("#achievements");
              closeDrawer();
            }}
          />
          <Nav
            icon="People"
            className={idSec === "#partners" ? "primaryText" : ""}
            label={<Typography>{dictionary.components?.public?.footer?.partners?.[lang]}</Typography>}
            onClick={() => {
              scrollToElement("#partners");
              closeDrawer();
            }}
          />

          <Nav
            className={idSec === "#contactus" ? "primaryText" : ""}
            icon="ContactMail"
            label={<Typography>{dictionary?.components?.public?.landingPage?.home?.contactus?.title?.[lang]}</Typography>}
            onClick={() => {
              scrollToElement("#contactus");
              closeDrawer();
            }}
          />
        </GroupBar>

        <GroupBar sxMode="none">
          <Nav ssMode="bottombar" icon="accountCircle" label={<>{dictionary?.components?.public?.footer?.account?.[lang]}</>} path={getNextStrURL()} onClick={closeDrawer} />
          <Nav ssMode="bottombar" icon="event" label={<>{dictionary?.components?.public?.landingPage?.home?.events?.title?.[lang]}</>} path={objAppRouting.Public_Events.url} onClick={closeDrawer} />
          <Nav ssMode="bottombar" icon="home" label={<>{dictionary?.components?.public?.footer?.home?.[lang]}</>} path={objAppRouting.Public_LandingPage.url} onClick={closeDrawer} />
          <Nav ssMode="bottombar" icon="people" label={<>{dictionary?.components?.public?.landingPage?.members?.title?.[lang]}</>} path={objAppRouting.Public_Team.url} onClick={closeDrawer} />
          <Nav ssMode="bottombar" icon="settings" label={<>{dictionary?.components?.public?.footer?.settings?.[lang]}</>} path={objAppRouting.Public_Settings.url} onClick={closeDrawer} />
        </GroupBar>
      </AppBar>
    </React.Fragment>
  );
}

export default Header;
