import { Container, Grid, Typography, Button, Paper } from "@cbmisorg/client-app";
import React, { useEffect, useState } from "react";
import * as appColor from "../../../../../appHelper/appColor";

import { FormData, FormField } from "../../../../../appHelper/formApi/FormAPI";
import { appStyle } from "../../../../../appHelper/appStyle";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import { CtrlLandingPage } from "../../controller/CrtlLandingPage";
import { nationalityID, objContactReasonOptions } from "../../../../../appHelper/appVariables";

const classes = {
  background: {
    boxShadow: "none",
    backgroundColor: appColor.App_Second_Color,
    "&before": {
      content: "",
      position: "absolute",
      width: "100%",
      height: "100%",
      display: "block",
      background: appColor.App_Second_Color,
      backgroundImage: `url(${require("../../../../../assets/images/bg-footer.png")})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "bottom center",
      opacity: "0.4",
      mixBlendMode: "overlay",
      transition: "background 0.3s, border-radius 0.3s, opacity 0.3s",
    },
  },
  button: {
    background: appColor.App_Primary_Color,
    fontWeight: 800,
    width: "100%",
  },
  title: {
    textAlign: "center !important",
    position: "absolute",
    top: "-18px",
    left: "50%",
    background: appColor?.App_Beige_Color4,
    color: appColor.App_Primary_Color,
    transform: "translateX(-50%)",
    whiteSpace: "nowrap",
  },
};

function ContactUs({ appState = {}, lang, blnFromSponser = false, submitClicked }) {
  const labels = dictionary?.components?.public?.landingPage?.home?.contactus;
  const [isLoading, setIsLoading] = useState(false);

  const fields = FormData({
    strFullName: {
      strControl: "text",
      objValidations: { required: true },
      objLanguage: false,
    },
    bigNationality: {
      strControl: "list",
      objValidations: { required: true },
      objLanguage: false,
      lstOption: [
        { key: nationalityID?.kuwaiti, value: dictionary?.shared?.account?.country?.kwt?.[lang] },
        { key: nationalityID?.notKuwaiti, value: dictionary?.shared?.account?.country?.notKwt?.[lang] },
      ],
    },
    strEmail: {
      strControl: "text",
      objValidations: { required: true, email: true },
      objLanguage: false,
    },
    bigSubjectID: {
      strControl: "list",
      objValidations: { required: true },
      objLanguage: false,
      lstOption: [
        { key: objContactReasonOptions?.support, value: { arb: "دعم", eng: "Support" }?.[lang] },
        { key: objContactReasonOptions?.inquiry, value: { arb: "استفسار", eng: "Inquiry" }?.[lang] },
        { key: objContactReasonOptions?.complaint, value: { arb: "شكوى", eng: "Complaint" }?.[lang] },
        { key: objContactReasonOptions?.improvement, value: { arb: "تحسين", eng: "Improvement" }?.[lang] },
        { key: objContactReasonOptions?.other, value: { arb: "أخرى", eng: "Other" }?.[lang] },
      ],
    },
    strMessage: {
      strControl: "textarea",
      objValidations: { required: true },
      objLanguage: false,
    },
  });
  const handlers = CtrlLandingPage(appState, isLoading, setIsLoading);

  useEffect(() => {
    if (blnFromSponser) {
      fields?.bigSubjectID?.setValue(objContactReasonOptions?.support);
      fields?.strFullName?.setValue(appState?.userInfo?.jsnUserInfo?.strFullName);
      fields?.bigNationality?.setValue(nationalityID?.kuwaiti);
      fields?.strEmail?.setValue(appState?.userInfo?.strUserEmail);
    }
  }, [blnFromSponser]);

  function handleSubmit() {
    handlers.addMail(fields, submitClicked);
    
  }

  return (
    <React.Fragment>
      <Grid container sx={blnFromSponser ? null : { ...appStyle?.container, ...classes?.background, padding: "50px auto !important" }} py-0 my-0 id="contactus" px-0 spacing={0}>
        <Grid item xs="12" container justify="center">
          <Container sx={blnFromSponser ? null : { margin: "50px auto !important" }}>
            <Grid container spacing={2} justify="center">
              <Grid item xs="12" lg={blnFromSponser ? "12" : "8"}>
                <Paper elevation={blnFromSponser?0:""} className={blnFromSponser ? "" : "cardContainer"}>
                  <Grid container justify="center">
                    {blnFromSponser ? null : (
                      <Grid item xs="12">
                        <Typography as="h4" color="primary" className="fontHand" px-3 py-2>
                          {labels?.title?.[lang]}
                        </Typography>
                      </Grid>
                    )}

                    <Grid item md={"12"} xs={"12"} py-2>
                      <FormField objHandler={fields.strFullName} icon="accountCircle" iconColor={appColor.App_Primary_Color} dir={lang === "arb" ? "rtl" : "ltr"} />
                    </Grid>
                    <Grid item md={"12"} xs={"12"} py-2>
                      <FormField objHandler={fields.bigNationality} icon="public" iconColor={appColor.App_Primary_Color} dir={lang === "arb" ? "rtl" : "ltr"} />
                    </Grid>
                    <Grid item md={"12"} xs={"12"} py-2>
                      <FormField objHandler={fields.strEmail} icon="email" iconColor={appColor.App_Primary_Color} dir={lang === "arb" ? "rtl" : "ltr"} className={`inputLeft inputLeft${lang}`} />
                    </Grid>
                    {blnFromSponser ? null : (
                      <Grid item md={"12"} xs={"12"} py-2>
                        <FormField objHandler={fields.bigSubjectID} iconColor={appColor.App_Primary_Color} dir={lang === "arb" ? "rtl" : "ltr"} />
                      </Grid>
                    )}
                    <Grid item md={"12"} xs={"12"} py-2>
                      <FormField objHandler={fields.strMessage} iconColor={appColor.App_Primary_Color} dir={lang === "arb" ? "rtl" : "ltr"} />
                    </Grid>
                    <Grid item xs={"12"} py-2>
                      <Button label={<Typography as="subtitle1">{labels?.button?.[lang]}</Typography>} py-0 sx={classes.button} onClick={handleSubmit} />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ContactUs;
