import * as indexDBDatabase from "./IndexedDBApi/DatabaseIDB";
import * as indexDBFile from "./IndexedDBApi/FileIDB";
import * as IconGenerate from "./fileIconApi/IconGenerate";
import FetchIcon from "./fileIconApi/ShowIcon";

class PkgConfig {
  constructor(blnIsInTestMode = true, lstPartitionName = ["tblPartition"]) {
    this.blnIsInTestMode = blnIsInTestMode;
    this.lstPartitionName = lstPartitionName;
  }
}

export var pkgConfig = new PkgConfig();

export function updatePkgConfig({ blnIsInTestMode = true, lstPartitionName = ["tblPartition"] }) {
  pkgConfig.blnIsInTestMode = blnIsInTestMode;
  pkgConfig.lstPartitionName = !lstPartitionName || !Array.isArray(lstPartitionName) ? ["tblPartition"] : lstPartitionName;
}

export const DatabaseIDB = indexDBDatabase.DatabaseIDB(pkgConfig);

export const FileIDB = {
  fi: indexDBFile.fi(pkgConfig),
  path: indexDBFile.utilPackage(pkgConfig),
};

export const iconGenerate = IconGenerate;

export const ShowIcon = FetchIcon;
