/**
 *
 * @param {{blnIsInTestMode}} PkgConfig
 */
export function DatabaseIDB(PkgConfig) {
  /**
   *
   * @param {string} dbName
   * @param {number} dbVersion
   * @param {{strTableName:string,
   * strPrimaryKeyName:string,
   * blnIsPrimaryKeyAutoIncrement: boolean,
   * funOnCreateDB?:(idbObjectStore: IDBObjectStore) => void,
   * foreignKey?:{name:string,
   *              sourceKey:string | string[],
   *              options?:{multiEntry:boolean, unique:boolean}} |
   *             {name:string, sourceKey:string | string[],
   *              options?:{multiEntry:boolean, unique:boolean}}[]
   * }[]} lstTables
   * @returns {Promise<IDBDatabase>}
   */
  function connectDB(dbName, dbVersion, lstTables) {
    return new Promise((resolve, reject) => {
      try {
        // In the following line, you should include the prefixes of implementations you want to test.

        /**
         * @type {IDBFactory}
         */
        let indexedDB = null;
        if (window.indexedDB) {
          indexedDB = window.indexedDB;
        } else if (window.mozIndexedDB) {
          indexedDB = window.mozIndexedDB;
        } else if (window.webkitIndexedDB) {
          indexedDB = window.webkitIndexedDB;
        } else if (window.msIndexedDB) {
          indexedDB = window.msIndexedDB;
        }

        // Moreover, you may need references to some window.IDB* objects:
        window.IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.msIDBTransaction || { READ_WRITE: "readwrite" }; // This line should only be needed if it is needed to support the object's constants for older browsers
        window.IDBKeyRange = window.IDBKeyRange || window.webkitIDBKeyRange || window.msIDBKeyRange;
        // (Mozilla has never prefixed these objects, so we don't need window.mozIDB*)

        if (!indexedDB) {
          throw new Error(`This browser doesn't support IndexedDB`);
        }

        /**
         * @type {IDBDatabase}
         */
        let connection = null;
        let openDbRequest = indexedDB.open(dbName, dbVersion);

        // Fires when the version of the database goes up, or the database is created for the first time.
        openDbRequest.onupgradeneeded = (event) => {
          connection = openDbRequest.result;

          // Create an object store named notes, or retrieve it if it already exists.
          // Object stores in databases are where data are stored.
          for (let i = 0; i < lstTables.length; i++) {
            if (!connection.objectStoreNames.contains(lstTables[i].strTableName)) {
              let idbObjectStore = connection.createObjectStore(lstTables[i].strTableName, {
                keyPath: lstTables[i].strPrimaryKeyName,
                autoIncrement: lstTables[i].blnIsPrimaryKeyAutoIncrement,
              });
              if (lstTables[i].funOnCreateDB) {
                lstTables[i].funOnCreateDB(idbObjectStore);
              }
              if (lstTables[i].foreignKey) {
                if (Array.isArray(lstTables[i].foreignKey)) {
                  for (let j = 0; j < lstTables[i].foreignKey.length; j++) {
                    let options = {};
                    if (lstTables[i].foreignKey[j].options) {
                      options = lstTables[i].foreignKey[j].options;
                    }
                    idbObjectStore.createIndex(lstTables[i].foreignKey[j].name, lstTables[i].foreignKey[j].sourceKey, options);
                  }
                } else {
                  let options = {};
                  if (lstTables[i].foreignKey.options) {
                    options = lstTables[i].foreignKey.options;
                  }
                  idbObjectStore.createIndex(lstTables[i].foreignKey.name, lstTables[i].foreignKey.sourceKey, options);
                }
              }
            }
          }
        };

        // Fires once the database is opened (and onupgradeneeded completes, if onupgradeneeded was called)
        openDbRequest.onsuccess = (event) => {
          // Set the db variable to our database so we can use it!
          connection = openDbRequest.result;
          resolve(connection);
        };

        // Fires when we can't open the database
        openDbRequest.onerror = (event) => {
          reject(new Error("error opening database: \n" + openDbRequest.error));
        };
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   *
   * @param {IDBDatabase} dbConnection
   * @param {string} strTableName
   * @param {object} objInput
   * @returns {Promise<boolean>}
   */
  function create(dbConnection, strTableName, objInput) {
    return new Promise((resolve, reject) => {
      try {
        if (!dbConnection.objectStoreNames.contains(strTableName)) {
          throw new Error(`Schema ${dbConnection.name} already created and does not contain table ${strTableName}, Please change dbVersion!!`);
        }
        // request a transaction for object store
        let dbTransaction = dbConnection.transaction([strTableName], "readwrite");
        let tableResolver = dbTransaction.objectStore(strTableName);
        let transAdd = tableResolver.add(objInput);
        let result = false;

        transAdd.onsuccess = (event) => {
          result = true;
        };

        transAdd.onerror = (event) => {
          if (PkgConfig.blnIsInTestMode) {
            console.warn(`Error ${dbConnection.name} - ${transAdd.error}`);
          }
          resolve(false);
        };

        dbTransaction.oncomplete = (event) => {
          resolve(result);
        };

        dbTransaction.onerror = (event) => {
          if (PkgConfig.blnIsInTestMode) {
            console.warn(`Error ${dbConnection.name} - ${dbTransaction.error}`);
          }
          resolve(false);
        };
      } catch (error) {
        if (PkgConfig.blnIsInTestMode) {
          console.error("*****ERROR*****", error);
        }
        resolve(false);
      }
    });
  }

  /**
   *
   * @param {IDBDatabase} dbConnection
   * @param {string} strTableName
   * @param {object} objInput
   * @param {any} wherePrimaryKey
   * @returns {Promise<boolean>}
   */
  function update(dbConnection, strTableName, objInput, wherePrimaryKey) {
    return new Promise((resolve, reject) => {
      try {
        if (!dbConnection.objectStoreNames.contains(strTableName)) {
          throw new Error(`Schema ${dbConnection.name} already created and does not contain table ${strTableName}, Please change dbVersion!!`);
        }
        // request a transaction for object store
        let dbTransaction = dbConnection.transaction([strTableName], "readwrite");
        let tableResolver = dbTransaction.objectStore(strTableName);
        let transGet = tableResolver.get(wherePrimaryKey);
        let result = false;

        transGet.onsuccess = (event) => {
          let data = transGet.result;

          if (!data) {
            if (PkgConfig.blnIsInTestMode) {
              console.warn(`Cannot find records!`);
            }
            result = false;
          } else {
            Array.from(Object.keys(objInput)).forEach((i) => {
              data[i] = objInput[i];
            });

            let transUpdate = tableResolver.put(data);

            transUpdate.onsuccess = (event) => {
              result = true;
            };

            transUpdate.onerror = (event) => {
              if (PkgConfig.blnIsInTestMode) {
                console.warn(`Error ${dbConnection.name} - ${transUpdate.error}`);
              }
              resolve(false);
            };
          }
        };

        transGet.onerror = (event) => {
          if (PkgConfig.blnIsInTestMode) {
            console.warn(`Error ${dbConnection.name} - ${transGet.error}`);
          }
          resolve(false);
        };

        dbTransaction.oncomplete = (event) => {
          resolve(result);
        };

        dbTransaction.onerror = (event) => {
          if (PkgConfig.blnIsInTestMode) {
            console.warn(`Error ${dbConnection.name} - ${dbTransaction.error}`);
          }
          resolve(false);
        };
      } catch (error) {
        if (PkgConfig.blnIsInTestMode) {
          console.error("*****ERROR*****", error);
        }
        resolve(false);
      }
    });
  }

  /**
   *
   * @param {IDBDatabase} dbConnection
   * @param {string} strTableName
   * @param {any} wherePrimaryKey
   * @param {string} [index]
   * @returns {Promise<false | object>}
   */
  function findOne(dbConnection, strTableName, wherePrimaryKey, index) {
    return new Promise((resolve, reject) => {
      try {
        if (!dbConnection.objectStoreNames.contains(strTableName)) {
          throw new Error(`Schema ${dbConnection.name} already created and does not contain table ${strTableName}, Please change dbVersion!!`);
        }
        // request a transaction for object store
        let dbTransaction = dbConnection.transaction([strTableName], "readonly");
        let tableResolver = dbTransaction.objectStore(strTableName);
        let transGet = null;
        if (index) {
          let tableIndex = tableResolver.index(index);
          transGet = tableIndex.get(wherePrimaryKey);
        } else {
          transGet = tableResolver.get(wherePrimaryKey);
        }
        let result = false;

        transGet.onsuccess = (event) => {
          let data = transGet.result;
          if (!data) {
            if (PkgConfig.blnIsInTestMode) {
              console.warn(`Cannot find records!`);
            }
            result = false;
          } else {
            result = data;
          }
        };

        transGet.onerror = (event) => {
          if (PkgConfig.blnIsInTestMode) {
            console.warn(`Error ${dbConnection.name} - ${transGet.error}`);
          }
          resolve(false);
        };

        dbTransaction.oncomplete = (event) => {
          resolve(result);
        };

        dbTransaction.onerror = (event) => {
          if (PkgConfig.blnIsInTestMode) {
            console.warn(`Error ${dbConnection.name} - ${dbTransaction.error}`);
          }
          resolve(false);
        };
      } catch (error) {
        if (PkgConfig.blnIsInTestMode) {
          console.error("*****ERROR*****", error);
        }
        resolve(false);
      }
    });
  }

  /**
   *
   * @param {IDBDatabase} dbConnection
   * @param {string} strTableName
   * @param {any} wherePrimaryKey
   * @param {string} [index]
   * @returns {Promise<false | object>}
   */
  function findOneKey(dbConnection, strTableName, wherePrimaryKey, index) {
    return new Promise((resolve, reject) => {
      try {
        if (!dbConnection.objectStoreNames.contains(strTableName)) {
          throw new Error(`Schema ${dbConnection.name} already created and does not contain table ${strTableName}, Please change dbVersion!!`);
        }
        // request a transaction for object store
        let dbTransaction = dbConnection.transaction([strTableName], "readonly");
        let tableResolver = dbTransaction.objectStore(strTableName);
        let transGet = null;
        if (index) {
          let tableIndex = tableResolver.index(index);
          transGet = tableIndex.getKey(wherePrimaryKey);
        } else {
          transGet = tableResolver.getKey(wherePrimaryKey);
        }
        let result = false;

        transGet.onsuccess = (event) => {
          let data = transGet.result;
          if (!data) {
            if (PkgConfig.blnIsInTestMode) {
              console.warn(`Cannot find records!`);
            }
            result = false;
          } else {
            result = data;
          }
        };

        transGet.onerror = (event) => {
          if (PkgConfig.blnIsInTestMode) {
            console.warn(`Error ${dbConnection.name} - ${transGet.error}`);
          }
          resolve(false);
        };

        dbTransaction.oncomplete = (event) => {
          resolve(result);
        };

        dbTransaction.onerror = (event) => {
          if (PkgConfig.blnIsInTestMode) {
            console.warn(`Error ${dbConnection.name} - ${dbTransaction.error}`);
          }
          resolve(false);
        };
      } catch (error) {
        if (PkgConfig.blnIsInTestMode) {
          console.error("*****ERROR*****", error);
        }
        resolve(false);
      }
    });
  }

  /**
   *
   * @param {IDBDatabase} dbConnection
   * @param {string} strTableName
   * @param {any} [wherePrimaryKey] set it null if you want to get all records;
   * @param {string} [index]
   * @returns {Promise<false | any[]>}
   */
  function findAll(dbConnection, strTableName, wherePrimaryKey = null, index) {
    return new Promise((resolve, reject) => {
      try {
        if (!dbConnection.objectStoreNames.contains(strTableName)) {
          throw new Error(`Schema ${dbConnection.name} already created and does not contain table ${strTableName}, Please change dbVersion!!`);
        }
        // request a transaction for object store
        let dbTransaction = dbConnection.transaction([strTableName], "readonly");
        let tableResolver = dbTransaction.objectStore(strTableName);
        let transGet = null;
        if (index) {
          let tableIndex = tableResolver.index(index);
          transGet = tableIndex.getAll(wherePrimaryKey);
        } else {
          transGet = tableResolver.getAll(wherePrimaryKey);
        }
        let result = [];

        transGet.onsuccess = (event) => {
          let lstData = transGet.result;
          if (!lstData || !Array.isArray(lstData) || lstData.length < 1) {
            if (PkgConfig.blnIsInTestMode) {
              console.warn(`Cannot find records!`);
            }
            result = [];
          } else {
            result = lstData;
          }
        };

        transGet.onerror = (event) => {
          if (PkgConfig.blnIsInTestMode) {
            console.warn(`Error ${dbConnection.name} - ${transGet.error}`);
          }
          resolve(false);
        };

        dbTransaction.oncomplete = (event) => {
          resolve(result);
        };

        dbTransaction.onerror = (event) => {
          if (PkgConfig.blnIsInTestMode) {
            console.warn(`Error ${dbConnection.name} - ${dbTransaction.error}`);
          }
          resolve(false);
        };
      } catch (error) {
        if (PkgConfig.blnIsInTestMode) {
          console.error("*****ERROR*****", error);
        }
        resolve(false);
      }
    });
  }

  /**
   *
   * @param {IDBDatabase} dbConnection
   * @param {string} strTableName
   * @param {any} [wherePrimaryKey] set it null if you want to get all records;
   * @param {number} [count] set it null if you want to get all records;
   * @param {string} [index]
   * @returns {Promise<false | any[]>}
   */
  function findAllByCount(dbConnection, strTableName, wherePrimaryKey = null, count = null, index) {
    return new Promise((resolve, reject) => {
      try {
        if (!dbConnection.objectStoreNames.contains(strTableName)) {
          throw new Error(`Schema ${dbConnection.name} already created and does not contain table ${strTableName}, Please change dbVersion!!`);
        }
        // request a transaction for object store
        let dbTransaction = dbConnection.transaction([strTableName], "readonly");
        let tableResolver = dbTransaction.objectStore(strTableName);
        let transGet = null;
        if (index) {
          let tableIndex = tableResolver.index(index);
          transGet = tableIndex.getAll(wherePrimaryKey, count);
        } else {
          transGet = tableResolver.getAll(wherePrimaryKey, count);
        }
        let result = [];

        transGet.onsuccess = (event) => {
          let lstData = transGet.result;
          if (!lstData || !Array.isArray(lstData) || lstData.length < 1) {
            if (PkgConfig.blnIsInTestMode) {
              console.warn(`Cannot find records!`);
            }
            result = [];
          } else {
            result = lstData;
          }
        };

        transGet.onerror = (event) => {
          if (PkgConfig.blnIsInTestMode) {
            console.warn(`Error ${dbConnection.name} - ${transGet.error}`);
          }
          resolve(false);
        };

        dbTransaction.oncomplete = (event) => {
          resolve(result);
        };

        dbTransaction.onerror = (event) => {
          if (PkgConfig.blnIsInTestMode) {
            console.warn(`Error ${dbConnection.name} - ${dbTransaction.error}`);
          }
          resolve(false);
        };
      } catch (error) {
        if (PkgConfig.blnIsInTestMode) {
          console.error("*****ERROR*****", error);
        }
        resolve(false);
      }
    });
  }

  /**
   *
   * @param {IDBDatabase} dbConnection
   * @param {string} strTableName
   * @param {any} [wherePrimaryKey] set it null if you want to get all records;
   * @param {number} [count] set it null if you want to get all records;
   * @param {string} [index]
   * @returns {Promise<false | any[]>}
   */
  function findAllKeys(dbConnection, strTableName, wherePrimaryKey = null, count = null, index) {
    return new Promise((resolve, reject) => {
      try {
        if (!dbConnection.objectStoreNames.contains(strTableName)) {
          throw new Error(`Schema ${dbConnection.name} already created and does not contain table ${strTableName}, Please change dbVersion!!`);
        }
        // request a transaction for object store
        let dbTransaction = dbConnection.transaction([strTableName], "readonly");
        let tableResolver = dbTransaction.objectStore(strTableName);
        let transGet = null;
        if (index) {
          let tableIndex = tableResolver.index(index);
          transGet = tableIndex.getAllKeys(wherePrimaryKey, count);
        } else {
          transGet = tableResolver.getAllKeys(wherePrimaryKey, count);
        }
        let result = [];

        transGet.onsuccess = (event) => {
          let lstData = transGet.result;
          if (!lstData || !Array.isArray(lstData) || lstData.length < 1) {
            if (PkgConfig.blnIsInTestMode) {
              console.warn(`Cannot find records!`);
            }
            result = [];
          } else {
            result = lstData;
          }
        };

        transGet.onerror = (event) => {
          if (PkgConfig.blnIsInTestMode) {
            console.warn(`Error ${dbConnection.name} - ${transGet.error}`);
          }
          resolve(false);
        };

        dbTransaction.oncomplete = (event) => {
          resolve(result);
        };

        dbTransaction.onerror = (event) => {
          if (PkgConfig.blnIsInTestMode) {
            console.warn(`Error ${dbConnection.name} - ${dbTransaction.error}`);
          }
          resolve(false);
        };
      } catch (error) {
        if (PkgConfig.blnIsInTestMode) {
          console.error("*****ERROR*****", error);
        }
        resolve(false);
      }
    });
  }

  /**
   *
   * @param {IDBDatabase} dbConnection
   * @param {string} strTableName
   * @param {(primaryKey, key, value) => void} callbackFunction
   * @param {string} [index]
   * @returns {Promise<boolean | void>}
   */
  function cursor(dbConnection, strTableName, callbackFunction, index) {
    return new Promise((resolve, reject) => {
      try {
        if (!dbConnection.objectStoreNames.contains(strTableName)) {
          throw new Error(`Schema ${dbConnection.name} already created and does not contain table ${strTableName}, Please change dbVersion!!`);
        }
        // request a transaction for object store
        let dbTransaction = dbConnection.transaction([strTableName], "readonly");
        let tableResolver = dbTransaction.objectStore(strTableName);
        /**
         * @type {IDBRequest<IDBCursorWithValue>}
         */
        let transCursor = null;
        if (index) {
          let tableIndex = tableResolver.index(index);
          transCursor = tableIndex.openCursor();
        } else {
          transCursor = tableResolver.openCursor();
        }

        transCursor.onsuccess = (event) => {
          let cursor = transCursor.result;
          if (cursor) {
            let primaryKey = cursor.primaryKey; // next object store key (id field)
            let key = cursor.key; // next index key (current key name of the object)
            let value = cursor.value; // next object store object (current value of the object)
            callbackFunction(primaryKey, key, value);

            cursor.continue();
          } else {
            if (PkgConfig.blnIsInTestMode) {
              console.log("No more record");
            }
          }
        };

        transCursor.onerror = (event) => {
          if (PkgConfig.blnIsInTestMode) {
            console.warn(`Error ${dbConnection.name} - ${transCursor.error}`);
          }
          resolve(false);
        };

        dbTransaction.oncomplete = (event) => {
          resolve(true);
        };

        dbTransaction.onerror = (event) => {
          if (PkgConfig.blnIsInTestMode) {
            console.warn(`Error ${dbConnection.name} - ${dbTransaction.error}`);
          }
          resolve(false);
        };
      } catch (error) {
        if (PkgConfig.blnIsInTestMode) {
          console.error("*****ERROR*****", error);
        }
        resolve(false);
      }
    });
  }

  /**
   *
   * @param {IDBDatabase} dbConnection
   * @param {string} strTableName
   * @param {(primaryKey, key) => void} callbackFunction
   * @param {string} [index]
   * @returns {Promise<boolean | void>}
   */
  function cursorKey(dbConnection, strTableName, callbackFunction, index) {
    return new Promise((resolve, reject) => {
      try {
        if (!dbConnection.objectStoreNames.contains(strTableName)) {
          throw new Error(`Schema ${dbConnection.name} already created and does not contain table ${strTableName}, Please change dbVersion!!`);
        }
        // request a transaction for object store
        let dbTransaction = dbConnection.transaction([strTableName], "readonly");
        let tableResolver = dbTransaction.objectStore(strTableName);
        /**
         * @type {IDBRequest<IDBCursor>}
         */
        let transCursor = null;
        if (index) {
          let tableIndex = tableResolver.index(index);
          transCursor = tableIndex.openKeyCursor();
        } else {
          transCursor = tableResolver.openKeyCursor();
        }

        transCursor.onsuccess = (event) => {
          let cursor = transCursor.result;
          if (cursor) {
            let primaryKey = cursor.primaryKey; // next object store key (id field)
            let key = cursor.key; // next index key (current key name of the object)
            callbackFunction(primaryKey, key);

            cursor.continue();
          } else {
            if (PkgConfig.blnIsInTestMode) {
              console.log("No more record");
            }
          }
        };

        transCursor.onerror = (event) => {
          if (PkgConfig.blnIsInTestMode) {
            console.warn(`Error ${dbConnection.name} - ${transCursor.error}`);
          }
          resolve(false);
        };

        dbTransaction.oncomplete = (event) => {
          resolve(true);
        };

        dbTransaction.onerror = (event) => {
          if (PkgConfig.blnIsInTestMode) {
            console.warn(`Error ${dbConnection.name} - ${dbTransaction.error}`);
          }
          resolve(false);
        };
      } catch (error) {
        if (PkgConfig.blnIsInTestMode) {
          console.error("*****ERROR*****", error);
        }
        resolve(false);
      }
    });
  }

  /**
   *
   * @param {IDBDatabase} dbConnection
   * @param {string} strTableName
   * @param {any} [wherePrimaryKey]
   * @returns {Promise<boolean>}
   */
  function destroy(dbConnection, strTableName, wherePrimaryKey) {
    return new Promise((resolve, reject) => {
      try {
        if (!dbConnection.objectStoreNames.contains(strTableName)) {
          throw new Error(`Schema ${dbConnection.name} already created and does not contain table ${strTableName}, Please change dbVersion!!`);
        }
        // request a transaction for object store
        let dbTransaction = dbConnection.transaction([strTableName], "readwrite");
        let tableResolver = dbTransaction.objectStore(strTableName);
        let transDel = tableResolver.delete(wherePrimaryKey);
        let result = false;

        transDel.onsuccess = (event) => {
          result = true;
        };

        transDel.onerror = (event) => {
          if (PkgConfig.blnIsInTestMode) {
            console.warn(`Error ${dbConnection.name} - ${transDel.error}`);
          }
          resolve(false);
        };

        dbTransaction.oncomplete = (event) => {
          resolve(result);
        };

        dbTransaction.onerror = (event) => {
          if (PkgConfig.blnIsInTestMode) {
            console.warn(`Error ${dbConnection.name} - ${dbTransaction.error}`);
          }
          resolve(false);
        };
      } catch (error) {
        if (PkgConfig.blnIsInTestMode) {
          console.error("*****ERROR*****", error);
        }
        resolve(false);
      }
    });
  }

  /**
   *
   * @param {IDBDatabase} dbConnection
   * @param {string} strTableName
   * @returns {Promise<boolean>}
   */
  function clear(dbConnection, strTableName) {
    return new Promise((resolve, reject) => {
      try {
        if (!dbConnection.objectStoreNames.contains(strTableName)) {
          throw new Error(`Schema ${dbConnection.name} already created and does not contain table ${strTableName}, Please change dbVersion!!`);
        }
        // request a transaction for object store
        let dbTransaction = dbConnection.transaction([strTableName], "readwrite");
        let tableResolver = dbTransaction.objectStore(strTableName);
        let transDel = tableResolver.clear();
        let result = false;

        transDel.onsuccess = (event) => {
          result = true;
        };

        transDel.onerror = (event) => {
          if (PkgConfig.blnIsInTestMode) {
            console.warn(`Error ${dbConnection.name} - ${transDel.error}`);
          }
          resolve(false);
        };

        dbTransaction.oncomplete = (event) => {
          resolve(result);
        };

        dbTransaction.onerror = (event) => {
          if (PkgConfig.blnIsInTestMode) {
            console.warn(`Error ${dbConnection.name} - ${dbTransaction.error}`);
          }
          resolve(false);
        };
      } catch (error) {
        if (PkgConfig.blnIsInTestMode) {
          console.error("*****ERROR*****", error);
        }
        resolve(false);
      }
    });
  }

  /**
   *
   * @param {IDBDatabase} dbConnection
   * @param {string} strTableName
   * @param {any} wherePrimaryKey
   * @param {string} [index]
   * @returns {Promise<false | number>}
   */
  function count(dbConnection, strTableName, wherePrimaryKey, index) {
    return new Promise((resolve, reject) => {
      try {
        if (!dbConnection.objectStoreNames.contains(strTableName)) {
          throw new Error(`Schema ${dbConnection.name} already created and does not contain table ${strTableName}, Please change dbVersion!!`);
        }
        // request a transaction for object store
        let dbTransaction = dbConnection.transaction([strTableName], "readonly");
        let tableResolver = dbTransaction.objectStore(strTableName);
        let transGet = null;
        if (index) {
          let tableIndex = tableResolver.index(index);
          transGet = tableIndex.count(wherePrimaryKey);
        } else {
          transGet = tableResolver.count(wherePrimaryKey);
        }
        let result = null;

        transGet.onsuccess = (event) => {
          let data = transGet.result;
          if (!data) {
            if (PkgConfig.blnIsInTestMode) {
              console.warn(`Cannot find records!`);
            }
            result = false;
          } else {
            result = data;
          }
        };

        transGet.onerror = (event) => {
          if (PkgConfig.blnIsInTestMode) {
            console.warn(`Error ${dbConnection.name} - ${transGet.error}`);
          }
          resolve(false);
        };

        dbTransaction.oncomplete = (event) => {
          resolve(result);
        };

        dbTransaction.onerror = (event) => {
          if (PkgConfig.blnIsInTestMode) {
            console.warn(`Error ${dbConnection.name} - ${dbTransaction.error}`);
          }
          resolve(false);
        };
      } catch (error) {
        if (PkgConfig.blnIsInTestMode) {
          console.error("*****ERROR*****", error);
        }
        resolve(false);
      }
    });
  }

  return { connectDB, create, update, findOne, findAll, cursor, cursorKey, destroy, findOneKey, findAllByCount, findAllKeys, count, clear };
}
