import React from "react";
import DefaultFile from "./icons/default_file.svg";

/**
 * @param {{imageName:string }} imageInfo
 */
export default function ShowIcon({ imageName, ...rest }) {
  let Comp = require(`./icons/${imageName}`)?.default ? require(`./icons/${imageName}`)?.default : DefaultFile;

  return <Comp {...rest} />;
}

/*
export default function ShowIcon({ imageName, ...rest }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [Image, setImage] = useState(DefaultFile);

  useEffect(() => {
      const fetchImage = async () => {
      try {
        const response = require(`./icons/${imageName}`); // change relative path to suit your needs
        setImage(response?.default ? response?.default : DefaultFile);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchImage();  
  }, [imageName]);

  
   if (loading) {
    return <DefaultFile {...rest} />;
  } else if (error) {
    return <DefaultFile {...rest} />;
  } else {
    return <Image {...rest} />;
  }  

 }

*/
