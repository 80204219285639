import { BtnGoToTop, Button, ClientDirection, Container, Grid, Paper, Typography, useNavigate } from "@cbmisorg/client-app";
import React, { useContext, useEffect } from "react";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import { AppContext } from "../../../../configuration/contextapi/AppContext";
import ShredHeader from "../../../sharedUi/header/ShredHeader";
import objAppRouting from "../../../../appHelper/routing/appRouting";
import * as appColor from "../../../../appHelper/appColor";
import { App_Server_Url_DownloadFiles } from "../../../../appHelper/appVariables";


//!IMPORTANT CHANGE item?.strLogoImg TO  appVariables.App_Server_Url_DownloadFiles + item?.strLogoImg?.[0]?.path
function Partners() {
  const { appState } = useContext(AppContext);
  const lang = appState?.clientInfo?.strLanguage || "arb";
  const labels = dictionary?.components?.public?.landingPage?.home?.partners;
  const navigate = useNavigate();

  useEffect(() => {
    ClientDirection.setDirection(lang === "arb" ? "rtl" : "ltr");
  }, [lang]);

  return (
    <React.Fragment>
      <ShredHeader title={labels?.title?.[lang]} lang={lang} />
      <Container>
        <Grid
          container
          sx={{
            background: "url(https://tahaurban.com/wp-content/uploads/2019/10/Partners-2.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "top center",
            minHeight: "250px",
          }}
        />
        <Grid container py-10 id="partners" px-0 spacing={0}>
          <Grid item xs="12" container justify="center">
            <Container py-10>
              <Grid container justify="center" spacing={0} pb-sm-8 pb-md-8 pb-lg-8 pb-xl-8>
                <Grid item xs="12" pt-0>
                  <Typography as="subtitle1">{labels?.subtitle?.[lang]}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} justify="center">
                <Grid item xs="12">
                  <Grid container justify={"center"} spacing={2}>
                    {
                      [...appState?.clientInfo?.objSystemInfo?.lstPartner]?.map((item, index) => {
                        return (
                          <Grid item key={index}>
                            <Paper title={item?.jsnPartnerInfo?.strPlaceName} onClick={()=>{if(item?.jsnPartnerInfo?.strURL){window.open(item?.jsnPartnerInfo.strURL,{traget:"_blank"})}}} sx={item?.jsnPartnerInfo?.strURL?{cursor:"pointer"}:{}}>
                              <img src={App_Server_Url_DownloadFiles + item?.strLogoImg?.[0]?.path} alt={item?.title}  height={"150px"} />
                            </Paper>
                          </Grid>
                        );
                      })
                    }
                  </Grid>
                </Grid>

                <Grid item xs="12">
                  <Button
                    label={labels?.btn?.[lang]}
                    color="primary"
                    sx={{ border: `2px solid ${appColor.App_orang_color} !important` }}
                    onClick={() => navigate(objAppRouting?.Public_Join_Partners?.url, { replace: true })}
                  />
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Container>
      <BtnGoToTop className={lang === "arb" ? "arbLang" : ""} />
    </React.Fragment>
  );
}

export default Partners;
