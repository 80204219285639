import { Alert, Confirmation } from "@cbmisorg/client-app";
import * as appFunctions from "../../../../../appHelper/appFunctions";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import { generateQueries } from "../../../../../appHelper/appQuery/GenerateQuery";
import objAppRouting from "../../../../../appHelper/routing/appRouting";
import { App_ID, App_Server_Url_DownloadFiles, aaiot_System_ID } from "../../../../../appHelper/appVariables";

const strPageInfo = "@src/component/public/advertisementSystem/view/contact/controler/CtrlRequested.js";

const tblUser = generateQueries("tblUser")
const tblSystem = generateQueries("tblSystem")

export const CtrlRequested = (appState, isLoading, setIsLoading, stateInfo, setStateInfo,navigate=null) => {
  const lang = appState?.clientInfo?.strLanguage;
  const bigSystemID = appState?.userInfo?.bigSystemID || aaiot_System_ID

  let handler = {
    getRequested: async () => {
      try {
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
          return;
        }

        setIsLoading(true);
        const result = await tblUser(strPageInfo,appState,"aaiotAppUserAll",
        {
          objectCondition:{
            bigSystemID:bigSystemID,
            blnIsActive:true,
            blnIsDeleted:false, 
            blnIsCompleteReg:true,
            blnIsApproved:true,
            blnIsPaySubscribed:false, 
          }
        },
        );
        if (!result?.blnIsRequestSuccessful) {
          appFunctions.logMessage(result?.response, "");
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cantFindRecords?.[lang],"warning")
          setIsLoading(false);
          return;
        }
        stateInfo.blnIsInitDone = true;
        let lstTmp = Array.isArray(result?.response) ? result?.response : [];

        stateInfo.listRequested = lstTmp
        setIsLoading(false);
        setStateInfo({ ...stateInfo });
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
        setIsLoading(false)
      }
    },
    viewRequested:async(item,appDispatch) => {
      try {
        if(item && !item?.jsnUserInfo?.blnIsSeen){
            if (isLoading) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
              return;
            }
            setIsLoading(true);

            const result = await tblUser(strPageInfo,appState,"aaiotAppUserUpdate",
            {
              objectInput:{ jsnUserInfo:{
                ...item?.jsnUserInfo,
                blnIsSeen:true
              } },
              objectCondition:{bigUserID:item?.bigUserID, bigSystemID:bigSystemID}
            },
            );

            if (!result?.blnIsRequestSuccessful) {
              appFunctions.logMessage(result?.response, "");
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Update_Record?.[lang],"warning")
              setIsLoading(false);
              return;
            }

            if(appState?.clientInfo?.objSystemInfo?.intCountSubscription){
              const resultRequestedIncCount = await tblSystem(strPageInfo,appState,"aaiotAppIncDecInt",
              {
                arrAttributes:["intCountSubscription"],
                objectCondition:{
                  bigAppID: App_ID ,
                  bigSystemID:appState?.userInfo?.bigSystemID,
                  blnIsActive:true,blnIsDeleted:false,
                  operation:"dec",
                },

              });

              if (!resultRequestedIncCount?.blnIsRequestSuccessful) {
                appFunctions.logMessage(result?.response, "");

                return;
              }
              appState.clientInfo.objSystemInfo.intCountRequestedToJoin = appState?.clientInfo?.objSystemInfo?.intCountRequestedToJoin -1
            }
            setIsLoading(false)
            setStateInfo({ ...stateInfo, blnIsInitDone: true });
            appDispatch()

          }
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
        setIsLoading(false)
      }
    },
    funCall:(item, type) => {
      if(type ==="email"){
        window.open(`mailto:${item?.strUserEmail}`)
      }else if(type ==="phone"){
        window.open(`tel:${item?.strUserPhone}`)
      }
    },
    funOpenImg:(imhPath,title) => {
      setStateInfo({
        ...stateInfo,
        modalOpen:true,
        modalType:"img",
        modalImgPath:App_Server_Url_DownloadFiles+imhPath,
        modalTitle:title

      })
    },
    funCloseModal:() => {
      setStateInfo({
        ...stateInfo,
        modalOpen:false,
        modalType:null,
        modalImgPath:null
      })
    },
    funApprove:(item)=>{
      const fun=async()=>{
        try {
          if(item){
              if (isLoading) {
                Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
                return;
              }
              setIsLoading(true);
  
              const result = await tblUser(strPageInfo,appState,"aaiotAppUserUpdate",
              {
                objectInput:{ 
                  blnIsPaySubscribed:true,
                  jsnPaySubscribed:{
                    dtmSubscribedActive: appFunctions.getDateUSFormat(appFunctions?.getCurrentDate()),
                    dtmSubscribedDeactivate: appFunctions.getDeactivateDate(appState?.clientInfo?.objSystemInfo?.jsnSystemConfig?.intSubscriptionMonthDuration)
                  },
                  jsnUserInfo:{
                    ...item?.jsnUserInfo,
                    strNote:null

                  }
              },
                objectCondition:{bigAppID: App_ID, bigUserID:item?.bigUserID, bigSystemID:bigSystemID}
              },
              );
  
              if (!result?.blnIsRequestSuccessful) {
                appFunctions.logMessage(result?.response, "");
                Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Update_Record?.[lang],"warning")
                setIsLoading(false);
                return;
              }
              setIsLoading(false)
              setStateInfo({ ...stateInfo, blnIsInitDone: true });
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
              navigate(objAppRouting?.Manage_RequestActive?.url, { replace: true })
          }
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
          setIsLoading(false)
        }
      }

      Confirmation.viewConfirmation(
        dictionary?.shared?.confirmationMsg?.approveActive?.[lang],
        fun,
        () => null,
        dictionary?.shared?.confirmationMsg?.yes?.[lang],
        dictionary?.shared?.confirmationMsg?.no?.[lang]
      );
    },
    funReSubmit:async(item,fieldNote)=>{
      try {
        if(item){
            if (!fieldNote.isFormValid()) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang],"")
              return;
            }
            if (isLoading) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
              return;
            }
            setIsLoading(true);

            const strNoteValue= fieldNote?.strNote?.getValue() || null
            if(strNoteValue){
              if(!strNoteValue?.arb && strNoteValue?.eng){
                strNoteValue.arb = strNoteValue?.eng
              }
              if(strNoteValue?.arb && !strNoteValue?.eng){
                strNoteValue.eng = strNoteValue?.arb
              }
            }
            const result = await tblUser(strPageInfo,appState,"aaiotAppUserUpdate",
            {
              objectInput:{ 
                jsnUserInfo:{
                ...item?.jsnUserInfo,
                strNote: strNoteValue,
                blnIsSeen:false
                },
                blnIsCompleteReg:false,
                blnIsPaySubscribed:false
            },
              objectCondition:{bigAppID: App_ID, bigUserID:item?.bigUserID, bigSystemID:bigSystemID}
            },
            );

            if (!result?.blnIsRequestSuccessful) {
              appFunctions.logMessage(result?.response, "");
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Update_Record?.[lang],"warning")
              setIsLoading(false);
              return;
            }
            setIsLoading(false)
            setStateInfo({ ...stateInfo, blnIsInitDone: true });
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
            navigate(objAppRouting?.Manage_RequestActive?.url, { replace: true })
        }
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
        setIsLoading(false)
      }
    }
  };

  return handler;
};
