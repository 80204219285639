import React, { useContext } from "react";
import ShredHeader from "../../sharedUi/header/ShredHeader";
import { Container, Grid, useParams } from "@cbmisorg/client-app";
import { dictionary } from "../../../appHelper/appLanguage/dictionary";
import { AppContext } from "../../../configuration/contextapi/AppContext";
import { App_Server_Url_DownloadFiles } from "../../../appHelper/appVariables";

const vedioLink = "https://static-media-project.s3.eu-central-1.amazonaws.com/aaiot/fullstack.mp4";
const label = dictionary?.components?.public?.vedioTraining
function TrainingVideo() {
  const { appState } = useContext(AppContext);
  const lang = appState?.clientInfo?.strLanguage;
  const {strTrainingType} = useParams()
  const strTrainingTypeUrlVedio = appState?.clientInfo?.objSystemInfo?.jsnSystemConfig?.objTrainingSponserConfig?.[String(strTrainingType).toLowerCase()]?.lstVideo?.[0]

  return (
    <React.Fragment>
      <ShredHeader title={strTrainingTypeUrlVedio?.strVedioTitle?.[lang] ||label?.title?.[lang]}/>
      <Container>
        <Grid container justify={"center"} spacing={0} py-3>
          <Grid item xs="12">
            <video controls className="videoTraining">
              <source src={strTrainingTypeUrlVedio ?App_Server_Url_DownloadFiles + strTrainingTypeUrlVedio?.path: vedioLink} type="video/mp4" />
            </video>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default TrainingVideo;
