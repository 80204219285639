import { Alert, Confirmation } from "@cbmisorg/client-app";
import * as appFunctions from "../../../../../appHelper/appFunctions";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import { generateQueries } from "../../../../../appHelper/appQuery/GenerateQuery";
import { App_ID, aaiot_System_ID, globalKeyZero } from "../../../../../appHelper/appVariables";

const strPageInfo = "@src/components/stakeholders/admin/mngReqTraining/controler/CtrlReqTraining.js";

const tblJoinSymposium = generateQueries("tblJoinSymposium")

export const CtrlReqTraining = (appState, isLoading, setIsLoading, stateInfo, setStateInfo,navigate=null) => {
  const lang = appState?.clientInfo?.strLanguage;
  const bigSystemID = appState?.userInfo?.bigSystemID || aaiot_System_ID

  let handler = {
    getMembers: async () => {
      try {
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
          return;
        }

        setIsLoading(true);
        const result = await tblJoinSymposium(strPageInfo,appState,"aaiotAppFindAll",
        {
          arrAttributes:["bigAppID","bigSystemID","bigUserID","blnIsActive","blnIsApproved","blnIsCompletedReq","dtmCreatedDate","jsnUserInfo"],
          objectCondition:{
            bigSystemID:bigSystemID,
            blnIsActive:true,
            blnIsDeleted:false,
            blnIsCompletedReq:true,
          }
        },
        );
        if (!result?.blnIsRequestSuccessful) {
          appFunctions.logMessage(result?.response, "");
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cantFindRecords?.[lang],"warning")

          setIsLoading(false);
          return;
        }

        stateInfo.blnIsInitDone = true;
        let lstTmp = Array.isArray(result?.response) ? result?.response : [];

        stateInfo.listMembers = lstTmp
        stateInfo.listReadMembers = lstTmp
        setIsLoading(false);
        setStateInfo({ ...stateInfo });
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
        setIsLoading(false)
      }
    },
    searchMember: (e)=>{
      const value = e.target.value;
      if(!value){
        stateInfo.listReadMembers = stateInfo.listMembers
      }else{
        stateInfo.listReadMembers = stateInfo.listMembers.filter(item=>String(item?.jsnUserInfo?.strFullName).startsWith(value) || String(item?.strUserPhone).startsWith(value) || String(item?.strUserEmail).startsWith(value))
      }
      setStateInfo({...stateInfo})
    },
    funDelete:(item)=>{
      const fun=async()=>{
        try {
          if(item){
              if (isLoading) {
                Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
                return;
              }
              setIsLoading(true);
  
              const result = await tblJoinSymposium(strPageInfo,appState,"aaiotAppUpdate",
              {
                objectInput:{ blnIsActive:false, blnIsDeleted:true, dtmUpdatedDate: new Date().toISOString(), },
                objectCondition:{bigAppID: App_ID, bigUserID:item?.bigUserID, bigSystemID:bigSystemID}
              },
              );
  
              if (!result?.blnIsRequestSuccessful) {
                appFunctions.logMessage(result?.response, "");
                Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Update_Record?.[lang],"warning")

                setIsLoading(false);
                return;
              }
              setIsLoading(false)
              stateInfo.listMembers = stateInfo?.listMembers.filter(ele=>ele?.bigUserID !== item?.bigUserID)
              stateInfo.listReadMembers= stateInfo.listMembers
              setStateInfo({ ...stateInfo, blnIsInitDone: true });
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
          }
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
          setIsLoading(false)
        }
      }

      Confirmation.viewConfirmation(
        dictionary?.shared?.confirmationMsg?.deletemember?.[lang],
        fun,
        () => null,
        dictionary?.shared?.confirmationMsg?.yes?.[lang],
        dictionary?.shared?.confirmationMsg?.no?.[lang]
      );
    },
    funSave:async(fields,item)=>{
      try {
        if (!fields.isFormValid()) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang],"")
          return;
        }
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
          return;
        }
        setIsLoading(true);

        const result = await tblJoinSymposium(strPageInfo,appState,"aaiotAppUpdate",
        {
          objectInput:{

            strUserPhone: globalKeyZero + fields?.strPhone?.getValue(),
            blnIsActive:fields?.blnIsActive?.getValue()?.key==="1",
            dtmUpdatedDate: new Date().toISOString(),
            jsnUserInfo: {
              ...item?.jsnUserInfo,
              strFullName:fields?.strFullName?.getValue(),
              bigNationality:fields?.bigNationality?.getValue()?.key,
              strMajor:fields?.strMajor?.getValue(),
              bigEmploymentID:fields?.bigEmploymentID?.getValue()?.key,
              bigDegreeID:fields?.bigDegreeID?.getValue()?.key,
              bigGender:fields?.bigGender?.getValue()?.key,
              dtmDob:fields?.dtmDob?.getValue(),
              strWorkPlace:fields?.strWorkPlace?.getValue(),
            },
          },
          objectCondition:{
            bigAppID: App_ID,
            bigSystemID: item?.bigSystemID || bigSystemID,
            bigUserID:item?.bigUserID,
          }
        });
        if (!result?.blnIsRequestSuccessful) {
          appFunctions.logMessage(result?.response, "");
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Update_Record?.[lang], "warning")
          setIsLoading(false);
          return;
        }
        setIsLoading(false)
        setStateInfo({ ...stateInfo, blnIsInitDone: true });
        Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
    } catch (error) {
      appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
      setIsLoading(false)
    }
    },
    funCall:(item, type) => {
      if(type ==="email"){
        window.open(`mailto:${item?.strUserEmail}`)
      }else if(type ==="phone"){
        window.open(`tel:${item?.strUserPhone}`)
      }
    },
    funCloseModal:() => {
      setStateInfo({
        ...stateInfo,
        modalOpen:false,
        modalEmailOpen:false
      })
    },

  };

  return handler;
};
