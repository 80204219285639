import { Alert, ClientFunction } from "@cbmisorg/client-app";
import * as appFunctions from "../../../../appHelper/appFunctions";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import objAppRouting from "../../../../appHelper/routing/appRouting";
import { generateQueries } from "../../../../appHelper/appQuery/GenerateQuery";
import { App_ID, globalKeyZero, objUploadType, objUserRole, requirementFlow } from "../../../../appHelper/appVariables";

const strPageInfo = "@src/components/public/login/controller/CtrlLogin.js";
const tblUser = generateQueries("tblUser")
const tblSystem = generateQueries("tblSystem")

export const CtrlJoinRequest = (appState, appDispatch, isLoading, setIsLoading, navigate, stateInfo, setStateInfo) => {
  const lang = appState?.clientInfo?.strDataLanguage ||  appState?.clientInfo?.strLanguage;
  const objSystemInfo = appState?.clientInfo?.objSystemInfo;
  // const jsnLoginConfig = objSystemInfo?.jsnLoginConfig;

  return {
    addRequested:
    async (fields,uploaderGovID,uploaderRest) => {
      try {
        if (!fields.isFormValid()) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang],"")
          return;
        }
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
          return;
        }
        setIsLoading(true);
        const resultFindEmail = await tblUser(strPageInfo,appState,"aaiotAppFindOne",
        {
          arrAttributes:["bigUserID"],
          objectCondition:{
            bigAppID: App_ID,
            bigSystemID: appState?.userInfo?.bigSystemID,
            strUserEmail: String(fields?.strEmail?.getValue()).trim(" "),
          }
        });

        if(resultFindEmail.blnIsRequestSuccessful && typeof resultFindEmail?.serverResponse === "object"){
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.email_Already_Used?.[lang])
          setIsLoading(false)
          return
        }

        let bigID = ClientFunction.generateID(10);

        // const jsnSystemConfig= appState?.objSystemInfo?.jsnSystemConfig
        const govIDFile =  await uploaderGovID?.uploadHandler(objUploadType?.payment)
        const receiptFile = await uploaderRest?.uploadHandler(objUploadType?.payment)
        const result = await tblUser(strPageInfo,appState,"aaiotAppUserAdd",
        {
          objectInput:{
            bigAppID: App_ID,
            bigSystemID: appState?.userInfo?.bigSystemID,
            bigUserID:bigID,

            strUserEmail: fields?.strEmail?.getValue(),
            strUserPhone: globalKeyZero + fields?.strPhone?.getValue(),
            strUserPassword: fields?.strUserPassword?.getValue(),

            jsnUserInfo: {
              strFullName:fields?.strFullName?.getValue(),
              bigNationality:fields?.bigNationality?.getValue()?.key,
              strMajor:fields?.bigMajorID?.getValue(),
              bigEmploymentID:fields?.bigEmploymentID?.getValue()?.key,
              bigDegreeID:fields?.bigDegreeID?.getValue()?.key,
              bigGender:fields?.bigGender?.getValue()?.key,
              dtmDob:fields?.dtmDob?.getValue(),
              strWorkPlace:fields?.strWorkPlace?.getValue(),
              intUserRoleFK: objUserRole?.member,
              strGovIDFilePath:govIDFile && stateInfo?.listFileRestID?.[0]?`${objUploadType?.aaiot?.fileType}/${objUploadType?.payment}/${stateInfo?.listFileGovID?.[0]?.name}`:null,
              strReceiptFilePath:receiptFile && stateInfo?.listFileRestID?.[0]?`${objUploadType?.aaiot?.fileType}/${objUploadType?.payment}/${stateInfo?.listFileRestID?.[0]?.name}`:null,
            },
            blnIsCompleteReg :govIDFile &&  receiptFile && objSystemInfo?.jsnSystemConfig?.bigFlowID !== requirementFlow.requirementInfoAfterReg,
          }
        });
        if (!result?.blnIsRequestSuccessful) {
          appFunctions.logMessage(result?.response, "");
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang],"warning")
          return;
        }

        if(result?.response?.blnIsCompleteReg){
          const resultJoinIncCount = await tblSystem(strPageInfo,appState,"aaiotAppIncDecInt",
          {
            arrAttributes:["intCountRequestedToJoin"],
            objectCondition:{
              bigAppID: App_ID ,
              bigSystemID:appState?.userInfo?.bigSystemID,
              blnIsActive:true,blnIsDeleted:false,
            },
          });

          if (!resultJoinIncCount?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            return;
          }
        }

        setIsLoading(false);
        fields.resetField()
        Alert.viewAlert(dictionary?.shared?.alertFetchNote?.added_Successfully?.[lang], "success");
        appState.clientInfo.blnIsUserLoggedIn=true
        appState.userInfo=result.response
        appDispatch()
        if(objSystemInfo?.jsnSystemConfig?.bigFlowID === requirementFlow.requirementInfoAfterReg){
          navigate(objAppRouting?.RequirementInfo?.url, { replace: true })
        }else{
          navigate(objAppRouting?.Waiting?.url, { replace: true })
        }
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "addCategory-ERROR", error);
        setIsLoading(false);
      }
    },
  };
};
