import { useNavigate, useParams } from "@cbmisorg/client-app";
import objAppRouting from "../../../appHelper/routing/appRouting";
import React, { useEffect } from "react";
import ViewJoinPartner from "./view/ViewJoinPartner";

function JoinPartner() {
  const navigate = useNavigate();
  const { trainingType } = useParams();

  useEffect(() => {}, [trainingType]);

  if (trainingType) {
    return <ViewJoinPartner />;
  } else {
    navigate(objAppRouting?.Public_LandingPage?.url);
    return;
  }
}

export default JoinPartner;
