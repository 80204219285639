import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../../configuration/contextapi/AppContext';
import { Container, Grid, Icon, MenuItem, Typography,Loader, useNavigate, Badge, Button, } from '@cbmisorg/client-app';
import { App_Primary_Color, App_Text_Color } from '../../../../appHelper/appColor';
import { CtrlRequested } from './controler/CtrlRequested';
import ShredHeader from '../../../sharedUi/header/ShredHeader';
import { dictionary } from '../../../../appHelper/appLanguage/dictionary';
import objAppRouting from '../../../../appHelper/routing/appRouting';
import NoResultImg from '../../../sharedUi/noResultImg/NoResultImg';

const labels = dictionary?.components?.mnageRequestJoin
function RouteMngRequesteAaitoSubscrip() {
  const navigate = useNavigate()
  const { appState } = useContext(AppContext);
  const lang = appState.clientInfo.strLanguage
  const [isLoading, setIsLoading] = useState(false)
  const [stateInfo, setStateInfo] = useState({
    blnIsInitDone:false,
    listRequested:[],
  })

  const handlers = CtrlRequested(appState,isLoading, setIsLoading,stateInfo, setStateInfo);

  useEffect(() => {
    if(!stateInfo?.blnIsInitDone){
      handlers?.getRequested()
    }
  }, [])


  return (
    <React.Fragment>
      <ShredHeader title={labels?.requirementTitle?.[lang]} lang={lang} blnBottomBar={false}/>
      <Container py-3>
      {
        isLoading?<Loader backdrop={true} color={App_Primary_Color}/>:null
      }
        <Grid container justify={'center'}>
          {
            !stateInfo?.listRequested?.length?(
              <NoResultImg />
            ):
            stateInfo?.listRequested?.map((item,index)=>{
              return(
                <Grid item xs="12" key={index}>
                <MenuItem sx={{width:"100%"}} 
                    headItem={item?.jsnUserInfo?.blnIsSeen===true?<Icon icon={"AccountBox"} pt-1 color="#aaa"/>:<Badge mode='dot' sx={{background:"green !important"}}><Icon icon={"AccountBox"} pt-1 color={"primary"}/></Badge>}
                      outlined
                    >
                      <Grid container justify={'space-between'}>
                        <Grid item container spacing={0}>
                          <Grid item xs="12" pb-0>
                            <Typography as="subtitle2" pb-0 color={item?.jsnUserInfo?.blnIsSeen?App_Text_Color:""}>{item?.jsnUserInfo?.strFullName}</Typography>
                          </Grid>
                        </Grid>
                        <Grid item px-0>
                          <Button icon="visibility" p-0 onClick={()=>navigate(objAppRouting?.View_RequestActive?.url,{state:{selected:item}})}/>
                        </Grid>
                      </Grid>
                    </MenuItem>
                </Grid>
              )
            })
          }
        </Grid>
      </Container>

    </React.Fragment>
  )
}

export default RouteMngRequesteAaitoSubscrip