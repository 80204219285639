import React, { useContext } from "react";
import noResult from "../../../assets/images/noResult.svg";
import noMessage from "../../../assets/images/noMessage.svg";
import { AppContext } from "../../../configuration/contextapi/AppContext";
import { Grid, Typography } from "@cbmisorg/client-app";

/**
 *
 * @param {{lg?, md?, xs?, justify?, width?, jsnLabel?, type?: "data" | "message" | "initiative"}} param0
 */
export default function NoResultImg({ lg = 12, md = 12, xs = 12, justify = "center", width = "100%", jsnLabel = null, type = "data" }) {
  const { appState } = useContext(AppContext);
  let objMessage = { 
      message: { eng: "There are no emails!", arb: "لا يوجد بريد وارد" }, 
      subscription: { eng: "There are no subscription!", arb: "لا يوجد اشتركات حتى الآن" }, 
      data: { arb: "لا يوجد نتائج حتى الآن", eng: "No Data Found" },
      initiative : { arb: "لا يوجد مبادرات حتى الآن", eng: "No initiative Found" },
    };

  return (
    <>
      <Grid container justify={justify} pt-5>
        <Grid item lg={lg} md={md} xs={xs} p-0 m-0>
          <Grid container>
            <Grid item p-0 m-0 xs={12} justify="center">
              <Typography as="subtitle2" sx={{ color: "var(--cbmis-secondary)", textAlign: "center !important" }} pt-4>
                {jsnLabel ? jsnLabel?.[appState?.clientInfo?.strLanguage] : objMessage?.[type]?.[appState?.clientInfo?.strLanguage]}
              </Typography>
            </Grid>
            <Grid item p-0 m-0 xs={12} justify="center" pt-3>
              <img src={type === "data" ? noResult : type === "message" || type === "subscription" ? noMessage : noResult} alt="" style={{maxHeight:"250px", maxWidth:"250px"}}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
