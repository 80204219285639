
import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, Typography, Container, Paper, Line, useNavigate, Loader } from '@cbmisorg/client-app';
import ShredHeader from '../../sharedUi/header/ShredHeader';
import { AppContext } from '../../../configuration/contextapi/AppContext';
import { FormData, FormField } from '../../../appHelper/formApi/FormAPI';
import { globalKey, objUploadType, requirementFlow, workSectorID } from '../../../appHelper/appVariables';
import { dictionary } from '../../../appHelper/appLanguage/dictionary';
import { CtrlJoinToSymposium } from './controller/CtrlJoinToSymposium';
import { appStyle } from '../../../appHelper/appStyle';
import useCheckSystemInfo from '../../../appHelper/appHook/useCheckSystemInfo';
import RequirementInfo from '../../sharedUi/requirementInfo/RequirementInfo';
import Uploader from '../../sharedUi/uploader/Uploader';
import { App_Primary_Color, App_Text_Color } from '../../../appHelper/appColor';

const labels = dictionary?.components?.joinSymposium
function JoinToSymposium() {
	useCheckSystemInfo()
	const navigate = useNavigate()
	const {appState, appDispatch} = useContext(AppContext)
	const appLanguage = appState?.clientInfo?.strLanguage || "arb"
    const [isLoading, setIsLoading] = useState(false)
	const [state,setState] = useState({
		listFileGovID:[],
		listFileRestID:[],
	})
	const uploaderGovID = Uploader(state,setState,"listFileGovID", dictionary?.components?.manageSystem?.govID, {blnIsMultiple: false, blnForceNewName: true, fileType: objUploadType?.["aaiot"].id,},"Badge")
	const uploaderRest = Uploader(state,setState,"listFileRestID", dictionary?.components?.manageSystem?.rest, {blnIsMultiple: false, blnForceNewName: true, fileType: objUploadType?.["aaiot"].id, },"Description")

	const fields = FormData({
        strFullName: {
          strControl: "text",
          objLanguage: false,
          objValidations: { required: true },
        },
        strEmail: {
            strControl: "text",
            objLanguage: false,
            objValidations: { required: true , email: true},
        },
        strPhone: {
            strControl: "text",
            objLanguage: false,
            objValidations: { required: true , onlyNumbers:true, minLength:{condition:8}, maxLength:{condition:8}},
        },
		strPositionTitle: {
            strControl: "text",
            objLanguage: false,
            objValidations: { required: true },
        },
        bigWorkSector: {
            strControl: "list",
            objLanguage: false,
            objValidations: { required: true },
			lstOption:[
				{key:workSectorID?.academic, value:dictionary?.components?.joinSymposium?.academic?.[appLanguage]},
				{key:workSectorID?.gov, value:dictionary?.components?.joinSymposium?.gov?.[appLanguage]},
				{key:workSectorID?.prvt, value:dictionary?.components?.joinSymposium?.prvt?.[appLanguage]},
			]
        },
		strWorksPlace: {
            strControl: "text",
            objLanguage: false,
            objValidations: { required: true },
        },
      });

	const handlers = CtrlJoinToSymposium(appState, appDispatch ,isLoading, setIsLoading, navigate, state,setState);


	function handleSubmit() {
		handlers.addRequested(fields,uploaderGovID,uploaderRest)
	  }

	  useEffect(() => {
        if(appState?.cacheInfo?.objSymposiumInfo?.strEmail && appState?.cacheInfo?.objSymposiumInfo?.bigID ){
			handlers?.getRequested()
        }
    }, [])

	return (
		<React.Fragment>
    		<ShredHeader title={labels?.titleJoin?.[appLanguage]} lang={appLanguage}  blnBottomBar={false}/>
			<Container py-5 pb-xs-10 mb-xs-10 pb-sm-10 mb-sm-10 >
				{
				isLoading?<Loader backdrop={true} color={App_Primary_Color}/>:null
			  	}
				<Grid container justify={'center'} pb-xs-10 mb-xs-10>
					<Grid item xs="12" md="10">
						<Paper p-0 elevation={4} outline={1} sx={{borderRadius:"8px !important", borderColor:"var(--cbmis-dark)"}} className="cardContainer">
							<Grid container justify={'center'} pt-3>
								<Grid item xs="12" >
									<Typography as="body2" color={App_Text_Color} sx={{fontWeight:"700 !important",fontFamily:"Times New Roman !important",textAlign:"center !important"}}>{
										labels?.thank?.[appLanguage]
									}</Typography>
								</Grid>
							</Grid>
							<Line py-3/>
							<Grid container spacing={2} justify={'center'}>
								<Grid item xs={12}>
									<FormField objHandler={fields.strFullName} icon="AccountCircle" />
								</Grid>
								<Grid item xs={12}>
									<FormField objHandler={fields.strEmail} icon="email" className={`inputLeft inputLeft${appLanguage}`}/>
								</Grid>
								<Grid item xs={12}>
									<FormField objHandler={fields.strPhone} icon={appLanguage==="arb"?"phone":null} endIcon={appLanguage!=="arb"?"phone":null} endAdornment={appLanguage==="arb"?globalKey:null} adornment={appLanguage!=="arb"?globalKey:null}  className={`inputLeft`} />
								</Grid>
								<Grid item xs={12} md="4">
									<FormField objHandler={fields.strPositionTitle} icon="Badge" dir={appLanguage === "arb"?"rtl":"ltr"}/>
								</Grid>
								<Grid item xs={12} md="4">
									<FormField objHandler={fields.bigWorkSector} icon="Work" dir={appLanguage === "arb"?"rtl":"ltr"}/>
								</Grid>
								<Grid item xs={12} md="4">
									<FormField objHandler={fields.strWorksPlace} icon="business" dir={appLanguage === "arb"?"rtl":"ltr"}/>
								</Grid>
							</Grid>
							<Line py-3/>
							{
								appState?.clientInfo?.objSystemInfo?.jsnSystemConfig?.bigFlowID === requirementFlow.requirementInfoDuringReg &&(
									<React.Fragment>
										<Grid container spacing={4}>
											<Grid item xs={12}>
												<Typography as="subtitle1" sx={appStyle?.title1}>{dictionary?.components?.manageSystem?.requirementTitle?.[appLanguage]}</Typography>
											</Grid>
											<RequirementInfo
													objSystemInfo={appState?.clientInfo?.objSystemInfo}
													lang={appLanguage}
													uploaderGovID={uploaderGovID}
													uploaderRest={uploaderRest}/>
										</Grid>
										<Line py-3/>
									</React.Fragment>
								)
							}
							<Grid container justify={'center'}>
								<Grid item xs="12" pt-5 pb-8>
									<Button py-0 px-10
											label={labels?.btnReg?.[appLanguage]}
											onClick={handleSubmit}
										/>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			</Container>
		</React.Fragment>
	);
}

export default JoinToSymposium;

