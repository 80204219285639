import React, { useContext, useEffect, useState } from "react";
import { Button, Grid, Typography, Container, Paper, Line, useNavigate, Loader, useParams } from "@cbmisorg/client-app";
import ShredHeader from "../../../sharedUi/header/ShredHeader";
import { AppContext } from "../../../../configuration/contextapi/AppContext";
import { FormData, FormField } from "../../../../appHelper/formApi/FormAPI";
import { globalKey, objUploadType, workSectorID } from "../../../../appHelper/appVariables";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import { CtrlJoinPartner } from "../controller/CtrlJoinPartner";
import { appStyle } from "../../../../appHelper/appStyle";
import useCheckSystemInfo from "../../../../appHelper/appHook/useCheckSystemInfo";
import Uploader from "../../../sharedUi/uploader/Uploader";
import { getNextStrURL } from "../../../../appHelper/appFunctions";
import { App_Primary_Color, App_Text_Color } from "../../../../appHelper/appColor";
import useCheckCat from "../../../../appHelper/appHook/useCheckCat";
import objAppRouting from "../../../../appHelper/routing/appRouting";

function ViewJoinPartner() {
  const { getNewSystemInfo } = useCheckSystemInfo();
  const { getNewCatInfo } = useCheckCat();


  const searchParams = new URL(window.location.href);
  const extLang = searchParams.searchParams.get("lang") || null;
  const navigate = useNavigate();
  const { trainingType } = useParams();
  const { appState, appDispatch } = useContext(AppContext);
  const appLanguage = appState?.clientInfo?.strLanguage || "arb";
  const objSystemConfig = appState?.clientInfo?.objSystemInfo?.jsnSystemConfig;
  const labels = dictionary?.components?.public?.join;
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    blnIsInitDone: false,
    listLogo: [],
  });

  const uploaderLogo = Uploader(state, setState, "listLogo", "", {
    fileType: objUploadType.aaiot.id,
    blnIsMultiple: false,
    blnForceNewName: true,
    title: labels?.logoNote?.[appLanguage],
    file: labels?.logo?.[appLanguage],
  });

  const fields = FormData({
    strFullName: {
      strControl: "text",
      objLanguage: false,
      objValidations: { required: true },
    },
    strEmail: {
      strControl: "text",
      objLanguage: false,
      objValidations: { required: true, email: true },
    },
    strPhone: {
      strControl: "text",
      objLanguage: false,
      objValidations: { required: true, onlyNumbers: true, minLength: { condition: 8 }, maxLength: { condition: 8 } },
    },
    bigWorkSector: {
      strControl: "list",
      objLanguage: false,
      objValidations: { required: true },
      lstOption: [
        { key: workSectorID?.gov, value: dictionary?.components?.joinSymposium?.gov?.[appLanguage] },
        { key: workSectorID?.prvt, value: dictionary?.components?.joinSymposium?.prvt?.[appLanguage] },
      ],
    },
    strPlaceName: {
      strControl: "text",
      objLanguage: false,
      objValidations: { required: true },
    },
    strUserPassword: {
      strControl: "password",
      objLanguage: false,
      objValidations: { required: true, minLength: { condition: 6 } },
    },
    strConfirmPassword: {
      strControl: "password",
      objLanguage: false,
      objValidations: { required: true, minLength: { condition: 6 }, equal: { condition: "strUserPassword" } },
    },
  });


  const handlers = CtrlJoinPartner({appState, appDispatch, isLoading, setIsLoading, navigate, state, setState, trainingType});

  function handleSubmit() {
    handlers.addPartnered(fields, uploaderLogo);
  }

  useEffect(() => {
    if (appState?.clientInfo?.blnIsUserLoggedIn) {
      navigate(objAppRouting?.Account?.url, { replace: true });
    } else {
      if (!state?.blnIsInitDone) {
        handlers.initData({checkCatHookValidity: getNewCatInfo, checkSysHookValidity: getNewSystemInfo, fields });
      }
      handlers.countRegSponsorClk();
    }
    if (extLang) {
      appState.clientInfo.strLanguage = extLang;
      appState.clientInfo.strDataLanguage = extLang;
      appDispatch();
    }
  }, []);


  return (
    <React.Fragment>
      <ShredHeader title={labels?.partnerTitle?.[appLanguage]} lang={appLanguage} blnBottomBar={false} />
      <Container py-5 pb-xs-10 mb-xs-10 pb-sm-10 mb-sm-10>
        {isLoading ? <Loader backdrop={true} color={App_Primary_Color} /> : null}
        <Grid container justify={"center"} pb-xs-10 mb-xs-10>
          <Grid item xs="12" md="10">
            <Paper p-0 elevation={4} outline={1} sx={{ borderRadius: "8px !important", borderColor: "var(--cbmis-dark)" }} className="cardContainer">
              <Grid container justify={"center"} pt-3>
                <Grid item xs="12">
                  <Typography as="subtitle2" color={App_Primary_Color} className="textCenter">
                    {labels?.[String(trainingType).toLowerCase()]?.[appLanguage]}
                  </Typography>
                </Grid>
                <Grid item xs="12">
                  <Typography as="caption" color={App_Text_Color} sx={{fontWeight: "500 !important", fontFamily: "Times New Roman !important", textAlign: "center !important" }} className="textCenter">
                    {labels?.partnerSubtitle?.[appLanguage]}
                  </Typography>
                </Grid>
              </Grid>
              <Line py-3 />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography as="body2" sx={appStyle?.title1}>
                    {labels?.partnerInfo?.[appLanguage]}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md="6">
                  <FormField objHandler={fields.bigWorkSector} icon="Work" dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                </Grid>
                <Grid item xs={12} md="6">
                  <FormField objHandler={fields.strPlaceName} icon="business" dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                </Grid>
                <Grid item xs={12}>
                  <FormField objHandler={fields.strFullName} icon="AccountCircle" />
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    objHandler={fields.strPhone}
                    icon={appLanguage === "arb" ? "phone" : null}
                    endIcon={appLanguage !== "arb" ? "phone" : null}
                    endAdornment={appLanguage === "arb" ? globalKey : null}
                    adornment={appLanguage !== "arb" ? globalKey : null}
                    className={`inputLeft`}
                  />
                </Grid>
              </Grid>
              <Line py-3 />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography as="body2" sx={appStyle?.title1}>
                    {labels?.registrationInfo?.[appLanguage]}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} justify={"center"}>
                <Grid item xs={12}>
                  <FormField objHandler={fields.strEmail} icon="email" className={`inputLeft inputLeft${appLanguage}`} />
                </Grid>
                <Grid item xs={12} md="6">
                  <FormField objHandler={fields.strUserPassword} icon="vpnKey" className={`inputLeft`} dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                </Grid>
                <Grid item xs={12} md="6">
                  <FormField objHandler={fields.strConfirmPassword} icon="vpnKey" className={`inputLeft`} dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                </Grid>
              </Grid>
              <Grid container justify={"center"} spacing={2}>
                <Grid item xs="12" pt-5 pb-8>
                  <Button py-0 px-10 label={labels?.btnPartner?.[appLanguage]} onClick={handleSubmit} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default ViewJoinPartner;
